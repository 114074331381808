@use "sass:math";

@import '_pdpv2_drawers.scss';
@import '_quickviewv2.scss';
@import '_reviewsv2.scss';
@import '_pdpv2_bonusProducts.scss';
@import '../../../sass/partials/header/_wishlist-merge.scss';

// Default out any vars used in this partial
$white: $white !default;
$search-refinement-color: $white !default;
$refinement-hover: #241fff !default;
$icon-size-remember: 20px !default;
$icon-size-chat: 22px !default;
$icon-size-share: 20px !default;

.pdp-main.pdpv2 {
  margin-top: 50px;

  &.discontinued {
    .pdp-drawer.recommended {
      display: none;
    }
  }
  .pdp-above-fold {
    margin-bottom: 49px;
  }

  .alternate-views {
    position: absolute;
    bottom: 22px;
    width: 100%;

    h2 {
      font-size: 12px;
      margin: 0;
    }
  }

  .no-print .content-asset {
    position:relative;
    left: 50%;
    transform:translateX(-50%);
    text-align: center;
  }

  .promotion-callout {
    padding: 20px;
    text-align: center;
  }

  .product-col-1.product-hero-image {
    width: 617px;
    margin: 0;

    .product-primary-image {
      height: 524px;
      border-radius: 0;
      margin: 0;
      overflow: hidden;
    }

    .main-product-slider {
      width: 447px;
      margin: 55px auto 0;
    }

    li.thumb { position: relative; }

    .flex-control-paging {
      left: 0;
      bottom: 49px;

      &:hover {
        padding-top: 74px;
      }

      li a {
        border-radius: 100%;
        background: $mainbordercolor;
        height: 10px;
        width: 10px;

        &.flex-active {
          background: $primarylink;
        }
      }
    }

    .flex-direction-nav {
      a {
        opacity: 1;
        transform: translateY(-50%);
        text-indent:999px;

        &::before {
          content: unset;
        }
      }

      .flex-prev {
        @include sprite($arrow-left);
        left: 20px;
      }
      .flex-next {
        @include sprite($arrow-right);
        right: 20px;
      }
    }

    .pdp-zoom-button {
      position: absolute;
      left: 22px;
      bottom: 19px;
      z-index: 100;
      text-indent: -999em;
      @include sprite($pdp-zoom,2.5);
    }

    #thumbnails:hover {
      display: block !important;
    }

    #thumbnails {
      background-color: transparent;
      margin: 0 auto;
      position: absolute;
      left: 0px; right: 0px;
      height: 65px;
      overflow: hidden;
      padding: 0px;
      bottom: 70px;
      z-index: 1;

      ul {
        width: 321px; // 5 tiles;
        margin: 0 auto;
        overflow: hidden;
        font-size: 0px;
        white-space: nowrap;
        height: auto;
        padding-bottom: 1px;
        display: flex;

        &.no-animate {
          justify-content: center;
          width:auto;
        }

        #QuickViewDialog & {
          width: 257px; // 4 tiles when in QV
        }
      }

      li {
        width: 64px;
        height: 64px;
        margin: 0;
        padding: 0;
        background-color: white;
        border: 0px;
        float: none;
        display: inline-block;
        transition: left .2s ease-out;

        a {
          display: block;
          border: $borderMixin;
          width: 65px;
          height: 65px;
          overflow: hidden;
          box-sizing: border-box;
        }

        &.selected a {
          border: 2px solid $primarylink;
          position: relative;
          z-index: 1;
        }
      }
    }
  }

  .product-thumbnails {
    .play-icon {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      .productthumbnail {
        position: relative;
        top:50%;
        margin-top: -20px;
      }
    }
    .play-icon-button {
      z-index: 2;
    }
  }

  .product-actions .remember-item.enabled {
    margin-left: 0;
  }

  .product-col-1.product-set {
    .social-media-icons {
      float: left;
      margin: 22px 0 0 0;
    }
  }

  .product-col-2.product-info, .product-col-2.product-set {
    float: right;
    width: 263px;
    z-index: 10;

    .product-price {
      text-align: center;
      margin: 0;
    }

    h1.product-name-v2 {
      margin: 0 0 3px;
      text-align: center;
    }

    .reviews {
      text-align: center;
      margin-top: 20px;
    }

    .rating-container {
      float: none;
      display: inline-block;
    }

    .reviews-tab-count {
      position: relative;
      top: -2px;
    }
    
    .affirm-callout-container {
      text-align: center;
    }

    .mcallout {
      text-align: center;
      margin: 10px 0 20px 0;
    }

    .product-actions {
      float: none;
      clear: both;
      margin: 45px 0 0 0;
      overflow: visible;
      text-align: center;


      ul.menu { // Gift Card links
        width: 100%;
        float: none;
        margin: 20px 0;

        li {
          text-align: center;
          float: none;
          width: 100%;
          margin: 10px 0;
        }
      }

      div[class*="icon-"] {
        margin: 10px;
        height: 30px;
        position: relative;
        display: inline-block;
        vertical-align: bottom;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        & > a, .title {
          display: block;
          height: 30px;
          line-height: 60px;
          position: relative;
          z-index: 99;
        }
        a.remember-item {
          padding: 0;
          position: relative;
          &:before {
            @include sprite($icon-remember, 0.9);
          }
          &.enabled:before {
            @include sprite($icon-remembered, 0.9);
          }
          &:before,
          &.enabled:before {
            content: '';
            display: inline-block;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          }
        }

      }

      .livechat-productdetail { display: none; }

      .icon-livechat.lhnLiveChatLink {
        top: auto!important;
        left: auto!important;
        position: relative;
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          @include sprite($chat,2);
        }
        #lhnContainer { display: none; }

        &.hidden { display: none;}

        a { display: inline; }
      }

      .icon-share {
        position: relative;
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          @include sprite($icon-share,2);
        }
        .sharing-icons {
          position: absolute;
          background: $white;
          padding: 5px;
          transform: translateX(-50%);
          left: 50%;
          width: 100px;
          top: -180%;
          z-index: 99;

          &:before, &:after {
            content: "";
            display: block;
            height: 0;
            width: 0;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          }

          &:after {
            border: 10px solid transparent;
            border-top-color: $white;
            bottom: -20px;
          }

          &:before {
            border: 10px solid transparent;
            bottom: -22px;
          }
          a {
            float: right;
            cursor: pointer;
            margin: 5px;
            display: block;

            &.twitter-button { @include sprite($icon-twitter); }
            &.pinterest-button { @include sprite($icon-pinterest); }
            &.facebook-button { @include sprite($icon-facebook); }
            &.mailto-button { @include sprite($icon-mail); }
          }
          span.mailto-button {
            @include sprite($icon-mail);
            float: right;
            cursor: pointer;
            margin: 5px;
            display: block;
          }
        }
      }
    }

    .pdpForm {
      position: relative;

      .affirm-content-callout { clear: both; }
    }

    fieldset {
      min-width: auto;
      width: 100%;
      margin-top: 22px;
    }

    .persistent fieldset {
      margin-top: 18px;
    }

    #truefit-holder {margin-left: -3px;}

    .customerType {
      li {
        float: none;
        width: 100%;

        .value {
          padding: 10px;
        }
      }

      input { display: none; }

      label {
        margin: 0;
        cursor: pointer;
      }

      input[checked] + label {
        color: $primarylink;
      }
    }

    .product-variations {
      margin-bottom: 10px;
      position:relative;

      .sizewidthdropdown {margin-bottom: 18px;}
      .availability-msg { padding: 0; margin-bottom: 14px; }
      .attributecontentlink {
        margin: 0;
      }

      .toggle {
        color: inherit;
        border: $borderMixin;
        box-sizing:border-box;
        display: block;
        height: 55px;
        line-height: 55px;
        padding: 0 19px 0 21px;
        width: 100%;
        position: relative;
        background-color: $lightbackdrop;
        &.expanded:after,
        &:after {
          content: '' !important;
          display: inline-block;
          position: absolute;
          right: 19px;
          top: 50%;
          transform: translateY(-50%);
        }
        &:after {
          @include sprite($icon-refinement-down);
        }
        &.expanded:after {
          @include sprite($icon-refinement-up);
        }
      }

      .toggle-content {
        background: $lightbackdrop;
        border: $borderMixin;
        padding: 21px;
        position: absolute;
        width: 100%;
        z-index: 1002;

        &.popleft { left: -105px;}
      }

      ul.swatches.Color.swatch-size-small, %pdp-color-dropdown {
        float: none;
        width: 100%;
        margin: 0;
        box-sizing: border-box;
        position:relative;

        li {
          background: $white;
          width: 25%;
          height: 59px;
          box-sizing: border-box;
          border: 0;
        }

        .swatchanchor {
          background-position: center center !important; // to override inlined blanket background declaration
          border: $borderMixin;
          border-radius: 0;
          box-sizing: border-box;
          height: 60px;
          width: 82px;
        }

        .selected .swatchanchor {
          border: 2px solid $primarylink;
          position: relative;
          z-index: 1;
        }
      }

      .attribute { overflow: visible; }

      &.dropdownvariation {
        width: 100%;
        float: none;

        .toggle-content {
          box-sizing: border-box;
          width: 366px;

          li.attribute {
            width: 95px;
            margin: 0;

            label {
              background: $search-refinement-color;
              border: $borderMixin;
              border-radius: 0;
              box-sizing: border-box;
              margin: 0 5px 5px 0;
              text-align: center;
              line-height: 34px;
              height: 34px;
              width:34px;
            }

            li:hover, .selected {
              label {
                background: $refinement-hover;
                color: $search-refinement-color;
              }
            }

            .width, .length {

              label{
                width: 42px;
              }

              #swatch-OneSize {
                width: 90px;
              }
            }
          }

          span:focus {outline: 0;}

          .sizewidthdropdown li.attribute {
            &:first-child { width: 217px; }
          }

          // swapping the positioning of length/waist
          .waistlengthdropdown li.attribute {
            float: right;

            label { margin: 0; }

            &.attribute:last-child {
              width: 220px;
              float: left;
            }
          }
        }
      }
    }

    &:not(.alternate-color) .variations-color.variation-item .product-variations .value {
      width: 366px;
      box-sizing: border-box;
    }

    &.alternate-color .variations-color.variation-item .product-variations{
      .title {
        border: 0px;
        background: initial;
      }
      .value {
        display: block !important;
        position: static;
        max-height: 93px;
        overflow: hidden;
        transition: max-height .15s ease-out;

        &.expanded {
          max-height: 400px;
          transition: max-height .25s ease-in;
        }
      }

      ul.swatches {
        margin: 0;
        padding: 0 1px 1px 0;
        border: 0px;
        position:relative;
        li {
          height: 46px;
          margin-bottom: 0;
          .swatchanchor{
            width: 65.75px;
            height: 47px;
            background-size: contain !important;
            background-repeat: no-repeat !important;
          }
        }
      }

      .toggle-expand {
        text-align: center;
        cursor: pointer;
        padding-top: 10px;

        .open, .closed {
          background-image: url('../images/interface/icon-refinement-arrow-highlight.png');
          background-repeat: no-repeat;
          padding-right: 20px;
          background-position: right -39px;
        }

        .open {
          display: none;
        }

        &.expanded{
          .open {
            display: inline;
            background-position: right 5px;
          }
          .closed {
            display: none;
          }
        }
      }
    }

    .variations-other ul {
      clear: both;
    }

    ul.swatches {
      li.unselectable:hover{
        border-color: white;
      }
    }

    .variant-dropdown {
      .label { display: none; }
      select.variation-select {
        width: 100%;
        padding: 0 21px;
        margin-bottom: 10px;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;

        height: 55px;
      }
      span.label + div.value {
        select {
          background-image: none;
        }
        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          right: 20px;
          top: calc(50% - 5px);
          transform: translateY(-50%);
          @include sprite($icon-refinement-down);
          pointer-events: none;
        }
      }
    }

    .product-add-to-cart,
    .persistent .product-customize {
      width: 100%;
    }

    .product-customize {
      width: 100%;
      float: left;
      margin-right: 0;
      padding: 10px 0;

      .button {
        display: block;
        box-sizing: border-box;
      }

      span {
        padding-left: 22px;
        position: relative;
        line-height: 20px;
          &:before {
            content: '';
            @include sprite($icon-customize);
            position: absolute;
            left:-22px;//matches padding-left from above.
            top: 50%;
            // If we get a smaller image, zoom/scale() is unnecessary.
            -ms-zoom: .25;
            transform: translateY(-50%) scale(.25);
        }
      }
    }
    
    .express-pay-container {
      display: flex;
    }
    
    .paypalexpress {
      margin-top: 10px;
      min-width: 48%;
      width: 100%;

      a {
        img {
          display: block;
          max-width: 88px;
          margin: auto;
        }
      }
      
      + .dw-apple-pay-button {
        height: 35px;
        max-width: 48%;
        margin-left: 4%;
      }
    }

    .dw-apple-pay-button {
      -apple-pay-button-type: plain;
      width: 100%;
      margin: 10px auto 0;
      float: left;
      display: block;
      height: 55px;
    }

    .product-customize + .dw-apple-pay-button {
      margin-top: 0;
    }

    .persistent{
      .product-customize,
      .delivery-message {
        display: none;
      }
    }

    #product-content {
      .promotion.no-print,
      .product-marketing-asset {
        width: 100%;
        max-height: none;
        margin-top: 10px;
        margin-bottom: 0;
      }
    }
  }
  
  .persistent .paypalexpress {
    margin-bottom: 10px;
  }

  .product-col-2.product-set {
    width: 49%;

    .product-variations .toggle-content {
      box-sizing: border-box;
      width: 366px;
    }

    .quantity {display:none;}

    .product-actions {
      margin-top: 0;

      ul.menu li{
        margin: 0;
        text-align: left;
        .tfc-cfg-widget.tfc-fitrec-result {padding:0;}
      }
    }

    .menu .remember-item {float: left;}

    .add-sub-product {
      width: 100%;

      .add-to-cart {
        width: 100%;
      }
    }

    .product-actions {
      overflow: visible;
    }

    .product-set-details {
      .product-price {
        text-align: center;
        margin-bottom: 20px;
        width: 100%;
      }

      .promo-price + .price-standard {
        text-decoration: line-through;
      }
    }

    .quantity a {
      background-position: 190px -24px;
      width: 215px;
      box-sizing: border-box;
    }

    .product-add-to-cart {
      width: 100%;

      #add-all-to-cart {
        width: 200px;
      }

      .product-price {
        box-sizing: border-box;
        padding: 12px 14px;
        margin-top:0;
      }
    }
  }

  #product-zoom-container {
    height: 500px;
    width: 260px;
    position: absolute;
    overflow: hidden;
    right: 0;
    top: 0;
    background: white;
    visibility: hidden;
  }

  .product-marketing-slot, .product-marketing-asset {
    float: left;
    clear: both;
    width: 100%;
 	}

  #product-content {
    .persistent-shopping-expand,
    .persistent-shopping-close {
      display: none;
    }
  }

  #product-content.persistent {
    background-color: white;
    box-sizing: border-box;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 263px;
    top: 57px;
    padding: 20px;
    z-index: 10;

    &.active {
      padding-top: 49px;
    }

    .promotion, .reviews, .variation-item, .product-add-to-cart, .product-actions,
    .product-marketing-slot, .product-marketing-asset, .mystery-item,
    .affirm-callout-container,
    .affirm-content-callout {
      display: none;
    }

    .product-price {
      margin-top: 11px;
    }

    .persistent-shopping-expand {
      display: block;
      width: 100%;
    }

    .persistent-shopping-close {
      position: absolute;
      top: 12px + math.div((25px - math.div($icon-ui-close-width, 2)), 2);
      right: 9px + math.div((25px - math.div($icon-ui-close-height, 2)), 2);
      @include sprite($icon-ui-close,2);
      cursor: pointer;
    }

    .dropdownvariation .toggle-content { top: 41px; }

    &.active {
      .variation-item, .product-add-to-cart,
      .persistent-shopping-close, .mystery-item {
        display: block;
      }
      .persistent-shopping-expand {
        display: none;
      }
    }
  }

  .loader { display: none!important; }

  &.responsive .product-col-2 {
    &.product-info, &.product-set {
      .bonus-variations {
        ul.swatches.Color.swatch-size-small li{
          width: 82px;
        }

        .product-variations {
          .toggle {
            display: none;
          }
          
          .toggle-content {
            display: block!important;//needed to override toggle.js inline styley
            position: relative;
            width: 100%;
            padding: 0;
            background: none;
          }
        }
      }
    }
  }
}

.zoomContainer {
    z-index: 100;
    .zoomLens {
        min-height: 161px;
    }
}

div.ui-dialog.ui-widget.ui-widget-content {
  position: fixed;

  #send-to-friend-dialog {
    max-height: calc(90vh - 40px);
    overflow: scroll;
  }

  #dialog-container {
    max-height: calc(90vh - 40px);
  }
}

// SUP-2563
.isDesktopEnvironment .ui-dialog:not(.login-dialog):not(.zoom-dialog):not(.confirmation-login-modal) #dialog-container {
  max-width: 50vw;
  height: 100% !important;
  padding: 10px 20px;
}

.zoom-dialog {
  #dialog-container {
    float: left;
  }
}

div.ui-dialog.ui-widget.ui-widget-content.checkout-login #dialog-container{
  overflow:initial;
}

.ui-dialog .ui-dialog-content {
  clear: both;
}

#wrapper.pt_product-details {

  .no-print .content-asset {
    overflow: visible;
    position: relative;
    z-index: 1;

    img {
      display: block;
      margin: auto;
    }
  }
}

.pdp-main.pdpv2.bonus-product-list { margin-top: 0; }
/*** Discontinued Products ****/
#pdpMain.discontinued {

  #product-content.persistent {
    display: none;
  }

  .discontinued-h4 {
    @extend %discontinued-h4 !optional;
    text-align: center;
  }
}

/*** Scarcity Messaging ***/
#scarcity-messaging-block {
  padding: 15px 0 5px;
  text-align: center;
  span {
    display: inline-block;
  }
  .in-stock-icon,
  .scarce-icon {
    position: relative;
    margin-right: 8px;
  }
  .scarce-icon {
    top: 5px;
    $icon-scarcity-scarce: (0, 0, 0, 0, 0, 0, 0, 0, '', 'default-icon', ) !default;
    @include sprite($icon-scarcity-scarce);
  }
  .in-stock-icon {
    top: 1px;
    $icon-scarcity-in-stock: (0, 0, 0, 0, 0, 0, 0, 0, '', 'default-icon', ) !default;
    @include sprite($icon-scarcity-in-stock);
  }
  .scarcity-string {
        max-width: 90%;
    }
}

//for minicart bonus dialog modal

.ui-dialog.bonus-dialog {
  #bonus-product-list {
    .bonus-product-form {
      .size-chart-wrapper {
        display: none;
      }
      .size-chart-link:before {
        content: "";
        display: inline-block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-image: url(../images/size-chart.svg);
        background-size: contain;
        width: 20px;
        height: 14px;
        background-position: unset;
      }
      .clear-selections {
				display: none;
			}
    }
  }
  #scarcity-messaging-block .scarce-icon{
    width: 14px;
    height: 13px;
    top: 0px;
    margin-right: 5px;
    background-image: url(../images/cartcheckout/icon-alert.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: unset;
  }
}

