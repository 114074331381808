$radio-color: #929288;

.spc-form .spc-input-radio-group {
  margin-bottom: 16px;

  .spc-input-container-radio {
    background-color: white;
    border: 1px solid $checkout-border-color;
    cursor: pointer;
    margin: -1px;
    transition: background-color 0.2s;
    padding: 10px 0;

    &.spc-input-container-selected {
      background-color: $checkout-border-color;

      &:hover {
        background-color: $checkout-border-color;
      }

      input[type=radio] {
        background-image: radial-gradient($radio-color 50%, $white 50%);
      }
    }

    &:hover {
      background-color: scale-color($checkout-border-color, $lightness: 50%);

      input[type=radio] {
        background-image: radial-gradient($checkout-border-color 50%, $white 50%);
      }
    }

    p { margin: 0; }

    input[type=radio] {
      background-image: radial-gradient($checkout-border-color 0%, $white 0%);
      border: 2px solid $radio-color;
      border-radius: 100%;
      height: 20px;
      width: 20px;
      margin: 0 10px;
      -webkit-appearance: none;
      transition: 0.2s background-image;
      flex-shrink: 0;
    }

    .spc-input-radio-label {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      @include breakpoint($tablet) {
        flex-direction: row;
        align-items: center;
      }

      padding: 0;

      &-shipping {
        display: block;
        font-weight: bold;
        text-transform: uppercase;

        @media screen and (min-width: 768px) {
          display: inline-block;
        }
      }

      &-delivery {
        display: inline-block;

        @media screen and (min-width: 768px) {
          margin-left: 10px;
        }
      }
    }

    .spc-input-radio-callout {
      color: $checkout-label-color;
      font-size: 12px;
      line-height: 16px;
    }

    .spc-tooltip {
      position: static;
      margin: 2px 4px;
    }

  }

}

.spc-content-pre-shipping-methods-content > div:not(:empty) {
  padding: 0 0.5rem;
  margin-bottom: 16px;
}

.spc-content-shipping-information-asset {
  padding: 0 0.5rem;
  margin-bottom: 16px;
}

.spc-optin-form {
  margin-top: 20px;
}
