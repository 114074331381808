#QuickViewDialog {
  .pdp-main.pdpv2 {
    margin-top: 0;
    .pdp-above-fold {
      margin: 0;
    }

    .product-name-v2 {
      @include h2();
    }

    .product-price {
      .promo-price {
        color: $callout;
      }
      .price-sales ~ .price-standard {
        text-decoration: line-through;
        color: $secondary;
      }
    }

    .product-col-1.product-hero-image {
      width: 310px;

      .product-primary-image { 
        height: 350px;
        
        &.mobile-flexslider {
          height: 250px;
          
          .main-product-slider {
            width: 100%;
          }
        }
      }

      .product-image .primary-image { width: 100%; }

      .main-product-slider {
        width: 75%;

        .pdp-zoom-button { display: none; }
      }

      .flex-direction-nav a {
        // background-size: 15px;
        // height: 30px;
      }

      .flex-next {right: 0;}
      .flex-prev {left: 0;}

    }

    .product-col-2.product-detail {
      .variations-color {
        margin: 0;

        .product-variations {
          max-height: initial;
        }
      }

      .product-add-to-cart #add-to-cart { margin: 0; }

      .promotion {
        padding: 0;
        margin: 10px 0 0;
      }

    }

    .product-actions {
      margin-top: 10px;
      .remember-item.enabled {
        background-position: center -40px;
      }
    }
    .flex-control-nav { bottom: 22px; }
    .alternate-views { bottom: 0; }
  }

  .video-box iframe {
    max-width:100%;
    max-height: 200px;
  }

  h2 { margin: 0; }

  .b-read-reviews.m-read-reviews {
    display: none; // SUP-1491 Remove link for read review on the Quickview
  }
}

.js .ui-dialog-content .pdp-main .full-details-link {
  float: left;
  position: relative;
  margin: 0;
  text-align: left;

  a {
    margin: 0;
  }
}

// Hide remove from cart link by default
.quickview-cart-remove-item {
  display: none;
}
// Show only on the cart and checkout pages
.pt_cart ~ .ui-dialog #QuickViewDialog,
#single-page-checkout ~ .ui-dialog #QuickViewDialog {
  .quickview-cart-remove-item {
    display: block;
    float: left;
    width: 100%;
    text-align: center;
    margin-top: 12px;
  }
}

// Hide full details link on cart quickview
#wrapper.pt_cart ~ .ui-dialog #QuickViewDialog .full-details-link {
  display: none;
}

.isMobileEnvironment .ui-dialog.quickview {
  right: 0 !important;
  left: auto !important;
}
