%spc-cc-icons {
  background-image: url(../images/generic-cc.png),
    url(../images/icon-secure-dark.png);

  &[class*="american-express"],
  &[class*="amex"] {
    background-image: url(../images/amex.jpg),
      url(../images/icon-secure-dark.png);
  }
  &[class*="discover"] {
    background-image: url(../images/discover.jpg),
      url(../images/icon-secure-dark.png);
  }
  &[class*="mastercard"] {
    background-image: url(../images/mastercard.jpg),
      url(../images/icon-secure-dark.png);
  }
  &[class*="visa"] {
    background-image: url(../images/visa.jpg),
      url(../images/icon-secure-dark.png);
  }
  &[class*="paypal"] {
    background-image: url(../images/paypal-icon.jpg);
  }
  &[class*="amazon"] {
    background-image: url(../images/amazon.png);
  }
  &[class*="applepay"] {
    background-image: url(../images/applePayBtn.png);
  }
  &[class*="affirm"] {
    background-image: url(../images/affirm.png);
  }
  &[class*="pcipal"] {
    background-image: url(../images/pcipal.png);
  }
  &[class*="affirm-card"] {
    background-image: url(../images/affirm.png);
    border: 1px solid #D7D8D9;
    border-radius: 3px;
  }
  &[class*="afterpay"] {
    background-image: url(../images/afterpay.png);
  }
}

#single-page-checkout .spc-cc-form.spc-form {
  input[class^="spc-input-credit"] {
    padding-left: 50px;
    background-image: url(), url(../images/icon-secure-light.png);
    background-position: 15px 28px, 98% 50%;
    background-repeat: no-repeat;
    background-size: 26px, 13px;

    &:focus {
      @extend %spc-cc-icons;
    }
  }

  .spc-input-has-value input[class^="spc-input-credit"] {
    @extend %spc-cc-icons;
  }
}

.spc-icon-credit-card {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain, 0; // clearing out the lock icon for order confirmation
  display: inline-block;
  height: 18px;
  width: 24px;

  @extend %spc-cc-icons;
}

.spc-icon-klarna {
  margin-right: 5px;
}

.spc-icon-paypal,
.spc-icon-paypal-m,
.spc-icon-amazon-m,
.spc-icon-applepay-m {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain, 0; // clearing out the lock icon for order confirmation
  display: inline-block;
  height: 16px;
  width: 24px;
  border: 1px solid $checkout-border-color;
  vertical-align: text-bottom;

  @extend %spc-cc-icons;
}

.spc-icon-Afterpay-m {
  width: 70px;
}

.spc-klarna-content {
  background: $white;
  padding: $spacing * 2;
}

.spc-decline-modal {
  @include breakpoint-max($tablet){
    padding: $spacing * 2;
  }
}
