#single-page-checkout .spc-payment-picker {
  position: relative;

  .spc-input-select-label {
    @extend %spc-input-label;
  }

  label .spc-input-select-label-placeholder {
    transition: margin-top 0.5s, color 0.5s;
  }

  .rrs__label__text { color: black; }

  .rrs--options-visible {
      .rrs__label__text { display: none; }

      & + label .spc-input-select-label-placeholder {
        color: $black;
        display: block;
        margin-top: 10px;
      }

      #rrs-spc-payment-methods-menu {
        padding-bottom: 15px;
      }
  }

  #rrs-spc-payment-methods-menu {
    position: relative;
    top: -5px;

    .rrs__option {
      border: 0;
      padding: 5px 1rem;
      height: 28px;

      &:hover:before,
      &:before {
        content: "";
        display: inline-block;
        position: relative;
        height: 18px;
        width: 18px;
        background-image: url(../images/checkboxes.png);
        background-position: bottom;
        background-color: transparent;
        background-size: 18px;
        left: auto;
        top: auto;
        margin-right: 11px;
      }

      &:hover:before {
        background-position: center;
      }

      &--selected:before,
      &--selected:hover:before { background-position: top }

      span {
        @extend .spc-icon-credit-card;

        margin-right: 8px;
        height: 18px;
      }
    }
  }
}
