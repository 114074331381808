@use "sass:math";

#single-page-checkout,
#order-confirmation {
  #spc-secondary {
    // overwrites existing secondary styles
    width: 100%;
    max-width: 100%;
    float: none;

    @include breakpoint($tablet) {
      box-sizing: border-box;
      padding: 94px 0 0 ($spacing * 3);

      &.is-persistent {
        position: fixed;
        width: auto;
        margin-left: -($spacing * 0.5);
        max-width: 360px;
        padding: 94px 0 0 ($spacing * 3);
      }
    }
  }
  .order-summary {
    border-top: 1px solid black;
    border-bottom: 1px solid black;

    @include breakpoint($tablet) {
      border: 0;
      padding-left: ($spacing * 0.5);
    }

    &-header {
      display: block;
      padding: $spacing * 2;
      position: relative;

      @include breakpoint($tablet) {
        padding: 0;
      }

      h2 {
        display: inline-block;
        margin: 0;
        width: calc(100% - #{$drawer-icon-expand-width});

        @include breakpoint($tablet) {
          margin: ($spacing * 0.5) 0;
        }
      }
    }

    &-state {
      @include sprite($drawer-icon-expand);
      float: right;
      position: absolute;
      top: 50%;
      margin-top: -(math.div($drawer-icon-expand-height, 2));

      @include breakpoint($tablet) {
        display: none;
      }
    }

    &-content {
      display: none;
      padding: $spacing * 2;


      @include breakpoint($tablet) {
        display: block;
        padding: 0;
        margin-top: 15px;
      }
    }

    &.is-expanded {
      .order-summary-state {
        @include sprite($drawer-icon-expanded);
      }

      .order-summary-content {
        display: block;
      }
      .spc-loader-wrapper{
        margin-bottom: 0px;
      }
      .totals-table
        table:first-child{
          tr:first-child{
            td{
              padding: 8px 0px 0px 0px;
            }
          }
      }
       td{
        padding: 12px 0px 0px 0px;
      }
      .order-total{
        font-size: 15px;
        font-weight: bold;
      }
    }

    .spc-edit-product {
      display: block;
      width: 16px;
      margin-top: 8px;
      font-weight: normal;
      font-size: 12px;
      color: $primarylink;
    }
  }

  //product list styles
  .product-list {
    padding: 0;
    margin: 0;
    li:last-child {
      border-bottom: none!important;
    }
    li:first-child h3{
      padding-top: 15px;
    }
  }

  .product-item {
    padding: 0 15px;
    //border-top: $borderMixin;
    border-top: none!important;
    border-bottom: 2px dashed #EFEFEF;
    list-style: none;
    a {
      text-decoration: none;
      &:hover,
      &:active,
      &:focus { 
        h3 {
          color: $product-name-link; 
          text-decoration: none;
        }
      }
    }
    .product-item-container{
      border-bottom: 1px solid #EFEFEF;
      padding-bottom: 12px;
    }

    h3 {
      margin: 0 0 $spacing 0;
      font-size: 14px;
      padding-top: 36px;
      color: $base;
      font-family: $font-name-bold;
      display: inline-block;
   }

    .product-image {
      display: inline-block;
      text-align: center;
      // max-width: 105px;

      img { max-width: 100%; }
    }

    .product-info {
      display: inline-block;
      width: 77%;

      p {
        margin: 0;
        color: $base;
        font-size: 12px!important;
        line-height: 16px!important;
        font-weight: normal;
      }
      
      .scarcity-icon {
        @include scarcity-icon;
      }
    
      .promo-expiration-icon {
        width: 14px;
        height: 14px;
        margin-right: 5px;
        background-image: url('../images/cartcheckout/icon-expiration.svg');
        background-repeat: no-repeat;
      }
    
      .d-flex .cart-item-details-scarcity .scarcity-messaging,
      .d-flex .promo-expiration-messaging {
        @include body-small();
        margin: 3px 0;
        color: $cart-text-callout;
            
        @include breakpoint($desktop) {
          margin: 7px 0;
        }
      }
    }

    .product-price {
      @include body();
      display: flex;
      flex-flow: column;
      margin-left: auto;
      text-align: left;
      color: $base;
      font-size: 14px!important;
      font-weight: normal;
    }
    .productcarddetails-title{
      p{
        margin:0;
        font-size: 12px;
      }
    }
    .product-quantity-value{
      padding-bottom: 36px;
      p{
        font-size: 14px!important;
        color: $base;
      }
    }
    .productcarddetails-value{
      margin-top: 9px;
    }
    .productcarddetails-title,.productcarddetails-value{
      padding-left: 4px;
      padding-right: 4px;
      p{
        margin:0;
        font-size: 12px;
        color: $base;
        font-weight: normal;
      }
    }
  }

  // all toggles
  .spc-toggle {
    &-header {
      position: relative;
      display: inline-block;
      cursor: pointer;
      &::after {
        content: ">";
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        right: -15px;
        margin: auto;
        font-size: 10px;
        transform: scaleX(1.5) rotate(90deg);
      }
    }

    &-content {
      td {
        padding-left: 0;
      }
    }

    &.is-expanded {
      .spc-toggle-header::after {
        transform: scaleX(1.5) rotate(-90deg);
      }
      .spc-toggle-content {
        display: table-row;
      }
    }
  }

  // promo-list within toggle
  .promo-list {
    table {
      width: auto;
      max-width: 100%;
    }
    td {
      padding-top: 0;
      padding-bottom: 0;
    }

    .spc-toggle-content {
      font-size: 10px;
      td {
        color: $secondary;
        &:nth-child(2) {
          color: $callout;
        }
      }
    }
  }

  // just the promo-list inside the totals table
  .totals-table .promo-list {
    margin-top: -5px; //fixes uneven spacing, might be better fix
    color: $secondary;

    .spc-toggle-content {
      td:nth-child(1) {
        padding-left: $spacing * 2;
      }
    }
  }

  //totals table
  .totals-table {
    .order-total{
      border-top: 2px solid #EFEFEF!important;
    }
    &-savings-row td {
      padding-bottom: 0;
    }
  }

  td {
    padding: 8px 0px 0px 0px;
    &:nth-child(2) {
      padding-right: 0;
      text-align: right;
    }
    &.savings {
      color: $deep-red !important;
    }
    span {
      display: block;
      color: $secondary;
      font-size: 12px;
    }
  }

  .spc-sales-tax {
    padding-bottom: 0;
  }

  .free-shipping {
    font-weight: bold;
  }

  .order-total {
    border-top: $borderMixin;
    font-weight: bold;
    margin-top: $spacing;
  }
}
@include breakpoint($tablet) {
  html[lang="de-DE"],
  html[lang="de-AT"] {
    #backToCartLink .back-to-cart-anchor {
      margin-left: 3.5vw;
    }
  }
}

.order-summary .product-price-container .product-price {
  .product-price-discounted, .product-price-discounted-summary {
    color: $sale-text-price;
  }
}
.textalign-center{
  text-align: center;
}
.textalign-end{
  text-align: end;
  .product-price{
    align-items: end;
  }
}
.order-summary-content{
  .spc-loader-wrapper{
    margin-bottom: 16px;
    box-shadow: 0px 0px 3px #BBBBBB;
  }
}
#single-page-checkout .product-list li:last-child{
  border-bottom: none!important;
}
.pt-20{
  padding-top:20px;
}
.pt-5{
  padding-top:5px; 
}
.product-item #final-sale-indicator {
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 15px;
}
