// #navigation { border-top: none; }

.checkout-progress-indicator { 
 	div, a { @include h3($line-height: 24px); }	
}

//Checkout Form
.checkoutv2 #primary {
	input, select, .label-inline label { @include body(); }
	select.error-message { color: $callout; }
	label .error-message { @include body-small($color: $callout, $line-height: 17px); }
	a:not(.tooltip), a:not(.tooltip):hover { @include body($color: $secondary, $decoration: underline); }
	
	form .name a {
		@include h3($color: $base, $decoration: none);
		&:hover { @include h3($color: $secondary, $decoration: none); }
	}
	
	.shipping-list {
		.method { @include h3(); }
		.delivery-style { 
			border-color: $mainbordercolor; 
			.col-02 { width: 40%; } //I know, but comps
		}
	}

	.payment-showhide .field-description { @include body-small(); }

	.cart-drawer-label.toggle { 
		@include h3(); 
		&:hover { @include h3($color: $secondary, $decoration: none); }
	}
} 

//Checkout Sidebar
.checkoutv2 #secondary {
	.price-promotion { 
		.price-standard { color: $dark-grey !important; } // Don't blame me, blame the !important in _style.v2.scss
		.price-sales { color: $callout; }
	}

	.checkout-order-summary {
		h3 { @include h2($color: $dark-grey, $line-height: 0); }
		.mini-cart-name a:hover { @include h3($color: $secondary); }
	}
	
	.checkout-right-summary .cart-orders-total-box {
		tbody td { @include body(); }
		tr td:last-child { width: 200px; } //I know, but comps

		.order-totals-table tr.order-total {
			padding-top: 0; //I know, but comps
			border: none;
			td { @include h4(); }
		}
	}
	.tooltip { text-decoration: none; }
}

//Address Verification/Correction Modal
.pt_checkout {
	#dialog-container {
		button { @include cta(); }
		.newAddressText, .newAddressText:hover, a, a:hover { @include body($color: $secondary, $decoration: underline); }
	}
}