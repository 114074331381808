#mini-cart {
  position: absolute;
  top: 0;
  right: 0;
  height: 0;
  z-index: 1001;

  .mini-cart-empty {
    display: none;

    @include breakpoint($tablet) {
      display: inline;
      margin-left: 6px;
    }
  }
}

#mobile-header #mini-cart {
  position: static;

  &.triggered-show {
    position: static;

    @include breakpoint-max($tablet) {
      .mini-cart-content,
      .mini-cart-label {
        display: none !important;
      }
    }
  }

  .mini-cart-quantity-bag {
    @include icon-cart-counter;

    // allow brand specific class to override the above styles
    @extend %mobile-mini-cart-counter !optional;
  }
}

@include breakpoint-max($tablet) {
  // Temporary until remaining bits of mobile mini-cart are removed
  .mini-cart-content, .mini-cart-label {
    display: none;
  }
}

.mini-cart-label {
  padding: 9px 0;
  display: block;
  width: 22px;
  &:before {
    @include icon-cart;
  }
}

.mini-cart-quantity-bag {
  @include icon-cart-counter;
  @extend %cart-info-text !optional;
}

@include breakpoint($tablet) {
  #mini-cart {
    display: inline-block;
    margin: 0;
    position: relative;
    z-index: 200;
    padding: 0;
    height: 100%;
  }

  .mini-cart-total {
    position: relative;
    .active-persistentheader & {
      padding: 0;

      a,
      span {
        line-height: $persistentHeight;
        top: 26px;
      }
    }
    .mini-cart-quantity-bag {
    }

    .empty {
      display: none;
    }
  }

  .mini-cart-link-cart {
    margin: 20px auto 10px;
    width: 100%;
  }

  .mini-cart-subtotals {
    margin: 0 -5px;

    .label {
      box-sizing: border-box;
      padding-left: 11px;
    }
  }

  #mini-cart .mini-cart-product:first-child {
    padding-top: 0;
  }

  .mini-cart-slot {
    > :first-child {
      margin-top: 23px;
    }
    p {
      margin: 0; // normalize.css causing extra space
    }
  }

  .mini-cart-name,
  .mini-cart-attributes > span,
  .mini-cart-attributes > div > span,
  .mini-cart-pricing .label {
    display: inline-block;
    margin-bottom: 0;
  }

  .mini-cart-attributes > span:first-of-type {
    display: inline; //this is to keep empty spans from creating a whitespace
  }

  .mini-cart-attributes .attribute {
    width: 100%;
  }

  // This weird combination of padding between mini-cart-inner, mini-cart-totals,
  // and mini-cart-products is to account for the scrollbar that appears with more than 2 products
  .mini-cart-content .mini-cart-inner {
    box-sizing: border-box;
    padding: 24px 4px 23px 15px;
    width: 280px;
    top: 37px;

    .active-persistentheader {
      top: 45px;
    }
  }

  .mini-cart-totals {
    padding: 0 16px 0 5px;
  }

  .mini-cart-products {
    padding-right: 11px;

    &.jscrollpane {
      padding-right: 0;
      overflow-y: scroll;
    }
  }


  .mini-cart-label {
    @extend %cart-info-text !optional;
  }


  .mini-cart-empty,
  #remembered-count,
  .mini-cart-total {
    @extend %cart-and-remembered-num !optional;
  }

  .mini-cart-total {
    @extend %mini-cart !optional;
  }

  .mini-cart-content {
    border: none;
    display: none;
    position: absolute;
    right: -11px;
    top: -8px;
    width: 280px;

    .mini-cart-inner {
      background: none repeat scroll 0 0 $white;
      margin: 0;
      border: 1px solid $mainbordercolor;
      position: absolute;
      z-index: 1;
      width: auto;
      right: 0;
      @include mini-cart-shadow();
    }
  }

  @media screen and (min-device-width: 1025px) {
    #mini-cart:hover .mini-cart-content {
      display: block;
    }

    #mini-cart:hover .mini-cart-header .mini-cart-label {
      color: $minicarthovercolor;
    }
  }

  .mini-cart-header {
    height: 20px;
    background: $white;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    padding: 7px 10px 11px;
    border: 1px solid $mainbordercolor;
    border-bottom: none;

    a {
      display: block;
    }

    .active-persistentheader & {
      padding-bottom: 19px;
      top: -1px;
    }
  }

  .mini-cart-close {
    background: url("../images/interface/icon-mini-cart-close.png") no-repeat
      scroll left top transparent;
    display: none;
    height: 20px;
    text-indent: -999em;
    width: 19px;
  }

  // TODO: Make some variables for this
  .mini-cart-inner {
    .jspVerticalBar {
      width: 7px;
    }

    .jspTrack {
      background: none;
      border-radius: 6px;
    }

    .jspDrag {
      border-radius: 6px;
      background: $grey-silver;
    }
  }

  .mini-cart-products.jscrollpane {
    min-width: 238px;
  }

  .mini-cart-product {
    border-bottom: 1px solid $mainbordercolor;
    background: none repeat scroll 0 0 $white;
    overflow: hidden;
    padding: 21px 0 20px;
    @extend %mini-cart-text !optional;

    &.last {
      border: none !important;
    }
  }

  .mini-cart-image {
    float: left;
    width: 50%;
    padding: 0;
    box-sizing: border-box;

    img {
      max-width: 100%;
    }
  }

  .mini-cart-name {
    float: right;
    width: 50%;
  }

  .mini-cart-name a {
    @extend %mini-cart-titles !optional;
  }

  .mini-cart-attributes {
    float: right;
    width: 50%;
    margin: 0 0 0px;
    clear: right;
  }

  .mini-cart-product .product-availability-list {
    float: right;
    font-weight: bold;
    width: 50%;
  }

  .mini-cart-pricing,
  .mini-cart-action,
  .mini-cart-scarcity,
  .mini-cart-promo,
  .mini-cart-out-of-stock,
  .mini-cart-pre-order,
  .mini-cart-notify-me,
  .final-sale-indicator-minicart {
    clear: right;
    float: right;
    width: 50%;
    margin: 0;
  }

  .mini-cart-action a {
    display: inline-block;
    padding: 0 3px 0 0;

    a.mini-cart-product-edit,
    a.mini-cart-configured-product-edit {
      padding: 0;
    }

    a:hover {
      color: $mainbrand;
    }
  }

  .mini-cart-subtotals {
    padding: 14px 0;
    position: relative;
    text-align: left;
    border-top: 1px solid $mainbordercolor;
    border-bottom: 1px solid $mainbordercolor;

    .label {
      margin: 0;
      display: inline-block;
      width: 50%;
    }

    .value {
      font-weight: normal;
    }
  }

  .mini-cart-slot {
    background: none repeat scroll 0 0 transparent;
    margin: 0;
  }

  .mini-cart-link-cart {
    display: block;
    @extend %inverted-btn !optional;
  }

  .mini-cart-link-checkout {
    display: inline-block;
    @extend %normal-cta !optional;
  }

  .mini-cart-content .cart-bonus-product-box {
    padding: 25px 0;
  }

  // -------------------------------
  // Potentially Deprecated Styles
  // -------------------------------

}

.mini-cart-products {
  .MiniCart__ProductTile {
    .mini-cart-pricing {
      .quantity-main-wrapper {
        .label.quantityNAerr {
          color:$sale-text-price !important;
        }
        .quantity-wrapper.quantityNAerr {
          border: 1px solid $sale-text-price !important;
          .quantityNAerr {
            color:$sale-text-price !important; 
          }
        }
      }
    }
    .mini-cart-out-of-stock {
      margin: 0 16px 0 16px;
      &-container {
        display: flex;
        align-items: flex-start;
        margin-bottom: 8px;
      }
      svg {
        margin-right: 8px;
        flex-shrink: 0;
        stroke:$sale-text-price;
      }
      .out-of-stock-messaging {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400 !important;
        margin: 0;
        text-decoration: none;
      }
    }
    .notify-me-container {
      display: flex;
      align-items: center;
      width: inherit; 
      #Icon_Bell {
        stroke: $primarylink;
      }
      a.out-of-stock {
        font-size: 12px !important;
        line-height: 18px !important;
        font-weight: 400 !important;
        text-decoration: none !important;

        &:hover {
          color: $minicart-nwa-hover;
        }
      }
    }
    .mini-cart-out-of-stock.lowStockMsgDisp {
      margin-top: 0;
    }
  }
}



