/* Modals */
%gwp-modal-title {
  @include h2();
}

%gwp-detail-drawer-link {
  @include body-small();
  &:hover, &:active, &:focus { 
    color: $secondary;
    text-decoration: underline;
  }
}

%gwp-modal-container {
  @include box-shadow-01();
}

%gwp-detail-drawer-text {
  @include body-small();
}

%gwp-selected-product-name {
  @include h3();
}

%gwp-options-select {
  @include h3($line-height: 55px);
}

%gwp-add-cta {
  @include cta();
}

%gwp-details-change-selection {
  @include body();
}

/* Minicart */
%gwp-promo-code-box-title {
  @include h3();
}

%gwp-promotion-title {
  @include body();  
}

%gwp-minicart-promotion-title {
  @include h3();
}

%gwp-add-link {
  @include body($color: $secondary, $decoration: underline);
}

/* Cart & Promo Code */
%gwp-promo-code {
  @include h3();
}

%gwp-promo-code-title {
  @include body();
}

%gwp-applied-text {
  @include body();
}

%gwp-promo-code-input {
  background-color: $contrast;
}

%gwp-promo-code-button {
  @include cta();
}

/* ****** */
