  // swatches
.js .pdp-main .swatches li label input {
  display: none;
}

.pdp-main
  .product-variations.dropdownvariation
  .toggle.expanded
  span:first-child {
  background-position: $size-expanded;
}

.pdp-main .product-variations.dropdownvariation .attribute li {
  label {
    cursor: pointer;
  }

  &:not(.unselectable) {
    label {
      box-shadow: none;
      background: white;
      @extend .size-width-option !optional;
    }

    @include hover-supported() {
      &:hover label {
        color: $white;
        background: $refinement-hover;
      }
    }
  }
}

.pdp-main .product-variations.dropdownvariation .attribute li.selected label {
  background: $refinement-hover;
  color: $white;
  cursor: default;
}

.pdp-main .product-variations.dropdownvariation .attribute li label:hover,
.pdp-main .product-variations.dropdownvariation .attribute li.selected label {
  @extend .size-width-option-active !optional;
}

.pdp-main .product-variations.dropdownvariation h3 span.title-variation {
  background: none;
  width: 100%;
  padding: 0;
  vertical-align: middle;
  color: $mainbrand;
  letter-spacing: 0.1em;
}

.pdp-main .product-variations h3 span.tooltip-icon:hover,
.tooltip-icon:hover {
  @include sprite($icon-tooltip-hover);
}

.pdp-main .product-variations h3.active-filter span {
  background: url('../images/icon-add-filter.png') no-repeat scroll 100% 1px
    transparent;
}

.pdp-main .product-variations h3.active-filter span.numb-selected {
  color: $primarylink;
  background: none;
  width: auto;
}

.pdp-main .product-variations h3 {
  border: 1px solid $mainbordercolor;
  padding: 8px 9px 5px;
  margin: 0;
  font-size: 0.769em;
  position: relative;
  z-index: 26;
  
  span.tooltip-icon {
    margin: 0 0 8px 4px;
    position: absolute;
    top: 15px;
    right: 27px;
  }
  
  &.expanded {
    border-bottom: none;
        
    span {
      background-position: 100% 3px;
    }
  }
}

.pdp-main .product-variations .wrap-swatches-box {
  width: 230px;
  background: $white;
  display: none;
  border: 1px solid $mainbordercolor;
  position: absolute;
  z-index: 25;
  padding: 10px;
  margin: -1px 0 0;
}

.pdp-main .variant-color-name {
  @extend .the-variant-color-name !optional;
}

.pdp-main .swatches.size li,
.pdp-main .swatches.width li,
.pdp-main .swatches.cupsize li,
.pdp-main .swatches.bigkidshoesize li,
.pdp-main .swatches.littlekidshoesize li {
  border: none;
}

.nonactivelinks .pdp-main .swatches li:hover a {
  pointer-events: none;
  cursor: default;
}

// customerType js
.js .pdp-main .product-variations .customerType {
  .value {
    display: flex;
  }
  
  .linkvalue {
    display: none;
  }
}

.pdp-main {
  .more-colors-link a {
    font-size: .75em;
    display: block;
  }
  .swatches {
    width: 100%;
    margin-left: -1 * $swatch-padding-horizontal;
    li {
    float: left;
      padding-left: $swatch-padding-horizontal;
      margin-bottom: $swatch-padding-vertical;
    box-sizing: border-box;
    
      label {
        border: $borderMixin;
        border-radius: 0;
        box-sizing: border-box;
        text-align: center;
        line-height: $swatch-height;
        height: $swatch-height;
      }
    
      &.unselectable {
        label {
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100% 100%, auto;
          cursor: not-allowed;
        }

        &, &:hover {
          label {
            color: $gray;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'%3E%3Cpath d='M0 0 L100 100 ' stroke='gray' stroke-width='1'/%3E%3C/svg%3E");
            background-color: #efefef;

            span {
              color: $gray;
            }
          }
        }
      }
    }
    
    .selected label {
      color: $swatch-selected-text !important;
      background: $swatch-selected-bg;
      border-color: $swatch-selected-border;
    }
    .unselectable label {
      color: $swatch-disabled-text;
      background: $swatch-disabled-bg;
      border-color: $swatch-disabled-border;
    }

    .color-swatches {
      margin-bottom: 30px;
  
      .b-on_sale-label {
        font-size: 12px;
        font-weight: bold;
        margin: 24px 0 8px 4px;

        @include breakpoint-max($mobile) {
          font-size: 10px;
        }
      }
    }

    @include breakpoint($tablet) {
    :not(.selected,.unselectable):hover label {
      color: $swatch-hover-text;
      background: $swatch-hover-bg;
      border-color: $swatch-hover-border;
  }
    }
  
    &.size, &.Color, &.bigkidshoesize, &.littlekidshoesize, &.waist, &.length {
      li {
    width: 25%;// 4 across 
        @include breakpoint($widescreen) {
      width: 20%; // 5 across 
        }
        label {
          font-size: .75em;
      }
      }
    }
    
    &.width {
      li {
        width: 50%;
      }
      label {
        font-size: .75em;
      }
    }
    
    &[aria-owns='swatch-size-One Size'] {
        li { width: 50%; }
    }

    &.Color {
        margin-left: -4px;
      li {
        border: 0;
        a {
        display: block;
        margin: 0;
        padding: 0;
        text-indent: -999px;
        overflow: hidden;
      }
        @include hover-supported() {
          &:not(.selected,.unselectable):hover {
            a:not(.selected,.unselectable) {
              border-color: $swatch-hover-border;
            }
          }
        }
      }

      &.swatch-size-small {
        float: none;
        width: 100%;
        box-sizing: border-box;
        position: relative;
        @extend .pdp-variation-swatch !optional;

        .swatchanchor {
          display: block;
          height: 65px;
          width: auto;
          margin: auto;
          background-size: contain !important; // to override inlined blanket background declaration
          background-position: center !important; // to override inlined blanket background declaration
          border: $borderMixin;
          border-radius: 0;
          box-sizing: border-box;
          
          @include breakpoint($desktop) {
            height: 40px;
          }
        }

        .selected .swatchanchor {
          border: 2px solid $primarylink;
          position: relative;
          z-index: 1;
        }
      }

      &.swatch-size-tiny {
        @include breakpoint-max($tablet) {
          .pdp-main .Color.swatch-size-tiny li {
            box-sizing: border-box;

            a {
              width: 100%;
              height: 100%;
              background-position: center center !important;
              background-size: 90% !important;
            }
          }
        }

        li a {
          width: 40px;
          height: 20px;
          border: 1px solid #e0e0e0;
        }
      }

      // Out of stock color styles
      li {
        .swatchanchor.unselectable {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            inset: 0;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'%3E%3Cpath d='M0 0 L100 100 ' stroke='gray' stroke-width='1'/%3E%3C/svg%3E");
            background-color: rgba(250, 250, 250, 0.5);
            background-size: 100% 100%;
          }
        }

        &.selected .swatchanchor.unselectable::before,
        .swatchanchor.unselectable:hover::before {
          // Encode the hash in the color value
          $svgColorStr: '#{$color-swatch-x-color}';
          $svgStrokeColor: $svgColorStr;
          @if (str-index($svgColorStr, '#')) {
            $svgStrokeColor: '%23#{str-slice($svgColorStr, 2, str-length($svgColorStr))}';
          }
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'%3E%3Cpath d='M0 0 L100 100 ' stroke='#{$svgStrokeColor}' stroke-width='1'/%3E%3C/svg%3E");
        }
        }
      }
    }



  .product-variations {
    margin-bottom: 0px;
    @media screen and (max-width:768px) {
       margin-bottom: 32px;
    }
    position: relative;

    .availability-msg {
      padding: 0;
      margin-bottom: 14px;
        }

    .attributecontentlink {
      margin: 6px 0 0;
      color: $base;
      @extend .bold !optional;
      font-size: .75em;
      &:hover {
        text-decoration: underline;
        color: inherit;
        }
        }

    .attribute {
      overflow: visible;
      clear: both;
          }
    .variation-title {
      display: flex;
      font-family: $font-type5;
      font-weight: bold;
      .label {
        font-family: $font-type5;
        .tooltip-content{
          display: none;
        }
      }
      .normal{
        font-size: 14px !important;
        font-weight: normal !important;
        line-height: 21px;
        @media screen and (max-width:768px) {
          line-height: 24px;
        }
        margin-left: 5px !important;
        letter-spacing: normal !important;
      }
      &.error-show {
        .label {
          width: 70%;
          color: $label-error;
          font-family: $font-type5;
        }
        .error-required {
          width: 30%;
          text-align: right;
          font-family: $font-type5;
          font-size: 14px;
          float: right;
          color: $label-error;
          font-weight: normal;
        }
      }
    }
    .label {
      @extend .bold !optional;
      color: $base;
      .variant-color-name {
        @extend .normal !optional;
      }
    }

    a.clear-selections {
      font-size: .75em;
      display: block;
    }
    
  }
  
  .variation-container {
    .product-variations {
      .attributecontentlink, .label, .append {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
      }

      .label.m-margin {
        display: block;
        margin-bottom: 8px;
      }
    }

    .pdp-drawer-header {
      .pdp-drawer-title {
        font-weight: bold;
        letter-spacing: 0;
        font-size: 14px;

        @media screen and (max-width:768px) {
          text-transform: uppercase;
        }
      }
    }
  }
  
  .customerType {
    .value {
      display: none;
    }
    
    li {
      float: left;
      width: 50%;
      padding-right: 5px;
      margin-bottom: 5px;
      box-sizing: border-box;
      
      &:nth-child(2) {
        padding-right: 0;
      }
    
      .value {
        display: flex;
        align-items: center;
        justify-content: center;
        height: $swatch-height;
        margin: 0;
        border: $mainbordercolor 1px solid;
        
        label {
          display: flex;
          justify-content: center;
          align-items: center;
          text-transform: none;
          cursor: pointer;
          width: 100%;
          height: 100%;
          padding: 0 10px;

          @extend .default-p-text !optional;
        }
      }
      
      &.selected .value {
        background: $refinement-hover;
        color: $white;
        
        label {
          color: $white !important;
        }
      }
      
      .valuemobi input {
        display: block;
        float: left;
        width: auto;
        margin: 1px 0 0;
      }
    }
  
    input {
      display: none;
    }
  }

  &.alternate-color .variations-color.variation-item .product-variations {
    .title {
      border: 0;
      background: initial;
    }

    .value {
      display: block !important;
      position: static;
      max-height: 93px;
      overflow: hidden;
      transition: max-height 0.15s ease-out;

      &.expanded {
        max-height: 400px;
        transition: max-height 0.25s ease-in;
      }
    }

    ul.swatches {
      margin: 0;
      padding: 0 1px 1px 0;
      border: 0;
      position: relative;

      li {
        height: 46px;
        margin-bottom: 0;

        .swatchanchor {
          width: 65.75px;
          height: 47px;
          background-size: contain !important;
          background-position: center !important;
          background-repeat: no-repeat !important;
        }
      }
    }

    .toggle-expand {
      text-align: center;
      cursor: pointer;
      padding-top: 10px;

      .open,
      .closed {
        background-image: url('../images/interface/icon-refinement-arrow-highlight.png');
        background-repeat: no-repeat;
        padding-right: 20px;
        background-position: right -39px;
      }

      .open {
        display: none;
      }

      &.expanded {
        .open {
          display: inline;
          background-position: right 5px;
        }

        .closed {
          display: none;
        }
      }
    }
  }

  .variations-other ul {
    clear: both;
  }

  ul.swatches {
    li.unselectable:hover {
      border-color: white;
    }
  }

  .variant-dropdown {

    select.variation-select {
      width: 100%;
      padding: 0 21px;
      margin-bottom: 10px;
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      height: 55px;
    }

    span.label + div.value {
      select {
        background-image: none;
      }

      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        right: 20px;
        top: calc(50% - 5px);
        transform: translateY(-50%);
        @include sprite($icon-refinement-down);
        pointer-events: none;
      }
    }
  }
}

.variation-container {
  &.discontinued {
    margin-top: 15px;
  }
}

//mobile styles
@include breakpoint-max($tablet) {
  .pdp-main {
    .variant-dropdown {
      .value {
        width: 100%;
        text-align: center;
      }

      .label {
        display: none;
      }

      select.variation-select {
        margin: auto;
        margin-bottom: 15px;
        padding: 12px 10px;
      }
    }
  }
  
  .product-variations {
    &.dropdown,
    &.dropdownvariation {
      &:last-child .toggle-content .sizewidthdropdown li.attribute:first-child {
        width: 158px;
        margin-left: 0;
      }

      .toggle {
        text-align: left;
        box-sizing: border-box;
        margin: auto;
      }

      ul.swatches.Color.swatch-size-small .swatchanchor {
        background-position: center !important;
        background-size: contain !important;
      }
    }
    
    .attributecontentlink {
      margin-top: 15px;
    }
  }
}

#pdpMain .variations-color-container {
  margin-top: 0;
  @media screen and (max-width:768px) {
      margin-top: 0px;
  }
}

#pdpMain .variations-type.variation-item {
  margin-bottom: 32px;

  @media screen and (max-width:768px) {
    .product-variations { 
      margin-bottom: 0;
    }
  }
}

@include breakpoint-max ($desktop) {
    // Makes size/width dropdown display open by default
    .pt_product-details {
      .pdp-main.pdpv2
        .product-col-2
        .variant-content:not(.persistent)
        .product-variations {
          .toggle {
            display: none;
  
            &:after {
              content: '';
              background: none;
            }
          }
      }
    }
  
    .js #product-content:not(.persistent) .pdpForm .toggle-content,
    .js .pdpsetform .toggle-content {
      display: block;
      box-sizing: border-box;
      position: relative;
      z-index: 1;
    }
    
    .js .quickview #product-content:not(.persistent) .pdpForm .toggle-content {
      @include breakpoint-max($mobile) {
        border: $borderMixin;
        border-top: none;
        padding: 10px;
      }
    }
  
    .pdp-main.pdpv2 {
      .product-col-2 .variant-content:not(.persistent) {
        span.label {
          display: block;
          text-transform: uppercase;
          
          .mobile-only {
            display: inline-block;
          }
        }
  
        .product-variations .toggle-content {
          background: none;
          border: none;
        }
  
        .variations-type.variation-item {
          .toggle {
            background: none;
            padding: 0;
            height: auto;
            padding-bottom: 5px;
  
            &.expanded {
              border: none;
            }
  
            &:after {
              content: '';
              background-image: none;
            }
          }
  
          .toggle-content {
            display: flex !important;
            flex-wrap: wrap;
            margin: 0;
            left: 0;
  
            & > li {
              width: auto;
              padding-bottom: 5px;
              padding-right: 5px;
              box-sizing: border-box;
  
              &.selected {
                div.value {
                  background: $primary;
                  border-color: $primary;
  
                  label {
                    color: $white;
                  }
                }
              }
  
              div.value {
                border: $borderMixin;
                padding-top: 0;
                padding-bottom: 0;
  
                label {
                  color: initial;
                  height: $swatch-height;
                  line-height: $swatch-height;
                }
              }
            }
          }
        }
  
        .product-variations.dropdownvariation {
          .toggle-content {
            .attribute ul {
              display: flex;
              flex-wrap: wrap;
              box-sizing: border-box;
  
              li {
  
                &.attribute {
                  width: 100%;
                }
              }
            }
  
            .sizewidthdropdown {
              li.attribute {
                width: 100%;
  
                label {
                  margin: 0;
                  width: 100%;
                }
  
                .swatches.width label {
                  height: $swatch-height;
                  line-height: $swatch-height;
                }
              }
            }
          }
        }
      }
    }
}

.quickview {
  .pdp-main {
    .product-variations {
      margin-bottom: 22px;
      position: relative;        
    }
  }
}
#sizeChart {
  .size-chart-container {
    .attributecontentlink {
      cursor: pointer;
    }
  }
}
