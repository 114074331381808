@use "sass:math";

// Set default value for variation-select
$persistentHeight: 57px !default; // this one *shouldn't* be overridden
$headerHeight: 40px !default;
$nav-bg-width: 100% !default;
// svgs need height set
$persistent-logo-height: $persistentHeight - 24px !default;
$persistent-logo-offset: 18px !default;
$logo-width: 231px !default;
.top-header-banner {
  position: absolute;
  min-height: 31px;
  width: 100vw;
}
.minimal-header .eu-cookie-message + .header-style-wrapper .top-header-banner {
  min-height: 0;
}
.header-search {
  input[type="text"] {
    width: 245px;
  }
}

// Promotions are combined into one template and included in the mobile header to help
// address some layering/z-index weirdness. We just need to hide everything in the mobile 
// header except for the promotions.
#mobile-header {
  .header-top-box, .wrap-header-banners, .cookie-promo { display: none; }
  #header { min-height: 0; }
}

.header-style-wrapper {
  position: relative;
  z-index: 200;
}

//sticky-search-box
//----------------------
.sticky-search-enabled .sticky-search-container{
   -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    position: fixed;
    margin: 0 auto;
    height: 0;
    z-index: 2400;

    .header-search.site-search input[type="text"] {
      width: 190px;
    }
}

.menu-utility {
  margin-top: 9px;
  margin-bottom: 9px;
}

.current-lang .sprite-flag {
  display: block;
}

.primary-logo img {
  width: $logo-width;
  height: 100%;
  padding-top: 5px;
}

.active-persistentheader,
.minimal-header .persistentheader {
  height: $persistentHeight;
  
  // vertically center logo based on fixed height of parent .persistentheader
  .primary-logo {
    position: relative;
    top: ceil(math.div($persistentHeight, 2));
    transform:translateY(-50%);
    margin-right: $persistent-logo-offset;

    img {
      width: auto;
      max-height: $persistentHeight;
      padding-top: 0;
      vertical-align: middle;
      &.favicon-logo {
        max-width: 50px;
        max-height: 50px;
      }
      &:not(.favicon-logo) {
        height: $persistent-logo-height;
      }  
    }
  }

  .primary-logo a {
    display: block;
    height: 100%;
  }

  ul.level-1 {
    margin: 0;
  }
  
  a.level-1 {
    box-sizing: border-box;
    height: $persistentHeight;
    line-height: $persistentHeight;
    padding: 0 9px;
  }
  
  .mini-cart-content {
    top: 10px;
  }
}
.persistentheaderalias {
  display: none;
  height: $headerHeight;
}
//remembered items
//REF .remembered-box a.remembered-text:hover {color: $primarylink;}
.remembered-box {
  display: inline-block;
  margin: 0 21px 0 0;
  position: relative;
  z-index:205;
  padding-top: 9px;
  padding-bottom: 9px;
  
  .remembered-text{
    display: block;
    position: relative;
    padding-left: 23px;
    @extend .remembered-info-text !optional;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      @include sprite($header-icon-remember);
    }
  }
  .active-persistentheader &,
  .minimal-header .persistentheader & {
    padding: 0;
    a {
      line-height: $persistentHeight;
    }
  }
}
#mini-cart{
  display: inline-block;
  margin: 0px 0 0 0;
  position: relative;
  z-index: 200;
  padding: 9px 0 0;
}
.mini-cart-total {
  // padding: 9px 0;
  
  .active-persistentheader &,
  .minimal-header .persistentheader & {
    padding: 0;
    
    a, span {
      line-height: $persistentHeight;
    }
  }
  
  .mini-cart-empty { margin-left: 6px; }
}

.mini-cart-inner {
  .jspVerticalBar {width: 7px;}
  .jspTrack {
    background: none;
    border-radius: 6px;
  }
  .jspDrag {
    border-radius: 6px;
    background: $grey-silver;
  }
}

.mini-cart-link-cart {
  margin: 20px auto 10px;
}

.mini-cart-subtotals {
  margin: 0 -5px;
  
  .label {
    box-sizing: border-box;
    padding-left: 11px;
  }
}

#mini-cart .mini-cart-product:first-child {
  padding-top: 0;
}

.mini-cart-slot {
  > :first-child{margin-top: 23px}
  p {
    margin: 0; // normalize.css causing extra space
  }
}

.mini-cart-name,
.mini-cart-attributes > span,
.mini-cart-pricing .label {
  display: inline-block;
  margin-bottom: 6px;
}

.mini-cart-attributes > span:first-of-type {
  display: inline; //this is to keep empty spans from creating a whitespace
}

.mini-cart-attributes .attribute {
  width: 100%;
}

// This weird combination of padding between mini-cart-inner, mini-cart-totals,
// and mini-cart-products is to account for the scrollbar that appears with more than 2 products
.mini-cart-content .mini-cart-inner {
  box-sizing: border-box;
  padding: 24px 4px 23px 15px;
  width: 280px;
  top: 37px;
  
  .active-persistentheader &,
  .minimal-header & {
    top: 45px;
  }
}
.mini-cart-totals {
  padding: 0 16px 0 5px;
}
.mini-cart-products {
  padding-right: 11px;
  
  &.jscrollpane {
    padding-right: 0;
    overflow-y: scroll;
  }
}

// Begin Navigation
nav.inner {
  position:static;
  height: 100%;
  display: flex;
  align-items: center;
}
.submenu-background { 
  display: none;
  position:absolute;
  top:96%;
  left:50%;
  transform:translateX(-50%);
  width: $nav-bg-width;
}

.active-persistentheader,
.minimal-header .persistentheader {
  .submenu-background,
  div.level-2{
    top: 57px;
  }
}

div.level-2 {
  padding-top: 29px;
  padding-bottom: 24px; // 34 from comp minus the extra 10 added from js generated bg
} 

.menu-wrapper {
  .column1,
  .column2,
  .column3,
  .column4,
  .column5 {
    box-sizing: border-box;
    padding-right: 12px;
    width: 169px;
    @media screen and(min-width:768px) and (max-width:820px){
      width: 150px;
    }
  }
}

.adjust-menu-column {
	div[class^='column']{
		width: auto;
		max-width: 155px;
	}
}
.sub-sub-nav {
  padding-left: 13px;
  
  a {padding: 6px 0;}
}

$mainnav-tab-border-widths: 0 !default;
$mainnav-tab-border-color: transparent !default;
ul.level-1 > li.hover-item:hover,
ul.level-1 li.hover-item {
  border-width: $mainnav-tab-border-widths;
  border-color: $mainnav-tab-border-color;
}

//minimal header specific
.minimal-header {
  #header { min-height: 57px; }
	#navigation {
		min-width: 960px;
		position: fixed;
		top: 0;
		left: 0;

		.primary-logo {
			display: block;
		}
	}
}

.breadcrumb, .primary-content .breadcrumb {
  a {display: inline-block;}
}

.pt_checkout.minimal-header .persistentheader{ 
	.notpersistent {
		display:none;
	}
}

@media screen and (min-width: 742px){
  #desktop-header #header {
      position: initial;
  }
  #desktop-header #navigation .inner {
    max-width: 1137px;
    margin: 0 auto;
    padding: 0;
  }
  #navigation .primary-logo img {
    height: 22px !important;
  }
  #navigation .primary-logo {
    position: initial !important;
  }
  #desktop-header .header-style-wrapper #header {
    min-height: 90px !important;
    z-index: 1204;
  }
  #desktop-header .persistent-inactive {
    position: fixed !important;
    top: 35px !important;
    height: 62px !important;
  }

  #navigation.persistentheader .primary-logo {
    display: flex !important;
  }

  #navigation .primary-logo img {
    padding-top: 0 !important;
    width: 95px;
  }

  ul.level-1 li a.level-1 {
      position: relative;
      text-decoration: none;
      line-height: 23px;
      text-decoration: none;
      display: block;
  }

  #navigation .mini-cart-total .mini-cart-empty:not(.new-header), #navigation .mini-cart-total .mini-cart-quantity-bag:not(.new-header),
  #navigation .mini-cart-content .mini-cart-empty:not(.new-header), #navigation .mini-cart-content .mini-cart-quantity-bag:not(.new-header) {
    position: absolute;
    left: 8px;
    width: 13px;
    height: 13px;
    line-height: 12px;
    border-radius: 50%;
    text-align: center;
  }
  #navigation .desktop-mini-cart .mini-cart-total .mini-cart-quantity-bag:not(.new-header) {
    left: 17px !important;
  }
  .header-search input[type="text"] {
    width: 100% !important;
  }
  #navigation .mini-cart-content .mini-cart-inner {
    top: 42px !important;
  }
}

/*@media screen and (min-width: 1800px) {
  .site-search.header-search {
    left: 62.5%;
  }
}
@media screen and (max-width: 1700px) {
  .site-search.header-search {
    left: 63.5%;
  }
}
@media screen and (max-width: 1600px) {
  .site-search.header-search {
    left: 64.5%;
  }
}
@media screen and (max-width: 1500px) {
  .site-search.header-search {
    left: 65.5%;
  }
}
@media screen and (max-width: 1400px) {
  .site-search.header-search {
    left: 66.5%;
  }
}
@media screen and (max-width: 1300px) {
  .site-search.header-search {
    left: 67.5%;
  }
}
@media screen and (max-width: 1200px) {
  .site-search.header-search {
    left: 69.5%;
  }
}
@media screen and (max-width: 1100px) {
  .site-search.header-search {
    left: 71%;
  }
}
@media screen and (max-width: 1000px) {
  .site-search.header-search {
    left: 72.5%;
  }
}*/
@media screen and (max-width: 1000px) {
  /*.site-search.header-search {
    left: 73.5%;
    width: 210px;
  }*/
  #header .top-of-header .top-header-banner {
    width: 97% !important;
  }
  #desktop-header #navigation .inner {
    padding: 0 10px !important;
  }
}
@media screen and (max-width: 900px) {
  /*.site-search.header-search {
    width: 100px;
    margin-left: 40px;
  }*/
}
@media screen and (min-width: 768px) {
  #desktop-header #header {
    position: initial;
  }
  #desktop-header #navigation .inner {
    max-width: 1600px;
    margin-right: calc(235px + 11.5%);
    padding: 0 0 0 70px;
  }
  #navigation .primary-logo img {
    height: 42px !important;
    width: 190px;
  }
  #navigation .primary-logo {
    position: initial !important;
  }
  #navigation  #mini-cart .mini-cart-header {
    padding: 5px 10px 17px !important;
  }
}
header .header-search{
  position: fixed;
  top: 50px;
  width: 230px;
  margin-left: -30px;
  z-index: 1200 !important;
  padding: 8px;
  margin-top: 0px !important;
}
#desktop-header .primary-logo{
  display: none !important;

  a{
    line-height: 0px;
  }
}

//Sign in  header pop up
.nav-signin-popover{
  position: absolute;
  left: 50%;
  top: 40px;
  opacity: 1;
  background: $white;
  border-radius: 3px 3px 3px 3px;
  font-size: 13px;
  box-shadow: 0px 1px 4px #aaa;
  padding: 8px 5px;
  z-index: 6;
  width: max-content;
  height: auto;
  transform: translate(-50%, -0.5rem);
}

.top-center-arrow {
  height: 14px;
  width: 14px;
  background-color: $white;
  position: absolute;
  left: 50%;
  top: 0%;
  box-shadow: -2px -2px 3px #d8d8d8;
  transform: translate(-50%, -50%) rotate(45deg);
}

.nav-center-align{
  text-align:center;
  margin: 0 10px 0 10px;
}

.nav-center-align .signin-button{
  text-align: center;
  font-size: 12px !important;
  padding: 3% 25% !important;
  margin: 0 10px 0 10px;
}

.nav-signin-tooltip-footer{
  margin-top: 12px;
  font-size: 12px;
  padding-left: 5px;
}

.nav-signin-tooltip-footer a{
  font-size: 12px !important;
  padding-left: 5px;
  margin: 0px 0px 0px 10px !important;
  vertical-align: inherit!important;
}

.header-style-wrapper #header .menu-utility {
  z-index : 1005 !important;
}

#desktop-header .top-of-header .header-banner{
  right: 43%;
  position: fixed;
}

.nav-signin-popup-header-close  {
  img {
    width: 12px;
    height: 12px;
  }
  float: right;
  margin-right: 6px;
  cursor: pointer;
}

.menu-nav-centered {
  .desktop-navigation {
    display: flex;
    justify-content: center;
    flex: 1 1 100%;
    padding: 0 20px;
  }
}
