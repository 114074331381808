//Default Colors
$black: #000 !default;
$white: #FFF !default;
$yellow: #FF0 !default;
$blue: #00F !default;
$red: #F00 !default;
$green: #0F0 !default;

//Common Colors
$orange: #FFA500 !default;
$navy: #003 !default;
$beige: #F5F5DC !default;
$purple: #800080 !default;
$brown: #783201 !default;
$pink: #FE249A !default;
$tan: #DDCEB0 !default;
$gold: #C5B358 !default;
$cream: #FFFDD0 !default;

//Grey Library
$grey-charcoal-dark: #333;
$grey-medium-dark: #444;
$grey-charcoal: #414141;
$grey-medium: #666;
$grey-dove: #707070;
$grey-medium-light: #999;
$grey-silver-dark: #AAA;
$grey-gravel: #BBB;
$grey-silver: #CCC;
$grey-light: #DDD;
$grey-gainsboro: #DCDCDC;
$grey-light-very: #EEE;
$grey-white-light: #F0F0F0;
$grey-white-smoke: #F5F5F5;
$grey-sonic-silver: #767676;

//Messaging
$promo-adjustment-color: #990000 !default;
$errorColor: #990000 !default; 
$infoColor: #0000ff !default; 
$successColor: #008000 !default; 
$callout: #e71717 !default;

//global
$gray: $grey-medium-light !default;
$gray-swatch: #8F979D !default;
$base: $grey-charcoal !default;
$contrast: $white !default;
$primarylink: #241fff !default;
$cart-text-callout: #962226 !default;

//Brand
$primary: $black !default;
$secondary: $grey-light !default;
$tertiary: $gray !default;
$primarylink: #241fff !default;
$mainbackground: $white !default;
$secondarybackground: $grey-silver !default;
$mainbordercolor: $grey-charcoal !default;
$cart-text-callout: #962226 !default;

//Header
$header-message-background: #F0D8DB!default;

// Footer
$footer-background: $gray !default;
$footer-border: $gray !default;
$footer-bottom: $white !default;

//Price
$price-list: $grey-medium-light !default;
$price-promo: #990000 !default;
$price-strikethrough: $grey-sonic-silver !default;

//Gallery / Search / PLP
$sort-bg: $white!default;
$per-page-bg: #D9D9D9 !default;
$per-page-border: #C2C2C2 !default;
$per-page-color: $grey-charcoal-dark !default;
$search-bg: #EFEFEF !default;
$search-form-bg: $white !default;
$search-shaded-bg: rgba(128, 128, 128, 0.5) !default;

//Refinements
$search-refinement-color: $white !default;
$search-unselectable-color: #939598 !default;
$mobile-filter-buttons: $white !default;
$selected-filter-color: $white !default;

//PDP
$remember-btn-background:  $white !default;
$swatch-selected-text: $white !default;
$swatch-selected-bg: #373836 !default;
$swatch-selected-border: #373836 !default;
$swatch-hover-text: $white !default;
$swatch-hover-bg: $grey-charcoal !default;
$swatch-hover-border: #373836 !default;
$swatch-disabled-text: #7C7C7C !default;
$swatch-disabled-bg: $grey-gravel !default;
$swatch-disabled-border: $grey-gravel !default;
$pdp-toggle-color: $white !default;
$breadcrumb: $grey-sonic-silver !default;
$product-breadcrumb: $black !default;

//Form
$disabled-input-color: $grey-gravel !default;
$message-background: #F1E6E6 !default;

//Reviews
$secondarybackground: $grey-light-very !default;
$rating-bar-color: $grey-light !default; 

//Light Gallery
$lg-sub-html-bg: $white !default;

//Compare
$compare-unchecked: #737d85 !default;
$compare-checked:#A41E21 !default;

//Wishlist
$wishlist-light-color: #a8a8a8 !default;

//Cart
$even-row-background: $white !default;

//WCAG-Complaint
$destructive-red: #ED0000;
$deep-red: #962226;
$warning-yellow: #AD6200;
$success-green: #008114;

//Sale price & Sale Text Color
$sale-text-price: $deep-red !default;
$product-name-link:$blue !default;

//OrderStaus
$orderStatus: $black !default;
$drawer-heading-color : #071C2C !default;

// Checkout order summary
$order-summary-heading-text-color: $black !default;
$order-summary-heading-text-weight: normal;
$order-summary-estimated-total-color: $black !default;
$minicart-nwa-hover: $blue !default;
