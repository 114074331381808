@use "sass:math";
@use "sass:map";

@function opposite-direction($directions) {
  $opposite-directions: ();
  $direction-map: (
    'top':    'bottom',
    'right':  'left',
    'bottom': 'top',
    'left':   'right',
    'center': 'center',
    'ltr':    'rtl',
    'rtl':    'ltr'
  );

  @each $direction in $directions {
    $direction: to-lower-case($direction);

    @if map-has-key($direction-map, $direction) {
      $opposite-directions: append($opposite-directions, unquote(map-get($direction-map, $direction)));
    } @else {
      @warn "No opposite direction can be found for `#{$direction}`. Direction omitted.";
    }
  }

  @return $opposite-directions;
}

@function str-replace($str,$mtch,$rplc) {
  $rtn: $str;

  //Quick and dirty loop
  @for $i from 1 through 20 {
    $ind: str-index($rtn,$mtch);

    @if($ind) {
      $rtn: str-slice($rtn, 1, $ind - 1) + $rplc + str-replace(str-slice($rtn, $ind + str-length($mtch)), $mtch, $rplc);
    } @else {
      @return $rtn;
    }

  }
  @return $rtn

}

@mixin box-shadow($shadow) {
  box-shadow: $shadow;
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  -ms-box-shadow: $shadow;
  -o-box-shadow: $shadow;
}

@mixin transition($transition) {
  transition: $transition;
  -o-transition: $transition;
  -ms-transition: $transition;
  -moz-transition: $transition;
  -webkit-transition: $transition;

}

@mixin triangle($direction, $color: currentcolor, $size: 1em) {
  &:before {
    content : '';

    width   : 0;
    height  : 0;
    position: absolute;

    $transparent-borders: $size solid transparent;

    border-#{$direction}: $transparent-borders;
    border-#{opposite-direction($direction)}: $size solid $color;

    @if $direction == top or $direction == bottom {

      #{$direction}:-(2 * $size);
      right : 0;
      left  : 0;
      margin: auto;
      border-left : $transparent-borders;
      border-right: $transparent-borders;

    } @else if $direction == right or $direction == left {

      #{$direction}:-(2 * $size);
      top: 50%;
      transform: translateY(-50%);
      border-bottom : $transparent-borders;
      border-top    : $transparent-borders;

    }
  }
}

//Used for complicated addon shapes like tooltip triangles
@mixin filterShadow($direction, $shadow) {
  &:before {
    $hor  : 0.7 * nth($shadow, 1);
    $vert : 0.7 * nth($shadow, 2);
    $disp : 0.7 * nth($shadow, 3);
    $color: nth($shadow, 4);

    @if $direction == left    and  $hor > -2 * $disp { $hor  : -2 * $disp; }
    @if $direction == right   and  $hor <  2 * $disp { $hor  :  2 * $disp; }
    @if $direction == top     and $vert > -2 * $disp { $vert : -2 * $disp; }
    @if $direction == bottom  and $vert <  2 * $disp { $vert :  2 * $disp; }

    -webkit-filter: drop-shadow($hor $vert $disp $color);
    filter:         drop-shadow($hor $vert $disp $color);
  }
}

//Mixin for tabs on the customizer PDP page
@mixin configurator-pdp-tabs-border-radiuses ($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
  -webkit-border-top-right-radius:    $topright;
  -webkit-border-bottom-right-radius: $bottomright;
  -webkit-border-bottom-left-radius:  $bottomleft;
  -webkit-border-top-left-radius:     $topleft;

  -moz-border-radius-topright:        $topright;
  -moz-border-radius-bottomright:     $bottomright;
  -moz-border-radius-bottomleft:      $bottomleft;
  -moz-border-radius-topleft:         $topleft;

  border-top-right-radius:            $topright;
  border-bottom-right-radius:         $bottomright;
  border-bottom-left-radius:          $bottomleft;
  border-top-left-radius:             $topleft;
  border: 0;
  padding: 0;
}

// Option for shaded first-column ("Featured") in main navigation
@mixin featured-column($background-color){
  .column1 ul.level-2 {
    padding: 10px 20px !important;
    box-sizing: border-box;
    background: $background-color;
  }
}

@mixin sprite-width($sprite, $scale) {
  $sprite-width: nth($sprite, 5);
  @if type-of($sprite-width) == 'number' and type-of($scale) == 'number' {
    width: math.div($sprite-width, $scale);
  }
}

@mixin sprite-height($sprite, $scale: 1) {
  $sprite-height: nth($sprite, 6);
  @if type-of($sprite-height) == 'number' and type-of($scale) == 'number' {
    height: math.div($sprite-height, $scale);
  }
}

@mixin sprite-position($sprite, $scale: 1) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  @if type-of($sprite-offset-x) == 'number' and type-of($sprite-offset-y) == 'number' and type-of($scale) == 'number' {
    background-position: math.div($sprite-offset-x, $scale)  math.div($sprite-offset-y, $scale);
  }
}

@mixin sprite-image($sprite, $scale: 1) {
  $sprite-width  : nth($sprite, 7);
  $sprite-height : nth($sprite, 8);
  $sprite-image  : nth($sprite, 9);

  background-image: url(#{$sprite-image});
  @if type-of($sprite-width) == 'number' and type-of($sprite-height) == 'number' and type-of($scale) == 'number' {
    background-size: math.div($sprite-width, $scale)  math.div($sprite-height, $scale);
  }
}

@mixin sprite($sprite, $scale: 1) {
  @include sprite-image($sprite, $scale);
  @include sprite-position($sprite, $scale);
  @include sprite-width($sprite, $scale);
  @include sprite-height($sprite, $scale);
}

@mixin sprite-scale-width($sprite, $width) {
  $icon-width : nth($sprite, 5);
  @if type-of($icon-width) == 'number' and type-of($width) == 'number' {
    $scale      : math.div($icon-width, $width);

    @include sprite-image($sprite, $scale);
    @include sprite-position($sprite, $scale);
    @include sprite-width($sprite, $scale);
    @include sprite-height($sprite, $scale);
  }
}

@mixin sprite-scale-padding($sprite, $scale, $width, $height) {
  $icon-offset-x : nth($sprite, 3);
  $icon-offset-y : nth($sprite, 4);
  $icon-width    : nth($sprite, 5);
  $icon-height   : nth($sprite, 5);
  $sprite-width  : nth($sprite, 7);
  $sprite-height : nth($sprite, 8);
  $sprite-image  : nth($sprite, 9);

  $padding-x : math.div(($width  - (math.div($icon-width, $scale))), 2);
  $padding-y : math.div(($height - (math.div($icon-height, $scale))), 2);

  width               : math.div($icon-width, $scale);
  height              : math.div($icon-height, $scale);
  background-size     : math.div($sprite-width, $scale)  math.div($sprite-height, $scale);
  background-image    : url(#{$sprite-image});
  background-clip     : content-box;
  box-sizing          : content-box !important;
  background-position : math.div($icon-offset-x, $scale + $padding-x)  math.div($icon-offset-y, $scale + $padding-y);
  padding             : $padding-y $padding-x;
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

@mixin all-sprites() {
  .sprite-icon {
    display: block;
    @extend %sprite-icon !optional;
  }
  $sprite-size: (
    1: 'xl',
    2: 'l',
    3: 'm',
    4: 's',
    5: 'xs',
    6: 'xxs',
    7: 'xxxs'
  );
  @each $sprite in $spritesheet-sprites {
    $sprite-name: str-replace(nth($sprite, 10),' ','-');
    @for $size from 1 through 7 {
      .sprite-#{$sprite-name + '--' + map.get($sprite-size,$size)} {
        @include sprite($sprite,(.25 + ($size * .15)));
      }
    }
  }
}


@mixin scarcity-icon {
  width: 14px;
  height: 13px;
  margin-right: 5px;
  background-image: url('../images/cartcheckout/icon-alert.svg');
  background-repeat: no-repeat;
}

@-ms-keyframes spin {
    from { -ms-transform: rotate(0deg); }
    to { -ms-transform: rotate(360deg); }
}
@-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
@mixin spin($animationSpeed: 2000ms, $iteration: infinite, $timing: linear, $direction: normal) {
  -webkit-animation-name: spin;
  -webkit-animation-duration: $animationSpeed;
  -webkit-animation-iteration-count: $iteration;
  -webkit-animation-timing-function: $timing;
  -moz-animation-name: spin;
  -moz-animation-duration: $animationSpeed;
  -moz-animation-iteration-count: $iteration;
  -moz-animation-timing-function: $timing;
  -ms-animation-name: spin;
  -ms-animation-duration: $animationSpeed;
  -ms-animation-iteration-count: $iteration;
  -ms-animation-timing-function: $timing;

  animation-name: spin;
  animation-duration: $animationSpeed;
  animation-iteration-count: $iteration;
  animation-timing-function: $timing;
  animation-direction: $direction;
}

// Responsive media query mixins
// Variable sizes pulled from flexboxgrid
$adaptive-mobile: 321px;
$mobile: 577px;
$tablet: 769px;
$desktop: 937px;
$large-desktop: 1281px;
$widescreen: 1600px;
@mixin breakpoint($device) {
    @media screen and (min-width: $device), print {
        @content;
    }
}
@mixin breakpoint-max($device) {
  @media screen and (max-width: ($device - 1px)), print {
      @content;
  }
}

@mixin filter-count {
  background-color: $destructive-red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align-last: center;
  color: $white;
  line-height: 20px;
  font-size: 12px;
  align-self: center;
}

@mixin cart-header {
  font-size: 24px !important;
  font-weight: bold !important;
  line-height: 32px !important;
}




// START: Cart icon and counter
// -----------------------------------
// Used in the following locations (all breakpoints):
// * site header (check home AND interior)
// * mini cart
// * cart page
//
// Markup is inconsistent: in some instances the counter
// is a sibling in and others it's a child
//
// #refactor #todo

@mixin icon-cart {
  content: "";
  display: block;
  width: 22px;
  height: 22px;
  background: url('../images/svg/icon-cart.svg') center no-repeat;
  background-size: contain;
  .pt_storefront & {
    @include breakpoint($tablet){
      margin-bottom: -1px;
    }
  }
}

@mixin icon-cart-counter {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  padding: 0;
  font-size: 12px;
  line-height: 0;
  background: $destructive-red;
  color: $white;

  &.empty {
    display: none;
  }
}

// -----------------------------------
// END: Cart icon and counter

