.pdp-main .bonus-variations .product-variations .Color.swatch-size-small li {
  width: 70px !important;
}

.pdp-main .bonus-variations {
  .swatches.Color.swatch-size-small .swatchanchor {
    @media screen and (min-width: 937px) {
      height: 40px !important;
      width: auto !important;
    }
  }
  .product-variations {
    .sizewidthdropdown {
      margin-bottom: 0 !important;
    }
  }
}

.pdp-main .bonus-variations .swatches {
  margin-left: -8px !important;
  float: inherit;
  &.size {
    margin-right: 8px;
  }
}

.bonus-product-row {
  width: 100%;
  display: flex;
}

// When selecting product:
.bonus-dialog {
  // width: 785px !important; 
  max-width: 92vw !important; //overwriting janky jquery ui styles
  
  @include breakpoint($tablet) {
    top: 50% !important; //overwriting janky jquery ui styles
    transform: translateY(-50%) !important;
  }
    
  .js & .ui-widget-header.ui-dialog-titlebar {
    float: none;
    height: auto;
    padding: 0;

    .ui-dialog-title {
      margin-top: 0;
      display: block;
      float: none;
    }

    .ui-dialog-titlebar-close {
      top: 0px;
      bottom: 0px;
      margin-top: auto;
      margin-bottom: auto;
    }
    .bonus-product-modal-title {
      // Header area - "Select your free gift below"
      padding: 0 20px;
      height: 60px;
      line-height: 60px; //this is a best guess, not specified in comp
      @extend %gwp-modal-title !optional;
      // should return:
      // font-size, line-height, font-family, text-transform, color, letter-spacing
    }
  }
  
  .dialog-content {
    // min-height: 400px !important; //overwrite jquery ui added styles
    height: auto !important; //overwrite jquery ui added styles
  }

  .promotion.details {
    margin: 0;
  }

  .promotion.change-selection a {
    padding: 5px 20px;
    display: block;
  }

  .bonus-product-list-items {
    padding: 44px 29px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;

    &.no-selection {
      padding: 20px;

      .bonus-product-row {
        display: table-row;
        display: flex;
        flex-basis: 100%;

        + .bonus-product-row .bonus-product-item {
          border-top-color: transparent;
        }
      }

      .bonus-product-item {
        display: table-cell;
        width: 49%;
        flex-basis: 49%;
        @extend %gwp-item-selection-border !optional;

        .product-col-1 {
          padding: 20px;

          .product-primary-image {
            border: 0;
            max-width: 100%;

            img {
              max-width: 100%;
              height: auto;
            }
          }

          .product-name {
            // The comps say to just use the styles from the
            // gallery pages:
            @extend .product-zone-item-name !optional;
          }
        }

        &.grid-position-1,
        &.grid-position-2 {
          .product-col-1.full-width {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 0;
          }
        }

        &.grid-position-2 {
          border-left-color: transparent;
        }
      }

      .grid-position-1:nth-child(n + 2) {
        clear: left;
      }

      .product-col-1 {
        cursor: pointer;
      }
    }

    .bonus-product-item {
      display: none;

      &.selected {
        display: block !important; //overwrite js
        width: 100%;

        .product-primary-image {
          padding: 20px;
          max-width: 450px;
          margin: auto;
          
          img {
            max-width: 100%;
          }
        }

        .product-name {
          @extend %gwp-selected-product-name !optional;
          // => Should return:
          //      family, transform, color, font-size, line-height, letter-spacing
        }
      }

      .product-col-1 {
        width: 100%;
    
        @include breakpoint ($tablet) {
            box-sizing: border-box;
            float: left;
            width: 40%;
        }

        .product-primary-image {
          box-sizing: border-box;
          @extend %gwp-gift-product-box !optional;
        }
      }

      .product-col-2.product-detail {
        @include breakpoint ($tablet) {
            width: 40%;
            float: right;
            margin: 0;
            padding: 0;
        }

        .product-name {
          margin: 0;
          display: none;
        }

        .variant-content .bonus-product-form {
          .bonus-variations .variation-item {
            .title.toggle,
            .toggle > span,
            .toggle > span > a {
              @extend %gwp-options-select !optional;
              // => should return:
              //      family, color, letter-spacing, font-size, text-transform, color
            }

            .toggle,
            .toggle-content {
              @extend %gwp-options-select-background !optional;

              li.unselectable label:active {
                pointer-events: none;
              }
            }

            .product-variations {
              .toggle {
                display: none;
              }

              .toggle-content {
                margin-top: -2px;
                box-sizing: border-box;
                width: 100%;
                position: relative;
                padding: 0;
                border: 0;
                display: block !important;
                background: transparent;

                ul {
                  max-width: 100%;
                  padding: 0;
                }
              }
            }
          }

          .product-add-to-cart .button-select-bonus:not(:disabled) {
            @extend .cta-primary;
            @extend %gwp-add-cta !optional;
            // => Should return:
            //      background-color, color, font-family, text-transform, any hover styles
          }
        }
      }
    }
  }
  
  .availabilitycontainer {
    display: none;
  }
}

.no-bonus-btn {
  @extend .inverted-btn !optional;
}

.select-bonus-btn,
.no-bonus-btn {
  display: block;
  margin-bottom: 10px;
}

.buttonbar {
  margin: 11px 0;
  overflow: hidden;
  .bonus-discount-container & {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
  }
}

.bonus-product-list-footer {
  clear: both;

  button {
    float: right;
  }
}

.js .ui-dialog-content .pdp-main.bonus-product-list .product-variations {
  max-height: none;
}

@include breakpoint($tablet) {
  .bonus-dialog
    #bonus-product-dialog
    .bonus-product-list-items
    .bonus-product-item {
    .product-col-1 {
      width: 67%;
    }

    .product-col-2.product-detail {
      width: 33%;
    }
  }
}
