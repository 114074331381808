$rating-indicator-color: $mainbrand !default;
$secondarybackground: $grey-light-very !default;

.pdp-main.pdpv2 {
  .product-tabs.reviewtab-content-box {
    
    .ui-tabs-panel {
      overflow: visible;
    }
    
    .ui-tabs-nav li.ui-state-active {
      border: none;
      background: transparent;
      margin: 0;
      padding: 0;
      text-align: center;
      width: 100%;

      a {
        float: none;
        padding: 0;
      }
    }

    .tab-content {
      border-top: none;
    }

    &.no-review {
      .left-ratings-column {
        display: block;

        div {
          display: none;
        }

        div.write-review-link {
          display: block;
          margin-top: 50px;
          text-align: center;
        }
      }
    }
  }

  .rating-box {
    padding: 0 0 37px;

    .left-ratings-column {
      padding: 0;
      
      .total-rating {
        margin: 0;
        @extend .clearfix;
      }
    }

    .right-ratings-column {
      margin: 0;
    }

    .overall-feedback-box {
      border: 0;
    }
    
    .rating-count-header {
      margin-bottom: 19px;
    }

    &.rating-summary {
      padding: 0 0 34px;
      
      .total-rating-main {
        text-align: center;
        margin: 28px 0 0 0;

        .rating-container,
        .reviews-count {
          display:inline-block;
        }

        .rating-container {
          float: none;
        }
      }

      .left-ratings-column {
        float: none;
        width: 75%;
        margin: 0 auto;

        .more-link {
          text-align: center;
          margin-top: 10px;
        }

        .title-hold {
          float: none;
          text-align: center;
          max-width: none;
          margin-bottom: 17px;
        }

        .rating-breakdown-box {
          margin: 0;
          text-align: center;
          width: 100%;
          left: auto;
          top: auto;
          position: relative;

          .total-reviews {
            cursor: pointer;
            margin: 14px 0 33px;
            display: inline-block;

            &:hover + .rating-count-box {
              opacity: 1;
              z-index: 1;
            }
          }

          .rating-count-box {
            opacity: 0;
            overflow: visible;
            position: absolute;
            right: 30px;
            top: 35%;
            transform: translateY(-50%);
            background: $white;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
            border-width: 2px;
            padding: 22px 20px 20px;
            transition: opacity 0.2s;
            @media(max-width: 766px) {
              display: none;
            }
            &.active {
              display: block;
              opacity: 1;
              z-index: 1;
            }
            @media(min-width: 767px) {
              &:hover {
                display: block;
              }
            }

            &:before, &:after {
              content: "";
              display: block;
              border: 10px solid transparent;
              height: 0;
              width: 0;
              position: absolute;
              top: 50%;
              margin-top: -10px;
            }
            
            &:before {
              left:-22px;
              border-right-color: $mainbordercolor;
            }
            
            &:after {
              left:-20px;
              border-right-color: $white;
            }

            .rnumb {
              display: none;
            }

            .rowhold {
              margin: 0 0 4px;
              
              &:last-child {margin-bottom: 0;}
              
              > span {
                float: left;
                margin-right: 10px;
              }
            }
          }
          
          .rating-bar {
            float: right;
          }
        }

        .write-review-link {
          text-align: center;
          
          a {
            display: block;
            position: relative;
            left: 50%;
            transform:translateX(-50%);
            float: none;
            width: 202px;
          }
        }


        .rating-type-summary {
          margin-top: 58px;
          @extend .clearfix;
        }

        .overall-feedback-box {
          float: left;
          text-align: center;
        }

        .rating-performance-box {
          float: right;
        }
      }

      .overall-feedback-box {
        float: left;
        text-align: center;
      }

      .feedback-box-inner {
        float:none;

        p span:first-child {font-weight: bold;}
      }

      .right-ratings-column {
        max-width: none;
        width: auto;
        float: none;
        text-align: center;
      }

      .star-rating-filter-box .review-filter-sort {
        border: none;
        float: none;
        margin: 60px 0 0;
        width: 100%;

        > span {
          padding: 0;
          background: none;
          border: none;
          width: auto;
          display: inline-block;
          margin-right: 10px;
        }

        .sort-options,
        ul {
          display: inline-block;
        }

        li {
          display: inline-block;
          margin: 0 10px 0 0;
          overflow: visible;
          position: relative;
          
          // left this commented out because they may want this back
          // &.active:after {
          //   width: 0px;
          //   content:"";
          //   height: 0px;
          //   border-style: solid;
          //   border-width: 8px 6px 0 6px;
          //   border-color: $primarylink transparent transparent transparent;
          //   position: absolute;
          //   bottom: -14px;
          //   left: 42%;
          //   z-index: 2;
          // }

          span {
            float: none;
          }
        }
      }

      .rating-type-container {
        width: 40%;
      }

      .rating-slider-wrapper {
        position: relative;
        margin-bottom: 16px;
        
        &:last-child {
          margin-bottom: 0;
        }

        span {
          display: inline-block;
          width: 22%;

          &.title {
            position: absolute;
            right: 100%;
            padding-right: 12px;
            box-sizing: border-box;
            display: inline-block;
            width: auto;
          }

          &.first {
            text-align: left;
            float: left;
          }

          &.middle {
            width: 50%;
          }

          &.last {
            text-align: right;
            float: right;
          }
        }
      }

      .rating-slider {
        background-color: $secondarybackground;
        height: 4px;
        width: 100%;
        position: relative;
        margin-bottom: 14px;

        .rating-indicator {
          position: absolute;
          top: -3px;
          height: 10px;
          width: 10px;
          background-color: $rating-indicator-color;
        }
      }
    }

    &.rating-comments {
      background: none;
      border-top: $borderMixin;
      padding-top: 40px;

      .left-ratings-column {
        box-sizing: border-box;
        float: left;
        padding-right: 20px;
        width: 273px;
      }

      .review-date-box {
        margin: 0;
      }

      .rating-performance-box {
        margin: 25px 0 0;
      }

      .review-action-box {
        border: 0;
        padding: 0;

        .icon-thumb-up,
        .icon-thumb-down,
        #send-report {
          position: relative;
          border: 1px solid $mainbordercolor;
          height: 32px;
          line-height: 32px;
          padding: 0 15px 0 33px;
        }

        #send-report {
          display:inline-block;
          &:before {
            content: '';
            display: inline-block;
            @include sprite($icon-flag);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 10px;
          }
        }

        textarea {
          box-sizing: border-box;
        }
      }

      .overall-feedback-box {
        margin: 26px 0 0;
        text-align: left;

        .feedback-box-inner {
          padding: 0;

          p {
            span {
              display: inline-block;
              &:empty {display: none;}
            }
          }
        }
      }

      .rating-box .rating-count-box .rowhold {
        margin: 0;
      }

      .review-action-box {
        border: 0;
      }

      .review-comments-i {
        background: $secondarybackground;
        padding: 21px 24px 20px;
        margin: 30px 0 0;
        max-width:559px;

        .reply-header {
          padding-bottom: 5px;
        }

        .review-comment-text {
          padding: 0;
          margin: 0;
        }
      }
    }

    .rating-bar > span {
      margin-right:0;
    }

    .rating-box .rating-count-box .rowhold {
      margin: 0;
    }
  }

  #rating-data .rating-box-no-bg {
    background: none;
  }
  .user-info-box .user-name {
    margin: 7px 0 0 0;
  }

  .user-details {
    > span {
      clear:both;
      margin: 0;
    }

    p {
      padding-left: 0;
      margin: 0;
    }
  }

  .rating-performance-box .rating-count-box {
    
    .rowhold > span {
      float: left;
      margin: 0 5px 2px 0;
    }
    
    &:last-child .rowhold > span {
      margin-bottom: 0;
    }
    
    .rating-bar {
      float: right;

      > span {
        margin: 0;
      }
    }
  }

  .pdp-drawer .pagination {
    width: 100%;
    padding: 19px 0 0;
    border-top: $borderMixin;
  }
}

.user-info-box .user-details i {
  font-style: normal;
}


.ratings-data-style, 
.rating-box .left-ratings-column {
  width: 28.4%;
  float: left;
}

.rating-box .right-ratings-column {
  max-width: 620px;
  width: 64.3%;
  margin: 0px 0 0 7%;
  float: right;
}
