
%spc-input {
  @include body();
  line-height: 14px;

  background-color: white;
  border: 1px solid white;
  border-bottom-width: 2px;
  border-radius: 0;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  padding: ($spacing * 3) 0 0 ($spacing * 2);

  height: 56px;
  width: 100%;
  -webkit-appearance: none;

  &:focus {
    border-bottom-color: black;
  }

  &.error-message:invalid,
  &.spc-error-message {
    color: $callout;
    border: 1px solid;
    border-color: $callout;

    &:focus {
      border-bottom: 2px solid;
      border-color: $callout;
    }

    ~ label {
      color: $callout !important; 
    }
  }
}

%spc-input-label {
    @include body();
    font-size: 12px;

    box-sizing: border-box;
    line-height: 56px;
    text-transform: uppercase;

    pointer-events: none;
    height: 100%;
    margin: 0;
    padding-left: ($spacing * 2);
    position: absolute;
    top: 0;
    transition: 0.2s;
    width: calc(100% - #{$spacing * 2});
    z-index: 1;

  input:focus + label,
  select:focus + label,
  .spc-input-text-label-has-value,
  &.spc-input-select-label-has-value {
    color: $checkout-label-color;
    font-size: 12px;
    line-height: 34px;
    top: 0;
    pointer-events: none;
  }
}

%spc-static-label {
  color: $checkout-label-color;
  font-size: 12px;
  line-height: 12px;
  pointer-events: none;
  position: absolute;
  text-transform: uppercase;
  top: ($spacing * 2);
}

.spc-form,
.spc-cc-form,
.spc-fieldset {

  &.spc-shipping-override {
    margin-bottom: ($spacing * 3);
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="number"],
  select {
    @extend %spc-input;
  }

  .spc-input-checkbox-label-message {
    cursor: pointer;
    display: inline-block;
    line-height: 16px;
    width: calc(100% - #{$spacing * 4});
  }

  .spc-input-checkbox-label:hover input[type=checkbox] {
    @media(min-width: 768px) {
      background-position: center;
    }

    &:checked { background-position: top; }
  }

  fieldset {
    margin-bottom: $spacing;

    &[name=contact] .row span:first-child {
      @include breakpoint-max($tablet) {
        margin-bottom: $spacing * 2;
      }
    }
  }

  select {
    background-image: url(../images/select-arrow.svg);
    background-repeat: no-repeat;
    background-position: 100% 50%;
    background-size: 48px 24px;
    transition: 0.2s background-position;

    & + label {
      pointer-events: none;
    }
  }

  .spc-input-text-label,
  .spc-input-select-label {
    @extend %spc-input-label;
  }

  .spc-input-text-label-placeholder {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .spc-input-container {
    display: block;
    margin-bottom: $spacing;
    position: relative;

    input:not([type=checkbox]):not([type=radio]):focus + label,
    select:focus + label,
    .spc-input-text-label-has-value,
    .spc-input-select-label-has-value {
      color: $checkout-label-color;
      font-size: 12px;
      line-height: 34px;
      top: 0;
      pointer-events: none;
    }

    &.spc-container-error {
      input:not([type=checkbox]):not([type=radio]):focus + label,
      select:focus + label,
      .spc-input-text-label-has-value,
      .spc-input-select-label-has-value {
        color: $callout;
      }
    }
  }

  .spc-input-checkbox-label,
  .spc-input-radio-label {
    pointer-events: all;
    line-height: normal;
    padding-left: 0;
    position: relative;
    text-transform: none;
    width: 100%;
  }
}

.spc-input-container {
  display: block;
  margin-bottom: $spacing;
  position: relative;

  input[type="checkbox"]{
    background-color: transparent;
    background-image: url(../images/checkboxes.png);
    background-position: bottom;
    background-size: 18px;
    cursor: pointer;
    display: inline-block;
    height: 18px;
    margin-right: $spacing;
    position: relative;
    vertical-align: top;
    width: 18px;
    -webkit-appearance: none;
    -moz-appearance: none;

    @media(min-width: 768px) {
      &:hover { background-position: center; }
    }

    &:checked,
    &:checked:hover { background-position: top }
  }
}

.spc-input-text-label-optional {
  color: $checkout-label-color;
  font-size: 10px;
  padding-left: $spacing;

  html[lang="fr-CA"] & {
    font-size: 8px;
  }
}

.spc-input-error {
  color: $callout;
}

.spc-add-address {
  margin-top: $spacing * 2;
}

.spc-employee {
  padding-left: 0;
}

.spc-shipping-alternate-drop {
  margin-bottom: $spacing;
}
