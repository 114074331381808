// VARIABLES
// Variables are separated out because they need to be above _styles.sccs
// in order to be able to actually use them.

// Helper Variables
$borderMixin: 1px solid $mainbordercolor;
$primary-h1-transform: uppercase;

// Base Style Variables
$table-header-padding: 5px;
$odd-row-background: $contrast;
$odd-row-border: $borderMixin;
$even-row-background: $contrast;
$even-row-border: $borderMixin;
$qty-form-background: $contrast;
$qty-form-border: 1px solid $mainbordercolor;
$helper-background: transparent;
$form-field-background: $contrast;
$desktop-promotions-icon-top: 9px;

// Header Variables
$header-background: $white;
$header-border: solid 1px $mainbordercolor;
$logo-width: 84px;

// Search and Email Input Box Background
$search-background: $contrast no-repeat;
$search-form-border: 1px solid $base;

// Enhanced Site Search
$enhanced-search-base-color: $black;
$enhanced-search-clear-button-color: $dark-grey;

// Persistent Header Adjustments
$persistent-adjust: 0;
$persistent-menu-top-adjust: 50px;
$persistent-cat-link-padding: 15px;
$persistent-logo-height: 42px;
$persistent-logo-offset: 13px;

// Main Nav Variables
$remember-icon-position: 3px -40px;
$mini-cart-icon-position: 0 -30px;
$minicarthovercolor: $base;
$mainnavhovercolor: $base;
$mainnav-tab-border: solid 1px $secondary;
$flyout-custom-content-float: left;
$nav-bg-width: 1020px;

// Footer Variables
$footer-background: $contrast;
$footer-border: 3px solid none;
$footer-bottom: $contrast;
$social-alignment: right;

// Page, Product, & Tab Variables
$product-image-background: $contrast;
$alt-views-background: $contrast;
$page-break-background: $contrast;
$user-content-background: $contrast;
$write-review-border: 2px solid $mainbordercolor;

// Gallery Variables
$selected-filter: $primary;
$search-refinement-color: $contrast;
$refinement-hover: $primary;
$refinement-expanded: 9px;
$quickview-btn-top-margin: 0;

// PDP Variables
$product-background: $contrast;
$product-promo-background: $secondarybackground;
$rating-bar-color: $secondarybackground;
$rating-indicator-color: $primary;
$selected-rating-bar: $secondary;
$size-expanded: 134px 19px;
$select-size-background: $contrast;
$select-size-border: none;
$select-size-border-color: transparent;
$select-size-border-width: 0px;
$technology-grid-width: 50%;

$qty-select-background: $contrast;
$qty-hover: $secondary;
$qty-expanded: 100% 19px;
$qty-select-border: none;

// Cart Variables
$cart-link-hover: $secondary;
$cart-item-list-padding: 20px 4px;
$cart-table-sales: $callout;
$quantity-arrow-color: $primary;
$cart-page-image-padding: 0px;
$cart-orders-total-box-border: $borderMixin;
$cart-text-callout: $callout;
$cart-table-sales: $callout;

// Checkout Variables
$active-wayfinder-description: $base;
$shipping-estimate-btn-padding: 0;
$selectedShippingBg: $light-grey;

// Blog Variables
$blog-date-banner: $secondarybackground;
$blog-background-color: $contrast;
$sidebar-title-border: 1px solid $secondarybackground;

// Branding Specific Variables
$primary-content-jcarousel-border-arrows: solid 1px $secondary;

// Ungrouped Variables
$suggestion-shop-btn: $body-family;
$recently-viewed-text: uppercase;
$ctacolor: $base;
