.spc-modal {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 15;
  }

  &-content {
    background: $white;
    border: 1px solid $grey-silver;
    box-sizing: border-box;
    outline: none;
    padding: ($spacing * 3) 0 0;
    -webkit-overflow-scrolling: touch;

    position: fixed;
    top: 24px;
    right: 0;
    width: 85%;
    max-height: 90vh;
    overflow-y: auto;

    @include breakpoint($tablet) {
      top: 50%;
      left: 50%;
      right: auto;
      bottom: auto;
      transform: translate(-50%, -50%);

      min-width: 350px;
      max-width: calc(90% - 1em);
      padding: ($spacing * 5) ($spacing * 2) ($spacing * 2);
      width: 100%;
    }

    @include breakpoint($desktop) {
      max-width: calc(920px - 1rem);
    }
  }

  &-close {
    position: absolute;
    right: 15px;
    top: 15px;
    
    background-image: url(../images/icon-remove.png);
    background-repeat: no-repeat;
    background-size: 16px;
    background-color: transparent;
    border: 0;
    
    cursor: pointer;
    display: inline-block;

    height: 16px;
    width: 16px;
    padding: 0;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}
