.mystery-product {

	.mystery-item {
		label {
			margin-bottom: 5px;
		}
	}

	.toggle {
		// width: 100%;
		box-sizing:border-box;
	}

	.toggle-content {
		padding: 4px 16px 16px;
	}
	.dimension {
		display: block;
		float:left;
		margin: 0.5em 0 0;

		&:nth-child(odd) {
			width:60%;
		}
		&:nth-child(even) {
			width:40%;
		}

		span {
			display: block;
		}

		.ul {
			clear:both;
			display:block;
		}

		li {
			box-sizing: border-box;
			cursor: pointer;
			display: block;
			float: left;
			margin:0 7px 7px 0;
			text-align: center;
			line-height: 34px;
			width:34px;
			height:34px;

			&.unavailable,
			&.unselectable {
				opacity: 0.5;
			}
		}
	}

	.persistent-shopping-expand {
		top:0;
	}
}
.pdp-main.pdpv2 .mystery-product .product-col-2.product-info .product-variations {
	margin-bottom: 10px;

	.toggle {
		width: 100%;
	}

	&.dropdownvariation .toggle-content{
		margin-top: 22px;
	}
}

// cart/minicart views
.mini-cart-attributes, .item-details {
	.mp-selection {
		clear: both;
	}
}
