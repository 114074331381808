/* Site Search */
// This is from the Improved Site Search project (WWPS)
// It pertains to ajax'd results while typing, _not_ regular search

$search-product-cell-stroke: $borderMixin !default;
$search-form-border: $borderMixin !default;
$search-form-bg: $white !default;
$search-product-tile-text-align: center !default;

////////////////////////////////////////////////////////////////////////////////
// Site Search Styleguide template: these empty selectors should be copied,
// pasted, and then defined for every brand
////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////
// Colors
////////////////////////////////////////
%search-dropdown-bg {

}

$search-product-cell-stroke: $borderMixin !default;

%search-shaded-bg {

}

////////////////////////////////////////
// Typography
////////////////////////////////////////
%search-product-name {

  &:hover {
    // some color
  }
}

%search-product-price {

}

%search-search-text {

}

%search-h4 {

}

%search-body-copy {

}

%search-highlight-text {

}


////////////////////////////////////////
// Colors
////////////////////////////////////////
%search-cta {

  &:hover {
    // some bg color
    // some color
  }
}

%search-text-link {

  &:hover {
    // some color
  }
}

// Sticky Search
.sticky-search-enabled .sticky-search-container {
  .header-search input[type="text"] {
    width: 190px;
  }
}

////////////////////////////////////////////////////////////////////////////////
// END: Site Search Styleguide - template
////////////////////////////////////////////////////////////////////////////////

.site-search {
  position: absolute;
  border:0;
  right: 11.5%;

  &.expand-upward .site-search-results{
    top: auto;
    bottom: 100%;
    transform: translateY(0);
  }

  &.header-search {
    background: none;
    border:0;
    padding: 8px;
    //margin-bottom:-16px;
    //transform: translateY(-8px);
    z-index: 1002;
    &.active {
      @extend %search-shaded-bg !optional;
    }
  }

  &.active {
    @extend %search-shaded-bg !optional;

    // cover up box shadow overlap
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 8px;
      width: 100%;
      z-index: 1049;
      @extend %search-shaded-bg !optional;
      box-shadow: none;
    }

    &.expand-upward:after {
      top: 0;
      bottom: auto;
    }
  }

  form {
    background: $search-form-bg;
    border: $search-form-border;
    padding-right: 8px;
    position: relative;

    &:after {
      content: '';
      clear: both;
      display: block;
    }

    input[type="text"],
    input[type="submit"] {
      box-sizing: border-box;
    }
    input[type="text"] {
      @extend %search-search-text !optional;
    }
  }

  .site-search-results{
    @extend %search-shaded-bg !optional;
    position: absolute;
    right: 0;
    transform: translateY(8px); // not "top" because it would change per brand
    width: 100%;
    z-index: 1002;

    // cover up box shadow overlap
    &:after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 10px;
      @extend %search-shaded-bg !optional;
      box-shadow: none;
    }
    &.no-results {
      padding: 0;

      &:after {
        display: none;
      }
    }
    .no-results {
      padding: 0 20px 20px;
    }

    .content-asset {
      background: $white;
      padding: 20px;
    }

    p {
      margin-bottom: 0;
    }
  }

  .products-container {
    @extend %search-dropdown-bg !optional;
  }

  .products-container, .product-tile,
  .searchterms {
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
  }

  .products-container {
    overflow: hidden;
    padding: 22px;
    width: 550px;
    transform:translateX(-100%);
  }

  .product-tile {
    border-bottom: $search-product-cell-stroke;
    border-right: $search-product-cell-stroke;
    float: left;
    margin-top:0;
    padding: 15px;
    text-align: $search-product-tile-text-align;
    width: 33.3%;

    &:after {
      content: '';
      display: block;
      clear: both;
    }

    &:nth-child(3n + 1){
      border-left: $search-product-cell-stroke;
    }
    &:nth-child(-n + 3) {
      border-top: $search-product-cell-stroke;
    }

    .product-name a {
      @extend %search-product-name !optional;
    }

    .product-pricing {
      @extend %search-product-price !optional;
    }

    input[type="text"] {
      @extend %search-input-text !optional;
    }

    a {
      max-width: 100%;
    }

    .image-container {
      display: block;
      height: 94px;
    }

    img {width: 75%;}
  }

  .load-more-container {
    clear: both;
    text-align: center;

    a.load-more{
      @extend %search-cta !optional;
      display: inline-block;
      margin-top: 32px;
    }
  }

  .searchterms {
    position: absolute;
    left: 0;
    top: 0;

    &:after {
      content: " ";
      visibility: hidden;
      display: block;
      height: 0;
      clear: both;
    }

    ul {
      padding-left: 20px;
    }

    li {
      list-style: none;
    }

    .list-heading {
      @extend %search-h4 !optional;
      padding: 4px 4px 4px 12px;
    }

    a {
      @extend %search-body-copy !optional;
      display:block;
      padding: 4px 4px 4px 12px;

      span {
        @extend %search-highlight-text !optional;
      }
    }

    // .active {background:rgba(0, 0, 0, 0.1)}
  }

  &.nohits-search {
    border: none;
    padding: 8px;
    background-color: transparent;

    &.active {
      @extend %search-shaded-bg !optional;
    }

    .site-search-results {
      left: 0;
      right: auto;

      &:after {
        right: 0;
        left: auto;
      }
    }

    .products-container {
      transform: translateX(246px); // probably needs to change
    }

    .product-tile:nth-child(n+4) {display: none;}

    .searchterms {
      float: left;
    }

    form {
      box-sizing: border-box;
      width: auto;

      input[type="text"] {
        width: 200px;
        float: left;
        background: $search-form-bg;
        box-sizing: border-box;
      	height: 28px;
      }
      input[type="submit"]  {
        box-sizing: border-box;
      }
    }
  }

  .content-asset {
    @extend %search-body-copy !optional;
    a {@extend %search-text-link !optional;}

    .list-heading {@extend %search-h4 !optional;}
  }
}

.site-search {
  form {
    position: relative;
  }

  &.nohits-search {
    form {
      padding-right: 0;
      
      input[type="text"] {width: 190px;}
    }
    
    input[type="submit"] {
      float: right;
    }
  }
}

.sticky-search-enabled .sticky-search-container {
  .header-search input[type="text"] {
    width: 190px;
  }
}
