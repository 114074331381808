$tooltipiconcolor: #808080 !default;
$tooltiphovercolor: black !default;
$tooltipboxshadow: 0 3px 2px rgba(0,0,0,0.15) !default;
$tooltipbackground: white !default;

.sw-ui-tooltip {
    @include triangle(bottom, $tooltipbackground, 10px );
    @include filterShadow(bottom, $tooltipboxshadow );
}

.sw-tooltip-icon {
    font-family : "AvenirNextLTW01-Medium", "Helvetica Neue", Helvetica, Arial, sans-serif;
    display     : inline-block;
    color       : $white;
    background  : $tooltipiconcolor;

    width       : 12px;
    height      : 12px;
    margin      : 0 2px 0 1px;

    border-radius   : 50%;
    font-size       : 8px;
    line-height     : 13px;
    text-align      : center;
    text-transform  : lowercase;

    &:hover {
        background  : $tooltiphovercolor; 
    }
}

.sw-tooltip-container {
    display     : none;
    align-items : center;
    position    : relative;
    bottom      : 1px;

    &.show-tooltip {
        display: inline-flex;
    }
}
