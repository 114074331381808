.spc-modal-content.spc-qas-modal {

  h3 { margin: 0 0 $spacing; }

  .button-thin {
    width: 225px;
    min-height: 40px;
    line-height: 40px;
  }

  .spc-button-link {
    display: inline-block;
    height: auto;
    text-align: left;
  }

  .spc-qas-address-header {
    margin-bottom: $spacing * 2;

    h2, p { margin: 0; }
  }

  .address1, .address2, .postalcode { display: block; }

  .city {
    display: inline-block;
    margin-right: $spacing;

    &:after { content: ","; }
  }

  .state { display: inline-block; }

  .spc-qas-clickable-picklist {
    padding: 0;
    margin: ($spacing * 0.5) 0 $spacing;

    li {
      list-style-type: none;
      margin-top: $spacing * 0.5;
    }
  }

  @include breakpoint-max($tablet){
    padding: ($spacing * 5) ($spacing * 2) ($spacing * 3);

    .spc-qas-address-header {
      text-align: center;

      h2 {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: $spacing;
      }

      p {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .spc-qas-address-block:before {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background-color: $checkout-label-color;
      margin: ($spacing * 2) 0;
    }

    .spc-qas-address-display {
      margin-bottom: $spacing;
    }

    form + .spc-button-link { padding: ($spacing * 2) 0}
  }

  @include breakpoint($tablet){
    .spc-qas-address-container {
      background-color: $checkout-light-background;
      display: flex;
      justify-content: space-between;
      align-items: stretch;

      &-no-match .spc-qas-address-block:first-child:after {
        display: none;
      }
    }

    .spc-button-link { margin-top: 5px; }

    .spc-qas-address-block {
      box-sizing: border-box;
      flex: 0 0 49%;
      padding: ($spacing * 2) ($spacing * 2) ($spacing * 3);
      position: relative;

      display: flex;
      flex-flow: column;

      &:first-child:after {
        content: "";
        display: block;
        height: calc(100% - 67px); // padding-top + "We Recommend" + margin + padding-bottom = 67px
        width: 2px;
        background-color: $checkout-label-color;
        position: absolute;
        bottom: 24px;
        right: -2.5%;
      }

      form {
        margin: 0 0 ($spacing * 3);

        button { margin-top: $spacing; }

        + .spc-button-link { margin: 0 }
      }

    }

    .spc-qas-address-display {
      align-self: stretch;
      background-color: $white;
      height: 100%;
      margin-bottom: $spacing * 3;
      max-height: 104px;
      padding: $spacing ($spacing * 2);
    }

    .spc-qas-clickable-picklist-container {
      h4 { margin: $spacing 0 0; }
    }
  } // breakpoint

}
