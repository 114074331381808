
// compare tools
// -------------------------------------

.headerscrolltotop {
  top: calc(100vh - 220px) !important;
}

.compare-item-remove {
  display: none;
}
.active {
  .compare-item-remove {
    display: inline;
  }
}
.compare-item {
  background: none repeat scroll 0 0;
  float: left;
  min-height: 104px;
  margin-right: 18px;
  position: relative;
  width: 14%;
  &.active {
    .compareitemremove {
      @include sprite($icon-ui-close, 3);
    }
  }
}
.compareitems {
  background: none repeat scroll 0 0 $white;
  clear: both;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 203;
  width: 100%;
  box-shadow: 0px -2px 4px $grey-light;
  .bucket-content {
    overflow: hidden;
    .toogle-compare-items-tab {
      float: right;
      margin-right: 5px;
      margin-left: 12%;
      &:after {
        content: '';
        display: inline-block;
        opacity: 0.7;
        @include sprite($icon-refinement-down);
      }
    }
    .compare-item {
      margin-right: 33px;
    }
    .product-name {
      margin: 7px 0 0;
    }
  }
  h2 {
    margin: 0.5em 0;
    .float-right {
      float: right;
    }
  }
  .toogle-compare-items-tab {
    cursor: pointer;
    img {
      margin-top: 2px;
    }
  }
  .compare-items-tab {
    position: absolute;
    top: -53px;
    right: 0px;
    display: none;
  }
}
.compareitemcount {
  left: 30%;
  position: absolute;
  top: 15%;
  z-index: 1;
}
.compareproduct {
  width: 102px;
  z-index: 2;
  border: 1px solid $mainbordercolor;
}
.compareitemremove {
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 3;
  cursor: pointer;
}
.compare-buttons {
  display: grid;
  text-align: left;
  margin-top: 20px;
  width: 20%;
  button {
		line-height: 18px;
    margin-right: 0;
    float: right;
  }
	h4 {
		margin-top: 0px;
	}
}
.compare-print {
  float: right;
  margin: 0 0 10px;
}
.js {
  .comparisontable.tiles-container {
    .quickviewbutton {
      text-decoration: underline;
      cursor: pointer;
      padding: 0px;
      margin: 0px;
    }
  }
}


#compare-table {
  td {
    border-style: none solid solid;
    border-width: 0 1px 0 0px;
    border-color: #c5c5c5;
    .product-tile {
      padding: 0;
      .product-image {
        img {
          text-align: center;
          height: auto;
          max-width: 100%;
        }
      }
      .product-swatches {
        float: none;
        margin-top: 12px;
      }
      .quickviewbutton-box {
        top: 2%;
        .quickviewbutton {
          padding: 0.8em !important;
        }
      }
    }
    .product-remember {
      right: 15px;
      top: 93px;
    }
    .product-name {
      margin-top: 16px;
    }
  }
  .compare-lists {
    border-left: none;
    label {
      float: none;
      width: 100%;
    }
    select {
      float: none;
      width: 100%;
    }
  }
  tr {
    border-bottom: 1px solid #C5C5C5;
  }
  tr.evenattributerow {
    background: none repeat scroll 0 0 $lightbackdrop;
  }

  td.product {
    width: 110px;
  }
  .section-header {
    padding: 1em;
  }
  .selectcategory {
    label {
      display: block;
      margin: 1em 0;
    }
    select {
      margin: 0;
      width: 100px;
    }
  }
  .comparelists {
    padding: 0;
  }
  .remove-link {
    background: url("../images/icon-remove-lighter.png") no-repeat scroll right top transparent;
    width: 8px;
    height: 8px;
    text-indent: 20px;
    display: block;
    float: left;
  }
  .add-to-wishlist {
    padding: 0.2em 0;
  }
  .add-to-gift-registry {
    padding: 0.2em 0;
  }
  .product-tile {
    .product-swatches-all {
      padding: 2px 5px;
      display: block;
      width: auto;
    }
    .product-swatches {
      .jcarousel-container.jcarousel-container-horizontal {
        width: 122px;
      }
      .jcarousel-clip {
        width: 100px;
      }
    }
    .small-product-image-box.carousel {
      .jcarousel-clip {
        width: 337px;
      }
      .jcarousel-container.jcarousel-container-horizontal {
        width: 442px;
      }
    }
  }
  .small-product-image-box {
    margin: -30px 0 0 -163px;
    left: 50%;
  }
  .small-product-image-box.carousel {
    margin: -30px 0 0 -163px;
    left: 50%;
  }
  &.productattribute {
    border-left: none;
  }
}
.pt_product-details {
  .back {
    background: url("../images/icon-back-arrow.png") no-repeat scroll 0 3px transparent;
    display: block;
    padding: 0 0 0 13px;
  }
}

// Top Margin for H1
.primary-content h1.compare-header {
  margin-top: 50px;
  display: flex;
  align-items: baseline;
  white-space: normal;
}

// Compare Expanded Menu
.compareitems {
  border-top: 0;

  .bucket-content {
    .product-name { 
      margin-top: 0px;
      line-height: 20px;
    }
  }
}

#clear-compared-items {
  margin-left: 30px;
  float: right;
}

.compareitemremove { top: 4px; }

.compare-item-1, 
.compare-item-2, 
.compare-item-3, 
.compare-item-4, 
.compare-item-5 {
  background-image: none;
}

.compareproduct {
  border: 0;
}

.compareitems .compare-items-tab {
  top: -54px;
  padding: 20px 25px;
  line-height: 18px;
  z-index: 100;
  box-shadow: 0px -2px 4px $grey-light;
}

// Comparison Table
#compare-table {
  td {
    text-align: left;
  }

  td.product {
    padding: 1em 0em 0px 1em;
    width: 200px;
    position: relative;
    border-bottom: 1px solid transparent;
  }

  td.product a {
    background-color: $lightbackdrop;
    width: 180px;
    display: block;
    height: 117px;
    padding-top: 124px;
    line-height: 22px;
    text-align: center;
  }

  td .product-name a {
    text-align: left;
    margin-top: 0px;
  }

  td .product-name a {
    line-height: 18px;
    background: transparent;
    position: relative;
    height: auto;
    top: auto;
    left: auto;
    width: auto;
    padding-top: 0;
    font-size: 12px;
    white-space: normal;
  }

  td .product-tile {
    width: 180px;
  }

  .productattributetitle {
    font-weight: 600;
  }

  //compare table column style adjustments
  div.product-swatches.product-swatches-image-container {
    margin-top: 17px;
  }

  td.product a.learn-more {
    background: transparent;
    text-align: center;
    position: relative;
    height: auto;
    top: auto;
    left: auto;
    width: auto;
    padding-top: 0;
    margin-top: 12px;
  }

  td.product a.thumb-link {
    background: transparent;
    position: relative;
    height: auto;
    top: auto;
    left: auto;
    width: auto;
    padding-top: 0;
  }

  .remove-link {
    @include sprite($icon-ui-close, 2);
  }

  td.product a:before {
    content: '';
    display: block;
    width: 42px;
    height: 50px;
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-40%);
    opacity: 0.7;
    @include sprite($icon-plus, 1);
  }

  td.product a.thumb-link:before, 
  td.product a.learn-more:before, 
  td .product-name a:before {
    display: none;
  }

  td.product a span {
    width: 100%;
    display: inline-block;
  }

  td.productattribute {
    text-align: left;
    vertical-align: top;
  }

  td.producttitle {
    vertical-align: top;
  }
  
  td.value {
    vertical-align: top;
    word-wrap:break-word;
    max-width:131px;
    white-space: normal;
  }

  p.productattributetitleMobile {
    visibility: hidden;
    display: none;
  }

}

// Compare Popup
.ui-dialog.product-compare-confirm {
  width: 550px!important;
  padding-bottom: 30px;

  .ui-dialog-content#dlgcompare {
    text-align: center;
    line-height: 22px;
    margin-top: 15px;
    padding: 0 10px 10px;
  }

  .ui-dialog-buttonpane .ui-dialog-buttonset {
    float: none;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
  }

  .ui-dialog-buttonpane button {
    width: 200px;
    margin: 0px 5px;
    padding: 13px 10px;
    margin-top: 15px;
    margin-bottom: 40px;
    margin-right: 0;
    outline: none;
  }
}

.comparebar {
  width: 100%;
  display: flex;
}

.compareitemspanel {
  width: 80%;
  overflow: hidden;
  overflow-x: auto;
  white-space: normal;
}


#compare-table .product .product-tile .product-image {
  min-height: 170px;
}

.selectedproductcount {
  font-weight: 400;
  font-size: 16px;
  color: rgb(55, 56, 54);
  display: block;
  margin-left: 20px;
}

.selectedproductcountMobile {
  font-weight: 400;
  font-size: 16px;
  color: #373836;
  display: none;
}

.producttiletitle {
  border-bottom: 1px solid transparent !important;
}

.compare-table-container {
  padding: 0 4em;
	white-space: nowrap;
}

.compare-widget-options h4 a {
  text-decoration: none;
}

@include breakpoint-max($desktop) {

  #compare-table td {
    height: 60px;
    min-height: 60px;
    border-style: none none solid;
  }

 .compare-table-container .breadcrumb {
    padding-left: 0px;
  }

  .compare-widget-options {
    padding-bottom: 20px;
  }

  .ui-dialog.product-compare-confirm {
    width: 95% !important;
    padding-bottom: 0px;
  }
  
  #compare-table td.product {
    padding: 1em 1em 0px 1em;
  }

  .ui-dialog.product-compare-confirm {
    .ui-dialog-buttonpane button {
      min-width: 150px;
      width: 150px;
    }
  }

  .compare-table-container {
    padding: 0 1em 0 0 !important;
    overflow: auto;
    overflow-x: auto;
    white-space: nowrap;
  }
  
   .compareitemspanel {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
  }

  .compare-buttons{
    text-align: center;
    width: 100%;
    padding: 0 20px;
  }

  .compare-item-container{
    width: max-content;
    display: flex;
    flex-direction: row;
  }
  
  .comparebar {
    display: grid;
    margin: 10px 0;
    justify-items: center;
  }
  .compare-item {
    .product-name {
      display: none;
    }
    width: 17vw;
  }
  #clear-compared-items {
    margin-left: 10px;
    float: left;
  }
  .compareitems .bucket-content .compare-item {
        margin-right: 10px;
        min-height: 77px;
  }
  .compareproduct {
    width: 80px;
  }

  #compare-items-button {
    font-size: 16px;
    height: 40px;
    min-width: auto;
    width: 50%;
    margin: 0 auto;
  }

  #compare-table {
    td {
      .product-name {
        width: fit-content;
        margin: 0 20px;
        a {
          text-align: left;
        }
      }
      .product-tile {
        .product-image {
          height: auto;
          width: 100%;
        }
      }
    }
  }
  
td.product {
  width: 120px;
}

#compare-table td .product-tile {
	width: 180px;
}

.compareitems .bucket-content .toogle-compare-items-tab {
  margin-right: 10px;
}

#compare-table .productattributetitle {
  position: absolute;
  border-right: none;
}

.quickviewtd .quickviewbutton {
  display: block !important;
}

p.productattributetitleMobile {
  visibility: hidden !important;
  display: block !important;
  margin-bottom: 0;
}

.selectedproductcount {
  display: none;
}

.selectedproductcountMobile {
  display: block;
}

}

.compare-check-btn {
  padding: 0px;
  text-decoration: none !important;
}

.product-compare-overlay {
	z-index: 6011;
	background: rgba(0,0,0,0.75) !important;
}

#compare-table .price-standard {
  color: $grey-medium;
  text-decoration: line-through;
}

#compare-table .promo-price {
  color: #E0007A;
}

.compare_donotclear-btn {
  width: 200px;
  margin: 10px 10px;
  border: 2px solid #FB2626;
  background: $white;
  color: #FB2626;
  font-size: 16px;
  font-weight: 600;
}

.compare_clear-btn {
  width: 200px;
  margin: 10px 10px;
  border: 2px solid #FB2626;
  background: #FB2626;
  color: $white;
  font-size: 16px;
  font-weight: 600;
}

.compare-items-tab-label::after {
  content: '';
  display: inline-block;
  margin-left: 8px;
  opacity: 0.7;
  @include sprite($icon-refinement-up);
}

.compare-product-remove,
.compare-product-category,
.quickviewbutton {
  text-decoration: none !important;
}

