//HELPERS
//Gradient 1 (408px tall gradient)
@mixin maingradient($top: $light-blue, $bottom: $light-blue) {
	background-color: $light-blue;
	background-image: linear-gradient( to top, $top 0px, $bottom 408px 	);
	filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr=#{$top}, endColorstr#{$bottom}})";
}

@mixin border-radius($topleft: 5px, $topright: 5px, $bottomright: 5px, $bottomleft: 5px){
	border-radius: $topleft $topright $bottomright $bottomleft;
}

@mixin box-shadow-01 ($x: 1px, $y: 0px, $blur: 10px, $color: rgba(0, 0, 0, .15)){
	box-shadow: $x $y $blur $color;
} .box-shadow-01 { @include box-shadow-01() }

@mixin box-shadow-02 ($x: 0, $y: 1px, $blur: 5px, $spread: 0, $r: 95, $g:96, $b: 98, $alpha: 0.3) {
	box-shadow: $x $y $blur $spread rgba($r, $g, $b, $alpha);
} .box-shadow-02 { @include box-shadow-02() }

@mixin box-shadow-03 ($x: 1px, $y: 0px, $blur: 10px, $color: rgba(0, 0, 0, .3)){
	box-shadow: $x $y $blur $color;
} .box-shadow-03 { @include box-shadow-03() }

@mixin box-shadow-04 ($x: 0px, $y: 1px, $blur: 10px, $color: rgba(0, 0, 0, .15)){
	box-shadow: $x $y $blur $color;
} .box-shadow-04 { @include box-shadow-04() }

@mixin header-shadow() {}

@mixin remembered-item-shadow() {
	@include box-shadow-01($color: rgba(0, 0, 0, .3));
}

@mixin quickview-shadow() {
	@include box-shadow-01($color: rgba(0,0,0,.3));
}

@mixin alt-ratings-font() {
	font-family: $h1-family;
}

@mixin mini-cart-shadow() {}
