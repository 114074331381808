$checkout-border-color: #dededb !default;
$checkout-label-color: #888 !default;
$checkout-light-background: #f4f4f4 !default;
$disabled: #dededb !default;
$swatch-disabled-text: #7C7C7C !default;
// The spacing variable should be moved out of SPC
// as we update the site in the future. The plan is
// to have all spacing be multiples of 8px, set as a
// variable to be adjusted for sites with larger fonts
$spacing: 8px!default;

.placeholder-text {
  color: lime;
}

.placeholder {
  background-color: lime;
}

body {
  margin: 0;
}

#single-page-checkout,
#order-confirmation {
  box-sizing: border-box;
  position: relative;
  max-width: 936px;
  margin: auto;

  .spc-hidden {
    display: none !important;
  }

  .spc-mobile-only {
    @include breakpoint($tablet) {
      display: none !important;
    }
  }

  header {
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint($tablet) {
      justify-content: start;
      height: 44px;
    }

    .spc-store-logo {
      display: block;
      overflow: visible;

      &-image {
        display: block;
        height: 44px;
      }

      img {
        display: block;
        height: 100%;
        max-height: 44px;
        max-width: 166px;

        position: relative;
        top: 50%;
        transform: translateY(-50%);

        @include breakpoint-max($tablet) {
          max-width: 136px;
        }

        // IE11-only styles
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          height: auto;
        }
      }

      &-text {
        visibility: hidden;
        position: absolute;
      }
    }

    .spc-secure-checkout {
      text-align: right;
    }

    .spc-badge {
      color: #888;
      font-size: 10px;

      @include breakpoint($tablet) {
        font-size: 14px;
        line-height: 44px;
        display: flex;
      }

      &-text { line-height: 16px; }

      span:first-child:not(.spc-badge-text) {
        display: inline-block;
        @include sprite($icon-secure, 4);
        margin: 0 5px 0;

        @include breakpoint($tablet) {
          @include sprite($icon-secure, 2);
          height: 16px;
          margin: 0 10px 0 18px;
        }
      }
    }


    .spc-mobile-order-summary {
      text-align: right;
      text-transform: uppercase;
    }
  }


  .spc-applied-promotions {
    margin-top: -16px;
    // container above this has margin-bottom of 24px, but if this container exists,
    // we only want 8px of space
    margin-bottom: 24px;

    @media(min-width: 768px) {
      margin-top: -8px; // container above has margin-bottom 16px on desktop
      margin-bottom: 16px;
    }

    .spc-applied-discount {
      margin-top: 8px;
      margin-bottom: 0;

      .spc-button-link {
        line-height: 1.5;
      }
    }
  }

  #spc-primary {
    background-color: #f4f4f3;

    @include breakpoint($tablet) {
      min-height: 100vh;
      padding: ($spacing * 4) ($spacing * 1.5) ($spacing * 4) ($spacing * 0.5);

      // Using a pseudo to set the backrgound color since
      // the page isn't a clean 50/50 split down the middle
      &:before {
        content: "";
        background-color: #f4f4f3;
        display: block;
        height: 100%;
        width: 50vw;
        position: absolute;
        right: 50vw;
        top: 0;
        z-index: -10;
      }

      #size-adjust {
        background: white;
      }
    }

    @include breakpoint-max($desktop){
      padding-left: $spacing;
    }

    @include breakpoint-max($tablet){
      padding: $spacing * 2;
    }

    h1 {
      margin: 0;
      @extend %checkout-main-headers !optional;
    }

    h2 {
      @extend %checkout-sub-headers !optional;
      margin: ($spacing * 3) 0 ($spacing * 2);
    }

    h4 { margin: ($spacing * 2) 0; }
  }

  hr {
    margin: ($spacing * 5) 0 ($spacing * 4);
  }

  .spc-section{
    &-heading-step {
      @include body-small($color: #888);
    }
    &-error-wrapper {
      padding: 8px 0;
    }
    &-error {
      color: $callout;
    }
  }

  #spc-secondary {
    max-width: 100%;
    width: 100%;
  }

  .checkout-form {
    padding: 0 10px;
  }

  .order-summary-container {
    position: relative;
  }
  @media (max-width: 767px) {
    .spc-section__billing-empty {
      border-top: 1px solid black;
      margin: ($spacing * 2) -($spacing * 2) -($spacing * 2);
      background: $white;
      padding: 0 ($spacing * 2);
    }
  }

  // all product prices
  .product-price {
    font-weight: bold;
    line-height: 17px;
    text-align: right;
    .visually-hidden {
      visibility: unset !important;
    }
    &-original {
      text-decoration: line-through;
      text-decoration-color: $price-strikethrough;
      color: $price-strikethrough;
      font-weight: normal;
      line-height: 17px;
    }
    &-discounted {
      line-height: 17px;
    }
  }

  .spc-optin-container {
    margin-top: 16px;
  }

  .checkout-form {
    padding: 0 10px;
  }

  .checkout-section {
    padding: 16px 16px 40px;
    border-bottom: 1px solid $checkout-border-color;

    @include breakpoint($tablet) {
      padding: 0 0 40px;

      & ~ .checkout-section {
        border-bottom: 0;
      }
    }

    &.active {
      background: $secondarybackground;

      @include breakpoint($tablet) {
        background: none;
      }
    }
  }

  .checkout-subsection {
    margin-bottom: 40px;
  }

  .checkout-box {
    padding: 10px 16px;
    background: white;
    border: solid $mainbordercolor 0;
    border-left-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 2px;
  }

  .checkout-order-summary {
    padding: 0 10px;
    border-bottom: 1px solid $checkout-border-color;
  }
}

.spc-group {
  display: flex;

  &-justify{
    &-start {
      justify-content: flex-start;
    }

    &-end {
      justify-content: flex-end;
    }

    &-center {
      justify-content: center;
    }

    &-between {
      justify-content: space-between;
    }
  }

  &-direction {
    &-row {
      flex-direction: row
    }

    &-column {
      flex-direction: column
    }
  }

  &-align {
    &-center {
      align-items: center;
    }

    &-start {
      align-items: flex-start
    }

    &-end {
      align-items: flex-end
    }
  }

  &-width {
    &-full {
      width: 100%
    }
  }
}

// quickview
.spc-quickview {
  #trueToSizeMessage,
  #sizeChart,
  .availabilitycontainer,
  .full-details-link {
    display: none;
  }
  .pdp-responsive #product-content:not(.persistent) .pdpForm .toggle-content {
    @include breakpoint-max($tablet) {
      display: block !important;
    }
  }
}


.spc-quickview .pdp-main:not(.pdp-responsive) {
  .toggle {
    text-align: left;
  }
  #product-content:not(.persistent) .pdpForm .toggle-content {
    @include breakpoint-max($tablet) {
      display: none;
      width: 100%;
      left: 0;
    }
  }
}

// cybersource
.fingerprinting {
  height: 0;
  overflow: hidden;
}

// pencil SVG
.icon-pencil {
  fill: $primary;
  height: 16px;
  width: 16px;
  vertical-align: middle;
}

// PCI Pal page
#pcipal-top-banner {
  display: flex;
  box-sizing: border-box;
  height: 65px;
  padding: 12px 12px 0px;
  margin-bottom: 8px;
}

#pcipal-store-logo {
  height: 65%;
  max-height: 44px;
}

#pcipal-logo {
  width: 30%;
  max-width: 200px;
  position: absolute;
  right: 3%;
}

#pcipal-main {
  margin: 0 10px;
}

#pcipal-iframe {
  display: block;
  width: 95%;
  height: calc(90vh - 100px);
  margin: 0 auto 10px;
}

#pcipal-init-form, #pcipal-response-form {
  display: none;
}

#pcipal-info-panel {
  display: flex;
  margin-left: 3%;
}

#pcipal-debug-icon {
  width: 10px;
  position: absolute;
  right: 3%;
  margin-top: 10px;
}

#paymentTakingFailureOptions {
  display: block;
  width: 500px;
  margin: 20vh auto 20vh;
}

#payment-failure-message {
  @include h2();
  width: fit-content;
  margin: auto;
  margin-bottom: 20px;
}

@import "checkout/_address-summary.scss";
@import "checkout/_amazon-pay.scss";
@import "checkout/_button.scss";
@import "checkout/_checkout_help_asset.scss";
@import "checkout/_drawers.scss";
@import "checkout/_forms.scss";
@import "checkout/_modal.scss";
@import "checkout/_order-confirmation.scss";
@import "checkout/_order_summary.scss";
@import "checkout/_payments.scss";  
@import "checkout/_payment-picker.scss";
@import "checkout/_qas.scss";
@import "checkout/radio-drawer";
@import "checkout/_react-responsive-select.scss";
@import "checkout/_shipping-methods.scss";
@import "checkout/_spinner.scss"; 
