#offline-chat-dialog {
  --headerTextColor: $white;
  padding: 0;
  border-radius: 8px 8px 0 0;
  bottom: 0;
  top: unset !important;
  margin: 0 12px;

  @media screen and (max-width: 769px) {
    left: unset !important;
  }

  @include breakpoint($tablet) {
    margin: 0;
  }
  .ui-dialog-titlebar {
    border-radius: 0 0 0 0;
    height: 50px;
    text-transform: none;
    padding: 0;
  }

  .offline-header-template {
    display: none;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    padding: 0 14px;
    border-radius: 8px 8px 0 0;
    background-color: $cta-primary-background;
    min-height: 50px;
    font-size: 16px;

    .offline-chat-button {
      height: 32px;
      min-height: 32px;
      width: 32px;
      min-width: 32px;
      line-height: 32px;
      fill: var(--headerTextColor);
      background-color: transparent;

      &.offline-chat-min {
        display: none;
      }

      &:hover:before {
        content: " ";
        top: 9px;
        width: 32px;
        height: 32px;
        background-color: $white;
        opacity: .2;
        border-radius: 4px;
        box-sizing: border-box;
        position: absolute;
        transform: translateX(-6px);
      }

      svg {
        height: 20px;
        width: 20px;
        vertical-align: middle;
      }
    }

    .offlinechat-header-heading {
      color: var(--headerTextColor);
      margin: 0 10px;
      align-self: center;
      text-align: left;
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: inherit;
      font-weight: normal;
    }

    .offlinechat-header-text {
      color: var(--headerTextColor);
      margin-right: 0;
      flex-grow: 1;
      align-self: center;
      text-align: left;
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 12px;
      font-size: 12px;
      margin-top: 4px;
      font-weight: normal;
    }
  }
}
