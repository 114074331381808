.emailpreference {
	width: 99%;

	.emailpreference-header {
		display: block;
		clear: both;
		text-align: left;
	}

	.emailpreference-side-content {
		float: right;
		position: relative;
		width: 45%;
		margin-top: -10%;
		padding-left: 2%;
		text-align: left;
	}

	.emailpreference-form-content {
		width: 47%;
		padding-left: 0;

		form label { 
      width: auto; 
      padding: 0;
    }

		input[type="text"], 
		input[type="password"], 
		textarea { width: 94%; }

		input[type="checkbox"],
		input[type="radio"] {
			margin: 0 0.3em 0 0;
			+ label { padding-right: 12px; }
		}

		button { min-width: 200px; }

		div.emailpreference-form-fields {
			padding: 20px 0 0 0;
  		}

		div.pref-age {
			margin-top: 14px;
			padding-top: 14px;
			border-top: 1px solid $mainbordercolor;
			clear:both;

			label {
				float: none;
				clear: both;
				display: inline;
				margin: 0;
				width: auto;
				padding: 0 0.3em;
			}

			input[type="checkbox"] {
				float: left;
				margin: 0.2em 0.3em 1em 0;
				padding: 0;
				vertical-align: baseline;
			}
		}
		div.pref-zipcode {
			clear: both;
		}
		
		div.pref-unsubscribe {
			margin-top: 0;

			label {
				float: none;
				clear: both;
				display: inline;
				margin: 0;
				width: auto;
				padding: 0 0.3em;
			}
			input[type="checkbox"] {
				float: left;
				margin: 0.2em 0.3em 1em 0;
				padding: 0;
				vertical-align: baseline;
			}
		}

		/* Hide requirements * (asterisk) for DOB fields because it looks weird. */
		div.pref-dob {
			label { display: none !important; }
		}

		div.pref-interest, 
		div.pref-category {
			margin-top: 10%;
			clear: both;
			label.row-label { clear: both; }
		}

		.form-row {
			clear: none; 

      &.heading_pref-dob, &.pref-dob {
			  margin: 0;
      }

			&.pref-interest {
				overflow: hidden;
			}

			select.pref-dob {
				float: left;
				width: 31%;
				margin-right: 2%;
				margin-bottom: 9px;
			}

			div.pref-desktop { 
				float: left;
				position: relative;
				width: 33%; 
        display: flex;
        align-items: center;
        justify-content: flex-start;
			}
		}
		.heading_pref-unsubscribe{clear:both !important;}

		div.pref-gender {
			float: none;
			clear: both;
			padding-top: 1px;

			.pref-desktop {
				width: auto;
			}
		}

		.emailpreference-button-box {
			clear: both;
			margin: 16px 0; 
			text-align: center;
		}
	}
}
.ui-dialog-content .emailpreference {
	width: 585px;
	max-height: 525px;
	margin-top: 10px;
	
	.emailpreference-header {
		text-align: center;

		h1 { 
			font-size: 2em;
			margin: 5px 0 5px 0; 
		}
	}

	.emailpreference-side-content {
		display: none;
	}

	.emailpreference-form-content {
		width: 94%;
		padding: 0 2%;

		input[type="text"] { width: 96%; }
		
		div.pref-email { display: none; }

		div.emailpreference-form-fields {
			padding: 20px 0 0 20px;
		}

		div.pref-zipcode {
			clear: none;
			float: left;
			width: 33%;
			margin-right: 16px;
		}

		div.pref-gender {
			padding-top: 0;

			.pref-desktop {
				width: auto;
			}
		}

		div.pref-interest, div.pref-category {
			margin-top: 7%;
			clear: both;
			label.row-label { clear: both; }
		}

		.heading_pref-unsubscribe { display: none; }

		.form-row {
			label.row-label,
			label.group-label,
			.pref-unsubscribe label {
				font-size: 1.1em;
			}

			.pref-zipcode { width: 90%; }

			select.pref-dob {
				float: left;
				width: 20%;
				margin-right: 2px;
				margin-bottom: 9px;
			}
		
		}

		.emailpreference-button-box {
			clear: both;
			margin: 16px 0; 
			text-align: center;

			button { margin-top: 12px; }
		}
	}
}


.ui-dialog-content .emailpreference .emailpreference-header .content-asset{text-align:left;}
.pref-preferedcommunicationfreq,
.pref-unsubscribe {
 label {    
 display: inline-block;
    float: none;
    padding-left: 10px;
 }
 .input-checkbox {
     margin-top: 5px !important;
 }
}