/* 
 * Styling overwrites for jQuery UI things that got messed up in the update to v1.12.1
 *
 *    NOTE: at least for now, any changes made in this file must also be made in
 *    cartridges/www_store/cartridge/static/default/sass/core-v2/desktop/_jquery-ui-overwrites.scss
 */

// Previous (i.e. before the great jQuery updates of 2020) overwrite stuff 
//    moved over from www_store/cartridge/static/default/sass/deprecated/_style.v2.scss
.ui-widget-overlay {
  background: url("../images/interface/ui-overlay-shadow.png") repeat scroll 50% 50% $grey-charcoal-dark;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.ui-dialog {
  background: none repeat scroll 0 0 $white;
  max-width: 100%;
}

.ui-dialog .ui-widget-header {
  border: 0 none;
  border-radius: 0 0 0 0;
  height: 18px;
  text-transform: uppercase;
}
.ui-dialog .ui-dialog-title {
  @extend .bold !optional;
  top: -3px;
}
.ui-dialog .ui-icon-closethick {
  @include sprite($icon-ui-close, 2);
}
.ui-dialog .ui-dialog-titlebar-close {
  border: 0 none;
  border-radius: 0 0 0 0;
  height: 14px;
  margin: 7px 11px 0 0;
  padding: 0;
  right: 4px;
  top: 4px;
  width: 14px;
  background-color: transparent;
}
// end of previous overwrite stuff

.ui-widget,
.ui-widget button,
.ui-widget select {
  font-family: inherit;
  font-size: inherit;
}

.ui-widget button.cta-primary {
  @extend .cta-primary;
}

.ui-widget button.cta-secondary {
  @extend .cta-secondary;
}

.ui-widget-header {
  background: none;
}

.ui-widget.ui-widget-content {
  border: inherit;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  border: inherit;
  background: inherit;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  border: inherit;
  background: inherit;
}

// overwrites/fixes for Dialog widget things

.ui-dialog {
  z-index: 6012;
}

.ui-widget-overlay {
  z-index: 6011;
}

.ui-dialog-titlebar-close {
  &:hover .ui-icon-closethick,
  .ui-icon-closethick:hover {
    @include sprite($icon-ui-close, 2);
  }
}

.ui-dialog-content {
  max-height: calc(90vh - 40px) !important;
}
