.footer-item {
  float: left;
  width: 150px;
  margin-right: 15px;
  
  &:nth-child(4n) {
    width: 111px;
    margin-right: 0;
  }
  
  li {
    margin-bottom: 13px;
  }
}

.footer-top-banner {
  margin-bottom: 47px;
}

.footer-bottom-banner {
  
  // This is so .footer-bottom-banner only has a margin bottom when it isn't empty
  > :last-child{margin-bottom:30px;}
}

.footer-content {
  @include breakpoint($tablet) {
    margin-bottom: 98px;
  }
  
  h3 {
    margin-top: 0;
    margin-bottom: 11px;
  }
  
  .email-signup-box h3 {
    margin-bottom: 7px;
  }
  
  ul {
    margin-top: 0;
  }
  .footer-items-box {
    display: inline-block;
    width: 670px;
    
    .row.between-sm {
      -webkit-box-pack: flex-start;
      -ms-flex-pack: flex-start;
      justify-content: flex-start;
    }
  }
  .email-signup-box {
    width: 242px;
    
    input {height: 36px;}
    input[type="text"],
    input[type="email"] {width:100%;}
  }
}

.footer-links {
  ul {
    margin-top:8px;
    margin-bottom: 0;
    
    &:after {
      content:'';
      display:block;
      clear:both;
    }
  }
}

.footer-country-selected{
  float: left;
}
.footer-country-flags{
  float: right;
  
  .country-box{
    position: relative;
    margin-left: 5px;
  }
  
  a.current-lang{
    padding: 0 15px 5px;
  }
  
  ul {
    top: inherit;
    bottom: 22px;
    line-height: inherit;
    
    li{
      line-height: inherit;
    }
  }
}
