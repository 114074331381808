// default any vars used in this partial
$primarylink: springgreen !default; // primary link should always exist - make it an absurd color to highlight if it does not exist
$subnavtext: $grey-medium !default;
$backgroundaccent: #f4f4f3 !default;

.login-columns-container {
  clear: both;

  &:after {
    content: '';
    clear: both;
    display: block;
  }

  button {
    min-width: 223px;
  }
  .form-row-button {
    margin-bottom: 0;
  }
}

.pt_account #primary.hiddenLeftnav,
.login-columns-container {
  .col-1, .col-2 {
    box-sizing: border-box;
    width: 48%;
  }

  .col-1 {
    margin-right: 4%;

    .checkout-login-box & {
      margin-right: 2%;
    }
  }
}

.checkout-login-box {
  padding-left: 20px;
}

.amazon-btn {
  padding-left: 20px;
  padding-bottom: 8px;
}
.amazonheader {
  margin-bottom: 5px;
}

.checkout-OR-padding {
  float: left;
  width: 10%;
  text-align: center;
  padding: 16px 8px;
}
.checkout-extra-top {
  margin-top: 12px !important;
}

#login {
  float: left;
  width: 30%;
  white-space: nowrap;
  padding-left: 15px;
  padding-right: 15px;
}
#express {
  width: 54%;
  float: left;
  white-space: nowrap;
  padding-left: 15px;
  padding-right: 15px;
}

.pt_account {
  .primary-content {
    a:not(.cta-secondary) {
      color: $primarylink;

      &:hover {color: $subnavtext}
    }
  }
  #secondary {
    a:not(.cta-secondary) {
      color: $subnavtext;

      &:hover {color: $primarylink;}
    }
  }

  .account-dashboard {
    .account-dashboard-header {
      display: flex;
      align-items: baseline;

      h1 {
        display: inline-block;
      }

      .account-dashboard-controls a {
        margin-left: 16px;
      }
    }

    .account-dashboard-cart {
      padding: 0 0 22px 0;
    }

    .category-list {
      list-style-type: none;
      padding-left: 0;

      li {
        display: inline-block;

        &:not(:first-child) {
          margin-left: 16px;
        }
      }
    }
  }
}

.address-list, .payment-list {
  h3 {
    margin-top: 0;
  }

  li {
    // NOTE: border-box will mess up the js that sets the height, causing a bad overflow
    padding: 2%;
    margin: 0 2% 2% 0;
    width: 28%;

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:nth-child(4n) {
      clear: left;
    }

    a {display: inline-block;}
  }
}

// some global styles for uncomped forms
#edit-address-form,
#CreditCardForm,
#send-to-friend-form {
  padding-bottom: 16px;

  input,
  select {
    width: 48%;

    &.input-checkbox, &[type="checkbox"] {width: auto;}
  }

  .form-row-button {
    width: 100%;

    button, .cta-secondary {
      height: 55px;
      float: left;
      margin-right: 10px;
      min-width: 175px;
    }
  }
}

#send-to-friend-form .button-text {
  height: 55px;
  line-height: 55px;
}

#dwfrm_orders .intro-box {
  padding: 20px;
}

.order-history-items  button {
  height: auto;
  line-height: normal;
}

.order-detail-options {
  a {
    display: inline-block;
  }
}

.pt_remembereditems {
  .item-total {
    position: relative;
  }
}

.remembered-row {
  .item-total {
    padding-right: 0;
  }
}

#modalCheckoutLogin {
  button {
    min-width: 0;
  }
}

.manage-subscriptions legend {
  width: 100%;
}

.return-label-page {
  .packing-slip.button {
    display: inline-block;
  }
}

.order-details-content .confirm-address, 
.order-details-content .order-shipment-table .order-item-returnoptions {
  display: none; //shown with js
}

.pt_order-confirmation {
  .order-details-content .confirm-address, 
  .order-details-content .order-shipment-table .order-item-returnoptions {
    display: table-cell;
  }
}

.pt_multireturnexchange .order-shipment-table{ 
  .order-item-returnoptions {
    border-top: 0;
    td {
      padding: 0;
    }
    .return-wrapper {
      margin-bottom: 15px;
      padding: 5px 20px 20px;
      background: $secondarybackground;
    }
  }
  .return-options, .returnform, .exchangeform {
    display: inline-block;
    width: 47%;
    vertical-align: top;
    padding: 5px;
    border-top: 0;
    select {
      width: 100%;
      margin-bottom: 10px;
    }
    .variationoptions label {
      margin: 0;
      padding-bottom: 0;
    }
  }
}

.pt_multireturnexchange .change-address {
  position: relative;
  display: inline-block;
  margin-top: -10px;
  margin-bottom: 20px;
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    @include sprite($icon-refinement-down);
  }
  &.expanded::after {
    @include sprite($icon-refinement-up);
  }
}

.req-indicator-label {
  color:$destructive-red;
}

.section-header {
  margin-bottom: 16px !important;
}

.checkbox-error-age,
.checkbox-error {
  position: absolute;
  display: block;
  border: 2px solid #FF0000;
	border-radius: 4px;
}

.custom-pref-reg {
  margin-top: 32px !important;

  input[type="text"],
  input[type="password"],
  input[type="email"] {
    color: $black !important;
  } 

  label {
    .error-message.form-caption, .pw-invalid-input {
      font-size: 12px;
      font-weight: 400;
    }
  }
	.form-row {
		position: relative;
    margin-bottom: 24px;
	}

  .form-row-button {
    width: 80%;
    position: relative;

    .error-icon-reg {
      left: 253px;
      top: 10px;
    }

    .existing-account-error {
      font-size: 12px;
      position: absolute;
      left: 280px;
      top: 7px;
      text-transform: initial;
      color: $destructive-red;
      .existing-acc-error-link {
        padding-top: 10px;
      }
      a {
        color: #0099ff !important;
      }
    }
  }

  label, .form-label-text {
    padding-bottom: 8px;
  }

	.col-holder {
		margin-top: 16px;
	}

	.valid {
		border-color: #69D100;
	}

  input.valid {
    border: 1px solid #69D100;
    border-color: #69D100;
  }

	.valid-req {
		color: $black!important;
	}

  .email-opt-in {
    a {
      text-decoration: underline;
      color: #0099ff !important;
    }
  }
  
  .required-indicator {
    color: $destructive-red !important;
  }

  .email-match-error {
    font-size: 12px;
    position: absolute;
    top: 33px;
     left: 110%;
    display: block;
    width: fit-content;
  }

	.account-verification {
		border-top: none !important;
		margin-bottom: 50px;
	}

	@media (min-width:768px)  {
		.form-caption {
			position: absolute;
			top: 60%;
			left: 109%;
			width: fit-content;
			display: block;
      text-transform: initial;
		}

    .email-address-info {
      position: relative;
    }

    .reg-form-privacy-tag {
      display: none !important;
      top: 250px;
      left: 29px;

      a {
        text-decoration: underline;
        color: #0099ff !important;
      }
    }

    .checkbox-label {
      
      a {
        text-decoration: underline;
        color: #0099ff !important;
      }
    }

    .pw-invalid-input {
      left: 162%;
    }
	}

  .reg-input-container {
    position: relative;
  }

  .hide {
    display: none;
  }

  .reg-form-check,
  .reg-form-error {
    position: absolute;
    left: 102%;
    top: 34px;
    padding: 2px;
    background-color: $success-green;
    border-radius: 26px;
    height: 12px;
    width: 12px;
    text-align: center;
    color: $white;
    line-height: 12px;
    
    .check-mark {
      display: inline-block;
      transform: rotate(45deg);
      height: 8px;
      width: 3px;
      border-bottom: 2px solid $white;
      border-right: 2px solid $white;
    }
  }

  .reg-check-cont {
    position: relative;

    .agreeterms-checked {
      position: absolute;
      left: 60%;
      color: $destructive-red;
      display: table;
    }

    .agreeterms-age-check{
      top: 40px;
    }

    .hide {
      display: none;
    }

    .reg-form-error-check{
      background-color: $destructive-red !important;
      color: $white;
      padding: 2px;
      border-radius: 26px;
      height: 12px;
      width: 12px;
      display: inline-block;
      text-align: center;
      line-height: 13px;
    }
  }

  .field-name {
    color: $black !important;
    font-weight: normal;
  }

  .reg-form-error {
    background-color: $destructive-red !important;
  }


	.field-name {
		text-transform: capitalize;
	}

	.reg-pass-button-cont {
		position: relative;
		.register-password-show,
		.register-confirmpassword-show,
		.register-password-hide,
		.register-confirmpassword-hide {
			width: auto !important;
			background: transparent;
			position: absolute;
      top: 31px;
      left: 83%;
		}
	}
 
  .acc-pass-requirements {
    padding-left: 15px;
    margin-top: 0px;
    li {
      color: $destructive-red;
    }
  }

  .checkbox-label {
    a:after {
      content:"*";
      color: #FF0000;
    }
  }

  .age-check {
    .checkbox-label:after {
      content:"*";
      color: #FF0000;
    }
  }
}

.mainUnregistered {
  @media (min-width:768px)  {
    .custom-pref-reg { 
      .col-holder {
        .col-left {
          width: 37% !important;
        }

        .col-right {
          width: 62% !important;
        } 
      }
      .reg-check-cont {
        .agreeterms-checked {
          left: 45%;
        }
      }
    }
  }
}
