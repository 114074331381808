%text-button { @include body-small($color: $secondary, $decoration: underline, $transform: none); }

%cart-coupon-box {
	.cart-coupon-label { @include h3(); }
	input[type="text"] { 
		@include body();
		background-color: $contrast; 
		border: solid 1px $mainbordercolor;
	}
}

%need-help-text {
	@include body-small();
	a { @include body-small($color: $secondary, $decoration: underline); }
}

.pt_cart .title-hold h1 { line-height: 50px; }
.pt_cart label.cart-drawer-label.toggle { @include h3(); }

#cart-table {
	th.section-header { 
		@include h3(); 
		//Snowflakes because the table headings were all off
		&:first-child { padding-left: 0; }
		padding-left: 10px;
		&:nth-child(n+3) { padding-left: 15px; }	
	}

	.item-name {
		.name a { 
			@include h4(); 
			&:hover { color: $secondary; }
		}

		.item-details { 
			@include body-small(); 
			a { @include body-small($color: $secondary); }
		}
	}

	.item-price {
		.price-promotion {
			// Don't blame me, blame the !important in _style.v2.scss
			.price-standard { color: $dark-grey !important; }
			.price-sales { color: $callout; }
		}
	}

	.item-quantity {
		.quantity-up { border-bottom-color: $callout; }
		.quantity-down { border-top-color: $callout; }
	}

	.item-total {
		.price-adjusted-total { @include body(); }
		.notavailable { @include body-small(); } 
	}
	
	@media(min-width: 768px) {
		.item-edit-details a, 
		.item-edit-details a:hover { 
			@include body-small($color: $secondary, $decoration: underline); 
		}
	}
	
	.error-message { color: $base; }

	.rowcoupons {
		a.coupon-tooltip {
			@include h3($color: $base); 
			cursor: pointer;
			&:hover { color: $secondary }
		}

		.details a, .details a:hover { @include body($color: $secondary, $decoration: underline); }
	}

	.cart-promo .promo-callout { @include h3(); }
}

//Cart Sidebar
#secondary {
	h3 { 
		@include h2($color: $dark-grey); 
		cursor: default;
	}

	.shipping-list .delivery-style {
		.col-01 { @include h3($transform: none); }
		.selected { background-color: $light-grey; }
	}
	
	.shipping-method-v2 { border-color: $tertiary; }
	.indented, tbody { @include body-small(); }

	//Estimated Shipping Date
	.order-shipping td div, .order-promotions { @include body-tiny($color: $dark-grey);}
	.order-total { @include h4(); }
}

//Don't blame me, blame the unnecessary specificity in corev2
.pt_cart #secondary .shipping-list .free-shipping { color: $base; }

//Mini Cart
.mini-cart-inner {
	.mini-cart-name a { 
		@include h3($decoration: none); 
		&:hover { @include h3($color: $secondary, $decoration: none); }
	} 
	
	.mini-cart-attributes { @include body($line-height: 21px); }
	.mini-cart-action a, .mini-cart-action a:hover { @include body($color: $secondary, $decoration: underline); }
	.mini-cart-subtotals .label { @include h4(); }
}
