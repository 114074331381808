// www_store includes mobile and desktop header on all pages
// Adding #mobile-header here is just a temporary namespace to target these
// styles so they don't effect sites without the responsive cartridge activated.
// Once all sites have the responsive cartridge active, we can ditch this file.
#mobile-header {
  body[data-category-id='promotions-coupons'] {
    .landing-banner {
      max-width: 100%;
      width: 100%;
    }
  }

  $desktop-promotions-icon-top: 5px !default;
  $promotion-width: 260px !default;
  $promotions-wrapper-width: 320px !default;

  .desktop-promotions-icon {
    position: absolute;
    left: -46px;
    top: 264px;
    width: 47px;
    height: 47px;
    z-index: 1;
    box-sizing: border-box;
    box-shadow: -3px 2px 4px rgba(0, 0, 0, 0.3);

    @include breakpoint-max($tablet) {
      display: none;
    }

    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: $desktop-promotions-icon-top;
      @include sprite($icon-promo-cta);
    }
    cursor: pointer;
  }

  .promotions {
    &-wrapper {
      * { box-sizing: border-box; }

      box-sizing: border-box;
      height: calc(100vh - 53px);
      position: fixed;
      transform: translateX(100%);
      top: 53px;
      left: 0;
      right: 0;
      transition: transform 0.5s;
      z-index: 9;
      
      &.open {
        z-index: 300;
        transform: translateX(0);
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
      }
      
      @include breakpoint($tablet) {
        background-color: white;
        height: 100%;
        top: 0;
        left: auto;
        transition: transform 0.5s ease;
        transition-property: transform, z-index;
        width: $promotions-wrapper-width;
        z-index: 1000;

        &.open {
          box-shadow: -3px 2px 4px rgba(0, 0, 0, 0.3);
          overflow: visible;

          .promotions-scroll {
            padding: 0;
            left: auto;
          }
        }

        h1 {
          margin: 52px 0 15px;
          padding: 0 8px;
          text-align: center;
        }
      }

      .promotion {
        @extend %promotion-tile;
      }
    }

    &-scroll {
      position: relative;
      box-sizing: border-box;
      width: 85vw;
      left: 15vw;
      background-color: white;
      padding-bottom: 16px;
      border: 1px solid $mainbordercolor;

      @include breakpoint($tablet) {
        width: 100%;
        height: 100%;
        border: 0;
        overflow-y: auto;
        overflow-x: hidden;
        left: 0;
      }
    }

    &-close {
      @include breakpoint($tablet) {
        display: inline-block;
        @include sprite($icon-ui-close, 2);
        cursor: pointer;
        position: relative;
        top: 10px;
        left: 10px;
        margin: 5px;
      }
    }

    &-title {
      display: none;
      @include h2();

      @include breakpoint($tablet) {
        display: block;
        padding: 0 8px;
        margin: 28px 0 15px;
        text-align: center;
        font-size: 20px;
      }
    }

    &-callout {
      margin: 0 auto;
      width: 170px;
      text-align: center;

      p {
        padding-top: 32px;
        margin: 0;

        &.promos-unavailable { padding-bottom: 16px; } 
        &.promos-available { padding-bottom: 24px; }
      }

      @include breakpoint($tablet) {
        margin: 0 29px 29px;
        text-align: center;
        width: auto;

        p {
          margin: 0;
          padding-top: 0;

          &.promos-unavailable { padding-bottom: 0; } 
          &.promos-available { padding-bottom: 0; }
        }
      }
    }  
  }

  %promotion-tile {
    background-color: white;
    border: 1px solid $mainbordercolor;
    width: $promotion-width;
    margin: 0 auto 30px;

    @include breakpoint($tablet) {
      margin: 0 auto 43px;
      left: 0;
      padding: 0;  
    }

    .content {
      position: relative;
      transition: height .3s ease;
      overflow: hidden;
      line-height: 0;

      .content-asset {
        transition: margin-top .3s ease;
        line-height: normal;
      }
    }

    .info-details-poppup {
      position: absolute;
      min-height: 100%;
      width: 100%;
      left: 0;
      background: white;
      transform: translateY(-100%);
      transition: transform 0.3s ease;
      padding: 5px;
      overflow-y: auto;
      line-height: normal;
      z-index: 1;
      
      &.active {
        transform: translateY(0);
        overflow-x: auto;
        word-wrap: break-word;
      }
    }

    .content-asset {
      @include breakpoint($tablet) {
        transition: margin-top 0.3s ease;
        max-width: 100%;
        line-height: normal;
      }
    }

    .info-bar {
      padding: 10px 12px;
      border-top: 1px solid;
      position: relative;
      text-transform: none;
      
      .add-coupon {
        float: left;
        @include breakpoint($tablet) {
          cursor: pointer;
        }
      }

      .info-icon {
        @include sprite($icon-promo-add, 2);
        float: left;
        margin-right: 8px;

        &.working {
          @include spin(1s, infinite, ease-in-out, alternate);
        }

        &.added {
          @include sprite($icon-promo-remove, 2);
          animation-direction: alternate-reverse;
        }
      }

      .info-code {
        float: left;
        padding: 0px 5px;
        text-transform: none;

        @include breakpoint($tablet) {
          line-height: inherit;
          text-transform: uppercase;
          padding: 0;
        }
      }
    }

    .info-details {
      float: right;

      @include breakpoint($tablet) {
        background-repeat: no-repeat;
        background-position: 100%;
        cursor: pointer;
        line-height: inherit;
        text-transform: capitalize;
      }
    }
  }

  .info-poppup {
    display: none;
    position: absolute;
    font-size: 16px;
    min-height: 1em;
    background: $white;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.35);
    padding: 4px 11px;
    min-width: 30px;
    left: -3px;
    bottom: 15px;
    white-space: nowrap;
    text-transform: capitalize;

    &-wrapper {
      position: absolute;

      @include breakpoint($tablet) {
        position: static;
      }
    }
    
    @include breakpoint($tablet) {
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
      padding: 13px 11px 13px 12px;
      bottom: 50px;
      left: 2px;
      z-index: 99;
    }

    &.show {
      display: block;
    }

    &:after,
    &:before {
      content: '';
      border: solid transparent;
      position: absolute;
      top: 100%;
      height: 0;
      width: 0;
      border-width: 7px;
      border-top-width: 13px;
      margin: 0 -11px;
      left: 17px;

      @include breakpoint($tablet) {
        pointer-events: none;
        margin: 0;
      }
    }
    
    &:after {
      border-color: rgba(136, 183, 213, 0);
      border-top-color: white;
      top: 100%;

      @include breakpoint($tablet) {
        border-color: transparent;
        border-top-color: $white;
        border-width: 9px;
        border-top-width: 14px;
        margin-left: -9px;
        margin-top: -2px;
      }
    }

    &:before {
      border-color: rgba(194, 225, 245, 0);
      border-top-color: $mainbordercolor;

      @include breakpoint($tablet) {
        border-width: 9px;
        border-top-width: 14px;
        margin-left: -9px;
      }
    }
  }

}

.promotions-close:focus {
  outline-style: auto;
}
