$desktop-promotions-icon-top: 5px !default;
body[data-category-id="promotions-coupons"]{
	.landing-banner{
		max-witdh: 100%;
		width: 100%;
	}
}
.desktop-promotions-icon{
  position: absolute;
  left: -47px;
  top: 264px;
  width: 47px;
  height: 47px;
  z-index: 1;
  box-sizing: border-box;
  box-shadow: -3px 2px 4px rgba(0, 0, 0, 0.3);
  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: $desktop-promotions-icon-top;
    @include sprite($icon-promo-cta);
  }
  cursor: pointer;
}
// Promo Tile Style Start
.promo-tile{
  .desktop-promotion {
    border: 1px solid;
    .content{
      position: relative;
      transition: height .3s ease;
      overflow: hidden;
      line-height: 0;

      .content-asset{
        transition: margin-top .3s ease;
        max-width: 100%;
        line-height: normal;
      }
      .info-details-poppup{
        position: absolute;
        bottom: 100%;
        min-height: 100%;
        width: 100%;
        left: 0px;
        background: white;
        transition: bottom .3s ease;
        padding: 5px;

        &.active{
          top: 0;
          overflow-x: auto;
          word-wrap: break-word;
          line-height: normal;
        }
      }
      .promo-slot__details-wrapper{
      	margin-top: 0 !important;
      }
    }
 	#promo-subscribe {
	    height: 209px;
	    .promo-email-address-label{
	    	span[generated="true"]{
	    		margin-top: 99px !important;
	    	}
	    }
	}
    .info-bar {
      padding: 13px 15px 12px 11px;
      border-top: 1px solid;
      position: relative;
      text-transform: none;

      .add-coupon{
        cursor: pointer;
        float: left;
      }

      .info-icon {
        @include sprite($icon-promo-add,2);
        float: left;
        margin-right: 8px;

        &.working{
          @include spin(1s, infinite, ease-in-out, alternate);
        }
        &.added{
          @include sprite($icon-promo-remove,2);
          animation-direction: alternate-reverse;
        }
      }

      .info-code{
        float: left;
        line-height: inherit;
        padding: 0px;
        text-transform: uppercase;
      }
      .info-details{
        background-repeat: no-repeat;
        background-position: 100%;
        cursor: pointer;
        float: right;
        line-height: inherit;
        text-transform: capitalize;
      }
    }
    .info-poppup {
      display: none;
      position: absolute;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
      background: $white;
      padding: 13px 11px 13px 12px;
      bottom: 48px;
      left: -55px;
      text-transform: capitalize;
      white-space: nowrap;
      z-index: 99;
      bottom: 50px;
      left: 6px;

      &.show {
        display:block;
      }

      &:after, &:before {
        content: "";
        border: solid transparent;
        position: absolute;
        top: 100%;
        height: 0;
        width: 0;
        left: 16px;
        pointer-events: none;
      }

      &:after {
        border-color: transparent;
        border-top-color: $white;
        border-width: 9px;
        border-top-width: 14px;
        margin-left: -9px;
        margin-top:-2px;
      }

      &:before {
        border-top-color: $mainbordercolor;
        border-width: 9px;
        border-top-width: 14px;
        margin-left: -9px;
      }
    }
  }
}
// Promo Tile Style end
.promotions-wrapper {
  * {
    box-sizing: border-box;
  }

  .desktop-promotion {
    border: 1px solid;

    .content{
      position: relative;
      transition: height .3s ease;
      overflow: hidden;
      line-height: 0;

      .content-asset{
        transition: margin-top .3s ease;
        max-width: 100%;
        line-height: normal;
      }
      .info-details-poppup{
        position: absolute;
        bottom: 100%;
        min-height: 100%;
        width: 100%;
        left: 0px;
        background: white;
        transition: bottom .3s ease;
        padding: 5px;

        &.active{
          top: 0;
          overflow-x: auto;
          word-wrap: break-word;
          line-height: normal;
        }
      }
    }

    .info-bar {
      padding: 13px 15px 12px 11px;
      border-top: 1px solid;
      position: relative;
      text-transform: none;

      .add-coupon{
        cursor: pointer;
        float: left;
      }

      .info-icon {
        @include sprite($icon-promo-add,2);
        float: left;
        margin-right: 8px;

        &.working{
          @include spin(1s, infinite, ease-in-out, alternate);
        }
        &.added{
          @include sprite($icon-promo-remove,2);
          animation-direction: alternate-reverse;
        }
      }

      .info-code{
        float: left;
        line-height: inherit;
        padding: 0px;
        text-transform: uppercase;
      }
      .info-details{
        background-repeat: no-repeat;
        background-position: 100%;
        cursor: pointer;
        float: right;
        line-height: inherit;
        text-transform: capitalize;
      }
    }
  }

  .info-poppup {
    display: none;
    position: absolute;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    background: $white;
    padding: 13px 11px 13px 12px;
    bottom: 48px;
    left: -55px;
    text-transform: capitalize;
    white-space: nowrap;
    z-index: 99;

    &.show {
      display:block;
    }

    &:after, &:before {
      content: "";
      border: solid transparent;
      position: absolute;
      top: 100%;
      height: 0;
      width: 0;
      left: 75px;
      pointer-events: none;
    }

    &:after {
      border-color: transparent;
      border-top-color: $white;
      border-width: 9px;
      border-top-width: 14px;
      margin-left: -9px;
      margin-top:-2px;
    }

    &:before {
      border-top-color: $mainbordercolor;
      border-width: 9px;
      border-top-width: 14px;
      margin-left: -9px;
    }
  }
}

.desktop-promotions {
  position: fixed;
  box-sizing: border-box;
  height: 100%;
  width: 322px;
  top: 0px;
  background-color: white;
  right: -322px;
  transition: right .5s ease;
  transition-property:right, z-index;
  transition-delay:0ms, .5s;
  z-index:1000;


  &.open{
    box-shadow: -3px 2px 4px rgba(0, 0, 0, 0.3);
    right: 0px;
		  z-index:2500;
      transition-property:right;
  }

  h1 {
    margin: 52px 0 15px;
    padding: 0 8px;
    text-align: center;
  }

  .desktop-promotions-callout {
    margin: 0 29px 29px;
    text-align: center;

    p {margin: 0;}
  }

  .desktop-promotions-close{
    display: inline-block;
    @include sprite($icon-ui-close, 2);
    cursor: pointer;
    position: relative;
    top: 10px;
    left: 10px;
  }

  .desktop-promotions-title {
  	padding: 0 8px;
  	margin: 28px 0 15px;
    text-align: center;
    font-size:20px;
  }

  .desktop-promotions-scroll{
    position: relative;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    left: 0px;
  }
  &.open{
    .desktop-promotions-scroll{
      padding-left: 47px;
      left: -47px;
    }
  }

  .desktop-promotion {
    margin: 0 29px 43px;
  }
}

.promotions-wrapper.plp-feature-slider.account-promotions {
  display:inline;
  position:unset;

  .info-bar .info-code, .info-bar .info-details, .info-bar .add-coupon {
    width:unset;
  }
  .desktop-promotion.account-promotions {
	border:0;
	padding:0;
	margin:0;
	
	.promotion.promotion-item {
      width:99%;
      overflow:hidden;
      padding:0px;
      margin:0;
      height:302px;
      border-radius:0;
      border:solid 1px black!important;
      .content {
        max-height:270px;
      }
      .info-bar{
        padding:10px 16px;
      }
    }

    .promotions-title {
	  font-size:32px;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .desktop-promotion.account-promotions {
      width:100%;
    }
  }
}

@media screen and (min-width:740px) {
  .alt-mobile-header {
    .desktop-promotions {
      &.promotions-wrapper {
        display: none;
      &.open {
        display: block;
        }
      }
    }
  }
}
