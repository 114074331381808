#bonus-product-dialog.dialog-content {

	.pdpv2 {
		.product-name {
			width: 100%;
		}
		.product-primary-image img {
			max-width: 100%;
		}
		
		#product-content .attribute-holder {
			float: left;
		}
		
		.product-col-1 {
			padding-top:0;
		}
		.product-col-2.product-info {
			float: left;
			margin: 0 0 0 40px;
			
			fieldset,
			button,
			.product-add-to-cart {
				margin-top: 0;
			}
		}
		.variations-color {
			margin: 0 0 20px;
		}
		.swatch-size-tiny {
			box-sizing: border-box;
			margin-left: 0;
			max-width: 360px;
			padding: 10px;
		}
	}
}

.ui-dialog-titlebar-close {
	z-index: 9;
}

.bonus-product-modal-title {
  line-height: 24px;
  margin-right: 8px;
}
