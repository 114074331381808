// $cart-progress: $black !default;

.MiniCart__ProductTileContainer, .MiniCart__FreeShippingMessage {
  .MiniCart__FreeShippingdeliveryMessage {
    h4 {
      letter-spacing: 0.14px;
      line-height: 19px;
      margin-bottom: 0px;
      font-size: 14px;
      margin: 0em 0;
      text-transform: none;
      color:#373836;
    }
    p {
      letter-spacing: 0.14px;
      line-height: 19px;
      font-size: 14px;
      margin-bottom: 0px;
      text-transform: none;
      color:#373836;
    }   
  }

  .ProgressBar_On_Minicart {
    .Minicart_Container_Styles{
      height: 16px;
      width: 100%;
      background-color: #CDD2D5;
      border-radius: 50px;
    }

    .Minicart_Filler_Styles {
      height: 100%;
      border-radius: 50px;
      background-color: $minicart-progress;
      max-width: 100%;
    }
      .MiniCart_price_checkbox_display {
      height: 19px;
      width: 19px;
      display: inline;
      
      .MiniCart_price_display {
      float: left;
      height: 18px;
      width: auto;
      font-size: 14px;
      color: #373836;
      }

      .MiniCart_icon_check_status {
      float: right;
      height: 20px;
      width: 20px;
      }
    }
  }
}

.title-hold, .mobile-cart-header  {

  .MiniCart__ProductTileContainer {
    .MiniCart__FreeShippingMessage {
      border-bottom: none !important;
    .MiniCart__FreeShippingdeliveryMessage {
          h4,p {
            font-size: 16px !important;
          }
        }
    }
  }

  @media screen and (max-width: 769px) {
    .MiniCart__ProductTileContainer {
      .ProgressBar_On_Minicart {
        .Minicart_Filler_Styles {
          background-color: $base !important;
        }
      }
      .MiniCart__FreeShippingMessage {
        .MiniCart__FreeShippingdeliveryMessage {
          h4 {
            font-size: 14px !important;
            color: $base !important;
          }
          p {
            font-size: 12px !important;
            margin: 0;
            color: $base !important;
          }
        }
        .ProgressBar_On_Minicart {
            margin-top: 16px;
            margin-bottom: 8px;
            .Minicart_Container_Styles {
              margin: 0px 8px 0 8px;
              background-color: #D5D5D5 !important;
            }
            .MiniCart_price_checkbox_display {
              display: flex;
              justify-content: space-between;
              align-items: center;
              min-width: 100%;
            .MiniCart_price_display {
              font-size: 14px !important;
              color: $base !important;
              white-space: nowrap;
            }
            .Minicart_Filler_Styles {
              background-color: $base !important;
            }
            .MiniCart_icon_check_status {
              display: flex;
            }
          }
        }
        .MiniCart__FreeShippingdeliveryMessage {
          border-bottom: none !important;
        }
      }
    }
  }

  @media screen and (min-width: 769px) {
    .MiniCart__ProductTileContainer {
      min-height: 132px;
      .MiniCart__FreeShippingMessage {
      .MiniCart__FreeShippingdeliveryMessage {
          h4,p {
            font-size: 14px !important;
          }
        }
      }
    }
    .MiniCart__ProductTileContainer {
      min-height: fit-content;
      .ProgressBar_On_Minicart {
          .Minicart_Filler_Styles {
            background-color: $base !important;
          }
        }
      .MiniCart__FreeShippingMessage {
        .MiniCart__FreeShippingdeliveryMessage {
          h4, p {
            font-size: 16px !important;
            color: $base !important;
          }
        }
        .ProgressBar_On_Minicart {
          margin-top: 12px;
          margin-bottom: 10px;
          .Minicart_Container_Styles {
            margin: 0px 8px 0 8px;
            background-color: #D5D5D5 !important;
          }
          .Minicart_Filler_Styles {
            background-color: $base !important;
          }
          .MiniCart_price_checkbox_display {
              display: flex;
              min-width: 100%;
              justify-content: space-between;
              align-items: center;
            }    
            .MiniCart_price_display {
              font-size: 16px !important;
              color: $base !important;
              white-space: nowrap;
            }
            .MiniCart_icon_check_status {
              display: flex;
            }
        }
      }
    }
  }
  
}
