// Extended in _styles.scss
// ------------------------

// footer

.footer-bottom-text, 
.email-signup-box p { @include body-small(); }

.footer-bottom-util-link {
  @include body-small($dark-grey);

  &:hover { @include body-small($secondary); }
}

.footer-social-links {}

.footer-link {
  @include body($dark-grey, none, none, 16px);

  &:hover { @include body($secondary, none, none, 16px); }
}

.footer-link-headline { @include h4($base); }

.top-of-footer {
}

// Weird specificity here to affect footer country selection as some
// menuitems have an anchor tag and some do not.
#footer .footer-country-selector .country-select-menu {
  li, a {
    color: $dark-grey;
    &:hover,
    &:focus {
      color: $secondary;
      text-decoration: none;
    }
  }
}

// Styles
// ------

#footer { border-top: 3px solid $primary; }


// Footer email signup

.email-signup-box {
  text-align: left;
  
  input[type="email"]:not(.error-message) {
    border: $borderMixin;
    background-color: $contrast;
  }
  
  input[type="submit"] {
    background-image: none;	
    background-color: $cta;
    width: auto;
    text-indent: 0;
    line-height: 39px;
    transition: 0.2s;
    
    &:hover { background-color: $ctahover; }
  }
}

// Promos

.desktop-promotions {
  &.open {
    box-shadow: none;
    border-left: $borderMixin;
  }

  .desktop-promotions-icon {
    background-color: $contrast;
    box-shadow: none;
    border: $borderMixin;
    border-right: 1px solid $contrast;
  }
  .desktop-promotions-title { @include h2($dark-grey, null, none);  }

  .desktop-promotions-callout {
    p { @include body($dark-grey); }
    a {}
  }

  .desktop-promotion {
    border: 0;
    
    .content { background-color: $secondarybackground; }

    .info-bar {
      background-color: $secondarybackground;
      border-top: 1px solid $contrast;
      
      &,
      .info-code { @include h4($base, none, null, 24px); }

      .info-details { @include body($secondary, none, null, 24px); }

      .info-poppup {
        box-shadow: none;
        border: $borderMixin;
        @include body();
      }
    }
  }
}

.country-box a.current-lang span { margin-top: 5px; } // snowflake to center country select flag
#footer .country-box a.current-lang:not(.active)::after { // no caret on footer country select
  display: none;
}