/*
  Default React Responsive Select Styles - Should be freely overwritten
*/
#single-page-checkout {
  .rrs {
    position: relative;
    box-sizing: border-box;
  }

  .rrs *,
  .rrs *:before,
  .rrs *:after {
    box-sizing: border-box;
  }

  .rrs__button {
    @extend %spc-input;
    height: auto;
    min-height: 56px;

    .spc-input-select-label {
      @include body();
    }
  }

  .rrs__button:focus {
    outline: 0;
  }

  .rrs__button + .rrs__options {
    @include body();

    list-style: none;
    padding: 0;
    margin: 0;
    background: $white;
    position: absolute;
    z-index: 2;
    border-radius: 0;
    top: auto;
    left: 0;
    right: 0;
    height: 0;
    visibility: hidden;
    overflow: hidden;
    box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.25);
  }

  .rrs--options-visible .rrs__options {
    height: auto;
    visibility: visible;
    overflow: initial;
    -webkit-overflow-scrolling: touch;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .rrs__option {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 60px;
    padding: 0.5rem 1rem;
    margin: 0;
    border-top: 1px solid $mainbordercolor;

    @include breakpoint($tablet) {
      min-height: 50px;
      padding: 0.2rem 1rem;
    }

    span {
      display: block;
      width: 100%;
      @include breakpoint($tablet) {
        display: inline;
        width: auto;
      }
    }
  }

  .rrs__option * {
    pointer-events: none;
  }

  .rrs__option:focus {
    outline: 1px solid #e0e0e0;
  }

  .rrs__option:active {
    background: #e1f5fe;
  }

  .rrs__option:hover,
  .rrs__option.rrs__option--selected {
    background: lighten($lightbackdrop, 10%);
    position: relative;

    &:before {
      content: "";
      display: block;
      height: calc(100% + 2px);
      width: 6px;
      background-color: black;
      position: absolute;
      left: -1px;
      top: -1px;
    }

    @include breakpoint($tablet) {
      &:before { width: 3px; }
    }
  }

  .rrs__option.rrs__option--disabled {
    color: $grey-medium-light;
    background: $grey-white-smoke;
    cursor: default;
  }

  .rrs__option.rrs__option--header {
    cursor: default;
    font-size: 0.7rem;
    font-weight: 700;
    text-transform: uppercase;
    background: $grey-white-smoke;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .rrs__label {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &__text {
      padding-top: 0.4rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: calc(100% - 30px);
      width: 100%;
      padding-bottom: 10px;

      @include breakpoint($tablet){
        max-width: calc(100% - 40px)
      }
    }

    .spc-card-streetaddress {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .rrs__label * {
    pointer-events: none;
  }

  .rrs--has-changed .rrs__label {
    color: #0273b5;
  }

  // /*
  //   Caret Icon
  // */

  .rrs__label:after {
    background-image: url(../images/select-arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 48px 24px;
    content: "";
    display: block;
    height: 8px;
    width: 15px;

    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }

  .rrs--options-visible .rrs__label:after {
    transform: translateY(-50%) rotate(180deg);
  }
}
