// default any vars used in this file and declare them in brands to override
$selectedShippingBg: $grey-light-very !default;

.content-banner{
  // class to fit centered banner content.
  &.full-width {
    width: 300%;
    margin-left: -100%;
  }
  padding: 20px 0;
  text-align: center;
}

.content-banner-text{
  margin: 0 auto;
  font-size: 20px;
}

//top-header-banner is on the very top, and also contains the top-level links like live chat, customer service, find a store, and account.
.top-header-banner {
  .delivery-message-banner {
    width: 920px;
    height: 29px;
    margin: 0 auto;
    line-height: 29px;
  }
}

//universal delivery-message styling
.delivery-message {
  .delivery-tagline { font-weight: 900; }
}

//promo block
.delivery-message-block {
  .delivery-message {
    margin: 25px 0 0;
    text-align: center;
  }
}

//product description page
.delivery-message.pdp {
  text-align: center;
  padding: 15px 0;
  line-height: 1.7em;
}

//universal shipping method style (for cart & checkout)
.shipping-list {
  &.limited-width {
    max-width: 396px;

    .col-01 {
      box-sizing: border-box;
      width: 49%;
      padding-right: 6px;
      vertical-align: top;
    }

    .col-02 { width: 85%; }
  }

  .delivery-style {
    border: 1px solid;

    &:not(:first-child) {
      border-top: none;
    }
    
    &.selected {
      background: $selectedShippingBg;
    }

    input {
      margin: 0 2px 0 0;
      cursor: pointer;
    }

    label {
      width: 90%;
      padding: 0;
      cursor: pointer;
      &.full-width {
        width: 100%;

        .col-01 {
          display: inline-block;
          width: 86%;
          vertical-align: top;
        }
      }
    }

    .col {
      display: inline-block;
      &-01 { text-transform: uppercase; }
      &-02 { text-transform: none; }
      &.indented { padding-left: 23px; }
      .struck { text-decoration: line-through; }
    }
  }
}

.shipping-estimate-label {
  margin: 0 0 11px;
}

//cart-only styles
.pt_cart {
  .delivery-style {
    padding: 12px 7px 10px;

    input[type="radio"] {
      position: relative;
      top: 2px;
    }
  }
}

.product-recommendation-box ul.www-recommendations {
  padding: 0 0 40px;
  width: 100%;

  @media (min-device-width: 767px) {
    display: table;

    li { 
      display: table-cell;
      width: 25%;
      height: 325px;
    }

    .product-tile {
      padding: 22px;
    }
  }

  li {
    vertical-align: top;
    box-sizing: border-box;
    list-style: none;
  }
}
