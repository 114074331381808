.sticky-search-enabled {
  .sticky-search-container{
    width: 720px;
    left: 50%;
    top: 13px;
  }

  .active-persistentheader .primary-logo img{
    &.favicon-logo{
      max-width: 50px;
      max-height: 50px;
    }
  }

  .active-persistentheader a.level-1{
    padding: 0 6px;
    font-size: 15px;
  }
}

.search-field {
  @include h3($dark-grey);
  border: $borderMixin;
}

%search-h4 { @include h4(); }

%search-body-copy {
  color: $dark-grey;

  &:hover, &:focus {
    color: $secondary;
    text-decoration: none;
  }
}

.drop-down-nav-header {
  @include h4();
  text-decoration: none;
  &:hover { color: $base; }

  .column1 & {
    @include h3($transform: uppercase);
    &:hover { color: $base; }
  }
}

@include featured-column($contrast);
.column1 ul.level-2 { border: $borderMixin; }

//"Featured" text in dropdown and category names not all uppercase
div.level-2 div.column1 ul.level-2 > li {
  > div.catname-only, a { 
    text-transform: none; 
  }
}

div.column1 div.level-3 ul.level-3 li a, ul.menu-category li.more-tabs .tab .tablinks .tabcontent div.column1 div.level-3 ul.level-3 li a{
  @include h3();

  &:hover, &:focus {
    @include h3($color: $secondary);
    text-decoration: none;
  }	
}

.column1 .level-2 li a {
  @include h4($primary);
  font-size: 12px;
  &:hover { color: $tertiary; }
}

// Snowflake for header nav spacing issues
.menu-wrapper .column1 {
  width: 141px;
  margin-right: 57px;
  padding-right: 0;
  @media screen and (min-width: 768px) and (max-width:820px) {
    margin-right: 10px;
  }
}

.mainnav-tab {
  position: relative;

  a.level-1:hover {
    background: $primary;

    &::after { // create arrow under hovered item
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-left: 10px solid $contrast;
      border-right: 10px solid $contrast;
      border-top: 10px solid $primary;
      z-index: 10;
    } 
  }
}

.remembered-info-text, .main-nav-link {
  &, a {
    @include h4();
    text-decoration: none;
  }
}

// I don't understand why this is affecting .mini-cart-quantity-bag, but leaving it here in case it's needed elsewhere
// .cart-and-remembered-num { @include body($callout); }

.mini-cart-text {
  @include body();
  line-height: 14px;
  a {
    color: #303248;
  }
}

.mini-cart-subtotals { @include body(); }
.mini-cart-label { @include body(); }
.mini-cart-link:hover { text-decoration: none; }

.drop-down-nav-links {
  @include body($color: $dark-grey, $line-height: 13px);
  &:hover { @include body($color: $secondary, $line-height: 13px); }
}

div.level-2 ul.level-2 .level-3 .sub-sub-nav a {
  @include body-small($primary, none, none, 11px);
  &:hover { @include body-small($primary, underline, none, 11px); }
}

.utility-link {
  @include h3($dark-grey);
  text-decoration: none;
  &:active, &:focus, &:hover { @include h3($dark-grey); }
}

.products-container, %search-shaded-bg { background-color: $secondarybackground; }
.products-container .product-tile { background-color: $contrast; }
#header .primary-logo { top: 40px; }
.primary-logo img { padding: 0; }

#navigation {
  background-color: white;
  &.persistent-inactive { border-top: none; }
  &.active-persistentheader { border-top: none; }
}

.minimal-header #navigation { background-color: $mainbackground; }

.header-search form {
  input[type="submit"] { //snowflake to create button on search
    right: 0;
    padding: 15px 17px;
    background-color: $primary;
    background: url(../images/interface/icon-search.png) center center no-repeat $primary;
    &:hover, &:focus { background-color: $ctahover; }
  }
}

.sticky-search-enabled .sticky-search-container .header-search.site-search input[type="text"] { width: 235px; }

.submenu-background {
  top: 100%; // snowflake for dropdown nav
  background: $contrast;
  border: $borderMixin;
  @media screen and (min-width: 768px) {
    width: 100vw;
  }
}

.header-search.site-search input[type="text"], #header .nav-search input, #header .site-search input { border: 0; }

div.level-2 {
  border-top: $borderMixin;
  ul.level-2 > li > a { text-transform: none; }
}

.active-persistentheader div.level-2  { border-top: $borderMixin; }

.headerscrolltotop {
  box-shadow: none;
  border: $borderMixin;
  background-color: $contrast;
  
  &::before { //snowflake to adjust arrow sprite
    top: 8px;
    transform: translateX(-50%) scale(1);
  }

  a, a:hover { @include h3($color: $dark-grey, $line-height: 62px);}
}

.country-box ul li {
  &:hover { text-decoration: none; }

  a { 
    color: $dark-grey;

    &:hover, &:focus {
      color: $secondary;
      text-decoration: none;
    }
  }
}

.desktop-promotions {
  .desktop-promotion {
    border-color: $contrast;

    .info-bar {
      background-color: $secondarybackground;
      border-color: $contrast;

      .info-code { @include h4(); }

      .info-details {
        @include body($color: $secondary);
        &:hover { text-decoration: underline; }
      }
    }
  }

  .promos-available a { 
    @include body($color: $secondary);
    &:hover { text-decoration: underline; }
  }
}

.isDesktopEnvironment .desktop-promotions.promotions-wrapper .desktop-promotions-icon .promo-count { line-height: 20px; } //Snowflake per UAT
.desktop-promotions-icon { background-color: $contrast; }
.desktop-promotions-title { @include h2(); }
.country-box a.current-lang:not(.active):after { top: 62%; } // snowflake to center caret on country select


.MiniCart__Header .MiniCart__HeaderActions .icon-mini-cart::after {
  @include icon-cart;
}

.MiniCart__ButtonContainer .mini-cart-quantity-bag,
.MiniCart__ButtonContainer .mini-cart-empty,
.MiniCart__Header .MiniCart__HeaderActions .mini-cart-quantity-bag 
{
  @include brand-cart-counter;
}

.enhanced-site-search-results ul li a {
  color: $mainbrand;
  &:hover {
    color: $secondary;
  }
}

.top-header{
  background-color: $white !important;
}

#header {
  .top-of-header {
    background-color: $header-background;

    .helper-dropdown-links,
    .account-dropdown-links {
      background-color: $white;
    }

    .helper-dropdown-links .helper-dropdown-link,
    .account-dropdown-links a,
    .vertical-divider,
    .content-asset .toggle {
      color: #545859;
    }

    .account-dropdown-links .account-guest-new-account:hover, .account-dropdown-links .account-guest-signin:hover, .account-dropdown-links a:hover, .account-dropdown-links li:hover {
      font-size: 13px;
      font-weight: normal;
    }
  }
}

.top-header-links {
  li.menu-help{
    padding-left: 0px;
    padding-right: 0px;

    .help-link {
      color: #545859;
      padding-left: 6px;
      padding-right: 6px;

      &:hover {
        color: $black;
        font-weight: 600;
        background-color: #ffcc00;
      }
    }
  }
}

.header-style-wrapper nav ul.level-1>li {
  border: 0px solid transparent !important;
}

.nav-center-align .signin-button{
  background-color: #FFCC00;
  color: $black;
  text-transform: uppercase;
    &:hover{
      background-color: #DADADA!important;
    }
}

.nav-signin-tooltip-footer a:hover{
  text-decoration: underline !important;
}

.top-of-header .top-header-links .new-wishlist-count, .top-of-header .top-header-links .new-offer-feature-count{
  background: #e24d25;
  top: 6px;
}

ul.level-1 li a.level-1{
  padding: 20px 9px !important;
  outline-offset: -1px;
}

.country-select-pop .country-pop-close .country-close-icon {
  background-size: 189.5px 187.5px;
  background-position: -141px -124px;
}

.MiniCart__ProductTile {
  .mini-cart-action {
    a {
      font-size: 12px;
      padding: 0;
      font-family: $body-family;
    }
  }
  .mini-cart-name {
    a {
      font-weight: normal!important;
      &:hover,
      &:active,
      &:focus { 
        color: $product-name-link!important; 
      }
    }
  }
  .mini-cart-pricing {
    .mini-cart-price {
      font-size: 14px;
      color: $black;
    }
    .price-promotion {
      .price-sales {
        font-size: 14px;
      }
    }

    .quantity-main-wrapper {
      .quantity-number {
        font-size: 14px;
        color: $black;
        font-family: $body-family;
      }
    }
  }
}

.MiniCart__Footer {
  #spc-drawer-promoCode {
    .spc-applied-promotions {
      .spc-applied-discount-message {
        color: #071c2c;
        .row {
          .col-sm-11 {
            .spc-discount-seperator {
              font-family: $font-type2;
            }
          }
        }
        .MiniCart__PromoCodeDetails {
          .promoDescription {
            color: $base;
          }
        }
      }
    }

    #spc-idme-container {
      .spc-drawer-header {
        span {
          font-family: $Mier;
        }
      }
    }
  }
}

.mini-cart-products {
  .mini-cart-product {
    .mini-cart-out-of-stock {
      .notify-me-container {
        .out-of-stock {
          color: $blue !important;
          font-family: $Mier !important;
        }
      }
    }
  }
}
