//Slideshow Nav -- Don't blame me, blame the unnecessary specificity in corev2
.pdp-main.pdpv2 .product-col-1.product-hero-image {
	.flex-control-paging li a.flex-active { background: $dark-grey; }
	.alternate-views h2 { @include h3($transform: uppercase, $color: $dark-grey); }	
	#thumbnails { margin-bottom: 10px; }
}

//Product Info
.product-name-v2 { @include h2($color: $dark-grey); }

.pd-price {
	@include body-large($color: $dark-grey);
	
	//Price w/ Discount
	.sales-price + .original-price {
		@extend .pd-price;
		// color: $tertiary;
		text-decoration: line-through;
	}
	.sales-price {
		color: $callout;
		text-transform: uppercase;
	}

	//Price w/o Discount
	.price-sales { color: $dark-grey }
}

.b-write-reviews, .b-write-reviews:hover { @include body($color: $secondary, $decoration: underline); }

.reviews-tab-count { 
	@include body(); 
	&.ui-tabs-anchor { @include h2($color: $dark-grey); }
}

.pdp-main.pdpv2 .product-col-2.product-info {
	.delivery-message {
		@include body($dark-grey);
		span { @include body($color: $green); }
	}

	.product-variations {
		&.dropdownvariation .toggle-content {
			a { @include body($color: $secondary, $decoration: underline); }
	
			li.attribute li:hover label, .selected label {
				color: $base;
				border: none;
				padding: 1px;
			} 
		}
	
		.toggle { 
			background-color: $contrast;
			@include h3($line-height: 55px);
		}
	
		.toggle-content { 
			background-color: $contrast;
	
			@media (min-width: 767px) {
				&::before {
					content: '';
					background: $contrast;
					position: absolute;
					z-index: 999;
					width: 261px;
					height: 2px;
					left: 0;
					top: -2px;
				}
			}
		}
	}

	.product-actions {
		a, .title {
			@include h3($transform: uppercase);
			&:hover { color: $secondary; }	
		}
		
		.icon-share .sharing-icons {
			@include box-shadow-01();
			border: $borderMixin;
			left: 45%; //Forgive me, but it looked bad
		
			&:before { border-top-color: $mainbordercolor; }
		}
	}
}

.pdp-drawer {
	.pdp-drawer-title {
		@include h2($color: $dark-grey);
		&:hover { color: $base; }
	}

	.product-tile {
		.price-standard { @include body($color: $dark-grey); }
		
		.product-name a {
			@include h3();
			&:hover { color: $secondary; }
		}
	} 
}

.rating-box {
	.total-rating { @include body-large(); }

	.total-reviews, .icons-thumb a, .more-link a { @include body($color: $secondary, $decoration: underline); }
	
	.title-hold {
		h3 { @include h4(); }
		p { @include body($color: $dark-grey); }
	}
	
	.rating-indicator { border-radius: 50%; }
	.rating-count-box .rating-bar-value { background: $primary; }

	.sort-title { @include h3($transform: lowercase); }
	
	.star-rating-filter-box .sort-options { 
		span { @include body-small($color: $dark-grey); }
		.active span { @include body-small($color: $secondary); }
	}
	
	.user-name, .user-info-box i, .title, .reply-header { @include h4(); }

	.overall-feedback-box .feedback-box-inner p {
		span { @include body(); }
		span.title { @include h4(); }
	}

	#send-report { @include body($color: $secondary); }
}

.rating-performance-box .rating-count-box .rating-bar {
	overflow: hidden; 
	height: 4px; 
}

.pagination { 
	.page-link { @include body();}
	li.active { @include body($color: $dark-grey); }
}

//Persistent Shop -- Don't blame me, blame the unnecessary specificity in corev2
.pdp-main.pdpv2 #product-content.persistent .persistent-shopping-close + .persistent-details {
	h1 { @include h3(); }
	.product-price { @include body($color: $dark-grey); }
}