#desktop-header #header .top-of-header {
    width: 100%;
    height: 35px;
    position: fixed;
    z-index: 1250 !important;
    display: flex;
    align-items: center;
  }

  #desktop-header #header .top-header-banner {
    text-align: center;
    margin: 0 auto;
  }

  #header .top-header-banner-slider li, #header .top-header-banner-slider a {
    text-decoration: none;
    color: #0054a6;
  }
  .top-of-header .top-header-links{
    display: flex !important;
    background-color: transparent !important;
    position: absolute !important;
    //right: 8% !important;
    right: 10% !important;
    font-size : 15px !important;
    z-index: 1215;

    @media screen and (max-width: 900px) {
      right: 7.1% !important;
    }

    @media screen and (min-width: 901px) and (max-width: 940px) {
      right: 7.3% !important;
    }

    @media screen and (min-width: 941px) and (max-width: 1024px) {
      right: 7.5% !important;
    }

    @media screen and (min-width: 1025px) and (max-width: 1124px) {
      right: 7.7% !important;
    }

    @media screen and (min-width: 1281px) and (max-width: 1423px) {
      right: 8.2% !important;
    }

    @media screen and (min-width: 1424px) {
      right: 8.4% !important;
    }

    .new-wishlist-count{
      position: absolute;
      top: 5px;
      right: 0px;
      background: #036677;
      color: $white!important;
      height: 12px;
      text-align: center;
      line-height: 12px!important;
      font-size: 9px!important;
      width: 12px;
      border: 1px solid $white;
      border-radius: 22px;
    }
    .new-offer-feature-count{
      position: absolute;
      top: 5px;
      right: 2px;
      background: #036677;
      color: $white!important;
      height: 12px;
      text-align: center;
      line-height: 12px!important;
      font-size: 9px!important;
      width: 12px;
      border: 1px solid $white;
      border-radius: 22px;
    }
  }

  .top-header-links a{
    font-weight: normal;
    line-height: 15px !important;
    text-decoration : none !important;
    vertical-align: middle;

    .helper-dropdown-text{
      font-weight: normal;
    }
  }

  .top-header-links .help-link{
    letter-spacing: 0.5px !important;
    font-weight: 500;
    padding: 10.5px 0;
  }

  .header-style-wrapper #header .menu-utility a.help-link {
    font-size: 14px;
    outline-offset: -1px;
  }

  #header .top-of-header .helper-dropdown-links{
    display: none;
    position: fixed;
    background-color: $white;
    text-align: left;
    top: 35px;
    margin-left: -5px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.05);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0,0,0,0.05);
    -moz-box-shadow: 0 4px 8px 0 rgba(0,0,0,0.05);
  }

  #header .top-of-header .helper-dropdown-links .helper-dropdown-link{
    display: block;
    line-height: 40px !important;
    width: 213px;
    font-size: 13px;
    margin: -5px 12px !important;
  }

  #header .top-of-header .helper-dropdown-links .helper-dropdown-image, #header .top-of-header .helper-dropdown-links .helper-dropdown-text{
    display: inline;
    margin-right: 5px;
    vertical-align: sub;
    padding: 1px;
  }
  #header .top-of-header .helper-dropdown-links .helper-dropdown-image {
    margin-bottom: -5px;
  }

  .top-header-links li {
    line-height : normal;
    list-style: none;
  }

  .top-header-links a img{
    vertical-align : middle;
  }

  .new-header-banner-top-home{
    position: absolute;
    left: 35%;

    .slides_searchPromo li{
        font-size: 14px;
     }
  }

  #mini-cart{
    left: 85%;
  }

  .mini-cart-empty-newheader{
    color: $primary;
  }

  ul.menu-utility{
    padding: 0px !important;
    display: flex;
    width: 275px;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 820px) {
      width: 267px;
    }

    @media screen and (min-width: 821px) and (max-width: 868px) {
      width: 269px;
    }

    @media screen and (min-width: 869px) and (max-width: 990px) {
      width: 271px;
    }

    @media screen and (min-width: 991px) and (max-width: 1164px) {
      width: 273px;
    }

    @media screen and (min-width: 1281px) and (max-width: 1423px) {
      width: 277px;
    }

    @media screen and (min-width: 1424px) and (max-width: 1601px) {
      width: 279px;
    }

    @media screen and (min-width: 1602px) {
      width: 283px;
    }

  }

  .menu-utility li a{
    display: inline-block;
  }

  .pt_storefront .menu-utility .user-login {
    display: block !important;
  }

  ul.menu-category {
    display: inline-flex;

    li.more-tabs{
      > span.level-1 > a.level-1 {
        text-transform: capitalize;
      }
      .tab {
        position: absolute;
        padding-top: 9px;

        .tablinks {
          float: left;

          .tabcontent {
            position: absolute;
            width: max-content;
            top: 50px;
            display: none;

            .menu-wrapper {
              &::before{
                content: '';
                position: fixed;
                margin-top: -7px;
                width: 92.5%;
                border-bottom: 1.5px solid #C8C8C8;
                z-index: 1;
              }

              ul.level-2{
                ul.level-3 > li > a{
                  font-size: 15px;
                  font-weight: 500;
                  letter-spacing: 0.4px;
                  text-transform: capitalize;
                }
              }
            }
          }

          &.active{
            a.tab-level-1{
              border-bottom-width: 4px;
              border-bottom-style: solid;
              padding-bottom: 5px;
              line-height: 17px;
            }
            .tabcontent{
              display: block;
              left: 0px;
            }
          }
        }
      }
    }
  }

// account dropdown css 
.account-dropdown-links {
  display: none;
  position: fixed;
  background-color: $white;
  text-align: left;
  top: 35px;
  z-index: 1;
  margin-left: -5px;
  right: 9%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.05);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0,0,0,0.05);
  -moz-box-shadow: 0 4px 8px 0 rgba(0,0,0,0.05);
}

.user-login,
.user-amazon-logout,
.user-account,
.user-account-login {
  padding: 10.5px 0;
}

.account-dropdown-links {

  span.account-nav-item--title {
    display: block;
    line-height: 40px !important;
    font-size: 13px;
    margin: -5px 12px !important;
    a {
      padding: 15px;
      font-size: 13px;
      font-weight: normal;
    }
    .account-guest-signin {
      padding-right: 8px;
    }
    .account-guest-new-account {
      padding-left: 6px;
    }
    .vertical-divider {
      vertical-align: middle;
    }
  }

  .content-asset, .content-asset li, .logout-button {
    display: block !important;
    line-height: 40px !important;
    width: 213px;
    font-size: 8px;
    margin: -5px 0px;
    padding-left: 10px;
  }

  .account-link, .toggle {
    font-size: 13px;
    font-weight: normal;
  }

  .logout-button, .content-asset .toggle {
    margin: 0px 8px;
  }

  .content-asset .toggle {
    display: block;
  }

  .content-asset{
    margin-top: 10px;
  }
}

.chat-offline-text {
  position: absolute;
  right: 0;
  line-height: 3.6;
  text-transform: none;
}

//styles for the header country selector

#desktop-header {
  .top-header-links {
    .header-country-flags {
      width: 60px;
      height: 35px;
       .country-box a.current-lang {
        padding: 7px 14px 12px 14px;
        border: none;
        &:not(.active):after {
          top: 40%; 
          border: none;
          display: none;
        }
      }
      ul.country-select-menu {
        bottom: auto;
        left: 0;
        width: 270px;
        height: 556px;
        overflow-y: scroll;
        top: 31px;
        border: 1px solid #e1e3e5;
        li {
          float: initial;
          line-height: 24px !important;
          text-decoration: none;
          font-weight: 600;
          font-style: normal;
          color: #000;
          a {
            font-size: 1em;
            font-weight: 600;
            font-style: normal;
          }
        }
      }
    }
  }
}

.country-box a.current-lang.active {
  background: transparent !important;
}

//country selector pop up
.hide-pop {
  display: none;
}

.country-select-box {
  position:fixed;
  width:100%;
  height:100vh;
  left:0px;
  top:0px;
}

.country-select-overlay {
  position:absolute;
  width:100%;
  height:100%;
  left:0px;
  top:0px;
  background:#666666;
  opacity: 0.75;
  transition:opacity 1.3s;
}

.country-select-pop {
  width: 606px;
  height: 280px;
  position: fixed;
  left: 28%;
  top: 27%;
  z-index: 9999;
  opacity: 1;
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s linear;
  background-color: #ffffff;
  padding: 16px;

  .country-pop-close {
    padding: 10px;
    .country-close-icon {
      background-image: url(../images/spritesheet.png);
      background-size: 185.5px 169px;
      background-position: -98.5px -137.5px;
      width: 14px;
      height: 14px;
      position: absolute;
      top: 22px;
      right: 20px;
      margin-top: -8px;
      margin-left: -8px;
      cursor: pointer;
    }
  }

  .country-pop-info {
    margin-bottom: 25px;
    .country-pop-header {
      font-size: 30px;
      color: #333333;
      display: block;
      font-weight: 600;
      line-height: 1.3;
      margin: 25px 0;
    }

    .country-pop-desc {
      color: #333333;
    }
  }

  .country-pop-btns-cont {
    position: absolute;
    bottom: 17px;
    left: 18px;
    .country-switch-link,.country-cancel-btn {
      width: 155px;
      height: 40px;
      display: inline-block;
      text-align: center;
      text-decoration: none !important;
      line-height: 40px !important;
      font-size: 14px !important;
      margin-right: 16px;
      vertical-align: bottom;
    }
  }
}

.user-login.account-help-link{
  img.filledImage{
    display: none;
  }
}
  .user-login.account-help-link:hover {
    img.unfilledImage{
      display: none;
    }
    img.filledImage{
      display: block;
    }
  }
