$spcRadioDrawerRadioColor: $black !default;
$spcRadioDrawerContentBackground: #fafafa !default;
$spcRadioDrawerOptionBorderColor: $grey-silver !default;

.spc-radio-drawer {
  &__last {
    margin-bottom: $spacing * 3;

    .spc-radio-drawer--header {
      border-bottom: 0;

      &__active {
        border-bottom: 1px solid $spcRadioDrawerOptionBorderColor;
      }
    }
  }

  &__disabled {
    pointer-events: none;
    opacity: .5;
  }

  &--container {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  }

  &--header,
  &--body {
    padding: $spacing * 2;
  }

  &--header {
    background: $white;
    cursor: pointer;
    position: relative;
    padding-left: $spacing * 4;
    font-size: 14px;
    border-bottom: 1px solid $spcRadioDrawerOptionBorderColor;

    &:hover {
      &::before,
      &::after {
        opacity: 0.8;
      }
    }

    &::before,
    &::after {
      top: 50%;
      transform: translateY(-50%);
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: $spacing * 2;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 2px solid $spcRadioDrawerRadioColor;
      opacity: 0.6;
    }

    &__active {
      &:hover::before,
      &:hover::after,
      &::before,
      &::after {
        opacity: 1;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: $spacing * 2 + 3;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: $spcRadioDrawerRadioColor;
      }
    }

    > span {
      display: flex;
      align-items: center;

      .spc-icon-credit-card {
        margin-right: 5px;
        min-width: 40px;

        &-afterpay {
          min-width: 84px;
        }

        &-affirm {
          min-width: 48px;
        }

        &-pcipal {
          min-width: 48px;
        }
      }
    }
  }

  &--body {
    background: $spcRadioDrawerContentBackground;

    &:empty {
      padding: 0;
    }
  }

  .spc-cvv {
    position: relative;

    .spc-cvv-error {
      position: absolute;
      top: 0;
      right: 4px;
      transform: translate(0, calc(-100% - 10px));
      max-width: 90vw;
      background: white;
      border: 1px solid $callout;
      border-radius: 5px;
      padding: 10px;
      z-index: 1;
      box-shadow: 6px 6px 9px -6px;

      .spc-cvv-image {
        width: 33%;
        display: inline-block;
        padding-top: 8px;
      }

      .spc-cvv-text {
        width: 60%;
        float: right;
      }
    }
  }
}
