.dw_blogs .archive-title {
  margin-left: 2px;
}

.dw_blogs .dwBlog-content-area .dwBlog-site-content {
  .dw-widget-container {
    position: relative;
    display: block;
    height: 40px;
    margin: 0 0 30px 2px;
  }

  .dw-widget-container div:first-child {
    margin-left: 0px;
  }

  .refinement {
    position: relative;
    float: left;
    margin-left: 10px;
    border: 1px solid $mainbordercolor;
  }

  .refinement .wrap-swatches-box li a {
    color: $mainbrand;
  }

  .refinement .wrap-swatches-box li a:hover {
    color: $primarylink;
  }

  .post-categories {
    margin-left: -25px;
    font-style: italic;
    @extend .blog-category-text !optional;
  }

  .blog-landing-snippet h1 a {
    color: $mainbrand;
    text-transform: none;
  }

  .blog-landing-snippet h1 {
    margin-left: 14px;
  }
  .blog-landing-snippet {
    background: $lightbackdrop;
    padding-bottom: 10px;
    margin-top: 30px;
  }

  .blog-landing-snippet p {
    padding: 0 16px;
    margin-top: -8px;
  }

  .blog-landing-snippet .snippet-image-wrapper {
    width: 445px;
    height: 200px;
    overflow: hidden;
  }
}


#dwBlog-post.dwv2 {
  width: 925px;
  color: $fontaccent;

  p,
  h1,
  h2 {
    color: $mainbrand;
  }

  .clearAfter::after {
    width: 0px;
    height: 0px;
    display: block;
    content: '';
    clear: both;
  }

  .clear {
    clear: both;
  }

  .hidden {
    display: none;
  }

  .aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .alignright {
    float: right;
    padding: 0 0 10px 10px;
  }

  #feature-slider {
    width: auto;
    height: 430px;
    padding: 0px;
    position: relative;
    overflow: hidden;

    ul.slides {
      width: auto;
      height: 430px;
      margin: 0;

      li {
        height: 430px;
        padding: 0px !important;
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        opacity: 0;

        &:first-child {
          opacity: 1;
          display: block;
        }

        &.slide-post {
          .blog-pic-wrapper {
            width: 430px;
            height: 430px;
            margin: 0 0 0 30px;
            float: right;
            overflow: hidden;
            position: relative;

            img {
              height: 100%;
              position: absolute;
            }

            a {
              width: 100%;
              height: 100%;
              max-height: 430px;
              display: block;
            }

            &:after {
              width: 0px;
              height: 0px;
              margin: 45% 0px 0px 0px;
              display: block;
              position: absolute;
              left: 0;
              top: 0;
              content: '';
              border-top: solid transparent;
              border-bottom: solid transparent;
              border-left: solid white;
              border-width: 25px;
              transition: .25s ease;
              -o-transition: .25s ease;
              -ms-transition: .25s ease;
              -moz-transition: .25s ease;
              -webkit-transition: .25s ease;
              }
          }

          .post-category {
            padding: 50px 0px 0px;
          }

          h2 {
            padding: 30px 0px 0px;
          }

          .post_meta { padding: 30px 0px 0px; }

          p { padding: 30px 0px 0px; }
        }

        &.slide-link {
          > a {
            width: 100%;
            height: 100%;
            display: block;
            position: relative;

            img {
              width: 100%;
              max-height: 100%;
              position: absolute;
              top: 0px;
              left: 0px;
            }
          }

          .feature-content {
            width: 50%;
            position: absolute;
            bottom: 25%;
            left: 25%;
            text-align: center;
          }
        }
      }
    }

    ol.flex-control-paging {
      width: 90%;
      padding: 0 0 10px;
      position: absolute;
      bottom: 0px;
      left: 5%;
      z-index: 10;
      text-align: center;

      li {
        margin: 0 10px 0 0;
        display: inline-block;
        overflow: hidden;
        list-style: none;
        text-indent: -9999em;

        &:last-child{ margin: 0px; }

        a {
          width: 15px;
          height: 15px;
          display: block;
          @include border-radius( 15px 15px 15px 15px);
          background: rgba( 210, 210, 210, .5 );

          &.flex-active { background: $primarylink; }
        }
      }
    }
  }

  #page {
    width: auto;
    margin: 0 auto;
  }

  #sub_callout { display: none; }

  .dw-cat-list {
    padding: 25px 0 15px;
    text-align: center;

    #cat-list-dd { display: none; }

    ul { list-style: none; }

    li {
      display: inline-block;
      position: relative;

      &:after {
        width: 0px;
        height: 0px;
        margin: 0px 0px 0px -8px;
        display: block;
        position: absolute;
        top: 45px;
        left: 50%;
        content: '';
        border-left: solid transparent;
        border-right: solid transparent;
        border-top: solid $primarylink;
        border-width: 0px;
        @include transition( .25s ease );
      }

      &.active, &:hover {
        background: $primarylink;

        &:after {
          border-width: 6px;
        }
      }
    }

    a {
      padding: 15px;
      display: block;
      position: relative;
    }
  }

  #dwBlog-content {
    padding: 0 0 60px;

    .post-list { 
      margin: 0 0 0 15px; 
      display: flex;
      flex-wrap: wrap;
    }

    .blog-landing-snippet {
      flex: 1;
      min-width: 430px;
      width: 430px;
      margin: 30px 15px 30px 0;
      display: inline-block;
      background-color: transparent;

      .blog-pic-wrapper {
        text-align: center;
        
        img { max-width: 100%; }
        
        a {
          width: 430px;
          height: auto;
          max-height: 430px;
          display: block;

          img {
            width: 100%;
            height:auto;
          }
        }
      }

      .post-category {
        padding: 15px 0px;
        list-style: none;
      }

      .post_meta {  padding: 0px 0px 10px; }

      h2 { padding: 0px 0px 20px; }

      p {
        padding-left: 0px;
        padding-right: 0px;
      }

      &.simpleText {
        width: 330px;
        padding: 50px;
        border: $borderMixin;

        .blog-pic-wrapper { display: none; }
      }
    }
  }

  #content {
    width: 925px;
    margin: 0 auto;
  }

  .post {
    .entry-header {
      width: auto;
      padding: 0px 0px 30px;
      margin: 0px;

      .entry-thumbnail {
        text-align: center;
      }

      .entry-thumbnail, .entry-categories {
        padding: 15px 0 15px;
        text-align: center;

        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 450px;
        }
      }

      .entry-title {
        padding: 0 0 70px;
        margin: 0px;
        text-align: center;
      }

      .entry-meta {
        padding: 0 0 0 30px;

        .addthis_sharing_toolbox { display: none; }
      }
    }

    .entry-content {
      width: 530px;
      padding: 0 85px 0 30px;
      float: left;

      h2, h3, h4, h5, h6 {
        line-height: 2em;
      }

      p { margin: 0 0 25px; }
    }
  }

  #showMore {
    text-align: center;
    > div { 
      display: inline-block; 
    }

    .button {
      cursor: pointer;
    }
  }

  .comments-area {
    width: 560px;
    float: left;
  }

  #disqus_thread {
    padding: 0 30px;

  }

  .prof-header {
    width: auto;
    padding: 0 0 30px;
    margin: 0 auto 30px;
    text-align: center;
    border: solid fade( $fontaccent, 50% );
    border-width: 0 0 1px;

    #prof-pic {
      max-width: 750px;
      max-height: 500px;
    }

    .prof-title {
      padding: 25px 0;
    }

    p {
      padding: 0 150px;
      text-align: left;
    }

    .prof-social {
      padding: 25px 150px;
      text-align: center;

      span {
        padding: 0 15px 0 0;
        display: inline-block;
      }

      ul {
        padding: 0px;
        margin: 0px;
        display: inline-block;
        list-style: none;
      }

      li {
        padding: 0px;
        margin: 0px;
        display: inline-block;
      }

      a {
        padding: 0 15px;
        display: block;
        line-height: 10px;
        overflow: visible;
      }
    }
  }

  .sidebar-container {
    width: 250px;
    padding: 15px 0px 50px;
    margin: 0px;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    float: right;
    border: $borderMixin;

    &.mobi { display: none; }
    h3 { padding: 0 25px 25px; }

    hr {
      border: $borderMixin;
      border-width: 1px 0px 0px;
    }

    ul {
      padding: 0 25px;
      list-style: none;
    }

    li { margin: 0 0 40px; }

    div { padding: 0 0 10px;  }
    h4 { padding: 0 0 10px; }
    hr { margin: 15px 0 25px; }
  }
}


// Blog Comment Styles
#wwwblog-comment-tabs {
  .comment-list {
    padding: 0 5px;
    background: transparent;
    border: 1px solid $mainbordercolor;
  }

  .comment-list .comment {
    border-bottom: 0;
    margin-bottom: 10px;
    width: 100%;
    background: $white;
  }

  .comment-body {
    width: 96%;
    margin: auto;
  }

  .comment-author h5 { margin: 0 0 5px 0; }
  .comment-meta { margin-bottom: 20px; }
  .reply { @extend .hr-product-details-tab-default !optional; }

  div.reply {
    background: transparent;
    border: 0;
  }

  ul.children {
    width: 90%;
    margin: 20px 0 0 0;
  }

  ul li {
    border: 1px solid $mainbordercolor;
    background-color: $lightbackdrop;
    display: table;
    height: 44px;
    margin: 0 3px -1px 0;

    &.ui-state-active {
      border-bottom: 0;
      background-color: $white;
      padding-bottom: 1px;
    }
  }
}
