.spc-drawer {
  margin-bottom: $spacing * 3;

  @include breakpoint($tablet){
    margin-bottom: ($spacing * 2);
  }

  &-content {
    background-color: white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
    margin-bottom: $spacing;
  }

  &-header {
    cursor: pointer;
    line-height: 24px;
    padding: $spacing ($spacing * 2);

    span {
      vertical-align: middle;
    }

    .spc-icon-drawer-toggle-m {
      background-image: url(../images/icon-add.png);
      background-repeat: no-repeat;
      background-size: 16px;
      display: inline-block;
      height: 16px;
      margin-right: $spacing;
      width: 16px;

      .spc-drawer-open & {
        background-image: url(../images/icon-minus.png);
      }
    }
  }

  &-body {
    padding: 0 ($spacing * 2) ($spacing * 2);

    &:empty {
      padding: 0;
    }
  }

  .spc-form {
    margin-top: $spacing;

    fieldset { margin: 0 }

    #auto-populated-promo-message {
      margin-bottom: $spacing;
      padding-left: 4px;
      color: red;
    }

    @include breakpoint($tablet){
      .spc-input-container { margin: 0; }
    }
  }

  .spc-form &-input[type="text"] {
    box-shadow: none;
    border: 1px solid $checkout-border-color;

    &:focus {
      border-bottom-color: black;
      border-bottom-width: 2px;
    }

    &.error-message {
      border-color: $callout;

      &:focus {
        border-bottom: 2px solid;
        border-color: $callout;
      }

    }
  }

  &-button {
    button { width: 100%; }
  }
}

#spc-idme-container {
  .idme-container{
    display: flex;
    flex-flow: wrap;

    & > div {
      display: inline;
      margin: $spacing;
    }
  }
  [class^="IDmeButton"] p {
    display: none;
  }
  a {
    display: block;
    img {
      display: block;
      max-width: 132px;
    }
  }
}

.spc-applied-discount {
  box-sizing: border-box;
  height: auto;
  padding: ($spacing * 3) ($spacing * 2) $spacing;

  @include breakpoint($tablet){
    min-height: 56px;
    margin-top: 0;
  }

  &-label {
    @include body();

    box-sizing: border-box;
    color: $checkout-label-color;
    font-size: 12px;;
    line-height: 34px;
    position: absolute;
    text-transform: uppercase;
    top: 0;
  }

  &-message {
    @include body();

    box-sizing: border-box;
    display: inline-block;
    line-height: 12px;
    max-width: 90%;
    vertical-align: top;
    padding-top: $spacing;
  }

  a {
    margin-left: $spacing;
    color: $primarylink;
    line-height: 17px;
    vertical-align: text-top;
  }

  .spc-icon-giftcard-m {
    background-image: url(../images/icon-giftcard.png);
    background-repeat: no-repeat;
    background-size: 26px;
    display: inline-block;
    height: 20px;
    padding-right: $spacing;
    width: 24px;
    margin-top: 5px;
  }

  .spc-icon-checkmark-m {
    background-image: url(../images/icon-promo-applied.png);
    background-repeat: no-repeat;
    background-size: 14px;
    display: inline-block;
    height: 16px;
    padding-right: $spacing;
    width: 16px;
    vertical-align: middle;
  }

  .spc-icon-remove-m {
    background-image: url(../images/icon-remove.png);
    background-repeat: no-repeat;
    background-size: 13px;
    cursor: pointer;
    display: inline-block;
    height: 13px;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 13px;
  }

  .spc-icon-error-m {
    background-image: url(../images/icon-error.png);
    background-repeat: no-repeat;
    background-size: 14px;
    display: inline-block;
    height: 16px;
    padding-right: $spacing;
    width: 16px;
    vertical-align: middle;
  }
}
