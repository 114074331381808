// Pre-existing brand vars - some brands use name variations accounted for here via "default cascade"

// I.E. If a brand has a $primaryCTA var, use it. (only a couple brands do)
// Otherwise, fall back to a $cta var if it exists. (the rest of the brands have this one)
// Otherwise, use $primarylink which all brands have.
$cta: $primarylink !default;
$primaryCTA: $cta !default;
$ctacolor: $white !default;

$ctahover: darken($primarylink, 10%) !default;
$cta-hover: $ctahover !default;

$disabled: lightgray !default;

// New variables which can be freely overwritten by brand styleguides if needed
$product-slider-background: white !default;
$product-slider-pip: $primarylink !default;

.product-slider {
    background: $product-slider-background;
    padding-bottom: 3em;

    .slides > li {
        display: flex !important; // override inline styles
        justify-content: center;
    }

    .product-tile {
        display: flex;
        align-items: center;
        width: 100%;

        &-cta {
            background: $primaryCTA;
            color: $ctacolor;
            width: 100%;
            height: 4em;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            font-size: 10px;
            font-weight: bold;

            &:hover {
                cursor: pointer;
                background: $cta-hover;
            }
        }

        .name-link {
            font-weight: bold;
            display: flex;
            justify-content: center;
            text-align: center;
            margin: 0 0 .5em;
        }
    }

    .flex-control-nav {
        position: relative;

        li a {
            background: $disabled;

            &.flex-active {
                background: $product-slider-pip;
            }
        }
    }
}

.pt_account {
  .product-slider {
    background: none;

    .slick-track {
      margin-left: 0;
    }

    .product-tile {
      padding: 0 22px 0 22px;
      text-align: left;

      .name-link {
        font-weight: normal;
        justify-content: left;
        text-align: left;
      }
    }
  }
}
