.pt_error404 {
    #main {
        padding: 60px 0 130px 0;
        text-align: center;
    }

    .error-page-message {
        h1 {
            padding-bottom: 20px;
            margin-left: auto;
            margin-right: auto;
            border-bottom: 1px solid $mainbordercolor;
            max-width: 735px;
        }

        p {
            margin: 0;
        }
    }

    .error-page-search {
        margin: 50px 0 100px 0;

        .input-text {
            height: 55px;
            line-height: 55px;
            max-width: 365px;
            padding: 0 10px;
        }

    } 

    .error-404-bottom-content {
        @include breakpoint($tablet){
            max-width: 920px;
            margin: auto;
        }
    }
    
    #footer {
        .footer-country-flags {
            ul {
                bottom: 23px;
            }

            a.current-lang {
                padding: 0 15px 5px;

                &:not(.active):after {
                    top: 30%;
                }
            }
        }
    }
}
