//sign in pop up new changes

#checkout-login-popup-modal {
  overflow-x: hidden;
  fieldset {
    .form-row.remember-me {
      display: inline-flex;
      margin-bottom: 0;
      overflow: initial;
      position: static;
      margin-top: 0;
      label {
        width: auto ;
        font-size: 12px;
        text-transform: capitalize;
      }
      #rememberMe {
        margin-top: 0;
      }
    }

    .pass-reset-cont {
      display: inline-block;
      margin-top: 0;
      position: relative;
      top: -2.5px;
      a {
        display: inline-block;
        margin-top: 0;
        font-size: 12px;
      }
    }

    #checkout-account-login-btn {
      margin-bottom: 10px;
    }

    .login-not-registered {
      text-align: center !important;
      width:100%;
      line-height: initial;
    }
  }
}

#RegistrationForm {
  overflow-x: hidden;
  #checkout-account-register-btn {
    margin-bottom: 10px;
  }

  .login-create-new-account {
    text-align: center !important;
    line-height: initial;
    width: 100%;
  }
}

// classes specific to account login modal

.custom-error {
  color: $deep-red !important;
}

.custom-error-border {
  border: 1px solid $deep-red !important;
}


.dialog-form-check,
.dialog-form-error {
  position: absolute;
  left: 230px;
  top: 20px;
  padding: 2px;
  background-color: #008114;
  border-radius: 26px;
  height: 12px;
  width: 12px;
  text-align: center;
  color: $white;
  line-height: 12px;
  
  .check-mark {
    display: inline-block;
    transform: rotate(45deg);
    height: 8px;
    width: 3px;
    border-bottom: 2px solid $white;
    border-right: 2px solid $white;
  }
}

.dialog-form-error {
  background-color: $deep-red;
  border: 1px solid $deep-red;
  color: $deep-red;
  background-color: #fff;
  line-height: 13px;
}

.dialog-tooltip-mark {
  position: absolute;
  right: 12px;
  top: 20px;
  padding: 2px;
  background-color: #fff;
  border-radius: 26px;
  border: 1px solid #373737;
  color: #373737;
  height: 12px;
  width: 12px;
  font-size: 12px;
  text-align: center;
  line-height: 13px;
}

.pass-req-asset {
  padding: 15px;
  font-size: 10px;
  line-height: initial;
  border: 1px solid #BBBBBB;
  border-radius: 5px;
}

.email-custom-error, .pass-custom-error {
  display: block;
  margin-top: 7px;
  font-size: 12px;
  line-height: 18px;
}

.valid-pass {
  color: #008114;
}

#checkout-createaccount-popup {
  label.error {
    display: none !important;
  }
  #legalagreement-error {
    display: none !important;
  }
}

.error-message {
  color: $deep-red !important;
}

#login-form-email-error {
  display: none !important;
}


body.is-responsive #checkout-login-popup-modal fieldset{
  .pass-reset-cont {
    top: -2.5px;
  }
}
// reset password modal css

// for desktop
@media screen and (min-width: 768px) {
  .isDesktopEnvironment,.is-responsive {
    .requestpasswordreset {
      #dialog-container {
        padding-left: 183px !important;
        padding-right: 183px !important;
        padding-top: 0px;
        padding-bottom: 0px;
      }
    
      .ui-dialog .ui-corner-all {
        padding:0;
      }
    
      #ui-id-1 {
        padding-top: 40px;
        padding-left: 183px;
        padding-right: 183px;
      }
    
      div[data-content-asset="password-reset-request-confirm"] {
        display: block;
        padding-inline: 0px;
        padding-bottom: 40px;
        padding-top: 0;
      }
    
      .password-button {
        display: flex;
        margin-bottom: 68px;
        .resend-btn-pwds {
        margin-right: 12px;
        }
        button {
          clear: both;
          display: inline-block;
          width: 48%;
          height: 56px;
          line-height: 24px;
        }

      }
  
      .resetPasswordPara {
        font-size: 14px;
        line-height: 22px;
      }
      #PasswordResetForm button {
        clear: both;
        display: inline-block;
        margin-top: 63px;
        width: 48%;
        height: 56px;
        line-height: 24px;
      }
    }
  }
}


@media screen and (max-width: 768px) {

  .password-button, .resetPasswordButtons {
    display: flex;
  }

  .password-button .resend-btn-pwds {
    margin-right: 12px;
    }

  .resetPasswordPadding~#PasswordResetForm button , .resend-btn-pwds,.password-button .account-guest-signin {
    min-width: 47% !important;
    margin-top: 10vh;
    width: 47%;
    height: 56px;
    margin-bottom: 8.5vh;
    line-height: 24px !important;
  }

  .is-responsive div[data-content-asset="password-reset-request-confirm"] {
    display: block !important;
    padding-inline: 0px;
  }
}

.resetPasswordPadding~ #PasswordResetForm .reset-email {
  transition: all .3s;
  margin-bottom: 8px;
  position: relative;

  #dwfrm_requestpassword_email_label {
    padding-left: 12px;
      text-transform: uppercase;
      transform-origin: 0 0;
      transition: all .3s;
      font-size: 12px;
      line-height: 22px;
      position: absolute;
      top: 13px;
  }

  input {
    width: 100%;
    height: 56px;
      -webkit-appearance: none;
      box-shadow: 0px 2px 4px #00000033;
      -webkit-box-shadow: 0px 2px 4px #00000033;
      border: none;
      transition: all .5s;
      padding-top: 18px;
      padding-left: 12px;
  }

  input::placeholder {
    color: transparent;
  }

  input:focus + label.error + .row-label,
  input:focus + .row-label,
  input:not(:placeholder-shown) + .row-label,
  input:not(:placeholder-shown) + label.error +.row-label {
    transform: translateY(-15px);
  }

  #dwfrm_requestpassword_email-error {
    display: none !important;
  }

}

@media screen and (max-width: 768px){
#PasswordResetForm fieldset .form-row #dwfrm_requestpassword_email_label {
  left:0;
 }

 .confirmation-login-modal .ui-dialog .ui-dialog-titlebar-close,.confirmation-login-modal .ui-dialog .ui-dialog-titlebar-close:hover,.confirmation-login-modal .ui-dialog .ui-dialog-titlebar-close:focus {
  padding: 6px;
  top: 2vh;
  right: 1vh;
}
}

//added for password reset error message
.confirmation-login-modal {
  .reset-email {
    div.error {
      margin-top: 7px;
      font-size: 12px;
      line-height: 22px;
      color: $deep-red;
    }
  }
}


#dialog-container .resend-password-confirm {
  font-size: 24px;
  line-height: 30px;
  @media screen and (max-width:768px) {
      font-size: 32px;
      line-height: 40px;
  }
}

