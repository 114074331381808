 // default any vars used in this filter-by-title
$errorColor: #f00 !default;

.error-message {
  color: $deep-red;
}

// until form validation is fixed for the page (currently out of scope)
.locator-search-wrap {
  .error-message { color:inherit;}
  input.error-message {border: 0;}
}


input[type="text"],
input[type="password"],
input[type="tel"],
input[type="email"],
textarea {
  @extend .form-text-input !optional;
  width: 48%;
  outline-style: none;
}

input,
select {
  box-sizing: border-box;
  border: 0;
  height: 33px;
  line-height: 33px;

  &.error-message { border: 1px solid $deep-red !important;}

  &[type="radio"] {
    margin-right: 4px;
    height: 15px;
    width: 15px;
  }

  &[type="checkbox"] {
    margin-right: 14px;
    height: 14px;
    width: 14px;
  }

  .label-inline & {
    float: left;
  }
}

input,
select,
textarea {
  padding-left: 14px;
}

.input-checkbox {
  float: left;
}

legend {
  @extend .clearfix;
  border-bottom: 1px solid $mainbordercolor;
  margin-bottom: 29px;
  padding-bottom: 14px;

  span {
    display: inline;
  }
}

label,
.form-label-text {
  display: block;
  padding: 0 0 10px;

  &:not(.checkbox-label) {
    @extend .clearfix;
  }
}

.checkbox-label {
  margin-right: 25px;

  a {
    text-transform: capitalize;
  }

  &.disabled {
    opacity: 0.3;
  }
}

.legend-header {
  display: inline;
  margin-right: 20px;
}

select {
  background-image: url('../images/interface/select-arrow-bg.png');
  background-repeat: no-repeat;
  background-position: right center;
  border-width: 1px;
  border-style: solid;
  padding: 0 34px 0 14px;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  &::-ms-expand {display:none} // IE version of -prefix-appearance: none;
}

// this will probably grow into a list of forms
#RegistrationForm{
  margin-top: 46px;

  legend {
    width: 100%;
  }

  button {
    width: 100%;
  }

  fieldset {
    padding-bottom: 60px;

    &:last-of-type {
      legend {
        padding-bottom: 17px;
        margin-bottom: 80px;
      }
    }
  }

  .account-verification {
    padding: 0;
    border-top: 1px solid $mainbordercolor;

    legend {
      padding: 0;
      margin-bottom: 19px;
    }

    .legend-header{ display: none; }
  }

  .age-check label {padding-bottom: 0;}

  .form-row-button.center { // uncenter the centered class...
    margin: 0;
    text-align: left;
  }
}

// if more email pref styles are needed, copy over the entire partial
// until then, we don't want to maintain two partials seperately for one style.
.emailpreference .emailpreference-form-content form{
  input[type="text"],
  input[type="password"],
  textarea {
    width: 100%;
  }
  .form-row select.pref-dob {
    width:32%;

    &[name$="dob__year"] {
      margin-right: 0;
    }
  }
}

.confirmation-login-modal #RegistrationForm .checkbox-label {
  font-size: 10px;
  line-height: 16px;
  display: flow-root;
  font-weight: normal;
  text-transform: none;
 
  a {
    font-size: 10px;
  }
}
