.pt_customer-service #RegistrationForm {
	select, textarea {
		width: 100%;
		box-sizing: border-box;
	}

	textarea {
		padding: 14px;
		width: 100%;
		box-sizing: border-box;
	}

	.form-header {
		display: none;

		&:first-child { display: block; }
	}

	.left, .right {
		width: 48%;
		@media (max-width: 767px) {
			width: 100%;
			float: none;
		}

		select { max-width: none; }
	}	

	.form-row {
		margin: 0 0 32px 0;
		width: 100%;
		@media (max-width: 767px) {
			margin-bottom: 16px !important;
		}
	}

	.replacementTitle {
		margin-bottom: 24px;
		@media (max-width: 767px) {
			margin-bottom: 16px;
		}
	}

	.product-info {
		clear: both;

		.flex {
			display: flex;
			flex-flow:row;
			justify-content: space-between;

			.form-row {
				width: 30%;
			}

			&.style-row {
				.form-row {
					width: 55%;
					+ .form-row {
						width: 18%;
					}
				}
			}

			@media (max-width: 767px) {
				display: flex;
				flex-flow: column;
				justify-content: space-between;
				.form-row {
					width: 100% !important;
				}
			}


			.style-col {
				display: flex;
				flex-flow: column;

				label {
					margin-bottom: auto;
				}
			}
		}

		.form-additional-text {
			font-size: 11px;
			font-weight: normal;
			text-transform: none;
		}
	}
	

	// Default fields shown on page load
	.product-info,
	// .inquiry-details,
	.return-header,
	.additional-details,
	.defect-photos,
	.return-info,
	.tracking-number {
		display: none;
	}

	.return-header {
		margin: 0;
		padding-top: 16px;
		padding-bottom: 16px;
	}

	@media (max-width: 767px) {
		.return-header {
			padding-top: 24px;
		}
		#sendBtn {
			// full width submit button on mobile
			margin: 0;
			width: 100%;
		}

		fieldset {
			min-inline-size: auto;
			width: 100%;
		}

		label.row-label {
			margin-top: 0px !important;
			padding: 0;
		}
	}

	.verify-age .form-row {
		display: flex;

		label {
			order: 2;
		}
	}

	.proof-of-purchase-photo {
		margin-bottom: 10px;
	}
}
.user-contact-form {
		legend {
			display: flex;
			flex-direction: column;
			align-items: center;
			font-size: 40px;
			padding: 0;
			margin-top: 0 !important;
			margin-bottom: 48px;
			span {
				font-size: 14px;
				margin-top: 6px !important;
				margin-bottom: 24px;
			}
			@media (max-width: 767px) {
				font-size: 24px;
				margin-bottom: 30px;
				span {
					font-size: 14px;
					margin-top: 4px !important;
				}
			}
		}
		.input-textarea {
			margin-bottom: 16px;
		}
		.photoUploadContainer {
			margin-bottom: 48px;
		}
		
		@media (max-width: 767px) {
			.photoUploadContainer:first-of-type {
				margin-top: 24px !important;
			}
			.input-textarea {
				margin-bottom: 20px;
			}
			.photoUploadContainer {
				margin-bottom: 24px;
				&:last-of-type {
					padding-bottom: 24px;
				}
			}
		}
		label {
			display: inline-block;
			margin: 0;
			margin-bottom: 4px;
			padding-bottom: 0px !important;
			.required-indicator {
				line-height: inherit;
				vertical-align: baseline;
				font-size: 12px;
				@media (max-width: 767px) {
					font-size: 10px;
				}
			}
			& .error-message.form-caption {
				float: none;
				font-size: 12px;
				margin: 0px !important;
				text-transform: uppercase !important;
				@media (max-width: 767px) {
					font-size: 10px;
				}
			}
			.form-caption {
				margin-bottom: 0 !important;
				white-space: normal !important;
			}
			.field-name, .error-message .form-caption {
				white-space: normal;
				font-size: 12px !important;
				text-transform: uppercase !important;
			}
			strong {
				margin-top: 32px;
				margin-bottom: 24px;
			}
			@media (max-width: 767px) {
				margin: 0;
				margin-bottom: 7px !important;
				strong {
					margin-bottom: 16px;
				}
				.field-name {
					font-size: 10px !important;
				}
			}
		}
		.form-option .form-row {
			@media (max-width: 767px) {
				margin-top: 20px;
			}
		}
		.formrow-border {
			display: flex;
			align-items: center;
			margin-top: 18px !important;
			margin-bottom: 50px !important;
			@media (max-width: 767px) {
				padding-top: 0px !important;
				padding-bottom: 0px !important;
				margin-top: 22px !important;
				margin-bottom: 32px !important;
			}
		}
		.product-info:last-of-type {
			.flex.style-row {
				.form-row {
					margin-bottom: 24px;
					@media (max-width: 767px) {
						margin-bottom: 38px;
					}
				}
			}
		}
	.defect {
	  label {
		text-transform: uppercase;
	  }
	}
  }
  
