$cardbg: #f4f4f4;

#single-page-checkout .spc-express-checkout-buttons,
#mini-cart-root .MiniCart__ExpressCheckout {
  .spc-paypal a,
  .spc-amazon .amazon-btn,
  .spc-apple-pay button {
    display: flex;
    align-items: center;

    background-color: $white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid $checkout-border-color;
    border-radius: 0;
    box-sizing: border-box;
    height: 48px;
    margin: 0;
    transition: border-color 0.2s;
    overflow: hidden;
    width: 100%;

    &:hover,
    &:focus-within,
    &:focus {
      background-color: $white;
      border-color: $black;
    }

    img {
      max-width: 100%;
    }

  }

  

  .spc-paypal {
    margin-bottom: ($spacing * 2);

    img {
      margin: 0 auto;
      max-height: 100%;
    }

    .left {
      float: none;
    }
  }

  .spc-amazon {
    margin-bottom: ($spacing * 2);

    .amazonpopup {
      display: none;
    }

    .amazon-btn {
      background-image: url(../images/amazonPayBtn.png);
      padding: 0;
      border: none;
    }
    .amazon-btn:focus-within {
      outline: 2px solid black;
    }

    .amazon-btn:focus-within {
      outline: 2px solid black;
    }

    .paybutton {
      height: 100%;
      padding: 0;
    }

    .amazonpay-button-inner-image {
      display: block;
      height: 100%;
      width: 100%;
      opacity: 0;
      max-height: 100% !important; // Overriding inline style
    }
  }

  .spc-apple-pay {
    button {
      -webkit-appearance: none;
      background-image: -webkit-named-image(apple-pay-logo-black);
      background-size: 30%;
      background-position-y: 4px;
      padding: 0;
      margin: 0 0 ($spacing * 2);
      min-height: 0;
      min-width: 0;

      @include breakpoint($tablet) {
        width: 124px;
        background-size: 42%;
      }
    }
  }

  .spc-login-button {
    display: flex;
    align-items: center;
    border: 1px solid $checkout-border-color;
    box-sizing: border-box;
    height: 48px;
    margin-bottom: ($spacing * 2);
    width: 100%;
    &:hover {
      border-color: $black;
    }

    span {
      @include body-small();
      padding: 0 ($spacing * 2) 0 0;
      color: $secondary;
      text-transform: uppercase;
      display: none;
    }

    button {
      display: block;
      height: 100%;
      line-height: 1;
      overflow: hidden;
      text-align: center;
      width: 100%;
      font-size: 16px !important;
      text-decoration: underline;
    }

    & + .spacer {
      display: none;
    }

    @include breakpoint($tablet) {
      border: none;

      span {
        display: inline;
      }
    }
      button {
        display: inline-block;
        height: auto;
        width: auto;
      }
  }
}

#single-page-checkout .spc-express-checkout-buttons .spc-login-button {
    display: flex;
    align-items: center;
    border: 1px solid #dededb;
    box-sizing: border-box;
    height: 48px;
    margin-bottom: 16px;
    width: 100%;

    & span {
      display: inline;
      font-style: normal;
      font-size: 12px !important;
      line-height: 16px;
      letter-spacing: .12px;
      padding: 0 16px 0 0;
      font-weight: 600;
    }

    .spc-button-link {
      font-size: 12px !important;
      border: none;
      text-decoration: underline;
      font-weight: normal;
      border-radius: 0;
    }
    .amazon-btn:focus-within {
      outline: 2px solid black;
    }
}

#single-page-checkout .spc-express-checkout-buttons-redesign {
  margin-bottom: 10px;
  &.around-xs {
    display: flex;
    gap: 12px 0;
    justify-content: flex-start;
    .spc-paypal a,
    .amazon-btn,
    .spc-apple-pay button {
      padding: 0 !important;
      margin: 0 !important;
    }
    .spc-content-paypal-html, .spc-amazon, .spc-apple-pay button {
      margin-bottom: 0 !important;
    }
  }
  .spc-paypal a,
  .amazon-btn,
  .spc-apple-pay button {
    height: 42px !important;
    margin-right: 8px;
    border-radius: 30px !important  ;
    box-shadow: inset 0px 2px 2px #00000029, 0px 2px 2px #00000029;
    border: none;
    &:hover {
      border: none;
      border-color: none !important;
      box-shadow: inset 0px 3px 3px #00000029, 0px 3px 3px #00000029;
    }
  }
  .amazon-btn:focus-within {
    outline: 2px solid black;
  }
}
#single-page-checkout .spc-express-checkout-buttons-redesign {
  .spc-loader-wrapper {
    .spc-section {
      h2.spc-section-heading-title {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
  .spc-input-container {
    .spc-input-checkbox-label {
      margin-top: 16px;
    }
    .spc-input-checkbox-label {
      .spc-input-checkbox-label-message {
        font-size: 12px;
        line-height: 18px;
      }
      #spc-use-shipping-address {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.spc-express-checkout-buttons-redesign .spc-express-checkout {
  &-logout-section {
    padding-bottom: ($spacing * 3);
  }

  &-login-section, &-account-benefits {
    @include breakpoint($tablet) {
      // margin-bottom: ($spacing * 2);
    }

    &-cards {
      .spc-card {
        background: transparent;
        flex: 1;
        box-shadow: none;
        width: 100%;
        padding: 0 ($spacing * 2) ($spacing * 3);

        &:first-child {
          border-bottom: $borderMixin;
          @include breakpoint($tablet) {
            border-bottom: none;
          }
        }

        h2 {
          margin: ($spacing * 2) 0 0;

          &:focus {
            outline: auto;
          }

          & + button {
            margin-top: ($spacing * 2);
          }

          @include breakpoint($tablet) {
            margin-top: 0;
          }
        }

        p {
          margin-top: 0;

          & + button {
            margin-top: ($spacing * 2);
          }
        }

        .spc-button-link,
        .spc-input-checkbox-label {
          line-height: 20px;
        }

        .spc-input-checkbox-label-message {
          width: auto;
          font-size: 12px;
        }

        button:not(.spc-button-link) {
          width: 100%;

          @include breakpoint($tablet) {
            width: unset;
          }
        }

        #password-reset-request-confirm-html:focus {
          outline: auto;
        }

        @include breakpoint($tablet) {
          background: $cardbg;
          padding: ($spacing * 2);
          margin: 0;
          width: unset;

          &:not(:last-child) {
            margin-right: ($spacing * 2);
          }

          button {
            width: unset;
          }
        }

        button.account-guest-signin, button.account-guest-new-account{
          width: 100%;
        }
      }
    }
  }

  &-account-benefits {
    &-cards {
      .spc-card {

        h2 {
          text-align: center;
        }

        @include breakpoint($tablet) {
          background: transparent;
        }
      }
    }
  }
}

.spc-express-logout {
  margin-top: $spacing;

  &-billing {
    margin: $spacing 0 ($spacing * 2) 0;
  }
}

// Merge cart prompt
.merge-prompt-container {
  text-align: center;
  box-shadow: none;

  &.in-modal {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .cart-icon {
    display: inline-block;
    margin: 8px;
    @include sprite($icon-cart-large);
  }

  .merge-button-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .mergeButton,
    .cancelMergeButton {
      margin: 8px;
      text-transform: uppercase;
    }

    @include breakpoint-max($tablet) {
      .mergeButton,
      .cancelMergeButton {
        width: 100%;
      }

      .mergeButton {
        order: 0;
      }

      .cancelMergeButton {
        order: 1;
      }
    }
  }
}

.spc-modal-content .spc-account-checkout-buttons {
  justify-content: center;
}

#spc-content-klarna-express-html {
  min-width: -webkit-fill-available;
  @include breakpoint($tablet) {
    min-width: 256px !important;
    margin-right: 8px;
  }
}

#klarna-express-html {
  height: 42px !important;
  min-width: -webkit-fill-available;
  @include breakpoint($tablet) {
    width: 256px;
  }
  background: $white 0% 0% no-repeat padding-box !important;
  border-radius: 30px !important;
  opacity: 1 !important;
}

#klarna-express-checkout.kec-shape-pill #klarna-express-checkout__inner-container {
  border: 0px !important;
}