.country-locale-dialog {

  @include breakpoint($tablet) {
    padding: 20px;
  }

  .country-locale-dialog__content {
    div.row {
      justify-content: space-evenly;

      .flags-container {
        min-height: 155px;
        text-align: center;

        .choose-language{
          text-decoration: none;
          cursor: pointer;

          .arrow {
            display: inline-block;
            border-right: 2px solid black;
            border-bottom: 2px solid black;
            width: 8px;
            height: 8px;
            margin-left: 4px;
            transform: rotate(45deg);

            &.up{
              transform: rotate(-135deg);
              vertical-align: text-bottom;
            }
          }
        }

        .language-container {
          text-align: left;

          a {
            text-decoration: none;
            display: block;
            margin-bottom: 4px;
          }
        }
      }
    }
  }
}