@use "sass:math";

.spc-order-confirmation {

  #wrapper {
    background-color: transparent;
  }

  .header-style-wrapper {
    position: relative;
    z-index: 3;
  }

  #main {
    margin: 0;
    max-width: none;
    width: 100%;
  }

  #footer {
    margin-top: 0;
    position: relative;
    z-index: 2;
  }

  #order-confirmation {
    position: relative;
    z-index: 1;

    #spc-primary { padding-top: $spacing * 4; }
    #spc-secondary {
      padding-top: 0;
      @include breakpoint($tablet){
        padding-top: $spacing * 2;
      }
    }

    header {
      display: block;
      height: auto;

      h2 {
        margin: 0;

        + p { margin: $spacing 0 ($spacing * 2); }
      }

      form {
        margin: $spacing 0 ($spacing * 2);

        label {
          text-transform: initial;
          font-weight: initial;
        }
      }

      p {
        font-size: 12px;
        line-height: 16px;
        margin: 0;

        .spc-icon-check-m {
          background-image: url(../images/check.png);
          background-repeat: no-repeat;
          background-size: contain;
          display: inline-block;
          height: 12px;
          padding-right: $spacing;
          width: 12px;
        }

      }

      + hr { margin: ($spacing * 3) 0 ($spacing * 2); }
    }

    .spc-section-heading-step { display: none; }

    .order-summary {
      @include breakpoint-max($tablet){
        .order-summary-header {
          padding: ($spacing * 2) ($spacing * 3);
        }
        .order-summary-content {
          padding: ($spacing * 2);
        }
      }

      .order-summary-header {
        position: relative;
        .order-summary-header-title {
          @include breakpoint-max($desktop) {
            display: inline-block;
            width: 90%;
          }
        }
        h2 {
          width: calc(100% - #{$drawer-icon-expand-width});
        }
        svg {
          &.order-summary-state {
            path {
              display: none;
            }
          }
        }

        .order-summary-state {
          position: absolute;
          top: 50%;
          margin-top: -(math.div($drawer-icon-expand-height, 2));
        }
      }

      .product-item .product-info { width: 100%; }
      .spc-edit-product { display :none; }
    }

    .spc-content-checkout-customer-help {
      @include breakpoint-max($tablet){
        border-top: 1px solid;
      }
    }
  }
}

#spc-content-order-confirmation-footer {
  margin: ($spacing * 5) $spacing ($spacing * 3);

  @include breakpoint($tablet){
    margin-top: $spacing * 4;
  }
}

.poq-app {
  .poq-cta {
    padding: 24px;
    
    button {
      width: 100%;
    }
  }
}

.edit-address-form {
  input[type="checkbox"] {
    margin-right: 5px;
  }

  label {
    text-transform: none;
  }

  #checkout-save-users-details {
    margin-top: 20px;
  }

  .order-status-wrapper {
    margin-bottom: 30px;

    p {
      margin: 0;
      text-transform: capitalize;
    }
  }

  .checkout-successful-message {
    display: none;

    h2:focus-visible{
      outline: auto;
    }
    
    a {
      text-decoration: underline !important;
    }
  }
}

.confirmation-login-modal {
  #checkout-account-login-btn {
    margin-top: 50px;
    width: 100%;
  }

  #checkout-account-register-btn {
    margin-top: 7px;
  }

  #dialog-container {
    height: 100% !important;
  }

  #RegistrationForm, .guest-checkout-login-newaccount {
    margin-top: 25px;

    fieldset {
      padding-top: 0 !important;
    }
  }

  #login-form-email, #login-form-password {
    width: 100%;
  }

  #login-error-msg {
    margin: 8px 0;
  }

  .dialog-content {
    padding: 0 30px 30px 30px;
  }

  .remember-me {
    margin-top: 25px;
    line-height: normal;
  }

  .form-row:not(.form-row-button):not(.remember-me) {
    label {
      padding: 5px 0 5px;
      margin: 0;
    }
  }

  .login-box {
    .login-box-content {
      padding: 0;

      input[type="checkbox"] {
        margin-right: 5px;
        outline-offset: -1px;
      }

      .label-inline {
        width: auto;
      }
    }

    .section-header {
      margin: 5px 0;
      padding: 0;
    }
  }

  .login-not-registered, .login-create-new-account {
    padding: 0 !important;
    background: none !important;
    text-align: left !important;
  }
}

.confirmation-login-controls {
  margin: 0 auto;
}

.confirmation-login-controls-wrapper {
  box-sizing: border-box;
  position: relative;
  max-width: 936px;
  margin: auto;

  .confirmation-login-form {
    min-height: 200px;
    margin-top: 15px;
    padding: 0 25px 0 25px;

    .cta-primary {
      width: 100%;
      margin-top: 10px;
    }
  }
}

.sign-in-return-btn {
  margin-top: 50px;
  width: 100%;
}

.confirm-reset-password-form {
  .input-text {
    width: 100% !important;
  }

  .confirm-reset-pass-btn {
    width: 100%;
    margin-top: 50px !important;
  }
}

.account-login-register {
  #checkout-login-popup-modal, #checkout-createaccount-popup {
    .form-group-new .form-control-new {
      height: 56px;
      box-shadow: 0px 2px 4px #00000033;
      border: none;
      transition: all .5s;
      padding-top: 18px;
      -webkit-appearance: none;
      -webkit-box-shadow: 0px 2px 4px #00000033;
    }

    .form-group-new {
      transition: all .3s;
      margin-bottom: 8px;
      position: relative;

      .form-label {
        padding-left: 14px;
        text-transform: uppercase;
        transform-origin: 0 0;
        transition: all .3s;
        font-size: 12px;
        line-height: 22px;
        position: absolute;
        top: 13px;
      }

      input[type=text],
      input[type=password] {
        font-size: 16px;
        padding-left: 14px;
      }

      .form-control-new::placeholder {
        color: transparent;
      }

      .form-control-new:focus + label.error + .form-label,
      .form-control-new:focus + .form-label,
      .form-control-new:not(:placeholder-shown) + .form-label,
      .form-control-new:not(:placeholder-shown) + label.error +.form-label  {
        transform: translateY(-13px);
      }
    }

    label.error {
      color: #e71717 !important;
      text-transform: inherit;
    }

  }

  #checkout-createaccount-popup {
    .form-field--legal-agreement {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 20px;

      input.legal-agreement {
        margin-top: 0;
        margin-bottom: 0;
      }

      label {
        width: calc(100% - 33px);
      }
    }

    .form-field--email-opt-in {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 32px;

      input.legal-agreement {
        margin-top: 0;
        margin-bottom: 0;
      }

      label {
        width: calc(100% - 33px);

        a {
          font-size: inherit;
          line-height: inherit;
        }
      }
    }
  }



  #checkout-login-popup-modal {
    .remember-me {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      input#rememberMe {
        margin-top: 0;
        margin-bottom: 0;
        order: 1;
      }

      label {
        width: calc(100% - 33px);
        order: 2;
      }
    }
  }
}

#checkout-login-popup-modal {

  border: none;

  .login-box-content form input[type="text"],.login-box-content form input[type="password"] {
    background-color: $white;
    border: 1px solid $grey-medium;
  }

  .login-not-registered,
  #password-reset {
    text-align: left;
  }
}

#checkout-createaccount-popup {
  form fieldset input {
    background-color: $white;
    border: 1px solid $grey-medium;
  }
  .login-create-new-account {
    color: #0099ff;
  }
}

.confirmation-login-modal {
  h2.section-header {
    font-size: 32px;
    line-height: 40px;
  }

  .resetPasswordPadding {
    font-size: 24px;
    line-height: 30px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 812px) {

  .spc-info-icon-modal {
    display: inline;
    cursor: pointer;
    font-size: 16px;
    text-transform: lowercase;
    left: 5px;
    position: relative;
  }
}

@media only screen and (min-device-width: 813px) {

  .spc-info-icon-modal {
    display: none;
  }
}
