$cardbg: #f4f4f4;

#single-page-checkout .spc-express-checkout-buttons {
  .spc-paypal a,
  .spc-amazon .amazon-btn,
  .spc-apple-pay button {
    display: flex;
    align-items: center;

    background-color: $white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid $checkout-border-color;
    border-radius: 0;
    box-sizing: border-box;
    height: 48px;
    margin: 0;
    transition: border-color 0.2s;
    overflow: hidden;
    width: 100%;

    &:hover,
    &:focus {
      background-color: $white;
      border-color: $black;
    }

    img {
      max-width: 100%;
    }
  }

  .spc-paypal {
    margin-bottom: ($spacing * 2);

    img {
      margin: 0 auto;
      max-height: 100%;
    }

    .left {
      float: none;
    }
  }

  .spc-amazon {
    margin-bottom: ($spacing * 2);

    .amazonpopup {
      display: none;
    }

    .amazon-btn {
      background-image: url(../images/amazonPayBtn.png);
      padding: 0;
    }

    .paybutton {
      height: 100%;
      padding: 0;
    }

    .amazonpay-button-inner-image {
      display: block;
      height: 100%;
      width: 100%;
      opacity: 0;
      max-height: 100%!important; // Overriding inline style
    }
  }

  .spc-apple-pay {

    button {
      -webkit-appearance: none;
      background-image: -webkit-named-image(apple-pay-logo-black);
      background-size: 50%;
      padding: 0;
      margin: 0 0 ($spacing * 2);
      min-height: 0;
      min-width: 0;

      @include breakpoint($tablet){
        background-size: 60%;
      }
    }
  }

  .spc-login-button {
    display: flex;
    align-items: center;

    border: 1px solid $checkout-border-color;
    box-sizing: border-box;
    height: 48px;
    margin-bottom: ($spacing * 2);
    width: 100%;

    &:hover {
      border-color: $black;
    }

    span {
      @include body-small($color: $secondary);
      padding: 0 ($spacing * 2) 0 0;
      text-transform: uppercase;
      display: none;
    }

    button {
      display: block;
      height: 100%;
      line-height: 48px;
      overflow: hidden;
      text-align: center;
      width: 100%;
    }

    & + .spacer {
      display: none;
    }

    @include breakpoint($tablet) {
      border: none;

      span {
        display: inline;
      }

      button {
        display: inline-block;
        height: auto;
        width: auto;
      }
    }
  }
}

.spc-express-checkout {
  &-logout-section {
    padding-bottom: ($spacing * 3);
  }

  &-login-section {
    @include breakpoint($tablet) {
      // margin-bottom: ($spacing * 2);
    }

    &-cards {
      .spc-card {
        background: transparent;
        flex: 1;
        box-shadow: none;
        width: 100%;
        padding: 0 ($spacing * 2) ($spacing * 3);

        &:first-child {
          border-bottom: $borderMixin;
          @include breakpoint($tablet) {
            border-bottom: none;
          }
        }

        h2 {
          margin: ($spacing * 2) 0 0;

          & + button {
            margin-top: ($spacing * 2);
          }

          @include breakpoint($tablet) {
            margin-top: 0;
          }
        }

        p {
          margin-top: 0;

          & + button {
            margin-top: ($spacing * 2);
          }
        }

        .spc-button-link,
        .spc-input-checkbox-label {
          line-height: 20px;
        }

        .spc-input-checkbox-label-message {
          width: auto;
        }

        button:not(.spc-button-link) {
          width: 100%;

          @include breakpoint($tablet) {
            width: unset;
          }
        }

        @include breakpoint($tablet) {
          background: $cardbg;
          padding: ($spacing * 2);
          margin: 0;
          width: unset;

          &:not(:last-child) {
            margin-right: ($spacing * 2);
          }

          button {
            width: unset;
          }
        }
      }
    }
  }
}

.spc-express-logout {
  margin-top: $spacing;

  &-billing {
    margin: $spacing 0 ($spacing * 2) 0;
  }
}
