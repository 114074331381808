// fed- IF YOU SEE ME, READ ME!!!
// .fed- IF YOU SEE ME, READ ME!!!
// #fed- IF YOU SEE ME, READ ME!!!
// Please talk to the WWW FED team if
// think you need CSS rules with a "fed-" prefix!

// Brand Colors
$yellow: #ffcc00;
$blue: #2f547d;
$red: #e24d25;
$grey: #bcbec0;
$light-grey: $grey-white-light;
$dark-grey: #545859;
$green: #158c1b;
$red-error: #FF0000;
$minicart-nwa-hover: $blue;

// Styleguide keywords
$base: $black;
$primary: $yellow;
$secondary: $blue;
$tertiary: $grey;
$callout: $red;
$contrast: $white;

// Stepper styles color
$stepper-primary: $yellow;
$stepper-future-state: #999999;
$stepper-current-state:$dark-grey;
$stepper-link-state:$dark-grey;

//Progress bar color code
$minicart-progress: #000000;

// CTA buttons
$disabled: $dark-grey;
$cta-primary-background: $primary;
$cta-primary-background__hover: $tertiary;
$cta-primary-text: $base;
$cta-primary-text__hover: $base;
$cta-secondary-background: $primary;
$cta-secondary-background__hover: $tertiary;
$cta-secondary-text: $base;
$cta-secondary-text__hover: $base;

// Legacy Demandware Color Pallette Translation
$mainbrand: $base;
$primarylink: $secondary;
$fontaccent: $tertiary;
$mainbordercolor: $tertiary;
$secondarybackground: $light-grey;
$backgroundaccent: $tertiary;
$lightbackdrop: $light-grey;
$subnavtext: $base;
$mainbackground: $contrast;
$placeholder: $tertiary;
$label-error: $red-error;

//product name hover change minicart,cart,checkout
$product-name-link:$blue;
//minicart promocode drawer
$drawer-heading-Lh:21px;
$minicart-ideme-lh:19px;
//Font Types
$MierBlack: "Mier-A-Black", Arial, "Helvetica Neue", Helvetica, sans-serif;
$MierBold: "Mier-B-Bold", Arial, "Helvetica Neue", Helvetica, sans-serif;
$Mier: "Mier-B", Arial, "Helvetica Neue", Helvetica, sans-serif;

// Styleguide Fonts
$h1-family:   $MierBlack;
$h2-family:   $MierBold;
$h3-family:   $MierBold;
$h4-family:   $MierBold;
$body-family: $Mier;

// Legacy Demandware Font Translation
$font-type1: $h1-family;
$font-type2: $h2-family;
$font-type3: $h2-family;
$font-type4: $body-family;
$font-type5: $Mier;

$font-name-bold: $h2-family;
* { -webkit-font-smoothing: antialiased; }

// Typography
@mixin h1($color:$base, $decoration:null, $transform:none, $line-height: 41px){
  font-family: $h1-family;
  font-size: 36px;
  line-height: $line-height;
  letter-spacing: 0.9px;
  color: $color;
  font-weight: normal; // the font itself is bold
  text-decoration: $decoration;
  text-transform: $transform;
  fake-rule: "h1";
}

@mixin h2($color:$base, $decoration:null, $transform:null, $line-height: 28px){
  font-family: $h2-family;
  font-size: 20px;
  line-height: $line-height;
  letter-spacing: 0.5px;
  color: $color;
  font-weight: normal;
  text-decoration: $decoration;
  text-transform: $transform;
  fake-rule: "h2";
}

@mixin h3($color:$base, $decoration:null, $transform:null, $line-height: 17px){
  font-family: $h3-family;
  font-size: 12px;
  line-height: $line-height;
  letter-spacing: 0.3px;
  color: $color;
  font-weight: normal;
  text-decoration: $decoration;
  text-transform: $transform;
  fake-rule: "h3";
}

@mixin h4($color:$base, $decoration:null, $transform:null, $line-height: 21px){
  font-family: $h4-family;
  font-size: 14px;
  line-height: $line-height;
  letter-spacing: 0.35px;
  color: $color;
  font-weight: normal;
  text-decoration: $decoration;
  text-transform: $transform;
  fake-rule: "h4";
}

@mixin body($color:$base, $decoration:null, $transform:null, $line-height: 21px){
  font-family: $body-family;
  font-size: 14px;
  line-height: $line-height;
  letter-spacing: 0.35px;
  color: $color;
  font-weight: normal;
  text-decoration: $decoration;
  text-transform: $transform;
  fake-rule: "body";
}

@mixin body-small($color:$base, $decoration:null, $transform:null, $line-height: 18px){
  font-family: $body-family;
  font-size: 12px;
  line-height: $line-height;
  letter-spacing: 0.3px;
  color: $color;
  font-weight: normal;
  text-decoration: $decoration;
  text-transform: $transform;
  fake-rule: "body-small";
}

@mixin body-large($color:$base, $decoration:null, $transform:null, $line-height: 28px){
  font-family: $body-family;
  font-size: 20px;
  line-height: $line-height;
  letter-spacing: 0.5px;
  color: $color;
  font-weight: normal;
  text-decoration: $decoration;
  text-transform: $transform;
  fake-rule: "body-large";
}

@mixin body-tiny($color: $base, $line-height: null, $letter-spacing: 0.25px, $transform: null) {
  font-size: 10px;
  font-family: $body-family;
	color: $color;
	line-height: $line-height;
	letter-spacing: $letter-spacing;
	text-transform: $transform;
	fake-rule: 'body-tiny';
}

@mixin cta($color:$base, $decoration:null, $transform:uppercase){
  display: inline-block;
  color: $color;
  font-family: $h3-family;
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0.59px;
  text-decoration: $decoration;
  text-transform: $transform;
  fake-rule: "cta";
}


@mixin brand-cart-counter() {
  font-family: $MierBold;
  font-weight: normal;
  background: $deep-red; // color set in core 😬
  box-sizing: border-box; // needed for padding below
  // Using multiple breakpoints as these styles and stylesheets apply to things you don't expect!
  @include breakpoint-max($mobile){
    padding: 0 0 0 1px; // font requires extra padding to be centered in circle
  }
  @include breakpoint($tablet){
    padding: 0 0 2px 1px; // font requires extra padding to be centered in circle
  }
}
