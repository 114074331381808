// Slick base styles
// Wrapped in .product-tile to avoid conflicts with WWW FED's use of Slick
.product-tile, 
.product-slider,
.www-slider-container {
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    .slick-track,
    .slick-list {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    div {
      max-width: none;
      width: 100%;
    }
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;

    &:focus {
      outline: none;
    }

    &.dragging {
      cursor: pointer;
      cursor: hand; // This is likely for compatibility issues
    }
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
      display: table;
      content: '';
    }

    &:after {
      clear: both;
    }
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;

    img {
     display: block;
     width: 100%;
    }
    
    &.slick-loading img {
      display: none;
    }

    &.dragging img {
      pointer-events: none;
    }
  }

  [dir='rtl'] .slick-slide {
    float: right;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical {
    .slick-slide {
      display: block;
      margin-bottom: -4px;
      height: auto;
      border: 1px solid transparent;
    }
    
    .slick-prev,
    .slick-next {
      left: 50%;
      transform: translateX(-50%) rotate(90deg);
      z-index: 10;
      
      & ~ .slick-list {
        max-width: 100%;
        margin: 0;
      }
    }
    
    .slick-prev {
      top: 5px;
    }
    
    .slick-next {
      top: auto;
      right: auto;
      bottom: 5px;
    }
  }

  .slick-arrow.slick-hidden {
    display: none;
  }

  // Theme Specific Styles
  // -------------------------------------------------------
  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;

    &:focus,
    &:hover {
      color: transparent;
      outline: none;
      opacity: 1;
    }

    &:before {
      font-size: 20px;
      line-height: 1;
      opacity: 0.85;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &.slick-disabled:before {
      opacity: 0.25;
    }
  }

  .slick-prev {
    left: 0;
  }

  [dir='rtl'] .slick-prev {
    right: 0;
    left: auto;
  }

  .slick-next {
    right: 0;
  }

  [dir='rtl'] .slick-next {
    right: auto;
    left: 0;
  }

  /* Dots */
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;

    li {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;

      button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 20px;
        height: 20px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;

        &:hover,
        &:focus {
          outline: none;

          &:before {
            opacity: 1;
          }
        }

        &:before {
          font-size: 6px;
          line-height: 20px;
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          content: '•';
          text-align: center;
          opacity: .25;
          color: black;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }

      &.slick-active button:before {
        opacity: 0.75;
        color: black;
      }
    }
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    max-height: 100%;
    height: 100%;
  }
  .slick-slider .slick-slide > div {
    height: 100%;
  }

  .slick-next,
  .slick-prev {
    background-size: contain;
    background-repeat: no-repeat;
    width: 10px;
    height: 15px;

    & ~ .slick-list {
      max-width: calc(100% - 40px);
      margin: 0 auto;
    }
  }

  .slick-prev {
    background-image: url('../images/interface/arrow-left.svg');
  }
  
  .slick-next {
    background-image: url('../images/interface/arrow-right.svg');
  }

  .slick-disabled {
    display: none !important;
  }
}

div.www-slider-container {
  .slick-next,
  .slick-prev {
    display: flex !important; // need to overwrite added js
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgba($white, 0.6);
    z-index: 1;

    &::before {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 10px;
      height: 13px;
    }
  }

  .slick-prev {
    left: 25px;
    
    &::before {
      background-image: url('../images/interface/arrow-left.svg');
    }
  }

  .slick-next {
    right: 25px;
    
    &::before {
      background-image: url('../images/interface/arrow-right.svg');

    }
  }
}
