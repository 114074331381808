.spc-card {
  background-color: white;
  box-shadow:  0 1px 2px 0 rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  padding: $spacing ($spacing * 2);
  position: relative;

  &-streetaddress {
    @include breakpoint-max($tablet){
      display: block;
    }
  }
}

.spc-collapsed-shipping,
.spc-confirmation-shipping,
.spc-collapsed-billing {
  p {
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    max-width: calc(100% - 20px);

    @include breakpoint($tablet){
      max-width: calc(100% - 55px);
    }
  }

  .spc-group > div { width: 100%; }

  &-name {
    @extend .bold!optional;

    @include breakpoint($tablet){
      padding-bottom: $spacing;
    }
  }

  &-address {
    padding-bottom: $spacing * 2;

    &-secondary {
      @include breakpoint($tablet){
        display: block;
      }
    }
  }

  .spc-badge {
    color: $primary;
    cursor: pointer;
    line-height: 16px;
    position: absolute;
    right: 15px;
    top: 10px;

    &-text {
      font-size: 12px;
      line-height: 16px;
      vertical-align: middle;

      @media screen and (max-width:768px){
        display: none;
      }
    }

    .icon-pencil {
      margin-left: $spacing;
    }

    &-disabled {
      color: $disabled;
      cursor: initial;
      .spc-badge-text {
        color: $disabled;
      }

      .icon-pencil {
        fill: $disabled;
      }
    }
  }
}

.spc-collapsed-express-shipping,
.spc-collapsed-shipping,
.spc-collapsed-billing {

  &-card + button.spc-button-link {
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    margin: ($spacing * 2) 0 ($spacing * 3);
  }

  &-method span {
    font-weight: bold;
    @extend .bold !optional;
  }

  div.spc-edit-paypal {
    border: 2px solid $checkout-border-color;
    height: 24px;
    transition: border-color 0.2s;
    width: 70px;

    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);

    @include breakpoint-max($tablet){
      box-sizing: border-box;
      top: 8px;
      right: 8px;
      transform: translate(0);
    }

    &:hover { border-color: black; }

    div, a {
      display: block;
      height: 100%;
      width: 100%;
    }

    .icon-pencil {
      display: block;
      height: 10px;
      margin-left: 8px;
      position: absolute;
      width: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    img {
      display: block;
      float: right;
      max-width: 50px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

  }

  &-form {
    .spc-input-container {
      margin: ($spacing * 3)x 0 $spacing;
    }
  }
}

.spc-collapsed-billing {
  .spc-collapsed-billing-creditcard-number {
    display: flex;
    margin-bottom: $spacing;

    span:not(:last-child) { margin-right: $spacing; }

    &.spc-collapsed-billing-afterpay {
      margin-bottom: 0;
    }

    &.spc-collapsed-billing-affirm {
      margin-bottom: 0;
    }

    &.spc-collapsed-billing-klarna{
      margin-bottom: 0;
      align-items: center;

      .spc-collapsed-billing-name{
        margin: ($spacing * .5);
        margin-left: $spacing;
        padding-bottom: 0;
      }
    }
  }

  &-address {
    padding: 0;
  }

  &--alternative-payment {
    .alternative-title {
      color: $checkout-label-color;
    }
  }

  & ~ .spc-collapsed-billing {
    margin-top: $spacing;
  }

  .spc-icon-paypal-m,
  .spc-icon-amazon-m,
  .spc-icon-applepay-m,
  .spc-icon-pci-pal-m {
    display: inline-block;
    margin: ($spacing * 3) $spacing 0 0;

    @include breakpoint($tablet){
      margin: 0 $spacing 0 0;
    }
  }

  &-express {
    display: flex;
    align-items: center;

    &-label {
      @extend %spc-static-label;
    }

    &.spc-collapsed-billing-applepay {
      .spc-collapsed-billing-express-info .spc-collapsed-billing-name {
        &:after { content: ''}
      }
      @include breakpoint-max($tablet){
        .spc-mobile-only .spc-collapsed-billing-name { display: inline; }
      }
    }


    .spc-badge {
      @include breakpoint($tablet){
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &-express-info {
    display: inline-block;
    font-size: 12px;
    line-height: 18px;
    padding: ($spacing * 3) 0 0;
    max-width: calc(100% - 36px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include breakpoint($tablet){
      max-width: calc(100% - 115px);
      padding: $spacing 0;
    }

    .spc-collapsed-billing-name {
      font-weight: inherit;

      &:after { content: ' | '}

      @include breakpoint-max($tablet){
        display: none;
      }
    }
  }

  & + .spc-express-logout {
    margin-bottom: $spacing * 2;
  }

  &-pcipal-info {
    display: flex;
    align-items: center;
  }
}
