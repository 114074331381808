
// order details
// -------------------------------------
.order-details-content {
    .order-header { 
        border-bottom: 1px solid $mainbordercolor;
        margin: 0 0 20px 0;
        padding: 14px 0 10px;
        position: relative;
        width: 100%;
        
        h2 {
            display: inline-block;
            margin: 0; 
        }
    }

    .delivery-status {
        display: block;
        padding: 0;
        float: right;
        color: $orderStatus;
        text-transform: none;
        font-size: 14px;
        line-height: 20px;
        h4 {
            display: inline;
            font-size: 14px;
            line-height: 20px;
        }
    }
    
    h3 { margin-bottom: 5px; }
    
    p { margin: 0; }
    
    .shipment {
        border: 1px solid $mainbordercolor;
        border-radius: 5px;
        margin-bottom: 20px;
    }
    
    .shipment-header {
        background-color: $secondarybackground;
        border-bottom: 1px solid $mainbordercolor;
        overflow: hidden;
        padding: 15px 20px;

        h2 { margin: 0; }

        span { display: block; }

        .shipping-method {
            line-height: 23px;
            padding: 0;
        }
    }

    .shipment-body {
        padding: 20px;

        table { 
          margin: 0; 
          
          .item-image {
            padding: 15px 25px 11px 0;
          }
          
          .item-name {
            padding: 11px 0;
          }
        }

        th:first-child { padding-left: 0; }
    }

}

.order-summary-table, .order-shipment-table{margin:2em 0;}

.order-summary-table {
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    & > div {
        box-sizing: border-box;
        padding: 10px 20px 10px 0;
        width: 35%;

        &.order-payment-summary {
            padding: 0;
            margin-top: 71px;
        }

    }

    address { font-style: normal; }

    .textalign-right { text-align: right; }
    .order-totals-table-container {background-color: $secondarybackground;}
    .order-detail-summary {
        border: 1px solid $mainbordercolor;
        border-radius: 5px;
        padding: 9px 20px 10px;
        background-color: $secondarybackground;

        td {
            background-color: transparent;
            padding: 0 0 10px;
        }

        .discount .textalign-right { color: $callout; }

        .order-total {
            border-top: 1px solid $mainbordercolor;
            @extend .order-total-text !optional;

            td {
                padding-top: 10px;
            }
        }
    }
    
    .order-summary-heading {
        border-bottom: 1px solid $mainbordercolor;
        display: block;
        margin-bottom: 10px;
        padding-bottom: 5px;
        @extend .order-summary-headers !optional;
    }

    td {
        line-height: 1.5em;
        @extend .default-small-text !optional; //could text fall back to body?
    }
    
    
    .order-discount{ color:$mainbrand;}
    
     .mini-address-location{padding:0;}
    .order-subtotal{font-weight:normal;}

    .shipping-method { padding: 0; }
}

.pt_order-confirmation {
    .order-summary-table td {
        padding-left: 0;
        padding-right: 2em;
    }
}

.order-summary-table{
    .order-billing {
        .mini-address-name {
            inline-size: 200px;
            word-wrap: break-word;
        }
    }
    .orer-shipment-address {
        .mini-address-name {
            inline-size: 200px;
            word-wrap: break-word;
        }
    }
}

.order-details-content .order-shipment-table td, .order-details-content .order-shipment-table th{ padding:11px; border:none; text-align:left !important;}
.order-details-content {
    .order-shipment-table {
        .item-return-exchange {
            td {
                width: 20%;
                padding: 0;
                position: relative;
                padding-bottom: 1em;
            }
            .return-exchange-link:after {
                content: '';
                display: inline-block;
                position: absolute;
                top: 7px;
                right: -14px;
            }
        }
        .item-return-exchange {
            .return-exchange-link:after {
        	    @include sprite($icon-refinement-down);
            }
        }
        .item-return-exchange.expanded {
            .return-exchange-link:after {
                 @include sprite($icon-refinement-up);
            }
        }
    }
}

.order-details-header {
    .holder-box {
        p {
           color: $orderStatus;
        }
    }
}

.order-details-content .order-shipment-table td { @extend .default-small-text !optional;}//should fall back to body?

.order-details-content .order-shipment-table tr{ line-height:1.5em; }
.order-details-content .order-shipment-table tbody tr:not(.item-return-exchange, .final-sale-row){ border-top: 1px solid $mainbordercolor; } 
.pt_multireturnexchange .order-shipment-table tbody .order-item-noreturn td { width: auto; }
.pt_multireturnexchange .order-details-content .order-shipment-table tbody tr{ border-bottom: 0; }
#wrapper.pt_multireturnexchange .item-name .name a:hover { color: $cart-link-hover }
.order-details-content .order-shipment-table td.order-item-name{ width:25%;}
.order-details-content .order-shipment-table td.textalign-center{ text-align:center !important;}


.order-details-content .samplea{padding:1em 0; margin-bottom: 80px;}
.order-details-content .samplea .button{margin:0 0 1em; display:inline-block; color:$white;}
.order-details-content .samplea .shipping-method{padding-left:0;}

[cc-type]{
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    background-size: 31px 22px;

    height: 22px;
    width: 41px;
    display: block;
}
[cc-type="Amex"]{
    background-image: url(../images/checkoutv2/amex.png);
}
[cc-type="Discover"]{
    background-image: url(../images/checkoutv2/discover.png);
}
[cc-type="MasterCard"]{
    background-image: url(../images/checkoutv2/mastercard.png);
}
[cc-type="Visa"]{
    background-image: url(../images/checkoutv2/visa.png);
}