@import "../../../mixins.scss";

$header-banner-background: #F1F1F2 !default;
$mini-cart-text-small: 12px;
$mini-cart-text-medium: 14px;
$mini-cart-text-large: 16px;
$mini-cart-continue-shopping: $black !default;
$Gira-Sans: gira-sans, sans-serif;
$roboto: 'Roboto';
$drawer-heading-Lh:19px !default;
$drawer-button-Lh:17px!default;
$minicart-ideme-lh:18px!default;

@function svg-color-change($colour) {
  @return '%23' + str-slice('#{$colour}', 2, -1)
}

#navigation,
.remembered-box,
.headerscrolltotop,
#mobile-header #header .header-top-box,
#mobile-header .promotions-wrapper.desktop-promotions {
  // For mini-cart layering
  transition: all .25s ease-in-out;
}

html.mini-cart-open {
  &, body {
    overflow: hidden !important;
  }
}

.mini-cart-open {

  #navigation {
    z-index: 4000;
    transition-delay: 0s;
  }

  .remembered-box,
  .headerscrolltotop,
  #mobile-header #header .header-top-box,
  #mobile-header .promotions-wrapper.desktop-promotions {
    transition-delay: 0s;
    z-index: 1;
  }
}

@include breakpoint-max($tablet) {
  .responsive-mini-cart .displayWhenCartIsNotEmpty {
    display: none;

    &.active {
      display: block !important;
    }
  }
}

.mini-cart-container {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  left: 90%;
}

.MiniCart__Mask {
  visibility: hidden;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: $grey-medium 0% 0% no-repeat padding-box;
  left: 0;
  top: 0;
  z-index: 9;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;

  &.visible {
    visibility: visible;
    opacity: 0.4;
  }
}

.MiniCart__InnerContent {
  display: none;
  flex-direction: column;
  position: fixed;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  background-color: $white;
  right: -100vw;
  top: 0;
  z-index: 10;
  transition: right 0.5s;
  box-sizing: border-box;

  @media screen and (min-width: 769px) {
    right: -40vw;
    width: 40vw;
    max-width: 380px;
  }

  &.expanded {
    display: flex;
    right: 0;
    // hack for iOS Safari's need to hide fixed footers
    max-height: -webkit-fill-available;
    min-height: 100%;

    @include breakpoint($tablet) {
      overflow: auto;
    }
  }

  .spc-loader-wrapper {
    height: 100%;
    box-shadow: unset;
  }
}

// WMR3-227 IE fix. This rule will only apply to IE browser
_:-ms-fullscreen, :root .MiniCart__InnerContent {
  position: absolute;

  &.expanded {
    position: fixed;
  }
}

.MiniCart__Header {
  border-bottom: $borderMixin;
  height: 52px;
  display: flex;
  justify-content: space-between;

  &.MiniCart__error {

    .MiniCart__HeaderActions .MiniCart__HeaderActions--close {
      margin: auto;
      align-self: center;
    }
  }

  .MiniCart__HeaderTitle {
    margin: 0;
    padding-left: 16px;
    text-transform: uppercase;
    font-size: $mini-cart-text-medium;
    align-content: center;
  }

  .MiniCart__HeaderErrorContainer {
    flex-grow: 1;
    align-self: center;

    .MiniCart__HeaderTitle {
      padding: 5px 0 0 20px;
    }

    .MiniCart__HeaderError {
      padding-left: 20px;
      width: initial;
      .MiniCart__ErrorMessage {
        @include breakpoint($desktop) {
          margin: 3px 0;
        }
      }
    }
  }

  .MiniCart__HeaderActions {
    display: flex;

    .MiniCart__HeaderActions--cart,
    .MiniCart__HeaderActions--close {
      padding: 20px;
    }

    .MiniCart__HeaderActions--cart {
      padding-right: 10px;
    }

    .MiniCart__HeaderActions--close {
      .icon-close {
        display: inline-block;
        @include sprite($icon-ui-close, 2);
        cursor: pointer;
        position: relative;
      }
    }

    .icon-mini-cart {
      margin: auto;
      position: relative;
      width: 22px;
      height: 0; // because container is box-sizing: content-box, padding (see above) is additive

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @include icon-cart;
      }
    }

    .mini-cart-quantity-bag {
      @include icon-cart-counter;
    
      // allow brand specific class to override the above styles
      @extend %mobile-mini-cart-counter !optional;
    }
  }

  .MiniCart__HeaderError {
    width: 80px;
    display: flex;
    align-items: center;
  }

  .MiniCart__ErrorMessage {
    @include body-small();
    margin: 3px 0;
    color: $cart-text-callout;
    line-height: 1;

    @include breakpoint($desktop) {
      margin: 7px 0;
    }
  }
}

html[lang="nl-NL"] {
  .MiniCart__Header .MiniCart__ErrorMessage {
    // Reducing 2px for NL since it cuts off
    font-size: 10px;
  }
}

.MiniCart__ProductTileContainer {
  flex: 1;
  overflow: auto;

@include breakpoint($tablet) {
    min-height: 200px;
  }

  .MiniCart__FreeShippingMessage {
    padding: 12px 16px 8px 16px;
    text-align: center;
    @include h3();
    background-color: $header-banner-background;
    border-bottom: $borderMixin;
  }

  .MiniCart__GWPContainer {
    padding: 20px;
    background: $secondarybackground;

    .mini-cart-gift-with-purchase {
      display: flex;
      flex-flow: row;

      // only add padding if image is present
      img + div { padding: 0 0 0 20px; }

      p { padding-top: 0; }
    }

  }

  .mini-cart-products {
    padding: 0;
    margin: 0;
  }
}

.MiniCart__ProductTile {
  .mini-cart-promo,
  .mini-cart-scarcity,
  .mini-cart-out-of-stock {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-left: auto;

    @include breakpoint-max( $desktop ){
      padding: 0;
    }
  }

  .mini-cart-scarcity {
    flex-wrap: nowrap;
  }

  .mini-cart-pre-order {
    padding: 10px 0 0;
    width: 50%;
    margin-left: auto;
  }

  &.mini-cart-product {
    border-bottom: 1px solid $grey-silver;
    background: none repeat scroll 0 0 $white;
    overflow: hidden;
    margin: 0 16px;
    display: flex;
    flex-flow: row wrap;
    box-shadow: 0px 0px 3px $grey-gravel;
    margin-bottom: 15px;
    padding-top: 0;
    @media screen and (max-width: 769px) {
      margin: 15px 16.5px 0 ;
    }

    .mini-cart-product-block {
      background: none repeat scroll 0 0 $white;
      overflow: hidden;
      margin: 0 16px 12px 16px;
      display: flex;
      width: 100%;
      @media screen and (max-width: 769px) {
        margin: 0 14px 16px;
        flex-wrap: nowrap;
      }
    }
  }

  .mini-cart-image {
    flex-basis: 50%;
    padding-right: 0;
    box-sizing: border-box;
    max-width: 300px;

    img {
      width: 90%;
      text-align: center;
    }
  }

  .mini-cart-product-details {
    flex-basis: auto;
    flex: 1;
    .item-sku-number {
      font-size: $mini-cart-text-small;
      line-height: 16px;
    }
  }

  .mini-cart-messaging {
    flex-basis: 100%;
    font-size: $mini-cart-text-small;
    margin-left: auto;
    margin-top: 14.5px;
    display: flex;
    width: 100%;
  }

  .mini-cart-name {
    float: none;
    width: auto;
    flex-basis: 100%;
    padding: 12px 0 14px;
    box-sizing: border-box;
    max-width: 300px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 16px;

    a {
      line-height: 21px;
      font-weight: bold;
      text-transform: capitalize;
    }
  }

  .mini-cart-action {
      margin-top: 8px;
      a {
        font-size: $mini-cart-text-small;
        padding: 0;
      }
  }

  .action-divider {
    pointer-events: none;
    padding: 0 4px;
  }

  .mini-cart-attributes,
  .mini-cart-pricing,
  .mini-cart-action {
    float: none;
    font-size: $mini-cart-text-small;
    line-height: 16px;
    width: auto;
  }

  .scarcity-icon {
    display: inline-block;
    width: 14px;
    height: 13px;
    margin-right: 10px;
    background-image: url('../images/cartcheckout/icon-alert.svg');
    background-repeat: no-repeat;
  }

  .promo-expiration-icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 10px;
    background-image: url('../images/cartcheckout/icon-expiration.svg');
    background-repeat: no-repeat;
  }

  .out-of-stock-icon {
    display: inline-block;
    width: 14px;
    height: 13px;
    margin-right: 10px;
    background-image: url('../images/cartcheckout/icon-alert.svg');
    background-repeat: no-repeat;
  }

  .mini-cart-final-sale {
    padding: 16px;
    flex-basis: 100%;

    h2 { margin-top: 0; }
  }

  .scarcity-messaging,
  .promo-expiration-messaging,
  .out-of-stock-messaging,
  .pre-order-heading,
  .pre-order-messaging,
  .expected-ship-date {
    @include body-small();
    font-size: $mini-cart-text-small;
    margin: 3px 0;
    color: $deep-red;
    line-height: 1.5;

    @include breakpoint($desktop) {
      margin: 7px 0;
    }
  }

  .mini-cart-scarcity{
    margin: 0 16px 8px;
    .spc-icon-alert{
      stroke: #A96700;
      width: 17px;
      height: 18px;
    }
    .scarcity-messaging{
      color:#A96700;
      margin:0;
      padding-left: 9px;
      line-height: 18px;
      font-size: 12px;
      font-weight: normal;
      display: flex;
      align-items: center;
  }
}

  .out-of-stock-messaging {
    & + a {
      flex-basis: 100%;
      padding: 0 0 10px;
    }
  }
}

.MiniCart__ProductTile {

  .inside-box-line {
    border-bottom: 1px solid $grey-silver;
    width: 100%;
    margin: 16px 0 20px 0;
  }

  .mini-cart-pricing {
    padding: 0 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    > * {
      width: 29%;
    }
    .label {
      display: block;
      margin-bottom: 9.5px;
      text-align: inherit;
    }
    .cartItemSubTotal {
      font-size: 14px;
    }
  }

  // First column
  .mini-cart-price-each {
    overflow-wrap: break-word;
  }

  // Second column
  .quantity-main-wrapper {
    width: unset;
    .label {
      text-align: center;
    }
  }

  // Third column
  .mini-cart-price-subtotal {
    text-align: right;
    overflow-wrap: break-word;
    .mini-cart-price-each {
      display: block;
    }
  }

}

.MiniCart__Footer {
  border-top: $borderMixin;
  height: fit-content;

  .MiniCart__Padding24 {
    padding: 0 16px 16px;
  }
  .MiniCart__FooterTotal {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    margin-top: 12px;

    span { font-size: $mini-cart-text-large; }
  }

  .MiniCart__FooterViewCart {
    padding: 0;
    width: 100%;

    a { font-size: $mini-cart-text-large; }
  }

  .MiniCart__ExpressCheckout {
    padding-top: 16px;

    > *:not(:first-child) {
      padding-left: 16px;
    }

    .spc-paypal,
    .spc-amazon,
    .spc-apple-pay {
      flex: 1;
      margin-bottom: 0px !important;
    }

    // Hide Amazon Pay if Apple Pay is active
    .spc-apple-pay + .spc-amazon {
      display: none;
    }
  }

  .spc-drawer {
    #spc-idme-container {
      display: none;
    }
    .spc-drawer-content.spc-drawer-promoCode {
      padding: 12px 20px;
      margin-bottom: 0px;
      .spc-drawer-header {
        padding: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: $drawer-heading-Lh;
        span{
          font-size: 16px;
          color:$drawer-heading-color;
          font-family: $font-name-bold;
        }
      }
      .spc-drawer-body:empty {
        padding: 0;
      }
      .spc-drawer-body {
        padding: 0px;
        #spc-promo-code-form {
          margin-top: 24px;
        }
        .spc-applied-promotions {
          .spc-loader-wrapper{
            &:first-child{
              padding-top: 8px;
            }
            padding-top: 12px;
          .spc-applied-discount.spc-card {
            box-shadow: none;
            background-color: transparent;
            padding: 0px;
            border:none;
            min-height: 19px;
            .spc-applied-discount-message {
              .row {
                .col-sm-11 {
                  .spc-discount-seperator {
                    font-family: $font-type1;
                    font-weight: bold;
                    font-size: 14px;
                  }
                  .spc-discount-name {
                    font-weight: normal;
                  }
                }
              }
              font-size: 14px;
              padding-top: 0px;
              line-height: $minicart-ideme-lh;
              .MiniCart__PromoCodeDetails {
                display: block;
                line-height: $minicart-ideme-lh;

                .promoDescription {
                  font-weight: normal;
                }
              }
              button {
                @include body();
                text-decoration: none;
                font-size: 14px;
                padding-left: 1px;
                display: inline;
                line-height: $minicart-ideme-lh;
                &:before {
                  content: '';
                }
              }
            }
            .spc-icon-remove-m {
              right: 0px;
              top: 0px!important;
              transform: none;
              background-size: 10px;
              width: 10px;
              height: 10px;
              padding-right: 0px;
              margin-top: 4px;
            }
          }
        }
        }
        .spc-input-container {
          padding: 0px;
          max-width: 241px;
        }
          .spc-form {
            .spc-drawer-fieldset {
              .row {
                margin-left: 0;
              }
            }
            .spc-drawer-input[type=text],.spc-drawer-input[type=text].error-message {
              height: 36px;
              padding: 9px 12px;
              border: 1px solid $black !important;
              color:$drawer-heading-color!important;
              border-right: 0px;
              font-size: 14px;
            }
          }
        
        .spc-drawer-button{
          padding-left: 0px;
          padding-right: 0px;
          button{
            font-size: 13px;
            line-height: $drawer-button-Lh;
            height: 36px;
            border-radius: 0;
            padding: 9px 0px;
            text-transform: uppercase;
          }

        }
        #mini-cart-content-spc-idme-container {
          margin-top: 16px;
          #spc-idme-container {
            .spc-drawer-header {
              padding: 0px;
              display: inline;
              span {
                font-size: 14px;
                line-height: $minicart-ideme-lh;
                vertical-align: unset;
                text-decoration: underline;
              }
            }
            .minicart-id-me-tooltip {
              margin-left: 8px;
              margin-top: 1.5px;
            }
            .tooltiptext {
              .tooltip-idme-text {
                font-size: 12px;
                line-height: 18px;
              }
            }
          }
        }
      }
    }
    #spc-drawer-promoCode .spc-drawer-fieldset .spc-input-error {
      display: inline-block;
      width: 147%;
      padding-left: 0px;
      font-size: 14px;
      line-height: 19px;
      padding-top: 8px;
      color: $sale-text-price;
      font-weight: normal;
    }
  }
  .spc-drawer-open {
    #spc-idme-container {
      display: flex;
      align-items: center;
    }
    .idme-tool-tip {
      display: inline-block;
      vertical-align: text-top;
      padding-top: 5px; 
    }
  }
  .spc-drawer-header .spc-icon-drawer-toggle-m {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12px" height="6px" viewBox="0 0 24 24" version="1.1"><title>icon / caret-down</title><defs><polygon id="path-1" points="12 13.4545455 16.6666667 9 18 10.2727273 12 16 6 10.2727273 7.33333333 9"/></defs><g id="icon-/-caret-down" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><mask id="mask-2" fill="white"><use xlink:href="%23path-1"/></mask><use id="Mask" fill="%23EAEAEA" xlink:href="%23path-1"/><g id="colors-/-black" mask="url(%23mask-2)" fill="#{svg-color-change($drawer-heading-color)}"><g id="Base"><rect id="Rectangle-Copy-7" x="0" y="0" width="24" height="24"/></g></g></g></svg>');
    background-size: 22px 22px;
    float: right;
    margin-right: 0px;
    height: $drawer-heading-Lh;
    background-color: inherit;
    background-position: center;
  }
  .spc-drawer-open .spc-drawer-header .spc-icon-drawer-toggle-m {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12px" height="6px" viewBox="0 0 24 24" version="1.1"><title>icon / caret-down</title><defs><polygon id="path-1" points="12 13.4545455 16.6666667 9 18 10.2727273 12 16 6 10.2727273 7.33333333 9"/></defs><g id="icon-/-caret-down" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><mask id="mask-2" fill="white"><use xlink:href="%23path-1"/></mask><use id="Mask" fill="%23EAEAEA" xlink:href="%23path-1"/><g id="colors-/-black" mask="url(%23mask-2)" fill="#{svg-color-change($drawer-heading-color)}"><g id="Base"><rect id="Rectangle-Copy-7" x="0" y="0" width="24" height="24"/></g></g></g></svg>');
    transform: rotate(180deg); 
    padding-right: 0px;
    padding-left: 0px;
    position: relative;
    height: $drawer-heading-Lh;
    background-color: inherit;
    background-position: center;
  }
  .MiniCart__Vouchers {
		padding-bottom: 16px;
	}

  .MiniCart__cart-checkout-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    #mini-checkout-viewCart,
    #mini-checkout-form {
      margin-top: 8px;
    }
    .mini-cart-link-checkout,
    .mini-cart-link-checkout {
      min-width: 164px;
      height: 44px;
      line-height: 18px;
    }
  }
}

.MiniCart__ContinueShoppingtext {
  @media screen and (max-width: 769px) {
    margin: 0 0 27px 10px;
  }
  margin: 0;
  padding-top: 12px;

  a {
    text-decoration: none;
    color: $grey-charcoal-dark;
    font-size: 16px;
    line-height: 20px;
    text-decoration: underline;
    font-weight: bold;
  }
}

#mini-cart-root .MiniCart__ExpressCheckout {
  .paypalcheckout {
    width: 100%;
  }

  .spc-paypal a,
  .spc-amazon .amazon-btn,
  .spc-apple-pay button {
    height: 44px !important;
    margin-bottom: 0;
  }
}



//BD-85 changes
.MiniCart__ProductTile .mini-cart-pricing .quantity-wrapper{
  width: 92px;
  border: 1px solid $grey-gravel;
  margin-top: 10.5px;
  margin-bottom: 18.5px;
  margin-inline: auto;
  height: 29px;
  border-radius: 0 !important;
  text-align: center;
  align-items: unset;
  justify-content: space-evenly;

  span,
  button,
  input
  {
    height: 100%;
    width: unset;
    padding: 0 !important;
    text-align: inherit;
  }
  span,
  button
  {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input {
    width: 29px;
    flex: unset; // otherwise inherits from unscoped styles in Wishlist
  }

  button {
    background-color: $white;
    img {
      padding: 0;
      max-height: 50%;
    }
  }

}



/* Chrome, Safari, Edge, Opera */
.MiniCart__ProductTile .mini-cart-pricing .quantity-wrapper input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
.MiniCart__ProductTile .mini-cart-pricing .quantity-wrapper input[type=number] {
  -moz-appearance: textfield;
}

//BD-151 changes
.MiniCart__ProductTile .mini-cart-pricing .bfx-unit-price {
  display: block;
}

//sale price and sale text color change
.price-promotion {
  .price-standard {
    color: $price-strikethrough !important;
    font-size: 14px;
  }
  .price-sales {
    color: $sale-text-price !important;
    display: block;
  }
}

.MiniCart__ProductTile {
  .mini-cart-name {
    a {
      color: $base;
      font-family: $font-name-bold;
      &:hover,
		  &:active,
		  &:focus { 
        color: $product-name-link; 
      }
    }
  }

  .mini-cart-product-details {
    .mini-cart-attributes {
      .attribute {
        span {
          color: $base;
        }
        .sizeAndColorNA, .colorNA, .sizeNA {
          color: $sale-text-price !important;
        }
      }
    }
    .item-sku-number {
      color: $base;
    }
    .mini-cart-action {
      a {
        color: $primarylink;
      }

      .action-divider {
        color: $primarylink;
      }
    }
  }

  .mini-cart-pricing {
    .mini-cart-price-each, .quantity-main-wrapper, .mini-cart-price-subtotal {
      .label {
        color: $base;
      }

      .price-promotion {
        .mini-cart-price {
          color: $base;
        }
      }
    }
  }
}

.spc-modal-overlay.minicart {
  z-index: 200;
  .spc-modal-content{
    font-size: 20px;
    line-height: 26px;
    @include breakpoint-max($mobile){
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 48px;
      line-height: 24px;
      font-size: 14px;
    }
  }
}


.MiniCart__Footer{
  .tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    max-width: 300px;
    min-width: 200px;
    position: absolute;
    left: 50%;
    top: 40px;
    opacity: 1;
    background: #fff;
    font-size: 12px;
    box-shadow: 0px 1px 1px #00000029;
    padding: 8px 5px;
    z-index: 6;
    width: max-content;
    height: auto;
    transform: translate(-50%, -0.5rem);
    margin-left: 10px;
    margin-right: 10px;
    
  }

  .tooltiptext .tooltip-arrow {
    height: 14px;
      width: 14px;
      background-color: #fff;
      position: absolute;
      left: 47%;
      top: 0%;
      box-shadow: -2px -2px 3px #00000029;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}

.spc-error-button {
  background-color: unset;
  top: 34% !important;
}

.MiniCart__Footer {
  .spc-loader-wrapper {
    .spc-drawer-open {
      .spc-drawer-promoCode {
        background-color: #f7f7f7;
      }

      #spc-drawer-promoCode {
        background-color: #f7f7f7;
      }
    }

    .spc-drawer-collapsed {
      .spc-drawer-promoCode {
        background-color: #ffffff;
      }
    }
  }
}

@import "./_progress-bar.scss";

