//Global
$mobile-side-spacing: 15px; //not defaulted since these won't change by brand
$secondary-section-width: 230px;
$borderMixin: 1px solid $mainbordercolor;

//Brand
$mainbrand: $base !default;
$lightbackdrop: $contrast !default;
$backgroundaccent: $contrast !default;

//Header
$subnavtext: $base !default;

// Footer
$social-alignment: right !default;

//Price
$price-regular: $base !default;

//Gallery Search
$plp-product-tile-border-color: none !default;
$search-product-cell-stroke: $borderMixin !default;
$search-form-border: $borderMixin !default;
$search-product-tile-text-align: center !default;
$search-product-tile-text-align-mobile: left !default;
$search-box-shadow: none !default;
$search-h3-line-height: 30px !default;
$navback-arrow-size: 12px !default;
$search-nav-back-line-height: 45px !default;
$refinement-swatch-border: $mainbordercolor !default;

//Refinements
$filter-margins: -67px 0 0 !default;
$refinement-hover: $primarylink !default;
$selected-filter: $base !default;

//PDP
$paging: $secondary !default;
$active-paging: $primary !default;
$delivery-date-color: $callout !default;
$color-swatch-x-color: $primarylink !default;
$pdp-action-color: $primarylink !default;
$product-image-background: $contrast !default;
$icon-size-remember: 20px !default;
$icon-size-chat: 22px !default;
$icon-size-share: 20px !default;
$mobile-bottom-padding: 0 !default;
$hero-image-border: 1px solid $mainbordercolor !default;
$swatch-height: 48px !default;
$swatch-padding-horizontal: 8px !default;
$swatch-padding-vertical: 10px !default;
$select-pdp-border: 1px solid $mainbordercolor !default;

//Reviews
$rating-star-width: 21px;
$rating-star-height: 17px;
$rating-indicator-color: $mainbrand !default;

//Light Gallery
$lg-sub-html-color: $base !default;
$zindex-backdrop:  2400;
$zindex-outer:  2410;
$zindex-item: 2420;
$zindex-progressbar: 2450;
$zindex-controls: 2450;
$zindex-toolbar: 2450;
$zindex-subhtml: 2450;
$zindex-playbutton: 2450;

//Form
$qty-select-border: 1px solid $mainbordercolor !default;

//Cart
$even-row-border: 1px solid $mainbordercolor !default;

$roboto: 'Roboto';

// PLP Variables
$product-image-aspect-ratio: 1 !default;
