$quantity-arrow-color: $primarylink !default;
$cart-header-margin-top: 36px !default;
$quantity-arrow-color: $primarylink !default;
// Some brands need the padding because of background on product tables/drawers
$promo-toggle-padding: 17px 0 !default;
$cart-orders-total-box-border: 1px solid $lightbackdrop !default;
$cart-text-callout: $primarylink !default;
$cart-page-image-padding: null !default;
.tooltip-title {
  margin: 0 0 10px;
}
.pt_cart {
  h1 {
    margin: 35px 0 0;
    line-height: 28px;
  }
  
  .button-text:before {
    content: '< ';
  }
  
  .remembered-cart-box h1 {margin: 0 0 14px;}
  .recommended-products-box,
  .recently-viewed-products {
    
    h1 {margin: 0 0 19px;}
    .product-tile {
      width: auto;
    }
    .product-name {
      margin: 0 0 10px;
      width: auto;
    }
    img {
      max-width: 100%;
    }
  }
  
  .title-hold p{
    margin: 0;
  }
    
  @media (min-width: 760px){
    .amazon-btn {
      clear: right;
      padding:0;
      width: 148px;
    }

    .amazonpopup { display: none; }
  }
 
  .amazonpopup {
    padding: 0;
    float: right;
  }

  #secondary {
    .free-shipping {
      color: $cart-text-callout;
    }
    .shipping-estimate-box {
      margin: 0 0 25px;
      overflow: hidden;
      clear: both;
      h3.shipping-estimate-label {
        background: none;
        margin: 15px 0 0;
        padding: 10px 0 5px;
      }
      h3 {@extend .shipping-estimate-title !optional}
      .form-row {margin: 0;}
      label {width: 90%; text-transform: uppercase; padding: 0 0 0.5em;}
      @media(min-width: 767px) { label { padding: 0; } }
      input[type="text"] {padding: 0.7em 2%; width: 96%; margin: 0 0 10px;}
      button {width: 100%; margin: 0;}
      .shipping-selection {
        background: $lightbackdrop; padding: 1.3em 1.1em 0.5em; margin: 0 0 18px;
        input[type="radio"] {float: left; margin: 2px 5px 0 4px;}
        .hold {
          overflow: hidden; margin: 0 0 10px;
          span {float: left; width:85%;}
        }
      }
    }
  }

  a.amazonpopup {
    text-transform: uppercase; 
    font-size: 12px;
  }
}

.cart-action-checkout {
  margin-bottom: 61px;
}
.pt_cart {
    .cart-action-checkout {
      margin-bottom: 15px;
      width: 100%;
      float: none;
      clear: right;
      fieldset > button {
        width: 100%;
      }
    }
    .paypalcheckout,
    .amazon-btn {
      width: 46%;
      display: inline-block;
    }
    
    .amazon-btn img {
      max-width: 100%;
    }
}
.cart-banner-box {
  
  h3 {
    margin: 0 0 5px;
  }
}

#cart-items-form {
  @media (min-width: 768px){
    margin-top: 15px;
  }
}
.cart-drawer-label.toggle {
  &:not(.expanded) {
    #cart-table.billing-coupon-items {
      display: none;
    }
  }
}
#cart-table, .pt_multireturnexchange .order-shipment-table {
  .item-list tr { border: none; }

  &.billing-coupon-items {
    
    .rowcoupons {
      background-color: transparent !important;
      border: 0;
      .remove-item {
        background-color: transparent;
      }
      &:last-child {
        border-bottom: 0;
      }
      td {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        @media screen and (min-width: 767px) {
          &.coupon-indicator {
            min-width: 100px;
            &, a {
              text-transform: none;
            }
            a {
              font-size: 12px;
            }
          }
          &.coupon-info {
            padding: 15px 0;
          }
          &.item-remove {
            .remove-item {
              display: inline-block;
              float: right;
            }
          }
        }
      }
    }
  }

  .cart-row-coupons {
    border-bottom: 0;
    > td {
      padding: 0;
    }
  }

  th, td {
    box-sizing: border-box;


    @media (min-width: 768px){
      // because of empty .value on sizes
      .item-details > .value {
        &.hidden { 
          display: none;
        }
      }
      .value,
      .value.size,
      .attribute .value,
      .sku .value {display: inline-block;}
      
      &.item-image {
        padding-top: 15px;
        padding-left: $cart-page-image-padding;
        width: 117px;

        img { max-width: 100%; }
      }
      &.item-name {
        width: 220px;
      }
      &.item-details {
        width: 107px;
      }
      &.item-price {
        width: 73px;
      }
      &.item-quantity {
        width: 70px;
      }
      &.item-total {
        width: 173px;
      }
      &.item-remove,
      &.header-total-price {
        padding-right: 0;
      }
      
      &.item-remove button {
        line-height: normal;
      }
    }
  }
  
  .attribute-label { text-transform: capitalize; }
  
  .cart-coupon-row {
    border-top: $borderMixin;
    > td {
      padding: 15px 0;
      .toggle {
        padding: 0;
      }
      .cart-coupon-inner-box {
        padding: 5px 0 0;
      }
    }
  }

  .rowcoupons {
    @extend .clearfix;
    padding: 0;
    border: 0;
    .label {
      display: inline-block;
      width: 128px;
    }
    td {
      padding: 10px 15px 10px 0;
      .value:first-of-type {
        font-weight: 700;
      }
      
      div, span {
        margin: 0;
      }
    }

    &.gwp-rowcoupons td.item-details {
      padding-bottom: 8px;
    }
    .coupon-tooltip {
      display: block;
      @media screen and (max-width: 767px) {
        padding-left: 1em;
      }
      .coupon-not-applied {
        & + span {
          margin-left: 5px;
        }
        &:before {
          content: '';
          display: inline-block;
          $icon-tooltip: (0, 0, 0, 0, 0, 0, 0, 0, '', 'default-icon', ) !default;
          @include sprite($icon-tooltip);
          position: relative;
          top: 2px;
        }
        &:hover {
          &:before {
            $icon-tooltip-hover: (0, 0, 0, 0, 0, 0, 0, 0, '', 'default-icon', ) !default;
            @include sprite($icon-tooltip-hover);
          }
        }
      }
    }
    .coupon-indicator {
      span:before {
        font-weight: normal;
      }
    }
    .coupon-indicator {
      h3 {
        display: inline;
        &.coupon-applied:before {
          content: '';
          margin-right: 6px;
          background-image: url('../images/icon-coupon-applied.png');
          background-size: contain;
          display: inline-block;
          // $icon-coupon-applied: (0, 0, 0, 0, 0, 0, 0, 0, '', 'default-icon', ) !default;
          // @include sprite($icon-coupon-applied);
          height: 12px;
          width: 15px;
        }
     }
    }
    @media screen and (min-width: 767px) {
      .coupon-indicator {
        padding-left: $cart-page-image-padding;
        span {
          font-weight: bold;
        }
      }
      .coupon-info {
        > span {
          display: inline;
        }
      }
      .coupon-subtotal {
        color: $cart-text-callout;
      }
    }
  }

  .pt_checkout & {
    .item-image img {
      min-width: 90px;
    }
    div.item-edit-details a {
      font-size: 12px;
    }
    .item-remove button {
      background-color: transparent;
    }
  }
}

.revieworder-cart-summary {
  #cart-table td.item-image img {
    max-width: inherit;
  }
}

.promo-codes {
  padding: 0 19px 0 19px;
  label {
    padding: 23px 28px 22px 0;
  }
  input {
    box-sizing: border-box;
    width: 166px;
    margin-bottom: 22px;
  }
  button {
    box-sizing: border-box;
    height: 33px;
    line-height: 33px;
    padding:0 4px;
    width:59px;
  }
}

.amazonheader,
.paypalcheckout .divider {
  display: none;
}

.remembered-item-box {
  margin-bottom: 60px;
  
  .pt_remembereditems & {
    margin-bottom: 0;
  }
  
  td {
    box-sizing: border-box;
  }
  
  .item-image {
    width: 119px;
  }
  
  .item-name {
    width: 278px;
  }
  
  .item-details {
    width: 165px;
  }
  
  .item-price {
    width: 96px;
  }
}

.pt_cart {
  .cart-footer .www-recommendations-placeholder {
    h1 {margin-bottom: 14px;}
    
    #horizontal-carousel, & {
      ul {
        display: table;
        margin: 0;
        padding: 0;
      }
      
      li {
        box-sizing: border-box;
        display: table-cell;
        float: none !important; // inlined from flexslider
        margin: 0;
        padding: 0;
        width: 25%;
      }
    }
    
    #horizontal-carousel:not(.active-carousel) {
      display: block;
      max-width: 100%;
      li {
        float: left !important;
      }
    }
  }
  
  .recently-viewed-products {
    h1 {margin-bottom: 14px;}

    .jcarousel-container {
      margin: 0 -20px;
    }

    #horizontal-carousel li,
    #horizontal-carousel li .product-tile {
      width: 160px;
      display: block;
      float: left;
      margin: 0;
    }
  }

}

.error-message{
  color: $deep-red;
}

.error{
  color: $cart-text-callout;
}

.cart-footer .product-tile .product-image {
  height: auto;
  margin-bottom: 40px;
}

.recommended-products-box,
.recently-viewed-products-container {
  li {box-sizing: border-box;}
}

.cart-IDme {
  .toggle {
    color: inherit;
    &:before {
      content: '';
      display: inline-block;
      background-image: url('../images/id-me-logo.png');
      background-size: cover;
      width: 40px;
      height: 15px;
      margin-right: 7px;
    }
  }
}
.cart-coupon-code,
.cart-IDme  {
  @media (min-width: 765px){
    padding-left: $cart-page-image-padding;
  }
}
.cart-coupon-code label,
.cart-IDme .toggle {
  text-transform: uppercase;
  font-weight: bold;
}
.cart-coupon-code, .school-code, .cart-IDme {
  box-sizing: border-box;
  display: block;
  position: relative;
  width: 100%;
  @extend %cart-coupon-box !optional;

  &.fr {
    input[type="text"] {
      width: 52%;
    }
  }

  &.de input[type="text"] {
    width: 45%;
  }

  .error,
  .message {
    margin: 5px 0 0;
    clear: left;
    padding: 10px 0 0;
  }
  
  .toggle {
    color: inherit;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 15px;
    padding: $promo-toggle-padding;
    width: 100%;
    @extend %cart-coupon-toggle !optional;
    
    @media (min-width: 760px){  
      &:after {
        // $icon-plus: (0, 0, 0, 0, 0, 0, 0, 0, '', 'default-icon', ) !default;
  		  // @include sprite($icon-plus);
        content: "";
        background: url(../images/interface/icon-plus.png) no-repeat scroll center center;
        float: right;
        height: 15px;
        width: 14px;
        margin-right: 19px;
        visibility: visible;
      }
      
      &.expanded:after {
        // $icon-minus: (0, 0, 0, 0, 0, 0, 0, 0, '', 'default-icon', ) !default;
  		  // @include sprite($icon-minus);
        background: url(../images/interface/icon-minus.png) no-repeat scroll center center;
      }
    }
  }

  .cart-coupon-inner-box, .school-code-inner-box, .cart-IDme-inner-box {
  	overflow: hidden;
    position: relative;
    padding-bottom: 18px;
    @extend %cart-coupon-drawer !optional;
    
    input[type="text"] { 
      width: 219px;
      padding: 0.6em 0.5em;
      height: 33px;
      line-height: 33px;
      box-sizing: border-box;
    }
    @media screen and (min-width: 767px) {
      button {
        margin: 0;
        padding: 0 10px;
        height: 33px;
        font-size: 12px;
        line-height: 33px;
      }
    }

  .schoollookup-thanks-box {
    height: 0px;
    overflow:hidden;

    &.expanded { height: 105px; }

    h3 {
      @extend .heading4 !optional;
      margin: 0;
    }

  }

}

  .cart-IDme-inner-box {
    .content-asset {
      font-size: 12px;
      line-height: 17px;
      overflow: hidden;
    }
  }
  
  
  [class*="IDmeButton"] {
    clear: left;
    float: left;
    
    @media screen and (min-width: 767px){
      clear: none;
      height: 30px;
      padding: 4px 8px 4px 0;
      
      p { display: none; }
      a {
        display: block;
        height: 100%;
      }
      img {
        max-height: 100%;
        display: block;
      }
    }
    
    p { margin: 0; }
    
    @media screen and (max-width: 768px){
    	.login-trigger {
		    margin-top: 5px;
			display: block;
    	}
    }
  }

  .school-code-inner-box {
    height: 67px;
    overflow: hidden;
  }

}

.need-help p {
  @extend %need-help-text !optional; 
}

#cart-sidebar-style-wrapper {
  clear: both;
}

// Pulled from style.v2.scss
// ========================


// shopping cart
// -------------------------------------

.ie8 .pt_cart .item-list td { padding: 36px 2px;}
.pt_cart .item-list td {
  @media (min-device-width: 767px) {
    &.item-image {padding-top: 15px;}
  } 
  height:auto; @extend .cart-table-text !optional;
}
.pt_cart .item-name .name {min-width: 125px; margin: 0 0 3px;}
.pt_cart .item-name .name a {@extend .cart-product-name !optional}
.pt_cart #remembered-items-container .item-name .edit a {float: left;}
.pt_cart .item-list .rowcoupons td.item-remove{padding: 0.6em 0.6em 0 0.6em;}
.pt_cart .item-list td.item-image { @media (min-device-width: 767px) { padding-right: 15px; } }

.pt_cart .recently-viewed-products-container { margin-top: 60px; }

.remove-remember-item,
.remove-remember-item:hover {
	text-indent: -9999px;
  margin: 0;
  $icon-remove: (0, 0, 0, 0, 0, 0, 0, 0, '', 'default-icon', ) !default;
  @include sprite($icon-remove);
  background-color: transparent;
  padding: 0;
  display: block;
  border: 0;
}

.pt_cart .update-item {margin: 6px 0 0;}
.pt_cart .cart-empty{
  min-height:100px;
  margin-top: 40px;
}

.remembered-cart-box {margin: 0 0 60px;}
.remembered-cart-box h2 {text-transform: uppercase; font-weight: normal; font-size:2.462em; margin: 0 0 0.1em;}
#remembered-table .item-remove {min-width: 200px; text-align: right;}

.cart-footer.recommended-products-box .product-remember {right: 25px; top: 199px;}

#remembered-items-container .spinner-container  {
	position: relative;

	.loader-bg {
		background-color: transparent;
		height: 60px;
	}
}

.ie8 #cart-table { max-width: 678px }
#cart-table a:hover, #remembered-items-container a:hover {color: $cart-link-hover;}
#cart-table .item-quantity, .pt_multireturnexchange .order-shipment-table .item-quantity {
  input, .order-quantity-wrapper {
    margin:0;
    text-indent:0;
    width: 42px;
    height: 32px;
    padding:0;
    line-height: 33px;
    background: $qty-form-background;
    border: 1px solid $grey-silver; text-align: center;
    
    // this selector is to avoid the need to counter these styles on mobile
    // which uses type="text" for quantity
    &[type="number"] {
      box-sizing: border-box;
      padding-right: 15px;
      -moz-appearance: textfield; 
      
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
  
  .quantity-arrows {
    position: absolute;
    left: 27px;
    top: 0;
    bottom: 0;
    width: 10px;
    margin-bottom: 0;
  }
  
  .quantity-up,
  .quantity-down {
    border: 5px solid transparent;
    cursor: pointer;
    margin-bottom: 0;
    position: absolute;
  }
  
  .quantity-up {
    border-bottom-color: $quantity-arrow-color;
    top: 2px;
  }
  .quantity-down {
    border-top-color: $quantity-arrow-color;
    bottom: 2px;
    
    &.disabled {
      border-top-color: $grey-silver; // subject to change
      cursor: not-allowed;
    } 
  }
}

.quantity-wrapper {
  position: relative;
  border:1px solid $grey-silver;
  width: 135px;
  height: 45px;
  display: flex;
  align-items: center;
}

.price-promotion .price-standard {
  text-decoration:line-through;
  #cart-table & {
    @media screen and (min-width: 767px) {
      margin: 0;
    }
  }
}

#cart-table {
  .item-quantity {
    width:60px;

    button.button-text {
      font-size: 12px;
      font-family: $font-type3;
      color: $primarylink;
      text-transform: capitalize;
      background: none;
      padding: 0;
      &:hover { color: $primarylink }
    }
  }

  .item-user-actions {
    width:100px;

    a {
      display:block;
      font-size:0.9em;
    }

    button {
      display:block;
      font-size:0.9em;
    }
  }

  .item-total {
    text-align:left;
    word-wrap:break-word;
  }
  
  .scarcity-icon {
    @include scarcity-icon;
  }

  .promo-expiration-icon {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    background-image: url('../images/cartcheckout/icon-expiration.svg');
    background-repeat: no-repeat;
  }

  .scarcity-messaging,
  .promo-expiration-messaging {
    @include body-small();
    margin: 3px 0;
    color: $cart-text-callout;
    line-height: 1;
        
    @include breakpoint($desktop) {
      margin: 7px 0;
    }
  }

  .price-unadjusted {
    color:$mainbrand;
    display:block;
    margin:0 0 5px;

    span {
      text-decoration:line-through;
    }
  }

  .price-adjusted-total {
    font-weight: bold;
    display: block;
  }

  .gift-cert-delivery { color:#990000; }
}

.remembered-item-box .item-list td.item-price span, .pt_cart .item-list td.item-price span {display: block; min-width: 100px;}
.cart-promo {
	margin: 0.3em 0;
	padding: 1em;

	.bonus-item-actions {
		text-align: right;
		vertical-align: middle;
	}

	&.cart-promo-approaching {
		padding: 0 0 1em;
  }
}

.remembered-cart-box h1.toggle.caps {
	margin-top: 0px;
}

.rewards-select-box {
	position : relative;

	.rewards-inner-box {
		position: absolute;
		z-index: 5;
	}
}


.cart-footer {
	clear: both;
	margin: 0;
  @media (min-width: 767px) {
    .cart-coupon-code,
    .cart-IDme {
      border-top: $even-row-border;
      border-bottom: $even-row-border;
    }
  }
	&.recommended-products-box {
		margin-top: 0px;
	}

	.tooltip.tooltip-icon {
		margin-top: 9.5px;
		position: static;
		vertical-align: top;
		float: right;
		margin-left: 9px;
	}
}

.pt_checkout{
	.schoollookup-thanks-box {
		height: 0px;
		overflow:hidden;

		&.expanded {
			height : 105px;
		}

		h3 {
			@extend .heading4 !optional;
			margin : 0;
		}
	}
	.school-code {
		width:50%;
		margin: 0 0 30px 0;
		.school-code-label {
			margin:0;
			width: 95%;
		}
	}
	.school-code-inner-box.toggle-content {
	  overflow: auto;
	  padding: 15px;
	}
	.primary-content .school-code input[type="text"] {width: 66%}
	.add-school-code-button {
		padding: 11px 9px 9px;
		float: right;
	}
	.schoollookup-input-box .error {padding: 10px 0 0;}

	.IDmeButtonMil,
	.IDmeButtonStu,
    .IDmeButtonTea,
    .IDmeButtonRes,
    .IDmeButtonGov,
    .IDmeButtonNurse,
    .IDmeButtonMedical,
    .IDmeButtonEmployee {
		a {
		  height: 48px;
		  line-height: 0px;
		  width: 183px;

		  img {
		    height: 48px;
		  }
		}
	}
}

.cart-coupon-code input[type=text],
.rewards-select-box input,
.cart-footer .school-code input[type=text] {
	float: left;
	text-transform:none;
}

.rewards-select-box input {
	&[type=checkbox],
	&[type=radio] {
		width: 15px;
	}
}


.rewards-select-box label.toggle {
	margin : 0;
}

.cart-coupon-code button,
.rewards-select-box button,
.add-school-code-button
 {
	display: block;
	float: left;
}

.rewards-select-box .rewards-inner-box {
	@extend .cart-coupon-box !optional;
	margin-left: 0;
	background-color: white;
}

.cart-order-totals{
	float: right;
	width: 40%;
	margin-top: 26px;

	button {
		float: right;
	}
}

.rewards-container {
background: $lightbackdrop;
margin: 0px 37px 4px 0;
width: 100%;
	.rewards-column {
		a.button { float: left; margin-bottom: 10px;}

	}
}

.order-totals-table th{display:none;}

.order-totals-item{width:60%;}
.order-totals-value{width:40%;}
.order-subtotal{font-weight:700;}
#secondary .order-totals-table .order-shipping a,
#secondary.summary .order-totals-table .order-shipping a.tooltip {display:none;}

.cart-deliverby-banner{
  overflow-x:hidden;
  
  > *:last-child { // only create 30px space if delivery has elements
    margin-bottom:30px;
  }
}

.cart-action-checkout{float:right;}
.cart-actions {overflow: hidden; margin: 0 0 40px;}
.cart-actions .cart-action-checkout {width: 65%;}
.cart-actions {
  button, .button {
    float: right;
    width: 190px;
  }
  .cart-action-continue-shopping button {float: left;}
  
  .express-user-login,
  .express-user.button-text {
    background: none;
    margin: 9px 0 0 0;
    height: auto;
    line-height: normal;
    padding: 0;
  }
}

.cart-actions .paypalcheckout {clear: both; float: right;}
.paypalcheckout .divider { background-image: url('../images/checkout_paypal_bg.png'); background-repeat: no-repeat; height: 30px; width: 162px; display:table;margin-top:10px;  }
.cart-actions .paypalcheckout .divider .divider-text {display:table-cell; vertical-align : middle; text-align: center; font-size: 0.823em; font-weight:bold;text-transform:uppercase;}
.cart-actions .paypalcheckout button {background: none; padding: 0;}
.cart-actions div#update-cart {float: right; margin-right: 2%; cursor: pointer;}

.ie8 button.express-user-login.button-text, .ie7 button.express-user-login.button-text { @extend .express-login !optional; }
.cart-actions button.express-user.button-text, .cart-actions button.express-user-login.button-text, button.express-user-login.button-text { @extend .express-login !optional; }

// .promo-adjustment{color:$promo-adjustment-color;}
ul.product-availability-list{margin:0;padding:0;}
ul.product-availability-list li{list-style:none outside none;}
ul.product-availability-list .on-order,ul.product-availability-list .not-available{
  color:$cart-text-callout;font-size:0.9em;
}
.account-welcome .welcome-message{font-family:Georgia,serif;font-size:1.2em;font-style:italic;}
.account-welcome .not-user{margin:10px 0;}

#insertemployeeid {display:none; padding-bottom:5%;}
#insertemployeeid h2 { padding-bottom: 0; margin-bottom: -10px; margin-top: -5px; font-size: 1.5em; }
#insertemployeeid form{padding-top:3%;}
#insertemployeeid .error {display:block;clear:both; margin-left:0;}
#insertemployeeid input[type="text"], #insertemployeeid label{width:90%; margin-left:0;}

//cart right column
#secondary .print-box {
  display: none; // WWW wanted print box hidden for all brands moving to core-v2
  margin: 0 0 56px; text-align: right;
}
.print-icon {background:url("../images/icon-text.png") no-repeat 0 0 transparent; height: 16px; padding: 2px 0 0 18px; display: inline-block;}


.pt_cart #secondary {
  margin: $cart-header-margin-top 0 0;
}
.cart-banner-box {background: $helper-background; margin: 0 0 40px;}

.cart-banner-box p {margin: 0 0 1.2em 0;}
.cart-banner-box .action-hold ul li {text-align: center;}

ul.payment-list .options .button-text {
	background: none;
	color: $primarylink;
	padding: 0;
	@extend .primary-links !optional;
}

.cart-banner-middle > * { margin: 0 auto; }
.cart-banner-shipping {margin: 0 0 10px;}

@media screen and (min-width: 767px) {
  .cart-banner-security {
    float: right;
    margin-bottom: 10px;
  }
}

.rewards-select-box label.toggle,
.shipping-estimate-box h3.shipping-estimate-label {
  @extend .promo-code-box !optional;
  cursor: pointer;
}

.cart-footer .www-recommendations {padding:13px 0;}
.cart-footer .www-recommendations li { padding: 0 7px 0 0; width: 23%; float: left; list-style:none; margin-right: 5px;}

//cart orders
.cart-orders-total-box {
  @media screen and (min-width: 767px) {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  h3 {
    margin: 10px 0;
    @media screen and (min-width: 767px) {
      margin-bottom: 8px;
      padding-bottom: 5px;
      border-bottom: $cart-orders-total-box-border;
    }
  }
  
  tr {
      margin-top: 5px;
      display: block;
    
    &:first-child { margin-top: 0;}
    
    &.order-total {
      margin-top: 20px;
      @media screen and (min-width: 767px) {
        padding-top: 8px;
        margin-top: 8px;
        border-top: $cart-orders-total-box-border;
      }
    }
  }
  @extend .cart-orders-total-box !optional;
  h3 {@extend .cart-orders-total-title !optional}
  .order-subtotal {font-weight: normal;}
  .order-totals-table {
    td {
      padding: 0; 
      margin-bottom: 10px; 
      @extend .default-small-text !optional; 
      min-width: 70px;
      width: 100%;
    }
    .order-total td {
      @extend .bold !optional
    }
    .order-savings {
      line-height: 1;
      &-amount {
        color: $cart-text-callout;
      }
    }
    a.order-promotions {
      #secondary & {
        font-size: 10px;
        display: block;
      }
    }
    tr {
      td:last-child {
        text-align: right;
        width: 30%;
      }
      &.order-shipping {
        td .order-shipping-delivery {
          display: inline-block;
        }
      }
    }
  }
}
.coupon-list {
  padding: 0;
  list-style-type: none;
  .coupon-total {
    color: $cart-text-callout;
    float: right;
  }
  @media screen and (max-width: 767px) {
    li {
      display: flex;
      margin-bottom: 5px;
      .coupon-total {
        white-space: nowrap;
        margin-left: auto;
      }
    }
  }
}
.no-margin {
  margin: 0;
}
//end cart right column

@media (min-width: 756px) {
	.pt_cart #secondary {
    .amazon-btn{
      margin-right: 0;
    }
    .amazon-btn-wrapper {
      display: block;
    }
    .amazon-button{
      height: 28px;
  		width: 95px;
  		border: 1px solid $grey-silver;
  		position: relative;
      background-image: linear-gradient(to left, #f5f6f7 0%, #fbfdfd 30%, #eef1f1 100%);
      
      img{
        max-width: inherit;
        cursor: pointer;
        max-width: 100%;
    	}
    }
  }
  .paypalexpress a {
    display: block;
    height: 33px;
    overflow: hidden;
    border: 1px solid $grey-silver;
    position: relative;
    background-image: linear-gradient(to left, #f5f6f7 0%, #fbfdfd 30%, #eef1f1 100%);
      img {
        max-width: 100%;
      }
  }
}

.checkout-options-footer { display: none; }

.promo-detail-modal {
  max-width: unset;
  @media screen and (min-width: 767px) {
    max-width: 500px
  }
}

.shipping-method-v2 .col-02 {
   position: relative;
   display: inline-block;
   .tooltip {
    top: 0;
    right: -20px;
  }
}

.order-sales-tax .tooltip { margin-left: 5px; }

//BD-85 changes 
.quantity-wrapper .quantity-minus,.quantity-wrapper .quantity-plus{
  height:45px;
  width: 45px;
  background-color: $white;
  padding: 0px;
}
.quantity-wrapper .quantity-plus img{
  padding:1.5px 0px;
}
.quantity-wrapper .quantity-minus img{
  padding:8px 0px;
}
.item-quantity .quantity-wrapper .quantity-number{
  border:none !important;
  padding-right:0px !important;
  font-size: 16px !important;
}

#cart-items-form #cart-table .cart-row .item-total,#cart-items-form #cart-table .cart-row .item-price{
	padding-top:25px !important;
}