// base elements
// -------------------------------------

@import "mixins.scss";
@import "icon-vars";


// Responsive media query mixins
// Variable sizes pulled from flexboxgrid
$adaptive-mobile: 321px;
$tablet: 769px;
$desktop: 937px;
$large-desktop: 1025px;
$widescreen: 1281px;
@mixin breakpoint($device) {
    @media screen and (min-width: $device), print {
        @content;
    }
}
@mixin breakpoint-max($device) {
  @media screen and (max-width: ($device - 1px)), print {
      @content;
  }
}


.mobile-only {
  @include breakpoint($tablet) {
    display: none !important;
  }
}

.desktop-only {
  @include breakpoint-max($tablet) {
    display: none !important;
  }
}


body{background:none repeat scroll 0 0 ; @extend .body !optional;}
a { text-decoration: none; }
a { @extend .primary-links !optional }
fieldset{border:0 none;margin:0;padding:0;}
table{width:100%;}
th{text-align:left;}
td{padding:1em;vertical-align:top;}
 .print-only {display:none;}
 .hide { display: none; }
 .invisible { visibility: hidden; }

// Typography
p{ @extend .default-p-text !optional}
h1{@extend .heading !optional}
h2{@extend .heading2 !optional}
h3{@extend .heading3 !optional}
h4 {@extend .heading4 !optional}
h5{@extend .bold !optional; @extend .heading5 !optional}
body:not(.canFocus) {
  *:focus {
    outline: none;
  }
}
// make sure the live chat header color from SFSC doesn't get overridden
h2[embeddedService-chatHeader_chatHeader] {
  color: inherit;
}

// helper classes
// -------------------------------------
.block {
  display: block;
}
.white-space {
	&--no-wrap {
		white-space: nowrap !important;
	}
}
.align-baseline {
	align-items: baseline !important;
}
.clear{clear:both;}
.clearfix {
	&:after {
		clear: both;
		content: " ";
		display: block;
		font-size: 0;
		height: 0;
		visibility: hidden;
	}
}
.inner {
	@extend .clearfix !optional;
}
.clearfix-before {
	&:before {
		clear: both;
		content: " ";
		display: block;
		font-size: 0;
		height: 0.1px;
	}
}
.visually-hidden{
    border:0 none !important;
    height:1px !important;
    margin:-1px !important;
    overflow:hidden !important;
    padding:0 !important;
    position:absolute !important;
    width:1px !important;
}
@mixin visually-hidden {
  border:0 none;
  height:1px;
  margin:-1px;
  overflow:hidden;
  padding:0;
  position:absolute;
  width:1px;
}
.skip-nav {
    position: absolute;
    top: -100%;
    left: 5px;
    z-index: 1002;
    &:focus {
        top: 5px;
    }
}
.ie7 .visually-hidden{width:1px !important;}
ul.menu{float:left;margin:0;padding:0;}
ul.menu li{float:left;list-style:none outside none !important;}
.hideme {display: none !important; }
.right {float:right;}
.left {float:left;}
.horizontal-stack { overflow:hidden;width:100%;}
.horizontal-stack .stackme { float:left; }

.body-callout {
	color: $callout !important;
}
.body-secondary {
	color: $secondary !important;
}
.text-center {
	text-align: center;
}
.width-100 {
	width: 100%;
}
.spacer-bottom {
	margin-bottom: 15px;
}

// page layouts
// -------------------------------------
#main {background:none;}
#wrapper{background: $mainbackground; margin:auto;width: 100%; max-width: 100%; border: 0; overflow: hidden;}
//BD-94 changes
.sticky-div{position: fixed;}
.mainUnregistered{
  max-width: 100% !important; 
  padding:0;
  .primary-content{
    float: left;
  }
  #secondary{float:right;}
}
.inner, #main, .home-container-box {max-width: 920px; margin: 0 auto; position: relative; clear:both;}

.pt_storefront #main {max-width: 100%; padding:0;}
header {position:relative;margin-left:10px;margin-right:10px;}
.primary-content{float:right;position:relative; width: 75%; box-sizing: border-box;}
.primary-content.horizontally {border-left:none; width: 100%; float: none}
.pdp-main .image-callout-badges { position: absolute; top: 16px; right: 16px; text-align: right; z-index: 9; pointer-events: none;}
.product-col-1 .callout { @extend .product-callout-banner !optional; }
.callout-container { height: 31px; right: -8px; top: 30px;  padding: 0 10px;}
#secondary{float:left;max-width:230px; width: 25%;}
#secondary nav {padding-right:10px;}
#secondary nav a {margin-left:0; @extend .sub-nav-links !optional;}
.primary-focus .primary-content{border:0 none;float:left;margin:0; width:682px;}
.primary-focus #secondary{background:none repeat scroll 0 0 transparent;border:0 none;float:right;width:206px;}
.full-width .primary-content{border:0 none;width:100%; padding: 0 0 20px 0;}
.page-content .primary-content{padding-left:3%;width:71.7%; border:none;}
.js .toggle-content{display:none}
#mobile-pricing { display: none; }

// jQuery Ui
// -------------------------------------

//Quickview styles
.full-details-link {text-align: right; width: 871px; margin-top: 8px; position: absolute;z-index: 999;}
//quickview styles
.ui-dialog h1,.ui-dialog h2{margin:5px 0 5px;}
.ui-dialog h2.norightmargin{margin-right:0 !important;}
.ui-dialog .dialog-required{display:block;text-align:right;}
.ui-accordion .ui-accordion-content{padding:0;}
.ui-tabs {clear:left;}
.ui-tabs .tabs-menu li {white-space:normal !important;} //REF height:41px; text-align:center; width:100px;display: table-cell; vertical-align: middle}
// .ui-tabs .tabs-menu li a { width: 80px; text-align: center; height:27px;}
.product-tabs-vertical .tabs-menu li a {width: 89%;text-align: center;height: 27px; color:$mainbrand; padding-top: 13px; height: 25px;}
.ui-tabs .ui-tabs-nav{background:none repeat scroll 0 0 transparent;border:0 none;padding:0;}
.ui-tabs .ui-tabs-nav li {list-style: none outside none !important; @extend .hr-product-details-tab-default !optional}
.product-tabs-horizontal.ui-tabs .ui-tabs-nav li.ui-state-active { @extend .hr-tab-active !optional }
//REF .product-tabs-horizontal.ui-tabs .ui-tabs-nav li a{color:$mainbrand;padding: .7em 1em;}
.ui-tabs .ui-tabs-panel { background: none repeat scroll 0 0 $mainbackground; border-color: $mainbordercolor rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0); border-radius: 0 0 0 0; border-style: solid; border-width: 1px; box-shadow: none; margin: 0; padding: 20px 10px; overflow:hidden} //REF color: $grey-charcoal; font-size: 0.9em; line-height: 1.5em;   }

.ui-dialog .ui-dialog-content {
	padding: 0.2em 1.3em;
	margin-top: 1px;
}

// form components
// -------------------------------------
input[type="text"]:disabled,input[type="password"]:disabled, input[type="email"]:disabled, input[type="tel"]:disabled, textarea:disabled, select:disabled { color: $disabled-input-color !important; }

.button.postcodefinderanywhere {padding: 9px 17px;margin-top: -3px;}


#RegistrationForm > fieldset:first-child > legend
{@extend .shipping-form-header !optional}



.js button.simple-submit{display:none;}
legend { @extend .legend-text  !optional}
.ie7 legend{width:94%;}
legend span {@extend .requirement-text !optional}
legend span em { @extend .italic !optional; }
// label,.form-label-text{display:block;font-size:1em;padding:0.5em 0 0.5em;width:22%;}
.form-label-text span{margin-right:5px;}
.form-row{
  clear:both;
  margin:0 0 14px;

  &:last-child {
    margin-bottom: 0;
  }
}
.form-row-button{width:62%;margin:2% 0;}
.form-row-inline {float:left; width: 67%;}
.form-row-input{float:left; margin: 25px 0 0 10px}

select{text-indent:0;height:30px;border:1px solid $secondarybackground;padding:3px;width:49%;}
.search-result-options select {height:auto;}
.left-margin{ margin-left:2em !important;}
.label-inline label{display:inline-block;float:none;text-align:left;width:90%; }
#RegistrationForm span.email-opt-in, label[for="dwfrm_profile_customer_gender0"], label[for="dwfrm_profile_customer_gender1"] { @extend .inline-label-text !optional; }
#checkout-shipping-form .label-inline.delivery-style label { padding: 0; }
.confirmation-login-modal #RegistrationForm span.email-opt-in { @include body(); font-size: 10px; line-height: 16px; display: flow-root; font-weight: normal; text-transform: none;}
.confirmation-login-modal #RegistrationForm span.email-opt-in a {font-size: 10px;}
#RegistrationForm span.email-opt-in { padding: 0;}
.defaultaddressform .label-inline input{float: left;}
.defaultaddressform #edit-address-form label{ width:80%;}
.label-above label{float:none;text-align:left;width:auto;}
.label-above input{margin-left:0;}
.label-above select{margin-left:0;}
.label-above textarea{margin-left:0;}
.label-above .form-caption{margin-left:0;width:auto;}
.label-removed label{left:-999em;position:absolute;}
.label-hidden label{text-align:left;text-indent:-999em;}
.form-indent{margin-left:0;}
.form-indent .form-caption{margin-left:0;}
.form-caption{display:block;margin:0 0 22px 0;width:auto;}
#email-error-msg {width:100%; white-space:nowrap;}

.form-additional-text {color:$mainbrand;}
.field-description {color:$mainbrand; font-size: small;}
.error{background-color:$message-background;border-color: $callout;color: $callout;}
div.shipping-price-override-form{display:none;}
input.error{background-color:$message-background;border-color: $callout;color: $callout;}
label.error{background:transparent;border: 0 none; color: $callout;}
label .form-caption{display:inline;}
span.error{background:none repeat scroll 0 0 transparent;display:block;margin:1% 2% 2% 24%;}
div.error{background:none repeat scroll 0 0 transparent;}


.errormessage {
  background:url("../images/icon-message-error.png") no-repeat scroll left top transparent;
  color: $deep-red;
  min-height:1.8em;
  margin:1em 0;
  padding-left: 25px;
  outline: 0;
}
.successmessage{background:url("../images/icon-message-success.png") no-repeat scroll left top transparent;color:$callout;min-height:1.5em;margin:1em 0;padding-left: 25px;}
.infomessage{background:url("../images/icon-message-info.png") no-repeat scroll left top transparent;color:$callout;min-height:1.5em;margin:1em 0;padding-left: 25px;}
.login-box-content .errormessage { margin: 4px 0 0 0; line-height: 14px; font-size: 12px; }

.error-form{background:url("../images/interface/icon-alert.png") no-repeat scroll left top transparent;color:$callout;margin:1em 0;padding:0.5em 0 0 4em;}
.form-inline form{background:url("../images/interface/icon-double-line-header.png") repeat-x scroll left bottom transparent;padding:0 0 2em;width:98%;}
.form-inline .form-row{clear:none;float:left;margin:5px 2% 0 0;width:28%;}
.form-inline label{padding:0 0 0.75em;text-transform:uppercase;width:auto;}
.form-inline input[type="text"]{margin-left:0;width:95%;}
.form-inline select{margin-left:0;width:95%;}
.form-inline .form-row-button{float:left;margin:30px 0 0;width:10%;}
.form-inline button{padding-left:0;padding-right:0;width:100%;}
.login-box-content label{float:none;text-align:left;width:auto;}//font-size:1.1em
.login-box-content .label-inline{width:auto; overflow:hidden;}
.login-box-content .label-inline label{ padding: 0; margin: 0; line-height: normal; }
.login-box-content .label-inline input{ float:left;}
.login-box-content input[type="text"], .login-box-content input[type="password"], .login-box-content input[type="email"]{margin-left:0;width:94%;}
.login-box-content textarea{margin-left:0;width:87%;}
.login-box-content .form-caption{margin-left:0;}
.login-box-content input[type="checkbox"]{margin-left:0;}
.login-box-content select{margin-left:0;}
.login-box-content .form-row-button{margin-left:0;width:auto; bottom: 10px;}
.login-box-content .psw-reset-spacer{min-height:30px; }
.login-account .login-box-content #dwfrm_prodeallogin .label-inline label { float: none; }
.row-inline{display:inline;}
.row-inline label{width:auto;}
.form-field-tooltip{float:right;margin:-36px 0 0;width:21%;}
.with-helper-text{margin-top:-59px;}
.char-count{display:block;margin-left:24%;}
.text-right{text-align:right;}
.text-right button{margin-right:0;}

#dwfrm_login_rememberme { padding: 0; margin: 2px 6px 0 0; }


// tables used for product lists (wishlist, gift reg)
// -------------------------------------

table.item-list tr{border-bottom:1px solid $mainbordercolor;}
table.item-list tr.empty-remembered-list { border: none; border-top: 1px solid $mainbordercolor;  }
table.item-list tr.remembered-row { border-top: 1px solid $mainbordercolor; }
table.item-list tr.last{border:0 none;}
table.item-list tr.headings{display:none;}
table.item-list td{padding:1em;vertical-align:top;}
th.section-header{padding: $table-header-padding;}
td.item-details .product-list-item .name{font-family:Georgia,serif;font-size:1.167em;font-style:normal; color: $mainbrand;}
td.item-details .product-list-item .value{font-weight:700;}
td.item-details .product-list-item .promo{color:$price-promo;}
td.item-details .product-list-item .price-standard{color:$price-list;text-decoration:line-through;}
td.item-availability ul{list-style:none outside none;margin:0;padding:0;}
td.item-quantity-details ul{list-style:none outside none;margin:0;padding:0;}
td.item-dashboard{color:$grey-silver-dark;width:250px;}
td.item-dashboard .label{display:inline;float:none;font-size:1em;font-weight:400;padding:0;width:auto !important;}
td.item-dashboard label{display:inline;float:none;font-size:1em;font-weight:400;padding:0;width:auto !important;}
td.item-dashboard .input-text{margin:0;text-align:center;}
td.item-dashboard .input-select{margin:0;padding:8px;width:auto !important;}
td.item-dashboard .input-checkbox{margin:0 5px;}
td.item-dashboard .item-option{clear:both;font-size:1em;margin:0 0 0.5em;}
td.item-dashboard .item-option .form-row{display:inline;}
td.item-dashboard .option-date-added .value{color:$grey-charcoal-dark;font-weight:700;}
td.item-dashboard .option-quantity-desired input{padding:0;width:20px;}
td.item-dashboard .option-priority .label{color:$grey-charcoal-dark;font-weight:700;}
td.item-dashboard .option-priority label{color:$grey-charcoal-dark;font-weight:700;}
td.item-dashboard .option-add-to-cart .label{color:$grey-charcoal-dark;font-size:1.1em;font-weight:700;text-transform:uppercase;}
td.item-dashboard .option-add-to-cart label{color:$grey-charcoal-dark;font-size:1.1em;font-weight:700;text-transform:uppercase;}
td.item-dashboard .option-add-to-cart input{padding:0.8em 0.3em 0.7em;width:50px;}
td.item-dashboard .option-quantity-purchased span.exceeded {color:$callout}

// base tool tip
// -------------------------------------

.tooltip {
	position:relative;
	&.multireturnexchangeSubmitButton {
		display: block;

		button[disabled] {
			pointer-events: none;
		}
	}
}
.tooltip .tooltip-content{background:none repeat scroll 0 0 $white; box-shadow: 2px 4px 10px $fontaccent; color:$white;display:none;font-size:12px;font-style:normal;left:100%;max-width:300px;min-width:200px;opacity:0;padding:1em;position:absolute;text-decoration:none;top:100%;visibility:hidden;z-index:10;}
.js .tooltip:hover .tooltip-content{display:block;opacity:1;overflow:visible;visibility:visible;}
.js .tooltip-content {
	@media screen and (min-width: 768px) {
	  display:none!important;
	}
  }
.tooltip.link{color:$primarylink; cursor:pointer; display:inline-block; padding-bottom:7px;}
.tooltip.link-inline {color:$primarylink; cursor:pointer;}
#tooltip, .ui-tooltip {background: white; box-shadow: 2px 4px 10px $fontaccent; opacity:1; position:absolute;z-index:15000; padding: 10px; display: none;}

//position tooltip
.ui-tooltip .left-position:before, .ui-tooltip .right-position:after, .ui-tooltip .right-center-position:after, .ui-tooltip .right-bottom-position:after, .ui-tooltip .top-position:after, .ui-tooltip .bottom-position:after {background:url("../images/icon-tooltip-arrow.png") no-repeat -34px 0 transparent; content: ""; width: 18px; height: 23px; position: absolute; right: -18px; top: 15%;}
.ui-tooltip .right-position:after, .ui-tooltip .right-bottom-position:after {left: -18px; top: 28%; right: auto; background-position: 0 0;}
.ui-tooltip .right-center-position:after {left: -18px; top: 45%; right: auto; background-position: 0 0;}
.ui-tooltip .right-bottom-position:after {top: 21%; right: auto;}
.ui-tooltip .top-position:after, .ui-tooltip .bottom-position:after  {width: 27px; height: 14px;}
.ui-tooltip .top-position:after {left: 50%; margin: 0 0 0 -9px; bottom: -14px; top:auto; background-position: -28px -28px;}
.ui-tooltip .bottom-position:after {left: 50%; margin: 0 0 0 -9px; top: -14px; background-position: 0 -28px;}

#tooltip .small{width:300px;}
#tooltip .medium{width:500px;}
#tooltip .recommendation-tooltip-header{background:none repeat scroll 0 0 $grey-silver;font-size:1.2em;font-weight:bold;padding:10px;}
#tooltip .recommendation-tooltip-description{padding:10px;}
#tooltip .recommendation-tooltip-attributes{padding:10px;}

// content assets and slots
// -------------------------------------

.content-slot img{height:auto;max-width:100%;}
.slot-grid-header img {min-width: 100%;}
.slot-grid-header p{margin:0;padding:0;}
.product-listing-1x4{background:none repeat scroll 0 0 $white;overflow:hidden;padding:10px 0;}
.product-listing-1x4 ul.search-result-items .grid-tile{margin:0 1% 0 2%;width:18%;}
.account-nav-asset{line-height:1.5em;margin:40px 10px;padding:10px 0;}
.account-nav-asset h2{font-family:Georgia,serif;font-style:italic;font-weight:400;margin:14px 0 20px;}

.content-asset address{margin:1em 0;}
.content-asset ol li{list-style:decimal outside none;}
.content-asset ul.account-options{margin:0;padding:0;}
.content-asset ul.account-options li{border-bottom:1px solid #D9D9D9;float:left;height:80px;list-style:none outside none !important;margin:0 0 0 4%;padding:20px 0 0;position:relative;width:46%;}
.content-asset ul.account-options li a{display:block;font-size:1.5em;line-height:30px;padding:0 0 0 55px;}
.content-asset ul.account-options li img{left:0;position:absolute;top:10px;}
.content-asset ul.account-options li p{margin:0;padding-left:55px;}
.browser-compatibility-alert{background:url("../images/interface/icon-alert.png") no-repeat scroll 1em 1em #FAEDED;border:1px solid;color:$callout;margin:1em;padding:0.5em 4em;}
.technology-content-asset .content-asset p {margin: 1em;}
.technology-content-asset .content-asset strong, .technology-content-asset .content-asset a {margin: 0 1em;}
//prodeal prompt to shop button
.text-right.pro-deal-button-wrap {position: absolute;}

// header components
// -------------------------------------

#header{min-height:104px;position:relative; width: 100%; background: none; padding: 0;}
.ie7 #header{z-index:200;}
.top-of-header { @extend .top-of-header-style  !optional}
.top-of-header .email-dropdown-wrapper { display:none; }
.header-style-wrapper { @extend .header-background !optional; @include header-shadow(); }

#header .primary-logo {
	left: 0;
	margin: 0;
	position: absolute;
	top: 48px;
}
#header .primary-logo a{display:block;}
#header .primary-logo span{left:-999em;position:absolute;}
.header-banner{clear:both;float:right;margin: 4px 0 4px; text-align: right; z-index: 1;}
.header-banner a {@extend .header-banner-text !optional;}
.ie7 .header-banner {width:350px;}
.active-persistentheader,
.minimal-header {
	#mini-cart{padding:0;}
}
.mini-cart-label {
    padding: 9px 0;
    &:before {
        content: '';
        display: inline-block;
        margin-right: 10px;
        position: relative;
		top: -1px;
		vertical-align: middle;
        @include sprite($icon-cart);
    }
}
.mini-cart-label, .mini-cart-empty, .mini-cart-quantity-bag {@extend .cart-info-text !optional;}
.mini-cart-empty,
#remembered-count,
.mini-cart-quantity-bag,
.mini-cart-total {
	@extend .cart-and-remembered-num  !optional
}
.mini-cart-total{ @extend .mini-cart !optional;}
.mini-cart-content{border:none;display:none;position:absolute;right:-11px;top:-1px;width:280px;}

.mini-cart-content .mini-cart-inner {
  background:none repeat scroll 0 0 $mainbackground;
  margin: 0;
  border: 1px solid $mainbordercolor;
  position: absolute;
  z-index: 1;
  width: auto;
  right: 0;
  @include mini-cart-shadow();
}

@media screen and (min-device-width: 1025px) {
	#mini-cart:hover .mini-cart-content {display:block;}
	#mini-cart:hover .mini-cart-header .mini-cart-label { color: $minicarthovercolor; }
}

.mini-cart-header{
	height: 20px;
	background: $mainbackground;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
	padding:9px 10px;
	border: 1px solid $mainbordercolor;
	border-bottom: none;

	a { display: block; }

  .active-persistentheader &,
  .minimal-header & {padding-bottom:18px;top:-2px;}
}

.mini-cart-close{background:url("../images/interface/icon-mini-cart-close.png") no-repeat scroll left top transparent;display:none;height:20px;text-indent:-999em;width:19px;}
.mini-cart-products.jscrollpane{min-width:238px;}
.mini-cart-product { border-bottom: 1px solid $mainbordercolor; background:none repeat scroll 0 0 $white;overflow:hidden;padding:21px 0 20px; @extend .mini-cart-text !optional;}
.mini-cart-product.last{ border:none !important;}
.mini-cart-product.collapsed{background:none repeat scroll 0 0 $secondarybackground;}
.mini-cart-product.collapsed .mini-cart-name,.mini-cart-product.collapsed .mini-cart-attributes,.mini-cart-product.collapsed .mini-cart-pricing,.mini-cart-product.collapsed .product-availability-list, .mini-cart-product.collapsed .mini-cart-action{width:90%;}
.mini-cart-image{float:left;width:50%; padding: 0 9px; box-sizing: border-box;}
.mini-cart-image img{max-width:100%;}
.mini-cart-name{float:right; width:50%;}
.mini-cart-name a, .mini-cart-subtotals {@extend .mini-cart-titles !optional}
.mini-cart-attributes{float:right;width:50%; margin: 0 0 0px;clear:right;}
.mini-cart-product .product-availability-list{float:right;font-weight:bold;width:50%;}
.mini-cart-pricing, .mini-cart-action, .final-sale-indicator-minicart {clear:right;float:right;width:50%; margin: 0;}
.mini-cart-action a {display: inline-block; padding: 0 3px 0 0;}
.mini-cart-action a.mini-cart-product-edit, .mini-cart-action a.mini-cart-configured-product-edit{padding:0;}
.mini-cart-action a:hover {color: $mainbrand;}
.mini-cart-subtotals{padding:14px 0; position: relative; text-align:left; border-top: 1px solid $mainbordercolor; border-bottom: 1px solid $mainbordercolor;}
.mini-cart-subtotals .label{margin:0; display:inline-block; width:50%;}
.mini-cart-subtotals .value {font-weight: normal;}
.mini-cart-slot{background:none repeat scroll 0 0 transparent; margin:0;}
.mini-cart-link-cart{@extend .inverted-btn !optional; display: block;}
.mini-cart-link-checkout{@extend .normal-cta !optional; display: inline-block}

.mini-cart-content .cart-bonus-product-box { padding: 25px 0; }

.menu-utility {
	display: inline-block;
	float: right;
	height: 12px;
	@extend .utility-menu !optional;
}
.menu-utility li.usermessage {border-right: 1px solid #7F8FA6;}
.menu-utility > li:first-child #lpchatnav {min-width: 105px; text-align: right; display: inline-block;}
#lhnContainer { display: none; }
.menu-utility .lhnLiveChatLink {
	display: inline;
 	&.hidden { display: none;}
 }
.menu-utility .lhnLiveChatLink #lhnContainer { display: none; }
.menu-utility li .LPMcontainer {left: 10px !important;}
.menu-utility li .LPMlabel {@extend .utility-link !optional; left: 0px !important; top: 0 !important; position: relative !important;}
.menu-utility-user{color:#8F8F8F;float:right;font-size:0.769em;letter-spacing:1px;margin:7px 0 0 0;padding:0;text-transform:uppercase;}
.menu-utility li{float:left;list-style:none outside none;padding:0 6px; line-height: 12px; position: relative;}
.menu-utility li ul {padding: 0; margin: 0;}
.menu-utility-user li{float:left;list-style:none outside none;padding:0 6px;}
.menu-utility-user li.usermessage {border-right: 1px solid $mainbordercolor;}
.menu-utility-user li, .menu-utility-user li a, .menu-utility li, .menu-utility li a {@extend .utility-link !optional;}
.menu-utility-user li a {color: $subnavtext; }
.menu-utility-user li.usermessage {font-weight: bold;}

.menu-utility li.last{border:0 none;}
.menu-utility-user li.last{border:0 none;}
.menu-utility-user li.welcomeback{border-left: 1px solid $mainbordercolor;}


//Country Dropdown
.country-box {float: right;}
.country-box ul {position: absolute; display: none; z-index: 1003; background: $white; top: 27px; padding: 15px 15px 2px; margin: 0; width: 178px; border: 1px solid $mainbordercolor; right: 0px; @include header-shadow();}
.ie7 .country-box ul {margin-top: 11px;}
.country-box li {list-style: none; @extend .primary-links !optional; color: $mainbrand; &:hover{color: $primarylink;}}
.country-box a.current-lang {
    padding: 9px 15px;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    z-index: 202;
    position: relative;
    &:not(.active):after {
        content: '';
        display: inline-block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        @include sprite($icon-small-caret-down,2);
    }
}
.ie7 .country-box a.current-lang {
    background: url("../images/interface/icon-arrows.png") no-repeat scroll 100% -19px transparent;
    padding: 8px 15px;
}
.country-box a.current-lang.active{
  background: $white; border-left: 1px solid $mainbordercolor; border-right: 1px solid $mainbordercolor; position: relative; z-index: 1005;
  .footer-country-flags &{
    border-bottom: 1px solid $mainbordercolor;
  }
}
.country-box a.current-lang span {margin: 0;}
.js .country-box a.current-lang.withjs {display: inline-block; height: 12px}

.country-box ul li a {color: $mainbrand; font-size: 1em;}
.country-box ul li a:hover {color: $primarylink;}
.ext-ie .country-box ul {top: 26px;}
.ie7 .country-box ul {top: 20px;}
.country-box ul li {margin: 0 0 13px;}
.country-box ul li.locale, .country-box ul li.countrywithlocale {margin: 0 0 13px; cursor:pointer;}
.country-box ul div.locale {margin: 0 0 13px;}
.country-box ul li.locale.expanded, .country-box ul li.countrywithlocale.expanded {margin-bottom: 9px;}
.country-box ul div.locale {margin: 0 0 7px;}
.country-box ul div.locale{ padding:0 0 0 40px;}
.country-box ul li.locale span{width: 40px; display: inline-block;}

//country selector landing page
body.pt_gateway{background:url("../images/country-selector-bg.jpg") no-repeat center center fixed; -webkit-background-size: cover;-moz-background-size: cover;-o-background-size: cover;background-size: cover; }
#wrapper.pt_gateway{ width:800px; background-color:none; margin:10% auto 0 auto;}
#wrapper.pt_gateway .primary-logo {padding: 35px 0 20px 35px;}
#wrapper.pt_gateway .primary-logo img {width: auto;}
#wrapper.pt_gateway .primary-logo span {left: -999em;position: absolute;}
.gateway-main-box{padding:0 75px 40px; font-size:14px;}
.gateway-main-box h1{color: $mainbrand;}
.gateway-main-box ul{ padding:0;float:left; width:100%; margin:0; list-style: none;}
.gateway-main-box ul li{padding:0 0 10px; float:left; width:100%; @extend .primary-links !optional; color: $mainbrand; &:hover { color: $primarylink;}}
.gateway-main-box ul li a, .gateway-main-box ul ul li a {color: $mainbrand;}
.gateway-main-box ul li a:hover, .gateway-main-box ul ul li a:hover {color: $primarylink;}
.gateway-main-box ul li{width:25%;}
.gateway-main-box ul li.new-row-item {clear: both;}
.gateway-main-box .countrywithlocale{cursor:pointer;}
.gateway-main-box .locale{padding:5px 0 5px 35px; display:block; clear:both; width: 100%;}
.gateway-main-box {
  .top-container {
    margin-bottom: 1em;

    h2 {
      margin-bottom: 0;
    }

    h4 {
      margin-top: 0;
    }

    #countrySearchBar {
      margin: auto 0 1.33em auto;
    }
  }
}

//Country Selection Flags
.sprite-flag {background:url("../images/sprite-flag.png") no-repeat scroll 0 0 transparent; width: 16px; height: 10px; display:inline-block; margin: 0 11px 0 0;}
.flag-default, .flag-us {background-position: -65px -188px;}
.flag-gb {background-position: -16px -276px;}
.flag-uk {background-position: -16px -276px;}
.flag-be {background-position: -1px -210px;}
.flag-au {background-position: -16px -474px;}
.flag-cl {background-position: 0 -485px;}
.flag-cw {background-position: -64px -385px;}
.flag-de {background-position: -16px -23px;}
.flag-at {background-position: 0 -121px;}
.flag-fr {background-position: -16px -254px;}
.flag-fi {background-position: -17px -199px;}
.flag-ie {background-position: -17px -540px;}
.flag-lu {background-position: -32px -287px;}
.flag-nl {background-position: -49px -45px;}
.flag-cn {background-position: 0 -506px;}
.flag-br {background-position: 0 -308px;}
.flag-ca {background-position: 0 -385px;}
.flag-jp {background-position: -32px -66px;}
.flag-kr {background-position: -32px -154px;}
.flag-tw {background-position: -65px -133px;}
.flag-mn {background-position: -32px -418px;}
.flag-mx {background-position: -32px -528px;}
.flag-dk {background-position: -16px -44px;}
.flag-ee {background-position: -16px -100px;}
.flag-es {background-position: -16px -154px;}
.flag-it {background-position: -32px -34px;}
.flag-se {background-position: -48px -397px;}
.flag-ch {background-position: 0px -452px;}
.flag-tr {background-position: -64px -100px;}
.flag-no {background-position: -48px -56px;}
.flag-pl {background-position: -48px -187px;}
.flag-gr {background-position: -16px -397px;}
.flag-ru {background-position: -48px -320px;}
.flag-il {background-position: -16px -550px;}
.flag-ua {background-position: -64px -154px;}
.flag-nz {background-position: -48px -99px;}
.flag-pe {background-position: -48px -132px;}
.flag-za {background-position: -64px -352px;}
.flag-pt {background-position: -48px -242px;}
.flag-hr {background-position: -48px -265px;}
.flag-co {background-position: 0 -518px;}
.flag-si {background-position: -48px -429px;}
.flag-ph {background-position: -48px -166px;}
.flag-sg {background-position: -48px -407px;}
.flag-th {background-position: -64px -22px;}
.flag-my {background-position: -32px -539px;}
.flag-id {background-position: -32px -330px;}
.flag-hk {background-position: -16px -462px;}
.flag-pk {background-position: -48px -177px;}
.flag-ar {background-position: 0px -100px;}
.flag-ae {background-position: 0px -11px;}
.flag-cz {background-position: -17px -12px;}
.flag-lv {background-position: -33px -298px;}
.flag-lt {background-position: -33px -276px;}
.flag-cam {background-position: -64px -397px;}
.flag-in {background-position: -17px -561px;}
.flag-uy {background-position: -65px -198px;}
.flag-is {background-position: -32px -23px;}
.flag-pa { background-position: -48px -121px; }
.flag-bo { background-position: -16px -320px; }
.flag-ad { background-position: 0 0; }
.flag-ro { background-position: -48px -297px; }
.flag-ec { background-position: -16px -89px; }
.flag-gt { background-position: -16px -419px; }
.flag-sv {
  background-position: -64px -396px;
  height: 11px;
}
.flag-ni {     
  background-position: -48px -528px;
  height: 11px; 
}
.flag-cr { 
  background-position: 0 -539px;
  height: 11px; 
}
.flag-bg {
  background-position: 0px -231px;
  height: 11px;
}
.flag-cy {
  background-position: -16px 0px;
  height: 11px;
}
.flag-gi {
  background-position: -16px -331px;
  height: 11px;
}
.flag-hu {
  background-position: -16px -517px;
  height: 11px;
}
.flag-im {
  background-position: -16px -462px;
  height: 11px;
}
.flag-mt {
  background-position: -32px -484px;
  height: 11px;
}
.flag-ma {
  background-position: -32px -330px;
  height: 11px;
}

//header-search
.header-search{float: right; clear: both; background: $search-background;}
.header-search input[type="text"],
.email-signup-box input[type="text"],
.email-signup-box input[type="email"] {
	background: $search-background;
	padding: 0 10px;
	float: left;
	height:30px;
	margin:0;
	width:180px;
	@extend .search-field !optional
}
.header-search form {
    position: relative;
    &:after {
        content: '';
        display: table;
        clear: both;
    }
    input[type="submit"] {
        text-indent: -999em;
        padding: 0;
        background-color: transparent;
        height: 0;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        @include sprite($icon-search);
    }
}
.email-signup-box input[type="submit"] {
    background: transparent url("../images/interface/icon-arrows.png") -2px 13px no-repeat;
    // Saucony is using content to set value of submit button to '>'
    // Because of content differences, leaving this as is - doesn't seem to break sperry/saucony
    text-indent: -999em;
    width:15px;
    border: 0;
    position:absolute;
    right: 0;
}
.header-search legend{background:none repeat scroll 0 0 transparent;}
.oldie .header-search .submit-btn, .ie7 .header-search input[type="submit"] {color: transparent; text-indent: -9999px;}

//This fixes padding issues with the search box at the top of all brands
.ie8 .header-search input[type="text"], .ie7 .header-search input[type="text"] {height: 15px; margin-top: 9px;}
.header-search input[type="text"]:focus::-webkit-input-placeholder, .email-signup-box input[type="email"]:focus::-webkit-input-placeholder {color: transparent;}
.header-search input[type="text"]:focus::-moz-placeholder, .email-signup-box input[type="email"]:focus::-moz-placeholder {color: transparent;}

.headerscrolltotop.lowrez{ right:50%; margin-right:-480px;}


.headerscrolltotop {
    position: relative;
    &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 5px;
        left: 50%;
        transform: translateX(-50%) scale(.75);
        @include sprite($arrow-scroll-to-top);
    }

    background-color: $white;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
    display: none;
    position: fixed;
    width: 44px;
    height: 44px;
    bottom: 111px;
    right: 81px;
    z-index: 1012;

    a {
        display: block;
        font-size: 12px;
        line-height: 62px;
        height: 100%;
        width: 100%;
        text-align: center;
    }
}

// primary catalog navigation
// ------------------------------------

.pt_storefront #navigation {box-shadow: none;}
#navigation{position:relative;width:100%;z-index:1001; background: $header-background; padding: 0; border-bottom: $header-border;
  &.active-persistentheader {z-index: 1203;}
}
ul.level-1{background:none repeat scroll 0 0;padding: 0;position:relative; z-index: 2; }

#navigation.active-persistentheader { background: $mainbackground; min-width: 960px;}
.pt_storefront #navigation.active-persistentheader { @include header-shadow() }
.ie7 ul.level-1{height:30px;}
ul.level-1 li{float:left;padding:0;}
ul.level-1 li.hover-item:hover a.level-1, ul.level-1 li.hover-item a.level-1, ul.level-1 li.hover-item:hover span.level-1, ul.level-1 li.hover-item span.level-1{background:none repeat scroll 0 0; @extend .mainnav-tab !optional;}

a.level-1:focus + .level-2{display:block; left:auto;}
ul.level-1 li:hover div.level-2{display:block;} //display: none; }
ul.level-1 a{@extend .main-nav-link !optional; display:block; padding:9px;}

//flyout shoe size filter
.flyout-shoe-size-filter {
	float: left; max-width: 324px;
		h3 { @extend .drop-down-nav-header !optional; margin: 15px 0 4px; }
		ul { list-style-type: none!important; margin: 0; padding: 0; }
		li {
		a { font-size: 12px;padding: 6px 0!important;border: 1px solid grey;width: 33px;text-align: center;margin-right: 5px;border-radius: 5px;margin-bottom: 4px;
		 &:hover {@extend .shoe-filter-hover !optional}
			}
		}
 }

.persistent.hover-item span a.level-1,
.persistent span a.level-1:focus {
  color: $mainnavhovercolor;
}
.active-persistentheader ul.level-1 span.level-1 > span.level-1 {padding-bottom: $persistent-cat-link-padding;}

div.level-2{background:none repeat scroll 0 0 $white;margin:0;position:absolute; z-index:2; left: -9999px; width: 920px;}
.active-persistentheader div.level-2 {top: 43px;}
.ie7 div.level-2{width:452px;}
div.level-2 .menu-wrapper{
	position: relative; 
	outline-style: none;
	@media only screen and (max-width: 1072px) and (min-width: 768px) {
		left: 0 !important;
	}
}
div.level-2 ul.level-2{float:left;margin:0;padding:0;width:100%;}
div.level-2 ul.level-2:before, div.level-2 ul.level-2:after {content: " ";display: table;}
div.level-2 ul.level-2:after {clear: both}
div.level-2 ul.level-2 li{float:none;}
div.level-2 ul.level-2 a, div.level-2 ul.level-2 div.catname-only {padding:5px 0 6px;}
div.level-2 ul.level-2 > li > a, div.level-2 ul.level-2 > li > div.catname-only {@extend .drop-down-nav-header !optional;}
div.level-2 ul.level-2 .level-3 a{ @extend .drop-down-nav-links !optional}
div.level-2 .custom{float:$flyout-custom-content-float;text-align:center;}
div.level-2 .custom img{height:auto !important;width:auto !important;}
div.level-2 .custom.defaultcontent.full-width{float: none;width: 100%;display: block;clear: both;overflow: hidden;padding: 0;flex-basis: 100%;}
div.level-2.menu-horizontal ul.level-2{float:none;}
div.level-2.menu-horizontal li{float:left !important;height:100%;}
div.level-2.menu-horizontal li li{border-color:#EFEFEF;border:none;float:none !important;height:100%;}
div.level-2.menu-horizontal li.last li{border-width:0 !important;}
div.level-2.menu-horizontal .custom{clear:none; padding: 5px 0 0;}
div.level-3 ul.level-3{margin:0;padding:0; max-width: 150px;}
div.level-3 ul.level-3 li{padding:0px;width:auto;}

#navigation.persistentheader .primary-logo {float: left; margin: 0 5px 0 0; display: none; top: auto; transform: none;}

//Sub-menu background
#navigation .inner {@extend .navigation !optional}
ul.level-1 li.hover-item div.level-2, ul.level-1 li:hover.hover-item div.level-2 {
  display:block;
  margin-left: 4px;
  @mixin breakpoint($tablet){
	position: fixed;
  }
  .active-persistentheader &,
  .minimal-header & {
    margin-left: $persistent-adjust;
  }
}

.active-persistentheader .submenu-background {top: $persistent-menu-top-adjust;}

.ie7 .submenu-background, .ie8 .submenu-background {
	border-bottom:1px solid $mainbordercolor;
}
.ie7 .level-1 {z-index:1;}

//Header banners
.header-banners .email-signup-box, .header-banners .chance-win-txt, .header-banners .header-banner1{float:left;}
.header-banners {clear:both; overflow:hidden;}
.header-banners.inner { z-index: 2;}
.header-banners .header-banner1, .header-banners .header-banner2{padding:10px 0;}
.header-banners .header-banner2{float:right; font-size: 0.923em; line-height:30px; letter-spacing: 0.15em; text-transform: uppercase; color: $mainbrand;}
.header-banners .chance-win-txt {margin: 8px 0 0 10px; font-size: 0.923em;}

//Header EU Cookie Law
.eu-cookie-message{overflow:hidden; padding:1em; background:#E2E5EC; z-index:1001; position: relative;}
.eu-cookie-message p{ width:80%; float:left; margin:0;}
.eu-cookie-message .button{float:right; margin:0;}
.eu-cookie-message.mobile p{width:100%; float:left; margin:0 0 10px 0;}
.eu-cookie-message.mobile .button{float:left; clear:both;}
.minimal-header .eu-cookie-message {
	$eu-navbar-height: 51px !default;
	& + .header-style-wrapper { height: 0; }
	@media (min-width: 767px) {
		margin-top: $eu-navbar-height;
	}
}


// breadcrumbs
// -------------------------------------

.breadcrumb{margin:18px auto 17px;padding: 0 10px 0 0; max-width: 920px;}

.breadcrumb li {
	display:inline;
	list-style:none outside none;
}

.breadcrumb li,
.primary-content .breadcrumb li,
.primary-content .breadcrumb a {
    &:not(:last-child) {
        // padding-right: $icon-breadcrumb-separator-width;
        &:after {
            content: '';
            display: inline-block;
            @include sprite($icon-breadcrumb-separator);
            margin-left:2px;
        }
	}
    a {
        @extend .breadcrumb-text !optional;
    }
}

.breadcrumb h1{display:inline;font-size:1em;font-weight:normal;}
.breadcrumb h1 .paging-current-page{left:-999em;position:absolute;}
.breadcrumb-refinement-name{background:none repeat scroll 0 0 transparent !important;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;font-style:normal;}
.breadcrumb-refinement-value{background:none repeat scroll 0 0 transparent !important;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;font-style:normal; text-transform: capitalize;}
a.breadcrumb-relax{background:none repeat scroll 0 0 transparent !important;font-family:Georgia,serif;font-style:normal;padding:0 5px 0 0;}
a.breadcrumb-relax img {padding: 0 2px;}

.breadcrumb-last,
a.breadcrumb-last,
.primary-content a.breadcrumb-last,
.breadcrumb span.last,
.breadcrumb span.stock-number {
	background:none repeat scroll 0 0 transparent !important;
	@extend .breadcrumb-current !optional;
}

ol.breadcrumb li { display: inline-block; }

.breadcrumb-result-text a,.breadcrumb a.searchphraselink{background:none repeat scroll 0 0 transparent;color:$primarylink;font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;font-style:italic;padding:0;}
.breadcrumb-refined-by {text-transform: capitalize; color: $subnavtext; padding: 0 5px 0 0;}

// primary region elements
// -------------------------------------

.primary-content h1,#secondary h1{margin:0 0 12px;}
.primary-content h1, .page-content h1, .pt_product-search-result h1 {text-transform: $primary-h1-transform;}

.primary-content dl{clear:both;overflow:hidden;}
.primary-content dl dt{clear:both;float:left;margin:2% 0 0;min-width:15%;}
.primary-content dl dd{float:left;margin:2% 0 0;padding:0 0 0 5%;}

.content-header{margin:0 0 14px !important;}
.section-header{margin:0;position:relative;margin-bottom:20px;}
.section-header-note{right:1em;margin-left:2px;}
.back{display:block;margin:0 0 10px;}
.stacked.jcarousel-container .jcarousel-item {width: 425px;float:left;}
.primary-content .jcarousel-container {width: 436px;margin-left: 8px;}
.pt_cart .primary-content .jcarousel-container {width: 100%;margin-left:0;}
.primary-content .thumbnail-carousel .thumb.jcarousel-item {margin-left: 10px}
.primary-content .thumbnail-carousel .jcarousel-clip {margin-left: 8px;width: 426px;}
.primary-content .jcarousel-prev, .primary-content .jcarousel-next {
    // top: 28px;
}
.primary-content .pdp-main .product-primary-image { margin-top: 5px; margin-bottom: 5px;z-index:10;}
.pdp-main .page-break-backdrop .page-videos {width: 920px;float: none;margin: 0 auto;}
.page-break-backdrop .page-videos .video-box { float: left; }
.page-break-backdrop .page-videos .video-box:first-child { margin-right: 30px; }

.product-tabs-vertical {
.product-tab-video .video-box {
	position: relative;
	.play-icon-button {
		position: absolute;
		left: 50%;
		margin-left: -23px;
		top: 50%;
		margin-top: -23px;
		z-index: 1;
	}
	.video-image {
	width: 95%;
	position: relative;
	margin: 16px;
	}
}
}



// secondary region elements
// -------------------------------------

#category-level-1 a.refinement-link{background:none repeat scroll 0 0 transparent;padding:0 0 0 10px;}
#category-level-1 a.active{font-weight:bold;}
#category-level-1 li.expandable{background:url("../images/interface/icon-category-expand-off.png") no-repeat scroll 2px 5px transparent;}
#category-level-1 li.active{background-position:-490px 5px;}
#category-level-2 a.active{color:$base;font-style:italic;font-weight:normal;}
#category-level-2 a.refinement-link{margin:0 0 0 6px;padding:0;}
#category-level-2 li.expandable{background:url("../images/interface/icon-refine-link-arrow.png") no-repeat scroll left 6px transparent;}
#category-level-2 li.active{background-position:left -47px;color:$base;font-style:italic;}
#category-level-3{padding:5px 0 0;}
#category-level-3 a.refinement-link{font-style:normal;margin:0 0 0 15px;padding:0;}

.filter-by-title { display: none; }
.refinement{position:relative;}
.refinement-header{background: $mainbackground;display:block;font-size:1.1em;font-weight:normal;letter-spacing:1.5px;margin:0;padding:14px 9px 9px;text-transform:uppercase;}
.refinement .toggle{border-top: 1px solid $secondarybackground;margin:0;padding:10px 9px; @extend .refinement-filter-text !optional;}
.ie7 .refinement .toggle{clear:both;}
.refinement {
    .toggle {
        span {
            cursor: pointer;
            vertical-align: top;
            position: relative;
            display: block;
            width: 100%;
            &:after {
                content: '';
                display: inline-block;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                @include sprite($icon-refinement-down);
            }
        }
        &.expanded span {
            &:after {
                @include sprite($icon-refinement-up);
            }
        }
    }
}
.refinement .toggle .tooltip-icon.tooltip {
    display: inline-block;
    margin-left: 3px;
    top: 7px;
    @include sprite($icon-tooltip);
    letter-spacing: normal;
}
.refinement .toggle.active-filter span {background:url("../images/interface/filter-more.png") no-repeat scroll 100% center transparent; background-size:14px;}
.refinement .toggle.active-filter span:after {display: none;}
.refinement .toggle.active-filter span.numb-selected {background: none; width: auto;}
.refinement ul{list-style:none outside none;margin:0;}
.Features .wrap-swatches-box ul li,  .Avantage .wrap-swatches-box ul li { padding-top: 9px;}
.Features .wrap-swatches-box ul li a span, .Technologie .wrap-swatches-box ul li a span, .Avantage .wrap-swatches-box ul li a span{ margin-top: -5px;}
div#refinementdropdown-size{width:100%;}
.js .refinement li.cutoffthreshold{display:none;}

.refinements.vertically {margin-bottom: 20px;}

.refinements.vertically .refinement .wrap-swatches-box {
	display: none;
	border-bottom:1px solid $mainbordercolor;
}
.refinements.vertically .refinement .wrap-swatches-box{display: none; border-bottom:1px solid $mainbordercolor;}

.refinements.vertically .refinement .toggle {border-bottom: 1px solid $mainbordercolor; border-top: 0;}
.refinements.vertically .refinement .toggle.expanded {border-bottom: none;}
.refinements.vertically .toggle.active-filter span.tooltip-icon {margin: 0;}

.refinement ul.swatches li{float:left;}

.refinement ul.swatches li a.seemore, .refinement ul.swatches li a.seeless {border:none;border-radius:0;display:inline;}
.refinement ul.swatches li.unselectable a{background-image:url("../images/interface/icon-color-swatch-unselectable.png");background-position:right top;}
.refinement ul.Color li a, .refinement ul.Colore li a, .refinement ul.Colour li a{border:2px solid $mainbordercolor;height:32px;padding:0;text-indent:-999em;width:32px;}
.refinement ul.Size li.unselectable a, .refinement ul.Size li.unselectable a:hover {background: none; color: $search-unselectable-color !important;}
.refinement ul#width li.unselectable a, .refinement ul#width li.unselectable a:hover {background: none; color: $search-unselectable-color !important;}
.refinement ul#size, .refinement ul#littleKidShoeSize, .refinement ul#bigKidShoeSize {@extend .size-refinements !optional;}

.tooltip-icon {
    // background:url("../images/icon-tooltip.png") no-repeat 0 0 transparent;
    @include sprite($icon-tooltip);
    display: inline-block;
    // width: 14px;
    // height: 14px;
    // padding: 0px;
    letter-spacing: normal;
    position:absolute;
}

.tooltip-icon:hover {
    @include sprite($icon-tooltip-hover);
}

.refinement .clear-refinement{position:absolute;right:2.3em;top:1.1em;}
.refinement .refinement-link{background:url("../images/interface/icon-refine-link-check.png") no-repeat scroll left top transparent;border:0 none;padding:0 0 0 1.5em; color: $base;}
.refinement .selected .refinement-link{background-position:left bottom;}
.refinement .refinement-top-level{padding:12px;}
.scrollable{height:10em;overflow:auto;}
.nav ul{border:0 none;list-style:none outside none;margin:7px 0;padding:0;}
.nav ul li{list-style:none outside none !important;margin:0 0 7px !important;}

.nav h4.toggle {font-size:1em; color:$mainbrand;margin:20px 0 10px;}
.nav .toggle{border:0 none;display:block;margin:0 0 20px;padding:0;color:$primarylink;}
#secondary.nav .toggle { margin-bottom: 9px; }
.nav .expanded{background-position:left -96px;}
.nav .toggle span{background:none repeat scroll 0 0 transparent;}
.nav .left-nav-link-active {color:$mainbrand;}

//Refinements horizontally view
.refinements.horizontally {margin: 0 0 30px; white-space:nowrap; clear: both;}
.refinements.horizontally .refinement {float: left; min-width: 140px; margin: 0 10px 0 0;}
.refinements.horizontally .refinement:focus {outline-style: none;}
.ie7 .refinements.horizontally .refinement {min-width: auto;}
.refinements.horizontally .refinement a, .refinements.vertically a {color: $mainbrand;}
.refinements.horizontally .refinement ul.swatch-filter {white-space: normal;}
.refinements.horizontally .refinement .toggle {border: 1px solid $mainbordercolor; @extend .refinement-padding !optional;position: relative; z-index: 26;}
.refinements.horizontally .refinement .toggle:focus {outline-style: none}
.refinements.horizontally .refinement h4, .refinements.vertically .refinement h4 {font-weight: normal;}

.refinements.horizontally .wrap-swatches-box {width: 216px; border: 1px solid $mainbordercolor; z-index: 25; margin-top: -1px;}
.refinements.horizontally .refinement .Color-text, .refinements.vertically .refinement .Color-text{text-transform: capitalize;}
.js .refinements.horizontally .wrap-swatches-box {display: none; position: absolute;}
.refinements.horizontally .selected-filters {padding: 0;}
.refinements .refinement .toggle.active-filter .numb-selected {padding: 0 4% 0 0; display: inline-block; }
.refinement .toggle.active-filter span.tooltip-icon {margin: -1px 0 0;}

.refinements.horizontally .refinement ul#Width li a, .refinement ul#Weite li a, .refinement ul#Largeur li a {min-width: 39px;}
.refinements .refinement ul.swatch-filter li {width: 55px; display: inline-block;}

.refinements ul.Benefits li {float: none; margin: 17px 0 0;}
.refinements ul.Benefits li a, .refinements ul.Style li a {border: none; border-radius: 0; padding: 0; display: inline; font-size: 0.923em;}
.refinements ul.Benefits li span {display: block; float: left; margin: 6px 0 0;}
.refinements ul.Benefits li span.sprite-technologies {margin: 0 20px 0 0;}

.refinements.horizontally ul.Color li.selected a, .refinements.horizontally ul.Color li.selected a:hover { font-weight: normal; color: $primarylink;}

.refinements .refinement .toggle + .wrap-swatches-box {display:none;}
.refinements .refinement .toggle.expanded + .wrap-swatches-box {
	display:block;
}

//Selected filters
.refinement .selected-filters span {
	display: block;
	background: $selected-filter;
	color: $selected-filter-color;
	padding: 6px 8px 6px 11px;
	position:relative;
	margin: 0 13px 5px;
}

#secondary .refinement .selected-filters span a, .refinement .selected-filters span a {background:url("../images/interface/icon-remove-white.png") no-repeat scroll right 0 transparent; color: $selected-filter-color; display: inline-block; width: 10px; height: 10px; margin: -5px 0 0;top: 50%;position: absolute;right: 8px;}

//Technologies (benefits)
.sprite-technologies {@extend .tech-icons !optional; float: left; margin: 0 9px 0 0; display: block; background-image:url("../images/sprite-technologies.png"); background-repeat: no-repeat; background-attachment: scroll; background-color: transparent;}
.refinement-att-technologyIcons {background:url("../images/sprite-technologies.png") no-repeat scroll 0 40 transparent;}

// footer
// -------------------------------------

#footer{background: $footer-background; clear:both;padding:0; color: $subnavtext; margin-top: 35px;}
#footer .menu-footer{float:none;margin:1em 0;padding:0;text-align:center;text-transform:uppercase;}
#footer .menu-footer li{display:inline;float:none;padding:0 0.5em;}

.postscript{color:$tertiary;font-style:italic;}

#footer .footer-content {float: left;  margin-top: 35px; width: 100%; }
#footer .footer-content:not(.responsive-template) {border-top: $footer-border;}
#footer .footer-top-box {background: $secondarybackground; min-height: 25px; padding: 15px 0; margin: 0 0 25px;}
#footer ul:not(.country-select-menu), #footer ul li:not(.menu-item) {list-style: none !important; padding:0;}
#footer .footer-decoration { @extend .top-of-footer !optional; }
.footer-top-banner.clearfix {text-align: $social-alignment;}
#footer .footer-social-links li {@extend .footer-social-links !optional}
.ie7 #footer .footer-social-links li {float: left;}

.footer-persistent.fixed { position: fixed; bottom: 0; left: 0; width: 100%; z-index: 99; }

.footer-top-banner .email-signup-box, .footer-top-banner .chance-win-txt {float: left;}
.footer-top-banner .chance-win-txt {margin: 8px 0 0 10px; font-size: 0.923em;}
.footer-bottom-banner {max-width: 606px; }
.footer-bottom-banner img{margin:0 0 20px;}

#footer .footer-content .email-signup-box .email-signup-inner input[type="submit"] {float: right;}
#footer .email-signup-box > .container + .footer-items-box {width: 650px;}
#footer .row {min-width: 300px; float: left;}
.footer-item:last-child {margin-right: 0;}

.ie7 #footer .footer-items-box {width: auto;}

#footer .footer-item h3, #footer .footer-item h3 a, .email-signup-box h3 { @extend .footer-link-headline !optional;}
#footer .footer-item h6 a, #footer .footer-item h3 a {font-size:1em;}

.thin-footer-rule { display: none; }

#footer ul li, #footer ul li a {@extend .footer-link !optional;}

#footer .footer-bottom-box {clear:both; padding: 10px 0 36px; background: $footer-bottom;}
#footer .footer-links {float: right; text-align: right;}
#footer .secure-badges {float: right;}
#footer .footer-links ul li {float: left; margin: 0 0 0 15px;}
#footer .footer-links ul li:first-child {margin-left: 0px;}

.footer-country-selector {float: left;}
.footer-country-selector p {margin: 0 0 8px;}
p.copyright, span.copyright{margin:0px; clear: both;}
.footer-country-selector p, p.copyright, span.copyright {@extend .footer-bottom-text !optional;}
.footer-country-selector .select-country, #footer .footer-links ul li a {@extend .footer-bottom-util-link !optional}
header,
.footer-top-banner {
    &.context-livechat {
        .lhnLiveChatLink {
            display: none;
        }
    }
}
div#lhnHocButton.lhntab{
    top: 430px;
}
//footer last visited
.last-visited h2{font-size:1.2em;font-style:italic;font-weight:normal;}
.jcarousel-next{
    @include sprite($icon-pagination-right);
    // background:url("../images/interface/icon-jcarousel-horiz-arrow-right.png") no-repeat scroll left top transparent;
    cursor:pointer;
    // height:20px;
    position:absolute;
    //right:0;
    right:-8px;
    top:50%;
    // width:10px;
}
.jcarousel-prev{
    @include sprite($icon-pagination-left);
    // background:url("../images/interface/icon-jcarousel-horiz-arrow-left.png") no-repeat scroll left top transparent;
    cursor:pointer;
    // height:20px;
    left:0;
    position:absolute;
    top:50%;
    // width:10px;
}
.jcarousel-prev-disabled,.last-visited .jcarousel-next-disabled{background-image:none!important;}
#horizontal-carousel li{float: left;}
.jcarousel-container-horizontal .jcarousel-next-disabled-horizontal{display: none !important;}
.ui-dialog .jcarousel-next, .ui-dialog .jcarousel-prev {top: 25px;}
.small-product-item.jcarousel-item {margin-left: 5px;}
.small-product-image-box.carousel{margin-left: -89px;}
.ie7 .carousel .jcarousel-container.jcarousel-container-horizontal ul.swatch-list, .ie8 .carousel .jcarousel-container.jcarousel-container-horizontal ul.swatch-list {border: none}
.product-tile .small-product-image-box.carousel .jcarousel-clip{width:297px; height: 80px; margin: 26px 0 0 14px;}
.recently-viewed-products .jcarousel-clip{margin:0 20px;overflow:hidden;width:167px;}
.recently-viewed-products #horizontal-carousel li{
	float: left;
	margin: 6px 32px 0 0;
	width: 150px;
	list-style:none;
}
.pt_cart .recently-viewed-products #horizontal-carousel li{margin-right:12px;}
.recently-viewed-products .jcarousel-item{width:140px;}
.recently-viewed-products ul{overflow:hidden; padding: 0;}
.recently-viewed-products .product-tile .product-name{
	width:100%;
	margin-top: 20px;
}

.pt_content .recently-viewed-products #horizontal-carousel li {
	margin: 0;
	width: 229px;

}

.recently-viewed-products .product-tile .product-remember {display: none;}
.recently-viewed-products .product-name a {@extend .product-zone-item-name !optional}
.recently-viewed-products .product-name a:hover {color: $primarylink;}
.recently-viewed-products .product-tile .product-image{ height:150px; margin-bottom: 5px;}
.recently-viewed-products .product-tile img{max-width:144px; max-height: 145px;}
.recently-viewed-products .jcarousel-next, .recently-viewed-products .jcarousel-prev{top:46%;}


//footer email signup
.email-signup-box{ float: right; position: relative; }
.email-signup-box .fieldposition { background:$search-background; }
.email-signup-box.top{ width:570px; }
.email-signup-box.top .fieldposition {width: 225px; float:left; }
.email-signup-box p { margin: 0 0 19px; @extend .alt-default-small-text !optional; }
.email-signup-box .product-image { margin: 0 0 10px; text-align: center; }
.email-signup-box .product-image img { max-width: 100%; }

.email-signup-box .newsletterform .fieldposition {width: 225px; float:left; background: $search-background;}
.email-signup-box .newsletterform span.error, .newsletterformtop span.error, .newsletterformtopfooter span.error {position: absolute; left: 0; display: block; margin: 2%; bottom: -26px; display: none;}
.email-signup-box .form-error.remove-validation-class fieldset, .email-signup-box .form-error.remove-validation-class input[type="submit"], .form-error.remove-validation-class input[type="submit"], .email-signup-box .form-error.remove-validation-class input[type="email"], .form-error.remove-validation-class input[type="email"], .form-error.remove-validation-class input[type="submit"], .form-error.remove-validation-class input[type="email"] {background-color: $backgroundaccent;}
.form-error .error{display: block !important;}
.newsletterformtop span.error.horiz{position:static; float:left; margin: 8px 0 0 10px; font-size: 0.923em;}
.header-banners .newsletterformtop span.error {left: 224px; bottom: -4px;}
.header-banners .email-signup-box.top .form-error .chance-win-txt {margin-left: 192px;}
.header-banners .newsletterformtop.form-error .fieldposition, .header-banners .newsletterformtop.form-error input {background-color: $header-message-background;}

//hide google recaptcha logo
.grecaptcha-badge { opacity: 0;}

// color swatches
// -------------------------------------

// some brands are uppercase, some are not
.swatch-Black a, .swatch-black a{background-color:$black !important;}
.swatch-Beige a, .swatch-beige a{background-color:$beige !important;}
.swatch-Blue a, .swatch-blue a{background-color:$blue !important;}
.swatch-Purple a, .swatch-purple a{background-color:$purple !important;}
.swatch-Red a, .swatch-red a{background-color:$red !important;}
.swatch-Brown a, .swatch-brown a{background-color:$brown !important;}
.swatch-Green a, .swatch-green a{background-color:$green !important;}
.swatch-Gray a, .swatch-gray a, .swatch-Grey a, .swatch-grey a{background-color: $gray-swatch !important;}
.swatch-Pink a, .swatch-pink a{background-color: $pink !important;}
.swatch-Orange a, .swatch-orange a{background-color: $orange !important;}
.swatch-White a, .swatch-white a{background-color: $white !important; border: 1px solid $mainbordercolor;}
.swatch-Yellow a, .swatch-yellow a{background-color: $yellow !important;}
.swatch-Navy a, .swatch-navy a{background-color:$navy !important;}
.swatch-Tan a, .swatch-tan a{background-color: $tan !important;}
.swatch-gold a, .swatch-Gold a { background-color: $gold !important; }
.swatch-cream a, .swatch-Cream a { background-color: $cream !important; }
.swatch-miscellaneous a{background:url("../images/interface/icon-color-swatch-misc.png") no-repeat scroll left top transparent !important;}
.swatch-camo a, .swatch-Camo a, .swatch-camouflage a, .swatch-Camouflage a { background: url("../images/interface/camo_swatch_70x70.png") no-repeat left top $white !important; }
.unselectable.swatch-miscellaneous a{background:url("../images/interface/icon-color-swatch-misc-unselectable.png") no-repeat scroll left top transparent !important;}

// product tiles
// -------------------------------------

//re-center images on cat-landing specific tiles after image7 resize
.product-tile .product-image.small{height:90px;}
.product-tile .product-name a {margin:11px 0 1px; @extend .product-zone-item-name !optional }
.preconfig-link {@extend .product-zone-item-name !optional}
.product-tile .product-name h3{font-weight:normal;margin: 17px 0 10px;}
.product-tile .product-pricing, .overlay-contents .overlayPrice {margin: 0 0 15px; @extend .product-zone-price !optional;}
.product-tile .product-pricing .product-standard-price{text-decoration:line-through;}
.product-tile .product-pricing + .affirm-as-low-as { margin: -10px 0 8px; }
.product-tile .product-swatches{bottom: 0;position:relative;right:0;}
.product-tile .product-swatches-all{cursor:pointer; @extend .inverted-btn-more-colors !optional;}
.product-tile .product-swatches ul{list-style:none outside none;margin:0;overflow:hidden;padding:0; display: inline-block;}
.product-tile .product-swatches ul.swatch-toggle{background:none repeat scroll 0 0 $white;position:relative; padding: 1px 2px 0 2px; box-shadow: 2px 4px 10px $fontaccent; -webkit-box-shadow: 2px 4px 10px $fontaccent; -moz-box-shadow: 2px 4px 10px $fontaccent; -o-box-shadow: 2px 4px 10px $fontaccent; -ms-box-shadow: 2px 4px 10px $fontaccent;}

.product-tile .product-swatches .jcarousel-next {top: 0px;}
.product-tile .product-swatches .jcarousel-prev {top: 0px;}
.product-tile .product-swatches .jcarousel-container.jcarousel-container-horizontal {width: 213px;margin-left: -10px;}
.product-tile .product-swatches .jcarousel-clip {width: 191px;overflow: hidden;margin-left: 11px;}
.product-tile .small-product-image-box.carousel .jcarousel-clip{margin-left: 25px;overflow:hidden;width:350px; height: 87px;}
.product-tile .small-product-image-box.carousel .jcarousel-container.jcarousel-container-horizontal {width: 400px; background: $mainbackground; overflow: hidden;}

.ie8 .product-tile .small-product-image-box.carousel .jcarousel-container.jcarousel-container-horizontal,
.ie7 .product-tile .small-product-image-box.carousel .jcarousel-container.jcarousel-container-horizontal,
.ie8 .product-tile .product-swatches ul.swatch-toggle,
.ie7 .product-tile .product-swatches ul.swatch-toggle { border: 2px solid $subnavtext;}

//carousel styles depending on business manager settings
.product-tile .small-product-image-box.display-productimage .jcarousel-container.jcarousel-container-horizontal {height: 113px;}
.product-tile .small-product-image-box.display-productimage li { width: 74px; padding: 24px 10px;}
.product-tile .small-product-image-box.display-productimage li img { max-width: 100%; }
.product-tile .small-product-item.jcarousel-item.jcarousel-item-horizontal {padding: 0px; }

.product-tile .small-product-image-box.display-swatchornotfound .jcarousel-container.jcarousel-container-horizontal,
.product-tile .small-product-image-box.display-swatchorproductimage .jcarousel-container.jcarousel-container-horizontal
{height: 30px;}
.product-tile .small-product-image-box.display-swatchornotfound ul,
.product-tile .small-product-image-box.display-swatchorproductimage ul
{top: 3px!important;}
.product-tile .small-product-image-box.display-swatchornotfound ul li {width: 40px!important;}
.product-tile .small-product-image-box.display-swatchornotfound ul li.jcarousel-item,
.product-tile .small-product-image-box.display-swatchorproductimage ul li.jcarousel-item
{ width: 44px!important;}

//carousel arrow placement depending on what business manager settings
.product-tile .small-product-image-box.display-swatchornotfound .jcarousel-next, .product-tile .small-product-image-box.display-swatchornotfound .jcarousel-prev,
.product-tile .small-product-image-box.display-swatchorproductimage .jcarousel-next, .product-tile .small-product-image-box.display-swatchorproductimage .jcarousel-prev
{ top: 7px;}
.product-tile .small-product-image-box.display-productimage .jcarousel-next,
.product-tile .small-product-image-box.display-productimage .jcarousel-prev {
    // top: 44px;
}

.product-tile .small-product-image-box.carousel ul{box-shadow: 0 0 0 0 !important;}
.product-tile .small-product-image-box.carousel .jcarousel-prev {
    // left: 8px;
}
.product-tile .small-product-image-box.carousel .jcarousel-next {
    // right: 7px;
}
.ie7 .product-tile .product-swatches ul.swatch-toggle, .ie8 .product-tile .product-swatches ul.swatch-toggle{border: 2px solid $subnavtext;}

.product-tile .product-swatches {float:none; margin: 0;}
.ie7 .product-tile .product-swatches {margin-bottom: -15px;}
.ie7 .product-tile {height: 380px;}
.product-tile .product-swatches li{display:-moz-inline-stack;display:inline-block;zoom:1;*display:inline;}
.small-product-item{padding: 2px 1px 0 1px;}
.small-product-image-box {position: absolute; z-index: 3; margin: -40px 0 0 -51%; text-align: center; width: 400px;}

.product-tile .product-swatches .swatch{display:block;width: 23px;}
.product-tile .product-swatches .swatch img{border:1px solid $mainbackground;height:12px;padding:1px;width: 19px;}
.product-tile .product-swatches .selected img{border-color:$mainbrand;}
.product-tile .product-swatches img:hover{border-color:$tertiary;}
.product-tile .product-swatches .small-product-image-box {display:none;}
.product-tile .product-promo{font-size:0.8em;margin:6px 0;text-transform:uppercase;}
.product-tile .product-compare{position: relative;display:-moz-inline-stack;display:inline-block;zoom:1;*display:inline}
.product-tile .product-compare label{float:left;padding:0 0 0 7px; max-width: 130px; line-height: 14px; text-align: left;}
.product-tile .product-compare input{float:left;margin:0;}
.product-tile .pr_stars{height:auto;position:relative;left:0px; text-align: center;}
.product-tile .prSnippetTitle {float:left; display: none;}
.product-tile .prSnippetReadReviews {display:none;}
.product-tile div.prStars.prStarsSmall {margin: 0; width: 73px;}
.product-tile .prSnippetRatingUnclickable {float:none; display:-moz-inline-stack;display:inline-block;zoom:1;*display:inline;}
.product-tile .prSnippetNumberOfRatingsText {display:none;}
.product-tile .pr-snippet-review-count{display:none;}
.product-tile .pr-snippet-read-write{display:none;}
.product-tile .pr-snippet-social-bar{display:none;}
.product-tile .pr-clear{display:none;}
.product-tile .pr-snippet-stars{float:none;margin:0 auto;width:115px;}
.capture-product-id{left:-999em;position:absolute;}

.product-tile .product-discounted-price .product-sales-price {@extend .product-grid-sale-price !optional}
// REF.product-tile .product-arrival {position: absolute; top:37px; left: 6px; padding: 0 0 0 14px; height: 31px; background:url("../images/bg-arrival.png") no-repeat scroll 0 0 transparent; color: $subnavtext; font-style: italic; position: relative;display: inline-block;left: 7px;float: left;}
.product-tile .product-arrival span {display:block; background: $backgroundaccent;}

//Category Ad (a tile inside a product list)
.category-ad-content-asset {overflow:hidden}

//Product Technologies

.product-technologies {
	clear: both;
	text-align: left;
	padding-top: 0px;
	margin-top: -10px;
	margin-bottom: 7px;

	.sprite-technologies {
		float: none; display:
		inline-block;
		margin: 0 3px;
		cursor: pointer;
	}

	.rollover-tagline {
		font-style: italic;
	}
}

.product-technologies-icons {position: relative; z-index:2; text-align: center; margin-top: 20px;}
.product-technologies-hover { display: none; left: 0; position: absolute; top: 0; }
.product-tile .product-technologies-hover {display: none; left: 0; position: absolute; top: 0; @extend .product-grid-tech-tiles !optional; }
.product-tabs-horizontal .product-tab-content-assets .product-technologies-hover { margin-top: -262px; margin-left: 16px;}
.product-tab-content-assets .product-technologies-hover { @extend .pdp-tech-tiles !optional; }
.ie8 .product-technologies-hover, .ie7 .product-technologies-hover {border: 1px solid #939598;}

//Product Remember
.vertically .product-remember {
    @extend .vertically-grid-remember-icon !optional;
}
.product-remember {
    // @extend .grid-remember-icon !optional
    font-family: $font-type3;
    &.enabled:hover {
        .remember-item {
            width: auto;
            padding: 10px;
            margin: 0;
			top: -12px;
			left: -5px;
            &:before {
                margin-right: 5px;
                position: relative;
				top: 1px;
				left: -1px;
            }
        }
    }
    .remember-item {
        display: block;
        margin: 5px;
        width: $icon-remember-width;
        height: $icon-remember-height;
        overflow: hidden;
        position: absolute;
        top: -5px;
        z-index: 1;
        line-height: 1;
		white-space: nowrap;
        &:hover {
            background-color: $white;
        }
        &:before {
            content: '';
            display: inline-block;
            @include sprite($icon-remember);
			vertical-align: middle;
        }
        &.enabled {
            &:before {
                content: '';
                display: inline-block;
                @include sprite($icon-remembered);
            }
        }
    }
}

//recently viewed / recommended products
.recommended-products-box h1 { margin: 0 0 0.5em;}
.recommended-products-box ul li {padding: 0 2% 2%; width: 30%; float: left;}
.recommended-product-tile .product-review .popup-rating .rating-container,
.product-tile .product-review .popup-rating .rating-container { margin: auto; float: none; }

.recommended-product-tile .rec-ProductDetail img { max-width: 180px; max-height: 180px; }
.recommended-products-box .jcarousel-next {right: -6px;}
.recommended-products-box .jcarousel-prev {left: -6px;}

.recently-viewed-products .jcarousel-clip{margin:0 20px;overflow:hidden;width:886px;}
.pt_cart .recently-viewed-products .jcarousel-clip{width:auto;}

.product-carousel .jcarousel-item{width:195px;}
.product-carousel .jcarousel-clip{margin:0 20px;overflow:hidden;width:585px;}


.thumbnail-carousel ul li {padding: 0 2% 2%; width: 30%;}
.thumbnail-carousel .jcarousel-item{width:195px;}
.thumbnail-carousel .jcarousel-clip{margin:0 20px;overflow:hidden;width:445px;}


// search results, grid layout
// -------------------------------------

.pt_content-search-result .primary-content{background:none repeat scroll 0 0 $search-bg;}
.pt_product-search-result .primary-content h1.content-header{padding-left:15px;}

.pt_product-search-result .tabcontainer .primary-content {padding:0 0 20px 0;}
.pt_product-search-result .tabcontainer .refinements.horizontally {margin: 0; padding: 5px 0 20px; clear: both;}
.pt_product-search-result .tabcontainer .search-result-options {margin-bottom: 15px;}
.pt_product-search-result a.grid-page-nav.page-previous {float:left;}
.pt_product-search-result a.grid-page-nav.page-next {float:right;}

// tabs
.tabcontainer .tabs {height:40px;margin: 0;padding: 0;}
.tabcontainer .tabs LI.active {	background-color: $white;border-top:1px $mainbordercolor solid;border-left:1px $mainbordercolor solid;border-right: 1px $mainbordercolor solid;border-bottom: 0 none;}
.tabcontainer .tabs LI.active a{background: $white;}
.tabcontainer .tabs LI {border-bottom: 1px $mainbordercolor solid;float: left;height:40px; margin-right:5px; list-style:none;}
.tabcontainer .tabs LI A.active {color: $white;}
.tabcontainer .tabs LI A {display: block; padding: 6px 8px; background:$secondarybackground; line-height:28px; color:$mainbrand; }
.tabcontainer .tabcontent {background-color: $white;border-top:1px $mainbordercolor solid;min-height: 100px;padding:0;}
.tabcontainer a span {cursor: pointer;}
// END: tabs

.pt_product-search-result .marketing-slots .left{max-width:400px; float:left;}
.pt_product-search-result .marketing-slots .right{max-width:400px; float:right;}
.pt_product-search-result .marketing-slots.horizontal-stack .stackme { max-width:400px; padding:0px 2px;}
.pt_product-search-result .inner .landing-banner {max-width: 680px; float: left;}
.pt_product-search-result .refinement .level-2 {margin-left:20px;}
.pt_product-search-result .refinement .level-3 {margin-left:40px;}

.pt_product-search-result .cat-landing .vertical-category-links .category-col .grid-tile { float: left; width: 223px; padding: 0;}
.pt_product-search-result .cat-landing .vertical-category-links .search-result-content { float: left; }
.pt_product-search-result .cat-landing .vertical-category-links .next { float: left; clear: both; }

.pt_product-search-result .cat-landing .infscroll-wrapper { float: left; clear: both; }
.pt_product-search-result .cat-landing .infscroll-wrapper .infinite-scroll-placeholder { float: left; clear: both; }
.pt_product-search-result .cat-landing .flexslider {margin: 0;}
.pt_product-search-result .cat-landing img { display: block;}
.pt_product-search-result .cat-landing #main {padding-top: 0;}
.pt_product-search-result .cat-landing .landing-topbanner-box p {margin: 0 0 1em;}

.pt_product-search-result .cat-landing-e .marketing-middle-slots {float: left; margin-bottom: 0px;}
.pt_product-search-result .cat-landing-e .marketing-middle-slots .marketing-middle-slot {float: left;}
.pt_product-search-result .cat-landing-e p, .pt_product-search-result .cat-landing-c p {margin: 0;}
.pt_product-search-result .cat-landing-e .marketing-bottom-slots {float: left;}
.pt_product-search-result .cat-landing-e .marketing-bottom-slots .marketing-bottom-slot {float: left; width: 460px;}
.pt_product-search-result .cat-landing-e .shoe-selector-tabs .tab-content { float: left; margin:-4px 0 40px; width: 100%; max-width: 918px; padding: 20px 0px; border: 1px solid $mainbordercolor; border-top: none;}
.pt_product-search-result .cat-landing-e .shoe-selector-tabs .tab-content .grid-tile { float: left; width: 223px; padding: 0;}
.pt_product-search-result .cat-landing-e .category-col .search-result-items .grid-tile .product-tile,
.pt_product-search-result .cat-landing-d .category-col .search-result-items .grid-tile .product-tile
 { height: 285px; }

.cat-landing-e .quicklinks-box li a { color: $mainbrand;}
.cat-landing-e .shoe-selector-tabs.clearfix.ui-tabs.ui-widget.ui-widget-content.ui-corner-all { padding: 0; }
.ie8 .cat-landing-e .shoe-selector-tabs.clearfix.ui-tabs.ui-widget.ui-widget-content.ui-corner-all {margin-left: 0;}
.cat-landing-e .tabs-menu.ui-tabs-nav.ui-helper-reset.ui-helper-clearfix.ui-widget-header.ui-corner-all { width: 100%; margin-top: 0px; background-color: $mainbordercolor; }
.cat-landing-e .shoe-selector-tabs .ui-state-default.ui-corner-top { margin: 0; top: 0px; background-color: $mainbrand; border-bottom: 0;}
.cat-landing-e .shoe-selector-tabs .ui-state-default.ui-corner-top:first-child { margin-left: 0px;}
.cat-landing-e .shoe-selector-tabs .ui-state-default.ui-corner-top:last-child { margin-right: 0px;}

.cat-landing-e .shoe-selector-tabs.ui-tabs .tabs-menu.ui-tabs-nav li {position: relative; border: 1px solid $mainbrand; border-left: 1px solid #526687; border-bottom: none; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box;}
.cat-landing-e .shoe-selector-tabs.ui-tabs .tabs-menu.ui-tabs-nav li:first-child, .cat-landing-e .shoe-selector-tabs.ui-tabs .tabs-menu.ui-tabs-nav li.tab-next {border-left: 1px solid $mainbrand;}
.cat-landing-e .shoe-selector-tabs.ui-tabs .tabs-menu.ui-tabs-nav li a {color: $white; width: 100%;}
.cat-landing-e .shoe-selector-tabs.ui-tabs .tabs-menu.ui-tabs-nav li.ui-tabs-active {background-color: $white; border: 1px solid $mainbordercolor; padding-bottom: 0px;}
.cat-landing-e .shoe-selector-tabs.ui-tabs .tabs-menu.ui-tabs-nav li.ui-tabs-active a {color: $mainbrand;}
.cat-landing-e .shoe-selector-tabs.ui-tabs .tabs-menu.ui-tabs-nav li .tab-arrow-icon {background:url("../images/icon-tabs-arrow.png") no-repeat 0 0 transparent; display: none; width: 23px; height: 16px; position: absolute; bottom: -15px; left: 50%; margin: 0 0 0 -11px;}
.cat-landing-e .shoe-selector-tabs.ui-tabs .tabs-menu.ui-tabs-nav li .tab-arrow-position {bottom: -16px;}
.cat-landing-e .shoe-selector-tabs.ui-tabs .tabs-menu.ui-tabs-nav li.ui-tabs-active .tab-arrow-icon {display: block;}

.cat-landing-e .tab-content .view-all { color: $white; background-color: $mainbrand; float: right; margin-right: 20px; text-transform: uppercase; letter-spacing: 0.15em; font-size: 10px; width: 150px; padding: 12px 0; text-align: center;}
.cat-landing-e .view-all:hover {color: $white; background-color: $primarylink;}

.pt_product-search-result .cat-landing-f-assets {width: 920px; float: left;}
.pt_product-search-result .cat-landing-f-assets .asset {margin-bottom: 8px;}
.pt_product-search-result .cat-landing.cat-landing-f .landing-topbanner-box p {margin-bottom: 0px;}

.pt_product-search-result .www-recommendations {padding:0 !important; display: flex; }
.pt_product-search-result .www-recommendations li { padding: 0 17px 0 0; width: 23%; float: left; list-style:none;}

.pt_product-search-result .cat-landing-f-assets .cat-landing-f-column {width: 286px; float: left; margin-right: 31px; text-align:center}
.pt_product-search-result .cat-landing-f-assets .cat-landing-f-column img { display: inline; }
.pt_product-search-result .cat-landing-f-assets .cat-landing-f-column .image-callout-badges { display: none;}
.cat-landing-f-column .product-image-noquickview .product-tile-badges {position: absolute; right: 40px; top: 10px;}
.cat-landing-f-column .product-name .name-link { font-size: 12px; letter-spacing: 0.1em;}
.pt_product-search-result .cat-landing-f-assets .cat-landing-f-column3 {margin-right: 0;}

ul.technology-content-assets { float: left; clear: both; margin: 0; padding: 0; list-style: none; }
.technology-content-assets .technology-content-asset { width: 230px; float: left; }

.pt_product-search-result .primary-content .search-result-options {margin: 5px 0;}
.pt_product-search-result .primary-content .search-result-options .sort-by {display: none;}
.category-main-banner img{max-width:100%;}
.no-hits-search-term{color:$callout;font-style:italic;}
.no-hits-search-term-suggest{color:$callout;font-style:italic;}
.no-hits-footer{background:url("../images/interface/icon-double-line-header.png") repeat-x scroll left top transparent;margin:20px 0;padding:20px 0;}
.search-result-bookmarks{margin:15px;}
.search-result-bookmarks .first{border-right:1px solid $tertiary;padding-right:5px;}
.primary-content .search-result-options .sort-by {margin: 0;}
.search-result-options{overflow:hidden;width:100%;}
.search-result-options .grid-sort-header {@extend .sort-header !optional;}
.sort-by{background:none repeat scroll 0 0 $sort-bg; float:left;}
.sort-by a {
  @extend .sort-by-options !optional;
  margin-left: 10px;
}
.sort-by a.selected, .sort-by a:hover {@extend .sort-by-general !optional;}
.items-per-page{background:none repeat scroll 0 0 $per-page-bg;border-bottom:1px solid $per-page-border;float:left;margin:0 0 0 20px;padding:3px 5px;}

.items-per-page label{display:none;}

.items-per-page select{background:none repeat scroll 0 0 $per-page-bg;border:0 none;font-size:0.9em;margin:0 6px 0 0;padding:0;width:auto;}

.items-per-page button{background:none repeat scroll 0 0 $per-page-border;border:0 none;color:$per-page-color;font-size:0.9em;padding:0.3em;width:auto;}
.pagination .results-hits{color:$subnavtext;float:left;padding:5px 0;}
.pagination, .qa-pagination{float:right;margin:0;}
.pagination ul{float:right;margin:0;padding:0;}
.pagination li{display:block;float:left;list-style:none outside none;padding:0.5em;}
.pagination li.first-last a{background-color:#00A4E3;color:$white;font-size:0.9em;padding:0 2px;}
ul.search-result-items{margin:0;padding:0;}
ul.search-result-items li{list-style:none outside none;}

.vertically ul.search-result-items .new-row{clear:both;}
ul.search-result-items .new-row{clear:both;}
ul.search-result-items .grid-tile .product-tile {position: static;}

.tiles-container .image-callout-badges {

	div {
		height: 31px;

		&.product-tile-badges,
		&.product-badges {
			height: auto;
			position: absolute;
			right: 10px;
			top: 10px;
		}
	}

	@extend .small-product-callout !optional;
}
.tiles-container .product-tile-badges { position: relative; right: 6px; float: right; }

ul.search-result-items .invisible{display:none;}
.search-result-content{clear:both;}
h1#results-content{background:url("../images/interface/icon-double-line-header.png") repeat-x scroll left top transparent;padding-top:30px;}
.primary-content .blog-title h1 {margin-left: -260px; margin-bottom: 22px;}
.product-image-container { background: $product-image-background; }
ul.folder-content-list, ul.blog-content-list{margin:0 0 20px;overflow:hidden;padding:0;}
ul.folder-content-list li, ul.blog-content-list li{float:left;list-style:none outside none;margin:3% 3% 0 0;padding:3%;width:42.2%; border:1px solid $mainbordercolor; min-height:96px;}
ul.folder-content-list li.clear, ul.folder-content-list li.nomargin, ul.blog-content-list li.clear{margin-right:0;}
ul.folder-content-list li.clear{ clear:left; margin:3% 3% 0 0;}
ul.folder-content-list li h2{margin:0 0 0.4em;}
ul.folder-content-list li h2 a, ul.blog-content-list li h2 a{color:$mainbrand;}
ul.folder-content-list .content-title{display:block;}
ul.folder-content-list li p { font-size: 1em; margin: 0;}

ul.blog-content-list li{width:48.2%; border:1px solid $mainbordercolor; padding:0; min-height:360px;}
ul.blog-content-list li .blog-post{ padding:4%;}
ul.blog-content-list li .italic{font-style:italic;}
ul.blog-content-list li img{height:200px !important; width:100% !important;}
ul.blog-content-list li h2{margin:0.4em 0;}

.no-results{padding:20px;}
.toggle-grid{background:url("../images/interface/icon-toggle-grid.png") no-repeat scroll left top transparent;cursor:pointer;display:block;float:left;height:11px;margin:2px 10px 0 0;text-indent:-999em;width:24px;}
.wide-tiles ul.search-result-items .grid-tile{width:91%;}
.wide-tiles .product-tile{height:166px !important;}
.wide-tiles .product-tile .product-image{float:left;width:40%;}
.wide-tiles .product-tile .product-name,.wide-tiles .product-tile .product-pricing,.wide-tiles .product-tile .product-promo,.wide-tiles .product-tile .pr_stars,.wide-tiles .product-tile .product-swatches{float:left;text-align:left;width:55%;margin-bottom:5px;}
.wide-tiles .product-tile .product-name {margin-top:0px;}
.wide-tiles .product-tile .pr-snippet-stars{margin:0;}
.wide-tiles .product-tile .pr_stars{left:-5px;}
.wide-tiles .product-tile .product-compare{left:40.5%;width:32px;bottom:5px;line-height:12px;}
.wide-tiles .product-tile .product-swatches{bottom:auto;left:auto;position:relative;right:auto;margin-top:5px;}
.wide-tiles .product-tile .product-swatches li{float:left;}

.wrap-top-search {overflow: hidden;}
.wrap-top-search hgroup {float: left; max-width: 350px; }
.wrap-top-search hgroup h1 {margin: 0;}
.wrap-top-search hgroup h3.product-result-count { @extend .default-p-text !optional; margin: 2px 0 0;}
.pt_product-search-result .tabcontent .wrap-top-search hgroup h3.product-result-count {display: none;}

.pt_product-search-result .search-result-options .results-hits .toggle-grid {display: none;}
.wrap-top-search .search-result-options {width: auto; float: right; max-width: 708px;}
.wrap-top-search .search-result-options .pagination {margin: 0 0 0 15px; clear: right;}
.wrap-top-search .search-result-options .sort-by, .pt_product-search-result .search-result-options .items-per-page {float: right;}
.pt_product-search-result .search-result-options .items-per-page, .pt_product-search-result .search-result-options .items-per-page select {width: 92px;}
.wrap-top-search .search-result-options .items-per-page select {margin: 0;}

.nohits-search{
	width: 100%;
	margin-top: 16px;

	input[type="text"]{
		width: 180px;
		height: 30px;
		padding: 0 10px;
		box-sizing: content-box;
	}
	input[type="submit"] {
		height: 30px;
		line-height: 30px;
		padding: 0 8px;
		border: 0;
	}
}


// infinite scroll amendments
// -------------------------------------
.infinite-scroll .pagination ul{display:none}


//** RATINGS AND REVIEWS PAGINATION **
.infinite-scroll .pagination.light-theme.simple-pagination{
	> ul {
	  display:inline;
		li.active, li.disabled {margin-left: 10px;}
		li { padding: 0;
			a.page-link.next, a.page-link.prev { margin-top: -1px; }
			a.page-link.prev {
                // background: url("../images/interface/icon-jcarousel-horiz-arrow-left.png") no-repeat scroll left top transparent;
                @include sprite($icon-pagination-left);
            }
			a.page-link.next {
                @include sprite($icon-pagination-right);
                // background: url("../images/interface/icon-jcarousel-horiz-arrow-right.png") no-repeat scroll left top transparent;
            }
		}
	}

}


.infinite-scroll .pagination .current-page-label{display:none}
.infinite-scroll .items-per-page {display:none}

.disable-infinite-scroll div.search-result-content .page-element {display:none}


// power reviews
// -------------------------------------

body .pr-snippet-wrapper{background:none repeat scroll 0 0 transparent;border:0 none;}
body .pr-snippet-stars{border:0 none;}
body .pr-snippet-review-count{display:none;}
body .pr-snippet-read-write{border:0 none;clear:none;float:left;font-size:1.1em;margin:0;padding:4px 0;}
body .pr-snippet-read-write p{display:none;}
body .pr-snippet-read-write a.pr-snippet-link{color:#3EBAEA;}
body .review-image{float:left;margin:0 20px;}


// product detail page (pdp)
// -------------------------------------


.pdp-main .promotion .promo-detail-info {display:none;}
.page-break-backdrop { @extend .pdp-page-break-drop !optional }
.print-link {background:url("../images/icon-text.png") 0 0 no-repeat; padding:0 0 4px 17px; margin-right:19px; letter-spacing: 0.01em;}
.right.right-product-print-link {margin: -37px -8px 0 0;}
#product-nav-container{overflow:hidden;position:absolute;right:-47px;top:-7px;width:160px;}
#product-nav-container div{float:left;text-align:center;width:55px; height: 16px; color: $subnavtext; cursor: pointer;}
.product-remember {display:block;}
#product-nav-container img{max-height:100%;max-width:80%;}
#product-nav-container span{display:block;height:51px;}
#product-nav-container .divided div{border-right:1px solid #E0E0E0;}
.nav-container-arw { display: inline!important; border: 0!important; padding: 0!important; position: absolute; bottom: -17px; height: 30px!important; width: 10px!important; }
.pdp-prev-product .nav-container-arw { left: 4px; background: url("../images/grey_arw_left.png") no-repeat;}
.pdp-next-product .nav-container-arw { right: 49px; background: url("../images/grey_arw_right.png") no-repeat; }
.pdp-main ul{list-style:none outside none;margin:0;padding:0;}
.pdp-main label{padding:0;text-align:left;text-transform:uppercase;width:auto;}
.pdp-main .product-col-1{float:left;width:445px;}
.pdp-main .product-primary-image{
	max-width:100%;
	padding:0 0 0;
	text-align:center;
	position: relative;
	margin-left: -4px;
	img {
		max-width: 100%;
	}
}

.product-description { @extend .default-text !optional; float: left; clear: left; max-width: 300px; margin-bottom: 30px; }
.product-description.extra-width-description { max-width:420px; }
.ie7 .callout-container .callout span, .ie8 .callout-container .callout span { background-color: $backgroundaccent; padding: 3px 14px 2px 14px;}
.hires-thumbnails, .hires-display {float:left;}
.hires-thumbnails ul { padding: 0 25px 0 0; margin: 0; }
.hires-thumbnails ul li {margin-bottom: 10px; list-style:none;}

.pdp-main .product-description-overlay.full-width{width: 450px;}
.pdp-main .first-few-lines p:first-child {margin-top:0;}
.pdp-main .first-few-lines { @extend .italic !optional }
.pdp-main .content-asset {clear: both;}

.pdp-main .product-thumbnails { clear: both; display: inline-block; padding: 1.3em 0; margin-left:-5px; background: $alt-views-background;}
.pdp-main .product-thumbnails li{border-top:5px solid $white;float:left;margin:0 5px 0 16px;padding:3px 0 0;width:55px;}
.pdp-main .product-thumbnails li.selected{border-color:transparent;}
.pdp-main .product-thumbnails li img{border:1px solid $mainbackground;max-width:53px;}
.pdp-main .product-thumbnails h2{text-transform: uppercase;font-family: Arial, sans-serif;font-size: 1.5em;font-style: normal;font-weight: bold;margin: .83em 0;}
.pdp-main .product-thumbnails .play-icon-button { position: absolute; left: 50%; margin-left: -12px; top: 50%; margin-top: -12px;border:none; }
.ie7 .pdp-main .product-thumbnails .play-icon-button, .ie8 .pdp-main .product-thumbnails .play-icon-button { position: absolute; left: 17px; margin-left: 0px; top: 10px; margin-top: 0px;border:none; }
.pdp-main .product-thumbnails .play-icon { position: relative; }
.pdp-main .page-videos {float:left; width:445px; clear:left;}
.pdp-main .thumbnail-link { display: table-cell; vertical-align: middle; height: 57px; cursor: pointer;}
.pdp-main .product-thumbnails h2 {display:none;visibility:hidden;}
.pdp-main form .input-select{width:auto;}
.pdp-main .product-name {@extend .main-product-name !optional }
.pdp-main .product-number { color: $tertiary; font-size: 0.9em; margin: 0 0 0.5em 0.5em; }
.pdp-main .prSnippetTitle { display: none; visibility: hidden; }
.pdp-main .product-price{@extend .pd-price !optional; margin:0 0 0.5em; float: left; clear: left;}
.pdp-main .product-price .price-sales.promo-price { @extend .sales-price !optional; }
.pdp-main .product-price .price-standard{@extend .original-price !optional;}

.pdp-main .product-price .price-tiered{color:$price-promo;font-size:0.6em;line-height:1.5em;}
.pdp-main .product-review-links{overflow:hidden;}
.pdp-main .product-review-links li{margin-right:1em;}
// .pdp-main #product-content .variations-color {margin-top: 12px;}
.pdp-main .attribute{position:relative;overflow:hidden;padding:0;}
.pdp-main .quickview-product-columns .attribute {overflow: visible;}
.pdp-main .attribute h3,.pdp-main .attribute .label{clear:both;margin:0 0 3px 0; @extend .pdp-attribute-heading !optional}
.pdp-main .variant-color-name { @extend .the-variant-color-name !optional}
.pdp-main .lhnLiveChatLink #lhnContainerDone { display:none; }
.ui-dialog .variant-color-name {margin-left: 10px;}
.pdp-main .attribute .value {width:100%;}
//ie7 swatch layout fix
.ie7 .attribute .value {clear: left;}
//ie7 swatch layout fix
.pdp-main .attribute ul{float:right;width:100%;}
.pdp-main .attribute .selected-value{border:0 none;color:$grey-medium;font-style:italic;padding:0.5em 0 0 2em;}
.pdp-main .attribute .size-chart-link{clear:both;float:none;}
.pdp-main .attribute .size-chart-link a{border-color:$white !important;padding:0;}
.pdp-main .product-variations.dropdownvariation h3 span.title-variation {background: none; width: 100%; padding: 0; vertical-align: middle; color: $mainbrand; letter-spacing: 0.1em;}
.pdp-main .product-variations h3.expanded span {background-position:100% 3px;}

.pdp-main .product-variations h3 span.tooltip-icon:hover,
.tooltip-icon:hover {
    @include sprite($icon-tooltip-hover);
}
.pdp-main .product-variations h3 span.tooltip-icon {margin: 0 0 8px 4px; position: absolute; top: 15px; right: 27px;}

.pdp-main .product-variations h3.active-filter span {background:url("../images/icon-add-filter.png") no-repeat scroll 100% 1px transparent;}
.pdp-main .product-variations h3.active-filter span.numb-selected {color: $primarylink; background: none; width: auto;}
.pdp-main .product-variations ul {padding:0;}
.pdp-main .product-variations h3 {border: 1px solid $mainbordercolor; padding:8px 9px 5px;margin: 0; font-size: 0.769em; position: relative; z-index: 26;}
.pdp-main .product-variations h3.expanded {border-bottom: none; padding: 8px 9px 6px;}
.pdp-main .product-variations .wrap-swatches-box {width: 230px; background: $white; display: none; border: 1px solid $mainbordercolor; position: absolute; z-index: 25; padding: 10px; margin: -1px 0 0;}

.pdp-main .swatches li{float:left;}
.pdp-main .swatches.size li, .pdp-main .swatches.width li, .pdp-main .swatches.cupsize li, .pdp-main .swatches.bigkidshoesize li, .pdp-main .swatches.littlekidshoesize li { border: none; }
.pdp-main .swatches li:hover a,.pdp-main .swatches li.selected a{border-color:#00A4E4;}
.nonactivelinks .pdp-main .swatches li:hover a{pointer-events: none; cursor: default;}

.pdp-main .swatches li.unselectable:hover a{border-color:#E0E0E0;}
.pdp-main .swatches li.unselectable:hover{border-color:#E0E0E0;}
.pdp-main .swatches li a{-moz-border-bottom-colors:none;-moz-border-image:none;-moz-border-left-colors:none;-moz-border-right-colors:none;-moz-border-top-colors:none;border-color:#E0E0E0;border-radius:2px 2px 2px 2px;border-style:solid;border-width:1px 1px 3px;display:block;margin:0 0.3em 0.3em 0.2em;padding:0.3em 0.6em;}

.pdp-main .product-variations .customerType li {float:left; margin-left: 1px; width:auto;}
.pdp-main .product-variations .customerType li .value {width:auto;}
.pdp-main .product-variations .customerType li .value input{display:block; float:left; width:auto; margin:1px 0 0 0;}
.pdp-main .product-variations .customerType li .value label{display:block; margin:0 10px 0 20px; text-transform: none; @extend .default-p-text !optional;}
.ui-dialog .pdp-main .product-variations .customerType li .value label { letter-spacing: 0; }
.pdp-main .product-variations .swatches.Color.swatch-size-small {float: left; width: 452px; @extend .pdp-variation-swatch !optional}
// .pdp-main .product-variations div.value:not(.jcarousel-container) .swatches.Color.swatch-size-small li {margin-bottom:10px;}
.pdp-main .product-variations div.value:not(.jcarousel-container) .swatches.Color.swatch-size-small a {background-size: contain!important; height: 60px; width: 75px;}
//PDP retaier links - content asset, Example: "This item is sold exclusively at Nordstroms.com"
#retailerLinks {
	color: $callout;
}
.pdp-main .outside-retailer{
	@extend #retailerLinks !optional;
	float: left;
	clear: left;
}


//Links under Add to Cart Option
.pdp-main .product-detail .attributecontentlink,
.pdp-main .product-set-list .attributecontentlink {
    // background:url("../images/icon-size-chart.png") no-repeat left center transparent;
    padding: 0 0 0 27px;
    margin: 10px 0 0 1px;
    display: block;
    clear: left;
    position: relative;
    &:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        @include sprite($icon-size-chart);
    }
}
.pdp-main .product-actions .remember-item {padding: 0 10px 2px 24px;}
.pdp-main .livechat-productdetail a {background: url("../images/icon-text.png") no-repeat scroll 2px -32px transparent; display: block; padding: 0 0 2px 20px;}

.pdp-main .livechat-productdetail a,
.pdp-main .product-actions .remember-item,
.pdp-main .product-detail .attributecontentlink, .pdp-main .product-set-list .attributecontentlink {
@extend .pdp-additional-links !optional;
}
.pdp-main .LPMlabel {
	@extend .pdp-additional-links !optional;
	z-index: 0!important;
	text-align: right;
	position: relative !important;
}


//unobstrusive js

// customerType
.pdp-main .product-variations .customerType .value {display: none;}
.js .pdp-main .product-variations .customerType .value {display: block;}
.js .pdp-main .product-variations .customerType .linkvalue {display: none;}

// swatches
//.pdp-main .swatches li:hover label,.pdp-main .swatches li.selected label{border-color:#00A4E4;}
.pdp-main .swatches li.selected a{cursor:default;}
.js .pdp-main .swatches li label input{display:none;}
.js .pdp-main .swatches li.unselectable label{opacity:0.5; filter:alpha(opacity=50);}

.pdp-main .Color li:hover {border-color:transparent;}
.pdp-main .Color li:hover a,.pdp-main .Color li.selected a{border-color:$white;}
.pdp-main .Color li a{ border:none; margin:0;padding:0;text-indent:-999em;width: 87px; height: 68px;}
.pt_product-details .pdp-main .Color.swatch-size-tiny li a{width: 40px; height: 20px; border:1px solid #E0E0E0;}
@media(max-width: 767px) {
	.pdp-main .Color.swatch-size-tiny li {
		box-sizing: border-box;
		a {
			width: 100%;
			height: 100%;
			background-position: center center !important;
			background-size: 90% !important;
		}
	}
}
.pt_product-details .pdp-main .Color.swatch-size-tiny {float: none; width: 90%; clear: both;}
.pdp-main .Color.swatch-size-small li a{width: 86px;}
.pdp-main .product-main-attributes .attribute{padding:0.2em 0;}
.pdp-main .product-main-attributes .label{padding:0;}
.pdp-main .product-add-to-cart{margin:1em 0; padding:0; margin:0;width: 167px;}
.pdp-main .product-add-to-cart button {width: 100%; margin: 0;}

.pdp-main .product-add-to-cart button.out-of-stock[name="out-of-stock"] {padding: 0; min-height: 45px;}
.product-add-to-cart.giftcart { margin-top: 2px; }

.cartMessageTip {
	position: absolute;
	background: $white;
	box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
	margin-top: -64px;
	margin-left: -2px;
	width: 167px;
	text-align: center;
	font-size: 11px;
	border: 1px solid $secondarybackground;
	z-index: 2;

	p {
		font-size: 12px;
		color: $callout;
		padding: 0 4px;
	}

	&:after {
		width: 0px;
		content:"";
		height: 0px;
		border-style: solid;
		border-width: 15px 10px 0 10px;
		border-color: $mainbackground transparent transparent transparent;
		position: absolute;
		bottom: -14px;
		left: 74px;
		z-index: 2;
	}
}

.ui-dialog .cartMessageTip { margin-top: -54px; }
.nochoosenvariant { display: none; }
.product-set .product-add-to-cart{display:block;}
.pdp-main .product-add-to-cart .inventory{float:left;width:15%;}
.pdp-main .product-add-to-cart .inventory label{float:none;}
.pdp-main .product-add-to-cart .inventory input{margin:0.7em 0;width:56px;}

.pdp-main .product-add-to-cart .product-price {
	float:left;
	margin:0;
	padding-top:18px;
	text-align:right;
	width:47%;
	display:none;
	visibility: hidden;
}

.pdp-main .product-col-2.product-set .product-add-to-cart { width: 394px;}
.pdp-main .product-col-2.product-set .product-add-to-cart .product-price { display: inline; visibility: initial; float: left; margin-top: -15px;}
.pdp-main .product-col-2.product-set .product-add-to-cart #add-all-to-cart { width: 180px; float: right; }
.pdp-main .product-col-2 .product-variations { margin-bottom: 15px;}
.ui-dialog .pdp-main .product-col-2 .product-variations {
	margin-bottom: 20px;
	flex-wrap: wrap;
}

.pdp-main .product-add-to-cart .availability{position:relative;clear:both;float:left;padding:20px 0 20px 70px;}
.pdp-main .product-add-to-cart .availability .label{position:absolute;left:0px;}
 #QuickViewDialog .pdp-main .social-media-icons {float: left; position: relative; min-width: 243px;}
.pdp-main .social-media-icons {float: right; position: relative;}
.pdp-main .social-media-icons .content-asset div > a {padding: 0 2px;}
.pdp-main .social-media-icons .social-wide-box {width: 290px; position: absolute; right: 0px;}
.pdp-main .twitter-share-button {float: left; margin-right: 6px; }
.pdp-main .social-media-icons div { float: left; overflow: hidden; }
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px) {
.pdp-main .social-media-icons div { max-width: 28%; overflow: visible; }
}

.pdp-main .social-media-icons .twitter-english iframe { max-width: 77px!important; }
.pdp-main .social-media-icons .twitter-german iframe { max-width: 88px!important; }
.pdp-main .social-media-icons .twitter-french iframe { max-width: 86px!important; }
 #QuickViewDialog .pdp-main .twitter-share-button { margin-left: -18px; }
.pdp-main .social-media-icons > a { width: 74px!important; background-repeat: no-repeat; margin-left: 6px!important; float: right; }
#QuickViewDialog .pdp-main .social-media-icons > a { float: right; }


.fb_edge_widget_with_comment { overflow: hidden; position: relative; height: 20px; float: left;}
.pdp-main .left-buttons { float: left; margin-top: 2px; }
.pdp-main .image-toolbar {clear:both; overflow:hidden;}
#QuickViewDialog .pdp-main .image-toolbar { overflow: visible; width: 274px; }

.pdp-main .olapic-holder {@extend .olapic-layout !optional;}
.pdp-main .olapic-holder.vertical { margin: 0 auto; width: 920px;}
.pdp-main .image-toolbar-mobile { bottom: 45px !important; position: relative; float:right; padding-right: 15px;}
.pdp-main .product-weight {float:left; clear:both;}

.pdp-main .product-variations.dropdownvariation {float:left; width:155px; margin:0 10px 0 0; position:relative;}

.ui-dialog.quickviewdesktop-quickview .pdp-main .product-variations.dropdownvariation {flex-wrap: wrap;}


.pdp-main .pdpForm {clear: both;}
.pdp-main .styles-info-message {margin: 0 -12px 9px; font-style: italic;}
.pdp-main .product-variations.dropdownvariation .toggle {height: 44px; position:relative; z-index:2; background: $select-size-background; border: $select-size-border; display: table;}
.pdp-main .product-variations.dropdownvariation .toggle.expanded {border-bottom: none;}
.pdp-main .product-variations.dropdownvariation .toggle .variationsdropdown {color: $mainbrand; display: block;}
.pdp-main .product-variations.dropdownvariation .toggle span:first-child {@extend .product-var-select-font !optional; @extend .size-width-refinement-layout !optional; display: table-cell; vertical-align: middle; cursor: pointer;}
.pdp-main .product-variations.dropdownvariation .toggle .tooltip-icon {top: 15px; left: 114px;}
.quantityselect {position: absolute; @extend .product-qty-select !optional;}
.pdp-main .product-variations.dropdownvariation .toggle.expanded span:first-child { background-position: $size-expanded }
.quantityselect.expanded { background-position: $qty-expanded; }

.pdp-main .product-variations.dropdownvariation .toggle.littleKidShoeSize,
.pdp-main .product-variations.dropdownvariation .toggle.bigKidShoeSize,
.pdp-main .product-variations.dropdownvariation .toggle.size {
	z-index: 5;
}

.pdp-main .product-variations.dropdownvariation .toggle-content.littleKidShoeSize,
.pdp-main .product-variations.dropdownvariation .toggle-content.bigKidShoeSize,
.pdp-main .product-variations.dropdownvariation .toggle-content.size {
	z-index: 4;
}

.pdp-main .product-variations.dropdownvariation h3 span {color: $primarylink;}
// .pdp-main .product-variations.dropdownvariation .toggle-content {border: $select-size-border;}
.pdp-main .product-variations.dropdownvariation .toggle-content > ul {overflow:hidden;}
.pdp-main .product-variations.dropdownvariation .toggle-content .value {width: auto;}

.pdp-main .product-variations.dropdownvariation .toggle-content li:first-child.attribute {width: 250px; margin: 0.5em 0 0;}
.pdp-main .product-variations.dropdownvariation .toggle-content .attribute {float: left; width: 125px; margin: 0.5em 0 0 30px;}
.pdp-main .product-variations.dropdownvariation .toggle-content .attribute ul {float: none; clear: both; overflow: hidden; padding: 11px 0 0;}
.pdp-main .product-variations.dropdownvariation .attribute li label {box-shadow: none; cursor: pointer; @extend .size-width-option !optional;}
.pdp-main .product-variations.dropdownvariation .attribute li.selected label {cursor: default;}
.pdp-main .product-variations.dropdownvariation .attribute li label:hover, .pdp-main .product-variations.dropdownvariation .attribute li.selected label { @extend .size-width-option-active !optional}

.pdp-main .quantity {float:left; width:100px; margin:0 10px 0 0;}
.pdp-main .quantity select{width:100%; border:1px solid $mainbordercolor; padding: 1em 0.8em; height: 45px;}

.pdp-main .product-detail #product-content .no-print{float: left; max-width: 429px; max-height: 46px; margin-top:12px;}
.pdp-main .product-detail #product-content #truefit-holder .no-print {
  margin-top: 0;

  .tfc-fitrec-product {
    margin-top: 12px;
    &:empty {margin-top:0;}
  }
}
.pdp-main .product-detail #product-content .attribute-holder {clear: both; max-width: 250px; float: left;}

.pdp-main .availability.productav span.label, .pdp-main .availability.productav .availability-msg .in-stock-msg {color: $callout; font-size: 13px;}
.availability.productav {position: absolute;margin-top: -73px;margin-left: 137px;width: 137px;background: $white;border: 1px solid #ccd2dc;padding: 0px 8px 0 8px;box-shadow: 2px 8px 2px rgba(0, 0, 0, 0.2); text-align: center;}
.js .ui-dialog .availability.productav {z-index: 2;margin-left: 10px;margin-top: -37px;}
.availability.productav p { color: $callout; }
.availability.productav:after {width: 0px;content: "";height: 0px;border-style: solid;border-width: 14px 10px 0 10px;border-color: $white transparent transparent transparent;position: absolute;bottom: -13px;left: 66px;z-index: 2;}
.pdp-main .product-variations.dropdownvariation .availability-msg {padding: 10px 20px;}
.availability .availability-msg p {margin-top:0px;}
.availability .in-stock-msg {color:$base}
.availability .not-available-msg {color:$errorColor}
.availability .preorder-msg {color:$infoColor}
.availability .backorder-msg {color:$successColor}
.availability .in-stock-date-msg {color:$errorColor}
.pdp-main .review-marketing-snippet {@extend .reviews-area !optional }
.pdp-main .product-set-item div.mcallout { @extend .mcallout-text !optional }
.pdp-main .review-marketing-snippet .rating-container {margin-bottom: 5px; margin-top: 2px;}

.pdp-main .review-marketing-snippet .b-read-reviews:hover, .pdp-main .review-marketing-snippet .b-write-reviews:hover {color: $mainbrand;}
.pdp-main .review-marketing-snippet .b-read-reviews span {display: inline;}
.pdp-main div.prStars {float: none;}

.pdp-main .product-upc { color:$subnavtext;font-size:14px;line-height:20px; clear:both;}
.pdp-main .product-detail .review-marketing-snippet .product-upc {display: none;}
.js .ui-dialog-content .pdp-main .review-marketing-snippet .product-upc {text-align:right;}

.hidden-title .ui-dialog-title { display: none; }
.pdp-main .prSnippetEngine div { padding: 0; }
.pdp-main .product-actions{overflow:hidden; float: right; margin: 16px 0 0 0;}
.pdp-main .product-set-actions .product-actions{float: left; padding: 1.2em 0 0.4em;}
.pdp-main .product-set-actions .product-actions li{padding:0.3em 0 0.3em;}
.pdp-main .product-actions li{margin:0;}

.pdp-main .product-actions .remember-item.enabled {padding: 0 10px 2px 24px;}
.pdp-main .product-actions .remember-item:hover, .pdp-main .product-detail .attributecontentlink:hover, .pdp-main .livechat-productdetail a:hover, .pdp-main .product-set-list .attributecontentlink:hover {color: $mainbrand;}

.pdp-main .product-actions .social-actions{float:right;}
.pdp-main .product-actions .social-actions li{margin:0 0.2em;}

.pdp-main .recommendations{clear:left;padding:2em 0;width:350px;}
.pdp-main .recommendations h2{font-family:Georgia;font-size:2em;font-style:italic;font-weight:400;margin-left:14px;}
.pdp-main .recommendations li{float:left;width:100px;}
.pdp-main .recommendations .product-tile{padding:0 0.5em;}
.pdp-main .recommendations .product-image{height:90px;}
.pdp-main .recommendations .product-name{font-size:1em;margin:0.2em 0;width:auto;}
.pdp-main .recommendations .product-price{font-size:1em;}
.pdp-main .recommendations .jcarousel-clip{margin:auto;overflow:hidden;width:300px;}
.pdp-main .recommendations .jcarousel-prev{background:url("../images/interface/icon-jcarousel-horiz-arrow-left.png") repeat scroll 0 0 transparent;cursor:pointer;height:17px;left:0;position:absolute;top:20%;width:9px;}
.pdp-main .recommendations .jcarousel-next{background:url("../images/interface/icon-jcarousel-horiz-arrow-right.png") repeat scroll 0 0 transparent;cursor:pointer;height:17px;position:absolute;right:0;top:20%;width:9px;}
.pdp-main .recommendations .jcarousel-prev-disabled{background:none repeat scroll 0 0 transparent;}
.pdp-main .recommendations .jcarousel-next-disabled{background:none repeat scroll 0 0 transparent;}
.pdp-main .recommendations .product-tile:hover .recommendation-tooltip{display:block !important;}
.js .pdp-main .recommendations .product-tile .recommendation-tooltip{display:none !important;}
.pdp-main .recommendations .recommendation-tooltip{background:none repeat scroll 0 0 $white;border:1px solid;left:50%;position:absolute;text-align:left;top:50%;width:300px;z-index:100;}
.pdp-main .promotion{background-color: $lightbackdrop; clear: both; margin: 1em 0; width: 429px;}
.pdp-main .promotion .promotion-title{font-family: $font-type3; display:inline-block;font-size:1.5em;line-height:1em;vertical-align:top; font-style:italic; }
.pdp-main .promotion .promotion-callout { @extend .pdp-shipping-callout !optional; }
.pdp-main .product-set-item .promotion .promotion-callout {font-size: 1.154em;}
.pdp-main .product-set-item .product-description-overlay.full-width { width:200px;}

.pdp-main .product-tabs { border: 0 none; margin: 25px 0 0; width: 445px; float: left; }
.pdp-main .product-tabs.product-tabs-vertical { @extend .vert-product-details-tab-default !optional; }

.product-tabs-vertical.ui-tabs .ui-tabs-nav li.ui-state-active, .pdp-main .product-tabs.product-tabs-vertical > ul > li.ui-state-active:first-child { @extend .vert-tab-active !optional }
.product-tabs.product-tabs-horizontal .tab-content { max-height: 420px; overflow: auto; }
.product-tabs.product-tabs-horizontal.ui-tabs .tabs-menu li {width: 102px;}

.ie7 .product-tabs.product-tabs-horizontal.ui-tabs .ui-tabs-nav li a { position: absolute; right: 0px; }

.ie7 .product-tabs.product-tabs-vertical .tab-content, .ie8 .product-tabs.product-tabs-vertical .tab-content {border-bottom: none; border-right: none;}

.pt_product-search-result .inner.cat-landing-a .landing-banner{ max-width:920px;}
.pt_product-search-result .inner.cat-landing-a .landing-banner h1 {margin: 35px 0 18px;}
.pt_product-search-result .inner.cat-landing-a .ui-tabs .ui-tabs-panel {padding:10px 0 20px; border: none; border-top: 1px solid $mainbordercolor;}
.pt_product-search-result .inner.cat-landing-a .ui-tabs-nav li a strong{ display:block;}

.marketing-tabs.ui-tabs .ui-tabs-nav li.last{margin-right:0;}
.marketing-tabs.ui-tabs .ui-tabs-nav li a {padding: 0.7em 4px; width: 94px; display: table-cell; vertical-align: middle; float: none; text-align: center;}
.marketing-tabs.ui-tabs .ui-tabs-nav li a:hover {color: $mainbrand;}
.marketing-tabs.ui-tabs .ui-tabs-nav li.ui-state-active { border: 1px solid $mainbordercolor; border-bottom: none;}
.marketing-tabs.ui-tabs .ui-tabs-nav li { height: 41px; display: table; width:24%; margin-right:1%;}

.shoe-selector-tabs.ui-tabs .tabs-menu.ui-tabs-nav li.ui-tabs-active { border: 1px solid $mainbordercolor; border-bottom: none;}
.shoe-selector-tabs.ui-tabs .tabs-menu.ui-tabs-nav li a {padding: 0.7em 4px; width: 94px; display: table-cell; vertical-align: middle; float: none; cursor: pointer;}
.shoe-selector-tabs.ui-tabs .tabs-menu.ui-tabs-nav li a:hover {color: $mainbrand;}
.cat-landing-e .vertical-category-links .product-zone-light ul.search-result-items {margin-bottom: 20px;}

.vertical-category-links a.view-all {
	@extend .normal-cta !optional;
	@extend .button !optional;
	width: 170px;
	height: 41px;
	padding: 0;
	display: table-cell;
	vertical-align: middle;
}

.print-page {cursor:pointer;}
.pdp-main .product-tabs .print-page {float:right}
.list-table-header .print-page {font-size: 13.2px;float:right;}
.pt_gift-registry .list-table-header .print-page {margin-top:-0.5em;}
.pdp-main .product-tabs .tab-content {min-height:195px;}

.pdp-main .product-tabs .tab-content .product-tab-content-asset,
.pdp-main .product-tab-content-html
{float:left; @extend .default-p-text !optional; line-height: 20px; }

.pdp-main .product-tabs .tab-content .product-tab-content-asset .content-asset ul li {list-style: disc inside none !important;}

.pdp-main .product-set-list{margin:0 0 1.5em 6px;}
.pdp-main .product-set-list .product-set-holder {float: left; width: 45%;}
.pdp-main .product-set-list .product-set-item{clear:both;margin:1em 0;padding:1em 0 0;}

.pdp-main .product-set-list .product-set-item-border{
	border-bottom:1px solid $mainbordercolor;
	margin:0;
	padding:1em 0 0;
	height: 1px;
}

.pdp-main .product-set-list .product-set-image{text-align: center; margin: 5px 0 25px;}
.pdp-main .product-set-list .product-set-actions{float:left;width:52%; margin: 0 0 0 3%;}
.pdp-main .product-set-list .attribute h3 {width:25%;}

.pdp-main .product-set-list .product-name{
	float:none;
	margin:0 0 20px;
	width:auto;
}

.pdp-main .product-set-list .product-name a {color: $mainbrand;}
.pdp-main .product-set-list .product-name a:hover {color: $primarylink;}
.pdp-main .product-set .product-number {margin: 0 0 0.5em;}

.pdp-main .product-set-list .product-description {
	padding: 0 7px 0 0;
	max-width: none;
}
.pdp-main .product-set-list .attribute .value {width: 100%;}
.pdp-main .product-set-list .full-details-link {width: auto; text-align: left; position: relative; margin: 25px 0 5px; z-index: 1;}
.pdp-main .product-set-list .full-details-link a:hover {color: $mainbrand;}
.pdp-main .product-set-list .block-add-to-cart button{@extend .ps-add-to-cart-btn !optional}

.pdp-main .product-set-list .quantity {float: none; clear: both;width:211px;}
.pdp-main .product-set-list .quantity select {color: $mainbrand;}
.pdp-main .product-set-list .quantity a { background-position:190px -24px; width:158px;}
.pdp-main .product-set-list .quantity-option-dropdown {width: 209px;}
.pdp-main .product-set-list .attribute {margin: 0.5em 0;}
.pdp-main .product-set-list .availability {margin: 5px 0 2px;}
.pdp-main .product-set-list .availability.productav {display: none !important;}
.pdp-main .product-set-list { @extend .ps-variation-dropdown !optional }
.pdp-main .product-col-1.product-set .social-media-icons {margin: 15px 0 0 0;}
.pdp-main .product-set .product-add-to-cart {margin: 0 0 20px 5px;}
.pdp-main .product-col-1.product-set .product-name {margin: 0; width: auto; float: none;}
.pdp-main .product-col-1.product-set, .pdp-main .product-col-1.product-set .primary-image {width: 440px;}
.pdp-main .product-set-list .promotion {width: auto; padding: 7px 13px 15px; margin: 0.8em 0;}
.pdp-main .product-set-list .product-set-actions .product-actions li {float: none;}
.pdp-main .product-set-list .attributecontentlink {margin: 0;}


.pdp-main .product-set-list .block-add-to-cart .inventory{float:left;margin-left:22%;width:20%;}
.pdp-main .product-set-list .block-add-to-cart label{padding:0.7em 0;text-align:left;width:40%;}
.pdp-main .product-set-list .block-add-to-cart .input-text{width:35px;}
.pdp-main .product-set-list .block-add-to-cart .product-price{float:left;margin:0;padding:6px 0 0;text-align:right;width:35%;}
.pdp-main .product-set .product-add-to-cart .product-price{width:54%;}

//Prodcut Set Variation Carousels

.jcarousel-container.product-set-carousel {
	width: 170px!important;
	margin-left: 19px!important;
	overflow: visible;
	div.jcarousel-clip { width: 170px!important}
	ul{
	list-style-type: none;
	.jcarousel-item-undefined, .jcarousel-item-NaN { display: none;}
	li { margin-right: 10px; padding: 3px; }
	}
	@extend .ps-jcarousel-navigation !optional;

}

.pdp-main .product-variations .value.jcarousel-container.product-set-carousel .swatch-size-small .jcarousel-item a {background-size: 67px!important;width: 67px;}


.recently-viewed-products.clearfix h1 { text-transform: $recently-viewed-text; }

button.add-all-to-cart{display:none;} // not available in simple ui.
.js button.add-all-to-cart{display:inherit;} // available in rich ui.
.pdp-main .www-recommendations li { list-style: none; }
.pdp-main .www-recommendations-title { font-weight: normal; font-size: 1.385em;}
.pdp-main .page-break-backdrop .www-recommendations-title { @extend .heading2 !optional; }
.pdp-main .www-recommendations-placeholder.www-recommendations-2x2 { overflow: hidden;width: 420px;padding-left: 21px;clear: right;}
.pdp-main .www-recommendations-placeholder.www-recommendations-4x1 {clear:both;}
.pdp-main .www-recommendations-placeholder.www-recommendations-4x1 .www-recommendations li { margin: 13px; }
.pdp-main .www-recommendations-placeholder.www-recommendations-4x1 .www-recommendations-contents { background: $mainbackground; text-align: center; }
// .pdp-main .www-recommendations li {width: 180px;display: inline-block;margin: 10px 0 0 27px;vertical-align: top;*display: inline;*zoom: 1;}

.pdp-main .www-recommendations-placeholder .product-tile .prStars {display: none; visibility: hidden; }
.pdp-main .www-recommendations-placeholder .product-tile .product-promo { display:none; visibility: hidden; }

.pdp-main .page-videos .video-box .video-image {width:445px; height:300px;}
.pdp-main .page-videos .video-box {margin-bottom:20px;}
.pdp-main .page-videos .video-box-link { width: 445px; position: relative; display: block; }
.pdp-main .page-videos .play-icon-button { position: absolute; top: 50%; margin-top: -22px; left: 50%; margin-left: -22px; }
.pdp-main .livechat-productdetail {float: right; max-width: 210px; clear: right; padding: 0 10px 0 0; margin-top: 4px;}

.www-recommendations-placeholder.www-recommendations-4x1 {max-width: 920px; margin: 0 auto;}


//Gift Card Product Content
.pdp-main .giftcardmessage{position:relative;margin:1em 0 1.5em;overflow:hidden;padding:0; clear: both;}
.pdp-main .giftcardmessage .label {clear: both;font-size: 0.923em; font-weight: bold;margin: 0;padding: 4px 0 11px; display: inline-block;text-transform: uppercase;}
.pdp-main .giftcardproduct-content .product-actions {float: none; overflow: visible;}
.pdp-main #product-content.giftcardproduct-content .variations-color {float: right; width: 200px; margin-top: 0;}
.pdp-main .giftcardproduct-content .variations-other {float: left; width: 218px;}
.pdp-main .giftcardproduct-content .variations-other select {width: 100%; height: 2em; padding: 0.2em 0.3em; margin-top: 3px;}
.pdp-main .giftcardproduct-content .variations-other ul {margin: 0 0 22px;}
.pdp-main .giftcardproduct-content .product-variations ul {padding: 0;}
.pdp-main .giftcardproduct-content .attribute .label {padding: 4px 0 8px; display: inline-block; font-size: 0.923em;}
.pdp-main .giftcardproduct-content .product-description {margin-top: 20px;}

.pdp-main .giftcardproduct-content .product-actions {overflow: hidden;}
.pdp-main .giftcardproduct-content .product-actions li {margin: 0 0 3px; clear:both;}
.pdp-main .giftcardproduct-content .product-actions li a:hover {color: $mainbrand;}
.pdp-main .giftcardproduct-content .product-variations .swatches.Color {width: auto;}
.pdp-main .giftcardproduct-content .product-variations .swatches.Color li {margin: 0 10px 10px 0;}
.pdp-main .giftcardproduct-content .product-variations .swatches.Color li:last-child {margin-right: 0px;}
.pdp-main .giftcardproduct-content .livechat-productdetail {float: left; clear: both; padding-bottom: 7px;}
.pdp-main .giftcardproduct-content .livechat-productdetail a {background-position: 3px -32px; padding-left: 24px;}

.check-balance-box .form-row label.error-message, .check-balance-box .form-row span.error-message {padding-left: 0;}
.check-balance-box .buttons-wrapper.form-row-button, .check-balance-box .buttons-wrapper.form-row {margin: 4% 0;}
.check-balance-box .buttons-wrapper.form-row-button .button-wrap {float: left; margin-right: 7px;}
.check-balance-box .buttons-wrapper.form-row-button .button-wrap button {margin-right: 0;}
.ui-dialog .check-balance-box input, .ui-dialog .check-balance-box .form-row label {width: 80%;}
//end Gift Card Product Content

//Custom Quantity Select
.js .pdp-main .default-quantity {display: none;}
.pdp-main .custom-quantity-select {display: none;}
.js .pdp-main .custom-quantity-select {border: $qty-select-border; height: 44px; padding: 0; position: relative; display: block; background: $qty-select-background}
.pdp-main .custom-quantity-select h3 {margin: 0;padding: 15px; font-size: 0.769em; width: 69%; text-transform: uppercase; letter-spacing: 0.1em; background-position:84% -25px; font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif;}
.pdp-main .custom-quantity-select h3.expanded {background-position:84% 18px;}
.pdp-main .quantity-option-dropdown {position: absolute; z-index: 20; top: 42px; background: none repeat scroll 0 0 $qty-select-background; border: $qty-select-border; border-top: none; left: -1px; width: 98px;}
.pdp-main .quantity-option-dropdown ul {padding: 0 0 12px;}
.pdp-main .quantity-option-dropdown ul li {padding: 0 13px 3px; cursor: pointer;}
.pdp-main .quantity-option-dropdown ul li.qty-disabled, .pdp-main .quantity-option-dropdown ul li.qty-disabled:hover {cursor: text; color: $fontaccent; font-style: italic;}
.pdp-main .quantity-option-dropdown ul li:hover, .pdp-main .custom-quantity-select h3 span {color: $qty-hover;}
//end Custom Quantity Select


//Quick View
.js .ui-dialog.quickview .pdp-main .product-actions, .js .ui-dialog.quickview .pdp-main .livechat-productdetail {float: left; clear: both; max-width: none;}
.js .ui-dialog.quickview .pdp-main .livechat-productdetail {float: none; margin-top: 5px; margin-left: 1px;}
.js .ui-dialog.quickview .pdp-main .styles-info-message {margin: 0 0 10px;}
.js .ui-dialog.quickview .pdp-main .product-add-to-cart {clear: both;}
.js .ui-dialog.quickview .pdp-main .product-detail #product-content .product-actions {max-width: none; margin: -4px 0 0 0;}
.js .ui-dialog.quickview .viewdetails {position: absolute; right: 20px; top: 10px}

.js .ui-dialog.quickview .pdp-main .product-set-list .product-set-item {margin: 0; padding: 1em 15px; position: relative;}
.js .ui-dialog.quickview .pdp-main .product-set-list .product-set-item-border:last-child {border-bottom: none;}
.js .ui-dialog-content .pdp-main .product-set-list h1.product-name {width: 90%; margin: 0 0 10px;}
.js .ui-dialog-content .pdp-main .product-set-list .product-variations.dropdownvariation .toggle-content {left: auto; right: 0;}
.js .ui-dialog-content .pdp-main .product-set-list .product-set-item-border {padding: 0;}
.js .ui-dialog-content .pdp-main .product-set-list .mcallout {float: none;}
.js .ui-dialog-content .pdp-main .product-set .product-actions {padding:1.4em 0 0.4em 5px;}
.js .ui-dialog-content .pdp-main .product-set .promotion {width: auto; margin: 0 0 1em 0;}
.js .ui-dialog.ui-widget.ui-widget-content.ui-corner-all.quickview.ui-draggable {overflow: visible; @include quickview-shadow();}
.js .ui-dialog-content .pdp-main .product-detail #product-content .attribute-holder {float: none; margin: 0;}
.js .ui-dialog.checkout-password-reset-dialog{left:50% !important; margin-left:-300px; top:50% !important; margin-top:-150px;}

#QuickViewDialog {
  @media (min-device-width: 767px) {
  	overflow: visible;
  }
  .quickview-product-columns {
    overflow: visible;
  }
  //Quickview & PDP carousels for product variations
  .swatches.Color.jcarousel-list {
    width: 5000px!important;
  }
  .pdp-main:not(.pdpv2) {
    .product-variations .value{

      .swatchdisplay {
        &.jcarousel-container.jcarousel-container-horizontal {
          width: 326px;
          margin-left: 10px;
        }
        .jcarousel-clip.jcarousel-clip-horizontal {
          width: 308px;
          overflow: hidden;
        }
      }

      .product-variations .value{
        &.jcarousel-container {
          &.jcarousel-container-horizontal {
            width: 432px;
            margin-left: 10px;
            margin-top: 8px;
          }
          .swatch-size-small .jcarousel-item a {
            background-size: 78px!important;
            background-repeat: no-repeat!important;
            width: 81px;
            height: 55px;
          }
        }
        &.jcarousel-container .swatch-size-small .jcarousel-item a,
        .Color.swatch-size-small li a {
          background-size: 70px!important;
          background-repeat: no-repeat!important;
          width: 70px;
          height: 55px
        }
      }
    }
  }
}

.pdp-main .product-variations .value .jcarousel-clip.jcarousel-clip-horizontal { width: 432px; overflow: hidden;}
.pdp-main .product-variations .value .jcarousel-prev.jcarousel-prev-horizontal {left: -11px;}
.pdp-main .product-variations .value .jcarousel-prev.jcarousel-prev-horizontal,
.pdp-main .product-variations .value .jcarousel-next.jcarousel-next-horizontal
{top: 93px;}
.pdp-main .product-variations .value .jcarousel-next.jcarousel-next-horizontal { right: -4px;}
.pdp-main .product-variations .swatches.Color.swatch-size-tiny.jcarousel-list.jcarousel-list-horizontal {float: none;}
.pdp-main .product-variations .value.swatchdisplay .jcarousel-clip { width: 418px; left: 5px; overflow: hidden; }
.pdp-main .value.jcarousel-container.jcarousel-container-horizontal.swatchdisplay { margin-top: 10px; width: 429px;}
.pdp-main .swatchdisplay .swatches li { margin-right: 6px; }

.bonus-product-list.pdp-main .product-variations .value .jcarousel-prev.jcarousel-prev-horizontal,
.bonus-product-list.pdp-main .product-variations .value .jcarousel-next.jcarousel-next-horizontal {top: 25px;}

//To remove scroll from homepage bonus dialog
// .bonus-dialog {
// 	&.ui-dialog {
// 		max-height: 80%;
// 		overflow-y: scroll;
// 		overflow-x: initial;
// 	}
// 	#bonus-product-dialog {
// 		overflow : visible;
// 	}
// }

.bonus-product-list .bonus-product-list-items .size-chart-container .size-chart-wrapper {
	display: none;
}
#QuickViewDialog .product-variations .value.swatchdisplay .jcarousel-prev,
#QuickViewDialog .product-variations .value.swatchdisplay .jcarousel-next,
.primary-content .product-variations .value.swatchdisplay .jcarousel-prev,
.primary-content .product-variations .value.swatchdisplay .jcarousel-next
{ top: 4px;}

#QuickViewDialog .product-variations .value .jcarousel-prev,
#QuickViewDialog .product-variations .value .jcarousel-next
{ top:50%; margin-top:-10px;}

#QuickViewDialog .product-variations .value .jcarousel-next
{ right: -7px; z-index: 99; }



#QuickViewDialog .product-variations .value.swatchdisplay .jcarousel-prev { left: -4px;}
.primary-content .product-variations .value.swatchdisplay .jcarousel-next { right: -5px;}

//end Quick View

// Product Ratings & Reviews
.reviewtab-content-box {clear: both;}
.reviewtab-content-box.product-tabs {width: 100%; float: none; padding: 0; margin-top: 0;}
.reviewtab-content-box.product-tabs .tab-content, .product-tabs.product-tabs-horizontal .tab-content {border: none; border-top: 1px solid $mainbordercolor;}
.reviewtab-content-box.product-tabs .tab-content {padding: 0}
.reviewtab-content-box.ui-tabs .tabs-menu li {width: 170px;}
.reviewtab-content-box.ui-tabs .tabs-menu li.ui-state-active { background: none repeat scroll 0 0 $white; border:solid 1px $mainbordercolor; border-bottom: none;}
.reviewtab-content-box.ui-tabs .tabs-menu li a {width: 150px; padding:0; color: $mainbrand;}
.reviewtab-content-box .pagination a, .qa-pagination a {display: block; float: left; margin: 0 0 0 10px;}
.review-title-holder h1 {margin-top: 15px;}

.rating-box {clear: both; padding: 30px 20px; }
#rating-data .rating-box-no-bg{background: $white;}
.rating-container{
    // background: transparent url("../images/star-rating-icon.png") no-repeat 0 0;
    @include sprite($star-rating-icon);
    background-color: transparent;
    float: left;
    margin-top: 0;
    overflow: hidden;

    .rating-container-selected{
        @include sprite($star-rating-icon-selected);
        background-color: transparent;
        float: left;
    }
}

.write-review-box {
  $rating-star-width: 21px;
  $rating-star-height: 17px;

  .rating-container{
      float: left;
      margin-top: 0;
      overflow: hidden;
      width: 105px;
      background: none;

      &:before {
        content: "";
        @include sprite($star-rating-icon);
        background-color: transparent;
        display: block;
        position: absolute;
      }

      .rating-container-selected {
        @include sprite($star-rating-icon);
        width: 21px;
        position: relative;
        cursor: pointer;

        &.rating-container-active{
          @include sprite($star-rating-icon-selected);
          background-color: transparent;
          width: $rating-star-width;
          height: $rating-star-height;
        }

        &.rating-container-active-b{
          @include sprite($star-rating-icon-selected);
          background-color: transparent;
          width: $rating-star-width;
          height: $rating-star-height;
        }
      }

      .review-details-box & .rating-container-selected {
        background: none;
        width: $rating-star-width;
        height: $rating-star-height;
        float: left;
        position: relative;
        z-index: 2;
      }
  }

}

.write-review-box .review-details-box .selection-holder .form-row input[type="radio"] {float: left;}
.write-review-box .review-details-box .selection-holder .form-row label {margin: 5px 11px 0 2px; display: inline-block !important;}
.write-review-box .review-details-box .selection-holder .form-row > label:first-child {margin: 0; width: 0;}
.rating-box.rating-comments {background: #f9fafb url("../images/rating-bg.gif") repeat-x 0 0; overflow: hidden; }
.rating-box .left-ratings-column { @extend .ratings-data-style !optional; }
.rating-box .right-ratings-column {width: 647px; margin: 0px 0 0 7%; float: right;}

.rating-box .total-rating h4 {
	display: inline-block;
	margin: 0 3px 10px 0;
}

.rating-box .button {float: right;}

.bar-1 .bar1{background-position: 0 0 !important;}
.bar-2 .bar1, .bar-2 .bar2{background-position: 0 0 !important;}
.bar-3 .bar1, .bar-3 .bar2, .bar-3 .bar3{background-position: 0 0 !important;}
.bar-4 .bar1, .bar-4 .bar2, .bar-4 .bar3, .bar-4 .bar4{background-position: 0 0 !important;}
.bar-5 .bar1, .bar-5 .bar2, .bar-5 .bar3, .bar-5 .bar4, .bar-5 .bar5{background-position: 0 0 !important;}

.rating-box .user-info-box .detail-value {
	display: inline;
}

.rating-box .rating-breakdown-box {margin: 0 0 35px;}
.overall-feedback-box .feedback-box-inner p span { @include alt-ratings-font() }
@media (min-device-width: 767px) { .rating-box .star-rating-filter-box span { @include alt-ratings-font() } }
.rating-box .star-rating-filter-box li span { @extend .body !optional; }
.rating-box .rating-breakdown-box .total-reviews {margin: 0 0 10px; }
//REF .rating-box .rating-breakdown-box .total-reviews > span {font-style: italic;}

.rating-box .rating-count-box {overflow: hidden;}
.rating-box .rating-count-box .rowhold {overflow: hidden;}
.rating-box .rating-count-box .rating-bar {float: left; background: $rating-bar-color; margin: 7px 0 0 0; width: 128px; height: 4px;}
.rating-box .rating-count-box .rating-bar-value {
    background: $selected-rating-bar;
    display: block;
    height: 4px;
    min-width: 0;
    margin: 0;
}
.rating-box .rating-count-box .rnumb {float: left; margin: 0 0 0 10px;}

.rating-performance-box {margin: 0 0 17px;}
.rating-performance-box .rating-count-box span {@extend .performance-rating-font !optional}
.rating-performance-box .rating-count-box .rating-bar, .write-review-box .rating-bar {background: transparent; width: auto; height: auto;}
.rating-performance-box .rating-count-box .rating-bar > span,
.write-review-box .rating-bar > span {
    // width: 16px;
    // min-width: 16px;
    // height: 7px;
    margin: 0 5px 0 0;
    float: left;
    // background-image:url("../images/rating-performance-icon.gif");

}

.rating-performance-box .icon-bar-empty, .write-review-box .rating-bar .icon-bar-empty {
  // background-position: 0 -18px;
  @include sprite($rating-performance-empty);
}

.rating-performance-box .icon-bar-full,
.write-review-box .rating-bar .icon-bar-full,
.write-review-box .rating-bar .icon-bar-full-b,
.rating-performance-box .rating-count-box .rating-bar .icon-bar-full-b{
// 	background-position: 0 0 !important;
    @include sprite($rating-performance-full);
}

.rating-performance-box .icon-bar-half,
.write-review-box .rating-bar .icon-bar-half {
    @include sprite($rating-performance-half);
}

.overall-feedback-box {border: 1px solid $mainbordercolor; margin: 0 0 25px;}
.overall-feedback-box h4 {
	margin: 0;
	background: $mainbordercolor;
	padding: 10px;
	color: $mainbrand;
	text-transform: none;
	@extend .ratings-font !optional;
}

.overall-feedback-box .feedback-box-inner p {margin: 0.5em 0;}

.rating-box .title-hold {float: left; max-width: 450px;}
.rating-box .title-hold span {@extend .totals-highlight !optional}
.rating-box .title-hold h3 {margin: 0 0 28px;}
.rating-box .title-hold p {margin: 0;}
.rating-box .star-rating-filter-box {clear: both;}
.rating-box .star-rating-filter-box span {display: inline-block; margin: 0 50px 0 0; padding: 0.8em 0;}
.rating-box .star-rating-filter-box .select-hold, .rating-box .star-rating-filter-box .review-filter-star {float: left; width: 140px;}
.rating-box .star-rating-filter-box .review-filter-star {@extend .star-selector-dropdown !optional}

.rating-box .star-rating-filter-box .review-filter-star ul li {overflow: hidden; margin: 0 0 10px;}
.rating-box .star-rating-filter-box .review-filter-star ul li input {margin: 3px 9px 0 0; float: left;}
.rating-box .star-rating-filter-box .review-filter-star ul li label {margin: 0; padding: 0; float: left; text-transform: none;}

.rating-box .star-rating-filter-box .select-hold select {
	width: 100%;
	color: $mainbrand;
	padding: 1.1em 0.9em;
	height:3.8em;
}

.ie7 .rating-box .star-rating-filter-box .select-hold select {height: auto; margin: 8px 0 0;}
.rating-box .select-hold.sortby {float: right; clear: both; min-width: 140px; display: none;}
.rating-box .select-hold.sortby select {width: 100%; height: 2em; padding: 0.3em; color: $mainbrand;}

.rating-box .star-rating-filter-box .review-filter-sort {float: right; width: 140px;}
.rating-box .star-rating-filter-box .review-filter-sort {@extend .star-selector-dropdown !optional}
.rating-box .star-rating-filter-box .review-filter-sort ul li {overflow: hidden; margin: 0 0 10px; cursor: pointer;}
.rating-box .star-rating-filter-box .review-filter-sort ul li span {margin: 0; padding: 0; float: left; text-transform: none;}

.rating-box .user-data {
	overflow: hidden;
  padding: 0 0 6px;
}

.rating-box .user-info-box .avatar-img {max-width: 60px; overflow: hidden; float: left;}
.rating-box .user-info-box .avatar-img img {max-width: 100%;}
.rating-box .user-info-box .user-name {float: left; margin: 17px 0 0 20px;}

.rating-box .user-details span,
.rating-box.preview-rating-box .overall-feedback-box span {
	display: block;
    margin: 0 0 3px;
}

.rating-box.preview-rating-box span.selected-description {
	display: inline;
}

.rating-box .user-details p {margin: 1em 0;}

.rating-box .review-date-box {float: right; margin: 0.8em 0;}
.rating-box .review-text-box {overflow: hidden; max-width:559px; clear:both; color: $mainbrand;}
.rating-box .review-text-box p {margin: 0.5em 0; line-height: 20px; color: $mainbrand;}

.review-client-responses {position: relative;left: 5%;width: 80%;padding-bottom: 20px;max-width: 465px;}

.related-products-box {margin: 0 0 15px;}
.related-products-box, .related-products-box .related-product {overflow: hidden; clear: both;}
.related-products-box .related-product {margin: 0 0 15px;}
.related-products-box h3 {font-size: 1em; font-weight: normal; font-style: italic; margin: 0 0 15px;}
.related-products-box .rproduct-image {width: 150px; float: left;}
.related-products-box .rproduct-image img {max-width: 100%;}
.related-products-box .rproduct-name {float: left; margin: 10px 0 0 15px; max-width: 130px; line-height: 17px;}
.related-products-box .rproduct-name h4 {font-weight: normal; text-transform: uppercase; font-size: 0.857em; color:$mainbrand; margin: 0 0 8px;}
.related-products-box .rproduct-name h4 a {color:$mainbrand;}
.related-products-box .star-no-active{background: url("../images/star-rating-icon-small-no-active.png") no-repeat scroll 0 0 transparent; width: 73px; height: 12px;}
.related-products-box .star-no-active div{background: url("../images/star-rating-icon-small-active.png") no-repeat scroll 0 0 transparent; width: 0px; height: 12px;}
.related-products-box .star-no-active div.mini-bar-1{width: 14px;}
.related-products-box .star-no-active div.mini-bar-2{width: 28px;}
.related-products-box .star-no-active div.mini-bar-3{width: 43px;}
.related-products-box .star-no-active div.mini-bar-4{width: 57px;}
.related-products-box .star-no-active div.mini-bar-5{width: 73px;}

.review-action-box {border-top: 1px solid $mainbordercolor; padding: 26px 0 10px; clear: both; margin-top: 27px;}
.review-action-box p {float: left; margin: 0 10px 0 0;}
.review-action-box a:hover {color: $mainbrand;}
.review-action-box .icons-thumb {float: left;}
.review-action-box .icon-thumb-up,
.review-action-box .icon-thumb-down {
    // background: url("../images/icon-thumbs.png") no-repeat scroll 0 0 transparent;
    // height: 15px;
    display: inline-block;
    margin: 0 10px 0 0;
    color: $primarylink;
    text-transform: capitalize;
    float: left;
}
.review-action-box .icon-thumb-up:before,
.review-action-box .icon-thumb-down:before {
    margin-right: 5px;
    position: relative;
    top: 2px;
}
.review-action-box .icon-thumb-up:before {
    content: '';
    display: inline-block;
    @include sprite($icon-thumb-up);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
}
.review-action-box .icon-thumb-down:before {
    content: '';
    display: inline-block;
    @include sprite($icon-thumb-down);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
}
.review-action-box .review-social-box {float: right;}
.review-action-box .review-social-box .twitter-share-button, .review-action-box .review-social-box .icon-facebook, .bv-fb-share {background: url("../images/sprite-social.png") no-repeat scroll 0 0 transparent; width: 16px; height: 16px; display: block; float: left; margin: 0 0 0 5px;}
.review-action-box .review-social-box .twitter-share-button {background-position: -21px 0; text-indent: -9999px; width: 17px !important;}
.review-action-box .post-comment {clear: both; display: block; margin: 28px 0 0;}

.review-action-box .report-wrapper {
	clear: both;

  form {
    padding-top: 10px;
  }

	textarea {
		background: $white;
		width: 100%;
		height: 75px;
	}
}

.fb_edge_widget_with_comment span.fb_edge_comment_widget iframe.fb_ltr {
	display: none !important;
}
.rating-comments .product-image-items {overflow: hidden; float: left;}
.rating-comments .product-image-items .product-image-item {display: block; float: left; margin: 0 10px 10px 0;}
.rating-comments .product-image-items .product-image-item img {width: 80px; max-width: 100%;}
.rating-comments .review-comments-video-i {float: left; overflow: hidden;}
.rating-comments .review-comments-video-i .comment-video, .review-comments-i .comment-video {float: left; display: block; margin: 0 10px 10px 0; position: relative;}
.rating-comments .comment-video .video-icon,
.ask-question-box .comment-content .comment-video .video-icon,
.preview-rating-box .comment-video .video-icon {
    display: block;
    margin: -23px 0 0 -23px;
    position: absolute;
    top: 50%;
    left: 50%;
    @include sprite($video-icon-03-large);
}

.rating-comments .review-comments-video-i .comment-video img, .review-comments-i .comment-video img {max-width: 100%;}
.rating-box .divider-line, .full-width .divider-line, .ask-question-box .divider-line {display: block; border-bottom: 1px solid $mainbordercolor; margin: 15px 0; clear: both;}
.rating-box .review-comments-i{display: block; width: auto; overflow: hidden;}
.rating-box .review-comments-i .c-user {float: left; width: 250px;}
.rating-box .review-comments-i .c-user p {margin: 5px 0 0;  }
.rating-box .review-comments-i .c-user .avatar-img {float: left; max-width: 60px; margin: 0 15px 0 0;}
.rating-box .review-comments-i .detail-holder {overflow: hidden; clear: both;}

.cql-reviews .rating-box .review-comments-i .c-user { width: auto; }


.inactive, .upload-error, .review-message .example-box-b, .review-message .example-box-c{display: none;}
.review-comments-i .review-comment-title, .ask-question-box .comment-content .comment-title{display: block; font-weight:bold; width: 100%; border-bottom: none; padding: 0;}
.review-comment-text{display: block; width: 100%;}
.review-summary{display: block;}
.bf-qa{display: none;}
.bf-review, .tag-elem-off{display: none;}

.cql-reviews .upload-error { display : block; }

//Include Related Products
.include-related-product{display: none;}
.ui-dialog-product .related-products{overflow: visible !important;}
.recommend-list{display: block; float: left; width: 100%;}
.include-related-product .product-search-list{width: 580px;}
.include-related-product .product-search-result-content{float: right; width: 390px;}
.include-related-product .product-search-result-content li {overflow: hidden;}
.include-related-product .product-search-result-content .product-item-l {border-bottom: 1px solid $secondarybackground; padding-bottom: 10px; margin-bottom: 15px;}
.include-related-product .category-list-items{width: 155px; float: left;}
.include-related-product .selected-product-list{display: none; margin: 10px 0 0;}
.include-related-product .selected-product-list .button {display: inline-block; float: right; margin: 0 15px 20px 0; padding: 1em 2.5em;}
.include-related-product .sub-category-list ul {padding: 6px 0 6px 5px; margin:0; border-bottom: 1px solid $secondarybackground;}
.include-related-product .sub-category-list ul li {padding: 3px 0;}
.include-related-product .sub-category-list ul li p {margin: 0; font-weight: normal;}
.include-related-product .sub-sub-category-list ul {padding:  6px 0 6px 8px; border-bottom: none;}
.include-related-product .sub-sub-category-list ul li span, .include-related-product .sub-category-list ul li p {cursor: pointer;}

.category-list-items, .sub-category-list, .sub-sub-category-list{display: none;}
.sub-category-active, .sub-sub-category-active{display: block;}
.category-list-data{overflow: hidden; padding: 0 0 5px;}
.category-list-data{overflow-y: scroll; height: 380px}
.category-list-data{display: none;}

.include-related-product {margin: 0 0 30px;}
.include-related-product button {padding: 0.8em 2em;}
.include-related-product .product-search-form {overflow: hidden; margin: 0 0 20px;}
.include-related-product .recommend-list {margin: 0 0 10px;}
.include-related-product .recommend-list > div {margin: 0 0 5px;}
.include-related-product .recommend-list .remove-p, .ask-question-box .remove-p-item, .uploaded-images-box .remove-image {background: url("../images/icons-review.png") no-repeat 0 -44px; width: 16px; height: 13px; display: inline-block; text-indent: -9999px; margin: 2px 5px 0 0; padding-bottom: 2px; cursor: pointer;}
.include-related-product .product-search-form form, .include-related-product .product-search-form .browse-products {float: left;}
.include-related-product .product-search-form form {width: 320px;}
.include-related-product .product-search-form input[type="text"] {width: 63%;}
.include-related-product .product-search-form .browse-products {margin: 9px 0 0;}
.include-related-product .category-list-data {clear: both;}
.include-related-product .category-info-line {padding: 8px 0 19px; display: none;}
.include-related-product .sub-category-btn {font-weight: bold; border-bottom: 1px solid $secondarybackground; padding: 10px 0 10px 2px; margin: 0; cursor: pointer;}
.include-related-product .product-search-result-content ul {padding: 0;  margin: 0;}

.include-related-product .product-item-image {max-width: 90px; min-width: 90px; float: left; margin: 0 5px 0 0; }
.include-related-product .product-item-text {float: right; width: 270px; padding: 5px 12px 12px;}
.include-related-product .category-list-data .p-name {float: left; width: 196px;}
.include-related-product .category-list-data h3 {overflow: hidden; margin: 0 0 7px;}
.include-related-product .category-list-data h3 a {float: right;margin: 0;}
.include-related-product .category-list-data h3 .button {margin: 0; padding: 0.5em 12px; text-align: center; font-size: 0.611em;}
.include-related-product .p-add, .include-related-product .product-added .p-added {display: block;}
.include-related-product .p-added, .include-related-product .product-added .p-add{display: none;}
.include-related-product .p-added {opacity: 0.5;}

.ask-question-box .p-item-element-holder {margin: 0 0 0 21%;}
.ask-question-box .p-item-element {border: 1px solid $secondarybackground; margin: 0 0 7px; width: 400px; padding: 5px; overflow: hidden; position: relative; float: left; clear: both;}
.ask-question-box .include-photos-added .p-item-element-holder {margin: 0; float: left; clear: none;}
.ask-question-box .remove-p-item {float: left; margin: -7px 17px 0; position: absolute; top: 50%; left: 0;}
.ask-question-box .p-item-element img {float: left; margin: 0 0 0 45px;}
.ask-question-box .p-item-element span.p-title {float: left; display: block; margin: 4px 0 0 35px; width: 200px;}

.report-content .review-action-box{display: none;}
.no-review .left-ratings-column, .no-review .star-rating-filter-box, .no-review .title-hold, .no-review .right-ratings-column p, .no-review .rating-box .select-hold{display: none;}
.no-qa .question-sort-search, .no-qa .qa-content, .no-qa .question-answer-top p{display: none;}

//end Include Related Products

//End Product Ratings & Reviews

//Question and Answers
.question-title {overflow: hidden; border-bottom: 1px solid $mainbordercolor; position: relative;}
.question-title h4{ clear:both; padding: 0; overflow: hidden;}

.question-title h4 .title {
	float:left;
	width :85%;
	padding: 0 0 0 40px;
	background: url("../images/backgroundQBullet.gif") no-repeat left 1px;
	min-height:23px;
	line-height:23px;
}

.question-title.expanded {border-bottom: none;}
.question-title.expanded h4 .title {background: url("../images/backgroundQBulletOpen.gif") no-repeat left 1px;}
.question-title .answer-count{float:right; text-align:center; position: absolute; right: 10px; margin-top: -5px;}
.question-title .answer-count span{display:block; clear:both; font-weight:normal;}
.question-title .answers-box h4{border-bottom:1px solid #00274C; margin:10px 0; line-height:1.5em; background:$white;}


.question .additional-info-box {
	margin: 15px 0 0;
	border-top:1px solid $mainbordercolor;
	padding-top: 10px;

	span {
		float: left;
		margin: 7px 12px 0 0;
		display: block;

		&.count-success,
		&.count-total {
			float: none;
			margin: auto;
			display: inline;
		}
	}
}
.question a.button{padding:0.6em 1.5em; margin: 0 1% 0 0; display:inline-block;}
.question p{margin:0.5em 0;}
.question .user-info, .question .user-data-content{margin: 10px 0 1.5em;}
.question .answers-box .answer-item {background:#efefef; padding: 10px; margin: 0 0 12px;}
.question .wrap-answer-box h4 {border-bottom:1px solid #00274C; padding: 0 0 5px; margin: 12px 0 15px;}
.question .answer-content {margin: 0 0 17px;}
.question .answer-text {margin: 0.5em 0 1.5em;}
.question .toggle-content {border-bottom: 1px solid $mainbordercolor; padding: 0 0 10px;}

#question-answer-list-top {margin: 0 0 30px;}
#question-answer {padding: 30px 5px 10px; color: $mainbrand;}
.question-answer-top .question-ask {overflow: hidden;}
.question-answer-top .question-ask p {float: left;}
.question-answer-top .question-ask span {font-weight: bold;}
.question-answer-top .question-ask .button {float: right; margin: 7px 0 0;}
.question-answer-top .question-sort-search {padding: 15px 0; margin: 20px 0; clear: both; border-top: 1px solid $mainbordercolor; border-bottom: 1px solid $mainbordercolor;}
.question-answer-top .question-search {float: left; width: 550px;}
.question-answer-top .select-hold {float: right; margin: 5px 0 0;}
.question-answer-top .select-hold select {width: 140px; padding: 0.3em; height: 2em;}
.question-sort-search input {width: 45%; float: left;}
.question-sort-search label {float: left; margin: 7px 12px 0 0; text-transform: none;}
.question-sort-search button {
    background: url("../images/interface/icon-arrows.png") no-repeat scroll 2px 10px $secondarybackground;
    padding: 10px 20px 10px 0;
    margin: 0;
    float: left;
    width: 10px;
    text-indent: -9999px;
}

.question-details-box,
.question-information-box,
.review-details-box,
.share-photos-box,
.include-photos-box,
.indent-holder {
	position: relative;
	padding: 0 0 30px;
}

.question-information-box.customer-service-box {
	padding-bottom: 0;
}

.question-information-box .comment-content .links-holder {margin: 30px 0 20px;}

.question-information-box .form-row-radio .recommendation-num {
	display: inline;
}

.question-information-box .error-message.form-error {
	margin-top: 20px;
}

.review-details-box .error-message.form-error {
	margin-bottom: 20px;
}


.question-details-content .user-data-content p, .question-details-content .user-info p, .answer-preview-data .user-info p, .answer-preview-data .user-data-content p {margin: 0.5em 0;}
.question-details-preview .question-details-content .question-title {padding-bottom: 20px; margin-bottom: 25px; color: $subnavtext;}

//Write your answer
.review-product.review-product-answer {border: none; padding: 0 0 10px;}
.review-product-holder {float: left;}
.review-product.review-product-answer .review-product-info, .review-product.review-product-answer .review-product-image {float: none;}
.review-product.review-product-answer .review-product-image, .review-product.review-product-answer .review-product-info {width: 100%;}
.review-product.review-product-answer .review-product-holder {margin: 0 20px 0 0;}
.review-product-answer .question-title {border: none;}

//end Question and Answers


// bonus products
// -------------------------------------
.no-bonus-btn {
	@extend .inverted-btn !optional;
}

.select-bonus-btn,
.no-bonus-btn {
	display: block;
	margin-bottom: 10px;
	width: 100%;
}

.buttonbar {
	margin: 11px 0;
	overflow: hidden;
	width: 380px;
}

.bonus-product-list-footer {
	clear: both;

	button {
		float:right;
	}
}

.js .ui-dialog-content .pdp-main.bonus-product-list .product-variations {max-height: none;}

// send to friend
// -------------------------------------

.send-to-friend-logo {border-bottom:1px solid #E0E0E0;border-top:1px solid #E0E0E0;margin-bottom:20px;margin-top:20px;overflow:hidden;padding:20px 0px}
.send-to-friend-product .product-image{float:left;width:36%;}
.send-to-friend-product .product-image img{max-width:80%;}
.send-to-friend-product .product-description{float:left;width:63%;}
.send-to-friend-product .product-description h2 {margin-right:0;}
.send-to-friend-product .product-description .value {font-weight:bold;}
.send-to-friend-product .product-description h2{font-family:Georgia;font-size:1.2em;font-style:italic;}
.send-to-friend-product .product-description dt, .send-to-friend-product dt {clear:both;float:left; margin:10px 0 0 0;width: 100px;}
.send-to-friend-product .product-description dd, .send-to-friend-product dd {float:left;margin:10px 0 0 0; width: 200px;}
#send-to-friend-main .char-count, #send-to-friend-main span.error{margin-left:0;}
#send-to-friend-main textarea{width:47%;}
#send-to-friend-main h3{font-size:1.2em;margin:0 0 0.5% 0;padding:14px 0 10px;border-bottom:1px solid #E5E5E5; text-transform:uppercase; font-weight:700;}
#send-to-friend-main h3 span{font-weight:400;text-transform:none;margin-left:2%; font-size:0.8em;}
#send-to-friend-main h3 span em{color:$subnavtext; font-style:italic;}
#send-to-friend-main label{width:60%;}
#send-to-friend-main .label-inline input{margin-top:0.4em;}

// size charts
// -------------------------------------

.sizinginformation{border:0 none;padding:10px;}
.sizinginformation th{background:none repeat scroll 0 0 #F3F3F3;}
.sizinginformation td,.sizinginformation th{border-bottom:1px solid #F3F3F3;padding:10px;}
.sizinginformation td{border-left:1px solid #F3F3F3;}
.sizinginformation .ui-tabs-nav { height: 36px; }
.sizinginformation .sizing-charts{border:0 none;}
.sizinginformation .tips-list{padding:0 !important;}
.sizinginformation .tips-list li{list-style:none outside none !important;}

.livechat-productdetail{float: right;}

.shipping-method{padding:22px 0 0 28%;}
.shipping-method label{display:inline;float:none !important;padding-right:5px !important;}
.shipping-method .shipping-description{display:inline;}
#primary .login-columns-container { max-height: 100%; }


div.login-dialog.ui-dialog.ui-widget.ui-widget-content.ui-corner-all.ui-draggable{
	width: 835px!important;
	#dialog-container.ui-dialog-content {
		max-height: initial;
		padding: 0.2em 1.3em 1.3em 1.3em;
		margin:0;
		box-sizing: border-box;
		height: auto!important;
	}
}

.place-order-totals{clear:both;}
.place-order-totals .order-totals-table{float:right;width:50%;}
.order-component-block{margin:20px 0;}
.order-component-block .section-header a{float:right;font-weight:normal;}
.order-component-block .details{background:none repeat scroll 0 0 #E8E8E8;color:#A3A3A3;padding:0.5em 1em;}
.order-summary-footer{position:relative;}
.summary-help{float:right;margin:1em 0;}
.submit-order{clear:both;display:block;padding:20px 0 0;text-align:right;}
.submit-order button{margin-left:1.5em;}
.pt_order-confirmation .order-shipment-table{margin:0 0 20px;}
.pt_order-confirmation .order-shipment-table .order-shipment-details{border-left:1px solid #E0E0E0;}
.pt_order-confirmation #primary h2{margin:0 0 3% 0;padding:14px 0 10px;position:relative;width:100%;}

.pt_order-confirmation #primary{ border:none; width:73.7%; padding-top:4%;}
.pt_order-confirmation #secondary{ max-width:200px; padding-top:4%;}
.pt_order-confirmation .confirmation-secondary-rail {
	padding-top: 130px;

	.banner {
		padding: 0 0 40px;
	}

	.button {
		display: block;
		margin: 0 0 15px 0;
	}
}
.order-confirmation-details .order-details-content .order-shipment-table td.order-item-name{ width:35%;}
.order-confirmation-details .order-item-price{ width:17%;}
.order-confirmation-details .order-shipment-table tr:nth-child(even) {background:$lightbackdrop;}
.order-confirmation-details .recommended-product-tile { width:160px; float: left; margin:0 9px 0 0; }
.order-confirmation-details .recommended-product-tile .product-image{height:160px;}
.order-confirmation-details .www-recommendations{padding:0; overflow:hidden; list-style: none;}
.order-confirmation-details .www-recommendations-placeholder h2{border:none;}
.order-confirmation-details .product-remember{top:195px;}

.pt_order-confirmation a.print-page{float:right;}
.confirmation-message{margin-bottom:20px;padding-bottom:20px; width:72%;}
.gift-cert-used{padding:10px 0;}
.order-detail-options{border-top:1px solid #E0E0E0;margin:20px 0 0;padding:40px 0 0;}
.summary .edit {position: absolute;text-transform: uppercase;right: 10px;line-height: 1.5em;}
.pt_cart .section-header, #remembered-items-container tr th, .pt_remembereditems .section-header { @extend .cart-table-headers !optional;}

.pt_cart .breadcrumb {padding-left:0}
.pt_order-confirmation .order-confirmation-details .content-header {margin:7px 0 !important;}
.order-shipment-details .shipping-method { padding-left:0px;}
.order-shipment-details .label { position:absolute;width:150px;text-align:right;font-weight:bold}
.order-shipment-details .value { margin-left:155px;}
.order-shipment-details .order-shipment-address { width:240px;}
.order-shipment-gift-message-header {padding-bottom: 0px;}
.checkout-login-box .form-row-button #password-reset {display: block; margin: 0 0 20px;}
.js .ui-dialog.no-content-padding .dialog-content { padding: 0; }



//Share Order Popup
.ui-dialog.share-order-dialog .ui-dialog-title {text-transform: none; margin: 17px 16px 0 0;}
.ui-dialog.share-order-dialog .ui-dialog-titlebar {margin: 0 0 10px; height: auto;}
.ui-dialog.share-order-dialog .ui-dialog-content {margin: 0 0 25px; min-height:140px !important;}
.wrap-share-order h4 {margin: 0 0 25px; clear: both;}
.wrap-share-order ul {padding: 0;}
.wrap-share-order ul li {overflow: hidden; padding: 4%; background: $lightbackdrop;}
.wrap-share-order ul li:nth-child(even) {background:$secondarybackground;}
.wrap-share-order .product-image {width: 25%; float: left;}
.wrap-share-order .product-description {width:44%; margin: 5px 10px 0 0; float: left;}
.wrap-share-order .product-description p {margin: 0 0 5px; line-height: 18px; font-weight: bold;}
.wrap-share-order .share-order-button {float: right; margin: 5px 0 0 0;}
.wrap-share-order .button {color: $white !important; display: inline-block;}
.close-share-order {margin: 25px 0 20px; display: block; float: left; color: $white !important;}
//end Share Order Popup


// multishipping elements
// -------------------------------------
.cart-row:nth-child(odd) { background: $even-row-background; border-bottom: $even-row-border; }
@media(min-width: 767px) {
    table.item-list tr.cart-row,
    table#cart-table thead tr {
        border-bottom: 0;
    }
    table.item-list {
        tr {
            &.cart-row,
            &.cart-row-error {
                &:last-child {
                    border-bottom: $even-row-border;
                }
            }
            &.cart-row {
                border-top: $even-row-border;
                &:nth-child(odd) {
                    background: $even-row-background;
                }
            }
            &.rowcoupons {
            	&:nth-child(odd) {
            	    background: $even-row-background;
            	}
            }
            &.cart-row-error td {
                padding-top: 0;
                > p {
                    margin: 5px 0 0;
                    padding-left: 35px;
                    position: relative;
                    font-size: 12px;
                    line-height: 18px;
                    &:before {
                        content: '';
                        $cart-table-alert: (0, 0, 0, 0, 0, 0, 0, 0, '', 'default-icon', ) !default;
                        @include sprite($cart-table-alert, 2);
                        display: inline-block;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}

// account
// -------------------------------------
#PasswordResetForm {
	fieldset {
		position: relative;
	}

	button {
		clear:both;
		display:inline-block;
		margin-top:63px;
		width: 48%;
		height: 56px;
		@media screen and (max-width: 768px){
		  margin-top: 160px;
		  width: 47%;
		  height:56px;
		}
	}

	input {
		width:349px;
		height: 56px;
    	@media screen and (max-width: 768px){
      		width:309px;
      		height:56px;
     	}
	}
}

.ui-dialog .dialog-content #PasswordResetForm fieldset {
	padding-top: 5px;
	padding-bottom: 25px;
}

#RegistrationForm {

	.col-holder {
		width: 100%;
		@extend .clearfix !optional;

		input[type="radio"]{
			margin-top: .22rem;
		}
		.col-left {
      		box-sizing: border-box;
			float: left;
      		padding-right: 16px;
			width: 50%;
		}

		.col-right {
      		box-sizing: border-box;
			float: right;
			margin-top: 4%;
      		padding-left: 16px;
			width: 50%;
		}
	}

	.form-row.center {
		margin: 0 auto;
		text-align: center;

		button {
			margin: 0;
		}
	}

	.border-box {
		border: 1px solid $mainbordercolor;
		padding: 1% 2.5%;
		width: 41%;
		margin: 3% 0 0 0;
	}

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="password"],
  textarea {
    width: 100%;

    &[name*="_zip"] {
      max-width: 205px;
    }
	}

  select {width: 100%; max-width: 205px;}

	label,
	form-label-text {
		width: 100%;
	}

	.form-options .formrow-border {
		display: inline;

		input,
		span {
			display: inline-block;
		}
	}

	.border-box .formrow-border {
		padding-right: 8px;
	}

	.formrow-inline {
		label {
			display: inline-block;
			float: right;
			width: 94%;
		}

		input {
				float: left;
				margin-top: 0.6em;
			}
	}

	.form-row-verification {

		.form-row {
			overflow: hidden;
			margin: 0;
			float: left;

			label {
				float: right;
				margin: 0;
				width: 405px;
				padding-top: 0;
				line-height: 22px;

				span.nolink {
					color: #00204E;
					cursor: default;
				}

				.form-caption {
					display: block;
					width: 100%;
          			white-space: normal;
				}
			}

			input {
				float: left;
				margin-top: 0;
			}
			input[type="checkbox"]{
				margin-top: .22rem;
			}
		}

		.age-check .form-row {

			label {
				width: 405px;

				span.error-message {
					display: block;
					width: 100%;
					padding: 0;
				}
			}
		}
	}

	input.email-opt-in, input.legal-agreement {
		float: left;
    	vertical-align: top;
    	margin-top: .25rem;
	}

	span.email-opt-in {
    &:not(.new-registration){
      width: 285px;
      display: inline-block;
    }
		line-height: 22px;
	}

	.mkg-prefs {
		input {
			float: left;
		}

		label {
			float: right;
			width: 84%;
		}

		.form-row {
			overflow: hidden;
		}
	}
}

.user-contact-form label{width: 100% !important;}
#NewPasswordForm .form-caption.error-message {padding:0; }
#NewPasswordForm input[type='password'] { width:45%;  }
#NewPasswordForm .form-row label {width: 100%;}
#edit-address-form label, #edit-address-form.form-label-text{width:50%;}
.ui-dialog.resetPassword .dialog-content h1 {margin-right: 82px;}

h1 .capitalize{overflow-wrap: break-word;}
.registration-selection input,.registration-selection label{display:inline;float:none;}
ul.address-list{padding:0;}

ul.address-list li{
	float:left;
	list-style:none outside none;
	min-height:140px;
	background:$lightbackdrop;
}

ul.address-list li.default{background:$lightbackdrop;}
ul.address-list .mini-address-title{font-style:italic;}
.mini-address-location{
	padding:0 0 20px 0;
	span {
		display: block;
	}
}
ul.payment-list{overflow:hidden;padding:0;}
ul.payment-list li{float:left;list-style:none outside none;min-height:140px;}
ul.payment-list li.default{background:$lightbackdrop;}
ul.payment-list li .options{padding-top:20px;}
ul.payment-list li .options form{float:left; padding-right:5px;}
ul.payment-list .options .button-text.default{text-align:left; margin-right:0; border-right:1px solid $mainbrand; padding-right:5px;}
ul.payment-list .options .button-text.delete{min-width:50px; text-align:left;padding-left:0;}
.order-history-header{overflow:hidden;padding:10px 20px;}
.order-history-header button{float:right;}
.order-history-header .order-status{float:left;margin:2px 0;width:148px;}
.order-history-header .order-location{float:left;margin:2px 0;width:90px;}
.order-history-header .order-number{float:left;margin:2px 0;width:165px;}
.order-history-header .order-total{float:left;margin:2px 0;width:95px;}
.order-history-header .order-current-status{float:left;margin:2px 0;width:102px;}

.order-history-items{padding:20px; background:$lightbackdrop; overflow:hidden;}
div.order-history-header > div, div.order-history-items > div {text-align: left;}
.order-history-items .order-date, .order-history-items .order-location, .order-history-items .order-status, .order-history-items .order-number, .order-history-items .order-total{float:left; line-height:1.5em;}
.order-history-items .order-date{width:150px;}
.order-history-items .order-location{width:92px;}
.order-history-items .order-number{width:160px;}
.order-history-items .order-total{width:100px}
.order-history-items .order-status{width:100px;}
.order-history-header .order-current-status { margin-top: 2px;}
.order-history-items button{float:right; padding: 0.5em 0; width: 110px;}

ul.search-result-items.order-history li{overflow:hidden;}
ul.search-result-items.order-history li:nth-child(even) .order-history-items{background:$secondarybackground;}

.order-history-table th{padding:5px 10px;}
.order-history-table td{padding:5px 10px;}
.order-history-table ul{margin:0;padding:0;}
.order-history-table li{list-style:none outside none;}
.order-history-table th.order-shipped-to{width:33%;}
.order-history-table th.order-items{width:33%;}
.order-history-table th.order-total-price{width:33%;}
.order-history-table .toggle{cursor:pointer;display:block;}
.intro-box{ background:$lightbackdrop; padding:4em 2em;}
.js .order-history-table li.hidden{display:none;}

.pt_account #primary, .pt_order #primary, .pt_remembereditems #primary, .pt_multireturnexchange #primary { border:none; width:75.5%; padding-top:39px;}
.pt_account #primary.hiddenLeftnav{width:100%; padding-left:0;}
.pt_account #primary.hiddenLeftnav .col-1, .pt_account #primary.hiddenLeftnav .col-2{width:45%;}
.pt_account #secondary, .pt_order #secondary, .pt_remembereditems #secondary, .pt_multireturnexchange #secondary { max-width:170px; width:25%; padding-top:39px;}
.oldie .login-box.login-account form .formrow-border > label {padding-top: 1em;}

.account-overview ul.address-list, .account-overview ul.payment-list{clear:both; overflow:hidden;}
.account-overview .mini-address-location{padding:0;}
.account-overview h2{margin:0 0 3% 0;padding:14px 0 10px;position:relative;width:100%; border-bottom:1px solid #E5E5E5;}
.account-overview h2 span{padding:0 2%;}
.account-overview form{ padding:0 0 3%;}
.account-overview .intro-box{ background:$lightbackdrop; padding:2em; margin:0 0 3%; overflow:hidden;}
.account-overview .intro-box .section{float:left;margin:2px 0; line-height:1.6em; overflow-wrap: break-word; max-width: 275px; margin-right: 80px;}
.account-overview .intro-box .section + .section{ margin-right: 0;}
#account-nav-header { letter-spacing: 0.01em; }

.col-2 .login-account .login-box-content .label-inline {width: 100%; box-sizing:border-box;}
.col-2 .login-account .login-box-content .label-inline label {width: 92%; line-height: normal; @extend .login-box-text !optional; text-transform: none;}
.login-column.col-1, .login-column.col-2 {width: 47%; float: left;}

// Pro deal
// -------------------------------------
.prodeal-account-overview .intro-box{ background:none; padding:2em 0 0 0; margin:0 0 3%; overflow:hidden; position:relative;}
.prodeal-account-overview .intro-box .section{float:left;margin:2px 0; line-height:1.6em;}
.prodeal-account-overview .intro-box .section-row{float:left;margin:0 10px 0 0; width:30%;}
.prodeal-account-overview .intro-box .section-row .section{width:100%; margin:0 0 10px;}
.prodeal-account-overview .intro-box .text-right { position:absolute; bottom:16px; right:0; width:200px;}
.prodeal-application-register{float:left; width: 350px; padding-right:20px;}
.prodeal-application-help{float:left;  width: 320px; padding-top:98px;}
.prodeal-application-help .top, .prodeal-application-help .bottom{ padding: 20px;}

.prodeal-application-register #RegistrationForm .form-row,
.prodeal-application-register #RegistrationForm .form-caption,
.prodeal-application-register select{width:100%;}

.prodeal-application-help .helper-text, .prodeal-account-exists-help .note{color:$subnavtext; border:1px solid $mainbordercolor; padding:4%; width:90%; margin:0 0 10% 0;}
.prodeal-account-exists-help .note{width:46%; padding:3%; margin:0 0 1em;}
.prodeal-account-exists-form{ width:52%;}
.prodeal-account-exists-form label{ width:50%;}

.prodeal-login-wrapper {
  > div {
	 width: 442px;
	 float: left;
  }
  .col-1 { margin-right: 33px; }
}

.prodeal-autoregistration-form label {
	width: auto;
}

.prodeal-autoregistration-form-address {
	width: 50%;
	float: right;
	margin-top: 20px;
}
.prodeal-autoregistration-form-login {
	@extend .prodeal-autoregistration-form-address !optional;
	float: left;
}


// wishlist
// -------------------------------------

.pt_wish-list .list-table-header{background:url("../images/interface/icon-double-line-header.png") repeat-x scroll left top #EFEFEF;overflow:hidden;padding:2em;}
.pt_wish-list .list-table-header p{color:$grey-medium-light;float:right;margin:0;overflow:hidden;width:37%;}
.pt_wish-list .primary-content .list-table-header h2{float: left;}

// gift registry
// -------------------------------------

.pt_gift-registry .create-new-registry{margin:1em 0;}
.pt_gift-registry .create-new-registry button{float:left;margin-right:2em;}
.pt_gift-registry .create-new-registry p{margin:0;padding:0.5em 2em;}
.pt_gift-registry .add-gift-cert{border:1px solid #EFEFEF;margin-bottom:-1px;overflow:auto;padding:10px;}
.pt_gift-registry .add-gift-cert button{background:url("../images/banners/banner-gift-cert.gif") no-repeat scroll left top transparent;border:0 none;float:left;height:80px;text-indent:-999em;width:433px;}
.pt_gift-registry .add-gift-cert p{color:$grey-medium-light;float:right;font-style:italic;line-height:1.5em;width:40%;}
.pt_gift-registry .list-table-header{background:url("../images/interface/icon-double-line-header.png") repeat-x scroll left top #EFEFEF;overflow:hidden;padding:2em;}
.pt_gift-registry .hdr-options{width:5%;}
.page-content-tab-navigaton button{background:none repeat scroll 0 0 #D9D9D9;border-color:#EFEFEF;border-width:1px; margin:0 0 -1px;}
.page-content-tab-navigaton button.selected{background:none repeat scroll 0 0 $white;border-bottom:1px solid $white;}
.page-content-tab-wrapper{border:1px solid #EFEFEF;padding:5px;}
.gift-reg-purchases .item-purchased-by dd{float:left;font-weight:bold;margin:0 1em 0 0;position:relative;width:auto;}
.gift-reg-purchases .item-purchased-by dt{clear:both;float:left;margin:0 1em 0 0;position:relative;width:auto;}
.gift-reg-purchases .quantity-purchased{color: $callout;}
.gift-registry-advanced {clear: both;}
.gift-registry-advanced .toggle-content {overflow:hidden;padding:10px;}
.gift-registry-advanced .event-month {clear: both;}
.gift-registry-advanced .event-city {clear: both;}
.pt_gift-registry .primary-content .list-table-header h2{float:left;}

// gift certificates
// -------------------------------------

.gift-certificate-purchase{background:none repeat scroll 0 0 #F3F3F3;padding:10px;}
.gift-certificate-purchase .content-asset{float:left;width:50%;}
.gift-certificate-purchase .content-asset img{margin:20px 0;}
.gift-certificate-purchase form{background:none repeat scroll 0 0 $white;float:right;padding:0 20px 20px;width:40%;}
.gift-certificate-purchase form .input-text{width:90%;}
.gift-certificate-purchase form .input-textarea{width:90%;}
.gift-certificate-purchase form .cert-amount{clear:none;float:left;margin:0;width:40%;}
.gift-certificate-purchase form .form-row-button{clear:none;float:left;margin:25px 0 0 20px;width:50%;}
.gift-certificate-purchase .cert-amount .form-caption{margin:20px 0 0;position:absolute;width:40%;}
.gift-certificate-purchase .cert-amount.error{padding-bottom:35px;}
.gift-certificate-purchase span.error {margin-left:2%;}
.check-balance{border-bottom:1px solid #D9D9D9;margin:0 0 20px;padding:0 0 20px;}
.gift-cert-balance{float:left;width:40%;}
.gift-cert-balance input{margin-top:0;width:90%;}
.gift-cert-balance .form-caption{margin-left:0;}
.gift-cert-balance-submit{clear:none;float:left;}
.gift-cert-balance .label {display:none;}
// login pages
// -------------------------------------
//BD-254 login modal change in background color 
#checkout-login-popup-modal{background:none repeat scroll 0 0;}
.login-box{background:none repeat scroll 0 0 $lightbackdrop; position: relative;}
#modalCheckoutLogin fieldset .form-row.form-row-button p { margin: 2px; }
#RegistrationForm1 .form-row-button {width: 80%;}
#RegistrationForm1 .idme-buttons div {display: inline-block; margin-right: 30px;}
.checkout-login-box .login-box h2{@extend .heading2 !optional}
.login-box .section-header{margin:0; padding:1em 0 0 0.8em; position:relative; border:none;}
.login-box h3{margin:0;}
.login-box-content{padding:0.5em 1.5em 1.5em;}
.login-box.login-order-track, .login-box.login-order-track .form-row-button{background:none; padding:1.5em 0;}
.login-box.login-order-track .login-box-content{padding:0; width:48%;}
.login-box.login-order-track .helper-text{float:right; margin-top:32px; color:$subnavtext; border:1px solid $mainbordercolor; padding:3%; width:41%; font-style:italic;}
.login-divider{ float:left; margin:192px 0.5% 0 0.5%; text-transform:uppercase; width: 5%; text-align: center;}
.pt_account .login-account .login-box-content, .pt_account .login-create-account .login-box-content, .col-1.login-column, .login-box-content { @extend .login-box-text !optional}
div.login-box.login-order-track {min-height: 285px;}
div.login-box.login-order-track .login-box-content .form-row-button {position: relative;}
.login-dialog iframe { width: 800px;}
// category landing page
// -------------------------------------

.bottom-banner-container{overflow:hidden;}
.bottom-banner-container .bottom-banner-cell{float:left;overflow:hidden;text-align:center;}
.bottom-banner-container .banner-cell-1{width:33%;}
.bottom-banner-container .banner-cell-2{width:34%;}
.bottom-banner-container .banner-cell-3{width:33%;}
.cat-landing.cat-landing-a .stackme {float: left; margin:5px 0 0 6px; padding:0 !important; width:300px;}
.cat-landing.cat-landing-a .stackme:first-child {margin-left:3px;}
.cat-landing.cat-landing-a .stackme div { width:300px;}

.cat-landing.cat-landing-a .marketing-slots.horizontal-stack .stackme p {margin: 0;}
.cat-landing.cat-landing-a .marketing-slots.horizontal-stack .stackme:first-child, .cat-landing.cat-landing-a .marketing-slots.horizontal-stack .stackme {margin: 0;}
.cat-landing.cat-landing-a .marketing-slots.horizontal-stack .stackme {width: 33.3%; max-height: 387px; overflow: hidden;}
.cat-landing.cat-landing-a .marketing-slots.horizontal-stack {overflow:auto;}
.cat-landing.cat-landing-a .marketing-slots.horizontal-stack div {width: auto;}
.cat-landing.cat-landing-a .marketing-slots.horizontal-stack img {max-width: 100%;}

.cat-landing.cat-landing-a .marketing-tabs.ui-tabs {width: 99.4%;}
.cat-landing.cat-landing-a .marketing-tabs.ui-tabs .ui-tabs-nav li {margin-right: 1.11%; display: block; text-align: center; width: 219px;}
.cat-landing.cat-landing-a .marketing-tabs.ui-tabs .ui-tabs-nav li.last {margin-right: 0;}
.cat-landing.cat-landing-a .marketing-tabs.ui-tabs .ui-tabs-nav li a {display: inline-block; width: 96%; white-space: normal; padding: 0.7em 2%;}

.cat-landing.cat-landing-b h1, .cat-landing.cat-landing-c h1 { margin: .45em 0 0.6em;}
.cat-landing.cat-landing-b ul.search-result-items .grid-tile .product-tile .product-arrival, .cat-landing.cat-landing-c ul.search-result-items .grid-tile .product-tile .product-arrival{ top: 40px; left: 10px; }
.cat-landing.cat-landing-c a button, .cat-landing.cat-landing-c a.button{float: right; margin-top: -57px; margin-right: 8px;}

.cat-landing.cat-landing-c a.button,
.cat-landing a.button {
	@extend .normal-cta !optional;
	width: auto;
	min-width: 190px;
}

.cat-landing.cat-landing-b {
	.flex-direction-nav .flex-next {
		text-indent:999px;
		&:before {
		$icon-carousel-right: ('', '', '', '', '', '', '', '', '', 'default-icon', ) !default;
		@include sprite($icon-carousel-right);

		position: absolute;
		content: " ";
		left: 0; right: 0;
		margin: auto;
		}
	}
	.flex-direction-nav .flex-prev {
		text-indent:999px;
		&:before {
		$icon-carousel-left: ('', '', '', '', '', '', '', '', '', 'default-icon', ) !default;
		@include sprite($icon-carousel-left);

		position: absolute;
		content: " ";
		left: 0; right: 0;
		margin: auto;
		}
	}
}

.cat-landing.cat-landing-d ul.search-result-items .grid-tile .product-tile .product-arrival, .cat-landing.cat-landing-e ul.search-result-items .grid-tile .product-tile .product-arrival { top: 40px; }
.cat-landing.cat-landing-d h1 {margin: 0.67em 0 10px;}
.pt_product-search-result .cat-landing-e .category-col {margin-bottom: 30px;}
 .pt_product-search-result .cat-landing-d .category-col {margin-bottom: 0px;}
.cat-landing.cat-landing-e .marketing-bottom-slot .html-slot-container { height: 250px;}
.cat-landing.cat-landing-e .vertical-category-links .category-col { margin-top: 20px; float: left; width: 460px;}
.pt_product-search-result .cat-landing .vertical-category-links .category-col.category-col2 { float: right; }
.pt_product-search-result .cat-landing.cat-landing-d .vertical-category-links .category-col1 {float: left;}
.cat-landing.cat-landing-g .jcarousel-next { right: 0; top: 80px;}
.cat-landing.cat-landing-g .jcarousel-prev { left: 0; top: 80px; }
.flex-direction-nav .flex-next {background-position: 100% -58px;}
.flex-control-nav {bottom:20px;}
.cat-landing.cat-landing-g .product-tile .product-name, .cat-landing.cat-landing-h .product-tile .product-name {text-transform: uppercase; font-size: 0.923em;}
.cat-landing.cat-landing-g .product-tile .product-name a, .cat-landing.cat-landing-h .product-tile .product-name a {letter-spacing: 0.1em;}
.cat-landing.cat-landing-g .marketing-slots .left, .cat-landing.cat-landing-g .marketing-slots .right {margin-top:40px}
.primary-content .cat-landing.cat-landing-g .jcarousel-container, .primary-content .cat-landing.cat-landing-h .jcarousel-container { width: 669px;}
.primary-content .cat-landing.cat-landing-g .product-carousel .jcarousel-clip, .primary-content .cat-landing.cat-landing-h .product-carousel .jcarousel-clip { width: 640px;}
.cat-landing.cat-landing-g .product-carousel .jcarousel-item, .cat-landing.cat-landing-h .product-carousel .jcarousel-item { margin-right: 25px; margin-left: 0;}
.primary-content .cat-landing.cat-landing-h .jcarousel-prev, .primary-content .cat-landing.cat-landing-h .jcarousel-next { top: 81px; }

.cat-landing.cat-landing-g .product-carousel ul, .cat-landing.cat-landing-h .product-carousel ul {margin: 0; padding: 0;}
.cat-landing.cat-landing-g .product-carousel ul li, .cat-landing.cat-landing-h .product-carousel ul li {width: 195px; float: left; margin-left: 20px;}
.cat-landing.cat-landing-g .product-carousel ul li:first-child, .cat-landing.cat-landing-h .product-carousel ul li:first-child {margin-left: 0;}

.cat-landing.cat-landing-h .search-result-content, .cat-landing.cat-landing-g .search-result-content { padding: 0; margin: 0; }
.cat-landing.cat-landing-h .marketing-slots.horizontal-stack .stackme {padding: 0 3px;}
.cat-landing.cat-landing-h .html-slot-container{margin-bottom: 30px;}
.cat-landing.cat-landing-h .viewall {position:relative;top:-46px;float:right;color:$primarylink; a {text-decoration:underline;text-transform:uppercase;color:$primarylink;font-family: $font-type2;font-size:18px;}  a:after {content: " >";display:inline-block;text-decoration:none;}}

.cat-landing.cat-landing-g #category-level-1, .cat-landing.cat-landing-h #category-level-1 {padding: 7px 9px 18px;}
.cat-landing.cat-landing-g #category-level-1  li, .cat-landing.cat-landing-h #category-level-1 li {padding: 2px 5px; margin-top: 7px;}
.cat-landing.cat-landing-g #category-level-1  li.level-2, .cat-landing.cat-landing-h #category-level-1 li.level-2,
.cat-landing.cat-landing-g #category-level-1  li.level-3, .cat-landing.cat-landing-h #category-level-1 li.level-3 {margin-top: 0;}
.cat-landing.cat-landing-g #category-level-1 li.expandable, .cat-landing.cat-landing-h #category-level-1 li.expandable {background: none;}
.cat-landing.cat-landing-g #category-level-1  li a, .cat-landing.cat-landing-h #category-level-1 li a {padding: 3px 5px 3px 0; @extend .cat-h-nav-heading !optional}
.cat-landing.cat-landing-g #category-level-1  li.level-2 a, .cat-landing.cat-landing-h #category-level-1 li.level-2 a,
.cat-landing.cat-landing-g #category-level-1  li.level-3 a, .cat-landing.cat-landing-h #category-level-1 li.level-3 a {@extend .category-sub-level-links !optional;}
.cat-landing #category-level-1  li.level-2 a:hover, .cat-landing #category-level-1 li.level-3 a:hover {color: $primarylink;}

//Category Landing I
.cat-landing.cat-landing-i .search-result-content {
	h1 {
		float: left;
	}
  .search-result-items.tiles-container.clearfix {
    margin-bottom: 50px;
  }
  a.button{
    position: relative;
    top: -12px;
    float: right;
  }
}

// catalogsignup
// -------------------------------------
.catalog-modal {height: 500px !important; overflow: auto !important;}
.catalog-modal .catalogsignup #edit-address-form label {width: 100%;}
.catalogsignup .signupform{ float: left; width:326px;}
.catalogsignup .signupcontent{ float: left; padding: 30px 0 30px 60px;}
.catalogsignup .signupform label, .catalogsignup .signupform input{ width:96.5%;}
.catalogsignup .signupform input.input-radio{ width:auto; margin:1em 1% 0 0;}
.catalogsignup legend{ padding:30px 0 10px; margin:0 0 5%;}
.catalogsignup .divider{border-bottom:1px solid #E5E5E5; margin-bottom:6%; padding:5% 0 0 0; width:224%;}

//Grant form
//---------------------------------------------
.trail-grant{ clear:both; position:relative;}
.trail-grant .left, .trail-grant .right, .trail-grant select{width:50%;}
.trail-grant .form-row, .trail-grant .form-caption{ width:90%;}
.trail-grant textarea{height: 250px;width:100%;}
.trail-grant .full-width{width:100%;}
.trail-grant .form-row input {width:75%;}
.trail-grant .form-row select {width:79%; height:45px;}
.trail-grant .shift .form-row{margin-left:57px;}
.trail-grant .additionalinfo{font-family: "Knockout 50 A", "Knockout 50 B", Arial, "Helvetica Neue", Helvetica, sans-serif;color: $grey-charcoal-dark;text-transform: uppercase;}
.trail-grant .questions{color: $grey-medium;}
.trail-grant .info { padding-top:50px;}

// homepage elements
// -------------------------------------

//Moved to branding.scss as each brand has a unique implementation
#homepage-slides{padding:0;width:100%;}
#homepage-slider{overflow:hidden;}
#homepage-slider {
				 ul.flex-direction-nav {
				 width: 900px;
				 margin: auto;
				  li {
				   position: absolute; top: 50%;

				   a { position: relative;
					   -webkit-transform: translate3d(0, 0, 0);
					   transform: translate3d(0, 0, 0); }

				   a.flex-next { margin-left: 860px}
					  }
				   }
				   ol {
					z-index: 10;
					li{ a {
								background: rgba(255, 255, 255, 0.6);
								}
							a.flex-active { background: rgba(255, 255, 255, 0.99); }
						  }
					  }
				  }

#homepage-slider .slide{list-style:none outside none;margin:0;max-width:100% !important;padding:0;width:100%;}
#homepage-slider .slide img{height:auto;max-width:100% !important;}
#homepage-slider .slide a h2{border:0 none;clip:rect(0pt,0pt,0pt,0pt);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px;}
#homepage-slider .slide a span{position:absolute;text-indent:-999em;}

//Demandware < ie8 fallbacks
.ie8 #homepage-slider ol li a, .ie7 #homepage-slider ol li a { background: url("../images/flex-nav-circle.png");  }
.ie8 #homepage-slider ol li a.flex-active, .ie7 #homepage-slider ol li a.flex-active {  background: url("../images/flex-nav-circle-active.png");  }


.home-bottom-left {float:left;width:33.3%;}
.home-main-box {text-align: center;}
.html-bottom-block { width: 880px; margin: 15px auto; position: relative; @extend .bottom-block-style !optional; }

.html-bottom-block.html-bottom-block-grid { @extend .alt-bottom-block-style !optional }

#home-promo-shop-shoes{background-attachment:scroll;background-clip:border-box;background-color:transparent;background-image:url("../images/homepage/home-promo-shoes.png");background-origin:padding-box;background-position:left top;background-repeat:no-repeat;background-size:100% auto;height:413px;margin:auto;max-width:320px;position:relative;width:100%;}
#home-promo-shop-shoes h2{position:absolute;text-indent:-999em;}
#home-promo-shop-shoes ul{padding:30% 0 0;text-align:center;}
#home-promo-shop-shoes li{border-right:1px solid #3EBAEA;display:inline;list-style:none outside none;margin:0 5px 0 0;padding:0 5px 0 0;text-transform:uppercase;}
#home-promo-shop-shoes li:last-child{border:0 none;}
#home-promo-shop-shoes li a{color:#3EBAEA;}
#home-promo-shop-shoes span{font-style:italic;margin:0 10px 0 0;}
.home-bottom-center{background:none repeat scroll 0 0 #F3F3F3;float:left;width:33.3%;}
#home-promo-free-shipping{background-attachment:scroll;background-clip:border-box;background-color:transparent;background-image:url("../images/homepage/home-promo-free-shipping.png");background-origin:padding-box;background-position:center top;background-repeat:no-repeat;background-size:100% auto;height:413px;margin:auto;max-width:319px;text-indent:-999em;width:100%;}

.home-bottom-slots {margin: 0 0 45px;}
.home-bottom-tertiary {clear: both;}
.home-bottom-right{clear:right;margin:0;width:33.3%;}
.home-bottom-right h2{background:url("../images/homepage/hdr-this-weeks-deals.png") no-repeat scroll center center transparent;border-bottom:2px solid #01A4E5;height:54px;padding:30px 0;text-indent:-999em;}
.home-bottom-right .vertical-carousel{margin:0;padding:0;}
.home-bottom-right .vertical-carousel li{float:left;list-style:none outside none;padding:0;width:25%;}
.home-bottom-right .vertical-carousel li .capture-product-id{display:none;}
.home-bottom-right .vertical-carousel li .product-price .product-standard-price{text-decoration:line-through;}
.home-bottom-right .product-tile{border:0 none;margin:auto;min-height:255px;width:90%;}
.home-bottom-right .product-tile img{max-width:90%;}
.home-bottom{clear:both;overflow:hidden;padding:2em 0;}
#email-alert-signup{display:block;margin:0 0 0 33px;width:910px;}
#email-alert-signup label{color:#8F8F8F;float:left;padding:0.5em 0 !important;text-transform:uppercase;width:50%;}
#email-alert-signup input[type="text"]{float:left;padding:0.7em 0.5em;width:30%;}
#email-alert-signup input[type="image"]{float:left;padding:0.1em;}
#edit-address-form .form-caption.error-message { padding:0;}
#edit-address-form.giveaway-form .form-caption.error-message {display: block;width: 100%;}

.home-promo-boxes {margin: 0 0 45px;}
.home-promo-boxes > div {float: left; max-width: 460px; width: 50%;}
.home-promo-boxes .home-promo-banner {width: 100%; max-width: 100%; }
.home-promo-boxes .home-promo-banner img { left: 50%;margin-left: -801px;position: relative; }

//home email signup
.home-email-signup {margin: 0 0 20px;}
.home-email-signup-inner {border: 1px solid $mainbordercolor; padding: 2.7%; overflow: hidden;}
.home-email-signup .image-hold {float: left; max-width: 260px; margin: 0 30px 0 0;}
.home-email-signup .image-hold > img {display: block;}
.home-email-signup .text-hold {float: left; max-width: 565px; min-height: 103px; position: relative;}
.home-email-signup h4 {text-transform: uppercase; font-size: 2.000em; line-height: 30px; font-weight: normal; margin: 0 0 25px; padding: 3px 0 25px; letter-spacing: normal; font-family: 'Miller Display',Georgia,Garamond,"Times New Roman",serif;}
.home-email-signup .text-hold button {position: absolute; left: 0; bottom: 0; transition: background-color .2s; -webkit-transition: background-color .2s; -moz-transition: background-color .2s; -ms-transition: background-color .2s; -o-transition: background-color .2s;}
.home-email-signup button {font-size: 0.769em; padding: 1.5em 4em; display: block;}
.home-email-signup button:hover {background: $primarylink;}

//home social
.home-social-box {margin:0 0 20px; padding:0;}
.home-social-box h3 {text-transform: uppercase; font-weight: normal; margin: 0 0 15px; font-size: 1.385em; font-family:$font-type1; line-height:38px; height:28px;}
.home-social-box h3 span {font-weight: normal; font-family:$font-type2;}
.home-social-box h3 a {text-transform: none; font-weight: normal; color: $mainbrand; font-family: Georgia,serif;}
.home-social-box h3 a:hover, .home-social-box ul li a:hover {color:$primarylink;}
.home-social-box ul {margin: 0; padding: 0;}
.home-social-box ul li {float: left; width: 25%; max-width: 230px; height: 230px;}
.home-social-box ul li.text-box {background: $backgroundaccent; padding: 20px 2%; width: 21%; height: 190px;}
.home-social-box ul li.text-box > span {color: $subnavtext; text-transform: uppercase; font-size: 0.923em; font-family: Arial,sans-serif; font-weight: bold; margin: 0 0 35px; display: block;}
.home-social-box ul li.text-box p {margin: 0; line-height: 18px;}
.home-social-box ul li a {font-weight: bold; color: $mainbrand;}
.home-social-box .tweet-icon {background:url("../images/icon-social-tweet.png") no-repeat scroll 0 0; width: 21px; height: 19px; display: block;}
.home-social-box ul li.blog-text p{font-size: 1.385em; line-height: 22px; font-family:$font-type2;}
.home-social-box .left, .home-social-box .right{width:40%; padding:20px 0 0;}

//homepage product set carousel

.home-container-box .product-set-carousel {
	width: 920px;
	position: relative;
	margin: 0px auto;
	overflow: hidden;
	.flex-direction-nav {
		.flex-next, .flex-prev { background:url('../images/interface/ps-arrows-vertical-carousel.png') 0 0 no-repeat; }
		.flex-next { right: 10px; opacity: 1;background-position: 100% -58px;}
		.flex-prev { left: 10px; opacity: 1;}
	}
	img {
		width: initial;
	}
	.product-set-image {
		float: left;
		width:400px;
		height:600px;
		overflow:hidden;
		margin-left:50px;
	}
	.product-set-items {
		text-align: center;
		float: right;
		width: 470px;
		ul {
			margin: 48px 0 0 43px;
			padding: 0;
			li{
				float: left;
				width: 170px;
				min-height:210px;
				margin:0 10px;
				list-style: none;
				.product-image.small {
					margin: 13px 0;
					.product-name {
						a { font-family: "Interstate Black";
							font-size: 15px;
						}
					}
				}
			}
		}
	}
}

.home-product-carousel {
	background:$white;
	.product-swatches  {
		overflow:hidden;
		height:27px;
	}
}
//Quicklinks home page
.quicklinks-box {@extend .quicklink-home-wrapper !optional;}
.quicklinks-box .quicklinks-label {text-transform: uppercase; font-style: italic; color: $fontaccent; display: inline-block; background: none; margin: 4px 15px; letter-spacing: 0.1em; font-size: 0.923em;}
.quicklinks-box ul {padding: 2px 17px; display: inline-block; margin: 0;}
.quicklinks-box ul li {@extend .quick-links !optional}

.ie7 .quicklinks-box ul li {float: left;}

// JavaScript specific styles

/* Styles for loading spinner:
   The .loader-box class will only be used when the spinner needs to be contained in a specific area */
.loader-box {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	min-height: 64px;
}
/* Spinner box & spinner definitions */
.js .loader-bg {
	position: absolute;
  top: 0;
  left: 0;
	width: 100%;
	height: 100%;
	background-color: $white;
	opacity: 0.7;
}
.js .loader-indicator {
	position: absolute;
	left: 50%;
	top: 35%;
	width: 32px;
	height: 64px;
	transform: translateX(-50%);
	background: url("../images/loading-small.gif") no-repeat;
}
/* End of loading spinner */

.js .quickviewbutton { @extend .normal-cta !optional;}
.js .ie7 .quickviewbutton {position:absolute;left:0;width:200px;}
.js .product-image:hover .quickviewbutton-box {display:block;}
.js .quickviewbutton-box {width: 100%; display:none;position: absolute; top: $quickview-btn-top-margin; left: 0; cursor: pointer; text-align: center;}

// SEARCH SUGGESTION
// ---------------------------------------------------------------------------------------
.js #suggestions {
	width: 243px;
	border: 1px solid $mainbordercolor;
	background-color: $white;
	cursor: pointer;
	padding: 15px;
	display: none;
	position: absolute;
	z-index: 8184;
	top: 80px;
	right: 0;
	@include header-shadow();
}

.js #suggestions .additionalcontent{width:243px; overflow:hidden;}
.js #suggestions div.suggestion {height:27px; line-height:27px;overflow: hidden;white-space:nowrap; border-bottom:1px solid $mainbordercolor; }
.js #suggestions div.suggestion:hover {color:$primarylink;}
.js #suggestions div div.suggestionterm {float:left;overflow: hidden;width: 110px;}
.js #suggestions div span.hits {float: right;line-height: 1.8em;text-align: right;margin-left: 3px;}

.js #suggestions div.productsuggestion {overflow: hidden;border-bottom:1px solid $mainbordercolor; padding:15px 0;}
.js #suggestions div.productsuggestion.last{ margin-bottom:15px;}

.js #suggestions div.viewall {
	height:27px;
	line-height:27px;
	border-bottom:1px solid $mainbordercolor;
}

#lhnContainerDone div {display: none;}

.js #suggestions div div.productsuggestionterm {
	float:left;
	overflow: hidden;
	width:100%;
}

.js #suggestions div div.productsuggestionterm .product-tile{
	text-align: left;
	padding: 10px 0 5px;
	display: block;

	.product-remember {
		left: 86%;
		top: 42%;
	}
}

.js #suggestions div div.productsuggestionterm {
	.productsuggestion-name,
	.productsuggestion-price,
	.productsuggestion-image {float:left;}
}

.js #suggestions div div.productsuggestionterm .product-tile .product-image.small{
	float:left;
	margin-right:15px;
	width: 30%;
	height: 77px;
}

.js #suggestions div div.productsuggestionterm .product-tile .product-name {
	margin:0 0 5px;
	float: right;
	width: 58%;
	word-wrap: break-word;
	line-height: 17px;
}

.js #suggestions div div.productsuggestionterm .product-tile .product-pricing {
	float: right;
	width: 58%;
	margin-bottom: 7px;
}

.js #suggestions div div.productsuggestionterm .product-tile .pr_stars {
	float:left;
	width:137px;
	text-align:left;
}

.js #suggestions a.suggestion-shop-now {
	text-align: left;
	font-family: $suggestion-shop-btn;
}

.js #suggestions .product-review, .js #suggestions .suggestion-shop-box {
	width: 58%;
	float: right;
}

.js #suggestions .product-review .popup-rating {float: left;}
.js #suggestions .rating-container-selected {background: url("../images/star-rating-icon-small-active.png") no-repeat 0 0; width: 15px; height: 12px;}
.js #suggestions .rating-container {background: url("../images/star-rating-icon-small-no-active.png") no-repeat scroll 0 0; width: 73px; height: 12px; margin-bottom: 7px;}
.js #suggestions a:hover.suggestion-shop-now,
.js #suggestions div.productsuggestion  a:hover.suggestion-shop-now {color: $mainbrand;}

.js .mini-cart-product {position:relative;}
.js .mini-cart-toggler {
	background: url(../images/minicart_expanded.png) repeat scroll 0 0 transparent;
	cursor: pointer;
	height: 13px;
	left: 0px;
	position: absolute;
	top: 10px;
	width: 13px;
}

.js div.collapsed .mini-cart-toggler {
	background: url(../images/minicart_collapsed.png) repeat scroll 0 0 transparent;

}

.js div.collapsed .mini-cart-image {
	display:none;
}

// quick view
// ---------------------------------------------------------------------------------------
.js .ui-dialog-content a.inlineblock{display:inline-block;}
.js .ui-dialog-content .product-col-1{padding: 13px 0;width:43%;}
.js .ui-dialog-content .product-col-1 .product-image {
	max-width: 100%;

	img {
		max-width: 100%;
	}
}

.bonus-dialog .pdp-main .product-primary-image {
	max-width: 100%;

	img {
		max-width: 100%;
	}
}
.js .ui-dialog-content .product-col-2{padding-bottom: 20px;width:318px;}
.js .ui-dialog-content .pdp-main .product-set-list {border:1px solid #d9d9d9;height: 500px;overflow:auto;}
.js .ui-dialog-content .pdp-main .product-name { margin-top: 12px; }
.js .ui-dialog-content .pdp-main .product-set-list .product-set-item {margin:1em 0;}
.js .ui-dialog-content .pdp-main .product-set-list .product-set-image {width: 75%;}
.js .ui-dialog-content .pdp-main .product-set-list .product-set-details {width: 100%;}
.js .ui-dialog-content .pdp-main .product-set-list .product-name {margin:0;}
.js .ui-dialog-content .pdp-main .product-set-list .block-add-to-cart .price {padding: 0.4em 0 0;width: 130px;}
.js .ui-dialog-content .pdp-main .primary-image { width: 275px; }
.js .ui-dialog-content .pdp-main .full-details-link a { margin: .8em 0; display: block; margin-top: -13px; width: 120px; outline:none}
.js .ui-dialog-content .pdp-main .full-details-link a:hover {color: $primarylink }
.js .ui-dialog-content .pdp-main .full-details-link {float: right; margin-top: 10px;}
.js .ui-dialog-content .pdp-main h1.product-name {width: 40%; }
.js .ui-dialog-content .thumbnail-carousel .jcarousel-clip {width: 243px;}
.js .ui-dialog-content .pdp-main .promotion {width: 310px;}
.js .ui-dialog .full-details-link { box-sizing: border-box; width: 615px; }
.js .ui-dialog-content .pdp-main .product-thumbnails {margin-left: -28px;}
.js .ui-dialog-content .pdp-main .product-variations .value .jcarousel-clip.jcarousel-clip-horizontal {width: 296px; overflow: hidden; margin-left: 10px;}
.js .ui-dialog-content .pdp-main .product-variations .value.jcarousel-container.jcarousel-container-horizontal { width: 316px;}
.js .ui-dialog-content .pdp-main .attribute .label {padding-left: 10px;}
.js .ui-dialog-content .pdp-main .label.customertype { padding-left: 0px; }
.js .ui-dialog-content .pdp-main .product-variations.dropdownvariation .toggle-content .label {padding-left: 0;}
.js .ui-dialog-content .pdp-main .giftcardproduct-content .variations-other {width: 155px;}
.js .ui-dialog-content .pdp-main .giftcardproduct-content .variations-other .attribute{margin-top:0;}
.js .ui-dialog-content .pdp-main .variations-color {margin: 0 0 35px 0;}
.js .ui-dialog-content .pdp-main .product-variations.dropdownvariation .toggle-content li.attribute:first-child {width: 180px; margin-top: 0.5em;}
.js .ui-dialog-content .pdp-main .product-variations.dropdownvariation .toggle-content .attribute {margin: 0.5em 0 0 5px;}
.js .ui-dialog-content .pdp-main .product-variations.dropdownvariation .toggle-content {width: 350px;}
.js .ui-dialog-content .pdp-main .button-select-bonus {@extend .cta-primary;}
.js .ui-dialog-content .pdp-main .product-actions {padding: 0.5em 0 0.4em;}
.js .ui-dialog-content .pdp-main .product-description {clear: both;}
.js .ui-dialog-content .pdp-main .review-marketing-snippet {width: 275px; margin: 14px 45px 10px 10px;}
.js .ui-dialog-content .pdp-main .product-review-links {float: left; width: 160px;}
.js .ui-dialog-content .pdp-main .review-marketing-snippet .rating-container {float: none;}
.js .ui-dialog-content .pdp-main .review-marketing-snippet .product-review-links > a {display: block; padding: 2px 0;}
.js .ui-dialog-content .pdp-main .pr_stars {float:left;}
.js .ui-dialog-content .pdp-main .mcallout {float:right; margin-right: -20px;}
.js .ui-dialog-content .product-add-to-cart {clear:both;}
.js .ui-dialog-content #add-to-cart {margin: 10px 0; clear:left;}
.js .ui-dialog-content .pdp-main .image-callout-badges { position: absolute; top: 0; text-align: right; }
.js .ui-dialog-content .pdp-main .twitter-share-button { max-width: 90px; }
.js .ui-dialog-content .attributecontentlink.dialogify {float:left; margin: 5px 0;}
.js .ui-dialog-content .jcarousel-next {right: 4px;}
.js .ui-dialog-content .jcarousel-prev {left: 8px;}
.js .ui-dialog-content .thumb.jcarousel-item {margin: 0 5px 0 6px;}
.js .ui-dialog-content .pdp-main .sale-callout {width: 295px;}

//Twitter button in english....
html[lang="en"] .pdp-main .twitter-share-button { max-width: 79px!important}
html[lang="de"] .pdp-main .twitter-share-button { max-width: 91px!important}
html[lang="fr"] .pdp-main .twitter-share-button { max-width: 91px!important}

.js .ui-dialog-content .pdp-main .product-detail #product-content .quantity {margin-bottom: 12px;}
.js .zoomPad{position:initial;float:left;z-index:99;cursor:crosshair;width:100%;}
.js .zoomPreload{-moz-opacity:0.8;opacity:0.8;color:$grey-charcoal-dark;font-size:12px;font-family:Tahoma;text-decoration:none;border:1px solid $grey-silver;background-color:white;padding:8px;text-align:center;background-image:url(../images/zoomloader.gif);background-repeat:no-repeat;background-position:43px 30px;z-index:110;width:90px;height:43px;position:absolute;top:0px;left:0px;*width:100px;*height:49px;}
.js .zoomPup{overflow:hidden;background-color:$white;-moz-opacity:0.6;opacity:0.6;position:absolute;border:1px solid $grey-silver;z-index:102;cursor:crosshair;}
.js .zoomOverlay{position:absolute;left:0px;top:0px;background:$white;}//opacity:0.5;width:100%;height:100%;display:none;z-index:101;}
.js .zoomWindow{position:absolute;top:-1px;background:$white;height:auto;}
.js .zoomWrapper{position:relative;border:1px solid $grey-medium-light;z-index:110; height: 450px;}
.js .zoomWrapperTitle{display:block;background:$grey-medium-light;color:$white;height:18px;line-height:18px;width:100%;overflow:hidden;text-align:center;font-size:10px;position:absolute;top:0px;left:0px;z-index:120;-moz-opacity:0.6;opacity:0.6;}
.js .zoomWrapperImage{display:block;position:relative;overflow:hidden;z-index:110; height: 100%!important;}
.js .zoomWrapperImage img{border:0px;display:block;position:absolute;z-index:101;}
.js .zoomIframe{z-index:-1;-moz-opacity:0.80;opacity:0.80;position:absolute;display:block;}
.js .ui-dialog-content .pdp-main .quickview-product-columns .product-col-2 .product-variations ul li:first-child {margin-top: 0;}
.js .ui-dialog-content .pdp-main .quickview-product-columns { overflow: auto; width: 100%; clear: both;}
// homepage assets
// ---------------------------------------------------------------------------------------
.js .homepage-slides {height: 550px;}
.js #homepage-slider .jcarousel-item {max-width:100%;}
.js #homepage-slider .jcarousel-control {position: absolute;bottom:30px; left:50%; width:300px; margin-left:-150px; text-align:center;}
.js #homepage-slider .jcarousel-control a {background:url('../images/interface/home-slider-pager.png') left top no-repeat; display: inline-block;height: 11px;margin: 0 3px;text-indent: -999em; width: 11px;}
.js #homepage-slider .jcarousel-control a.active {background:url('../images/interface/home-slider-pager-current.png') left top no-repeat;}
.js #homepage-slider .jcarousel-prev, .js #homepage-slider .jcarousel-next{cursor:pointer;height:42px;position:absolute;width:22px;z-index:999; margin-top:-21px; background:url('../images/interface/arrows-vertical-carousel.png') 0 0 no-repeat;}
.js #homepage-slider .jcarousel-prev {margin-left:-440px; left:50%;}
.js #homepage-slider .jcarousel-next {margin-right:-440px; right:50%; background-position: 100% -58px;}

.js .home-bottom-left,
.js .home-bottom-center,
.js .home-bottom-right {clear:none;float:left;width: 307px; margin: 0 0 40px;}
.js .home-bottom-center {width: 306px;}

.js .home-bottom-right .jcarousel-clip {height: 282px; overflow:hidden;}
.js .home-bottom-right .vertical-carousel li {height: 282px;float:none; width:300px;}
.js .home-bottom-right .product-tile {min-height: 0;}
.js .home-bottom-right .jcarousel-prev {background:url('../images/interface/icon-vertical-carousel-prev.png') left top no-repeat;cursor:pointer;height:12px;position:absolute;bottom:20px;right:0;width:52px;z-index:999;}
.js .home-bottom-right .jcarousel-next {background:url('../images/interface/icon-vertical-carousel-next.png') left top no-repeat;cursor:pointer;height:12px;position:absolute;bottom:0px;right:0;width:52px;z-index:999;}
.js .home-bottom-right .jcarousel-prev-disabled, .home-bottom-right .jcarousel-next-disabled {display:none !important;}

.js button.select {display:none}
#ContactUsWindow{
	display: none;
}

#PCIPalValidationError{
	display: none;
}

#privacypolicywindow{
	display: none;
}

.storepopup .store-locator-details-map{width: 620px;}
.infowindowcontent h1{
	font-size: 12px;
	margin: 0px;
}

.infowindowcontent .store-locator-details p, .infowindowcontent .store-locator-details img{
	margin: 0px;
	font-size: 12px;
	line-height: 14px;
}

.infowindowcontent .locationinfodata{
	float: right;
	width: 140px;
}

.infowindowcontent .store-locator-details img{
	float: left;
	width: 130px;
	max-width: 130px;
}

.infowindowcontent .store-locator-details{
	width: 280px;
}

.pointDirectionForm{
	display: none;
}

.infowindowcontent .pointDirectionForm{
	display: block;
}

.gm-style-iw{width:310px !important; height:280px !important;}

.infowindowcontent .pointDirectionForm p{clear:both;}

.infowindowcontent .pointDirectionForm input[type="text"]{
	width:44%;
	float:left;
	padding: 0.7em 2%;
	margin-bottom: 8px;
}

#storeLocator{
	margin: 0;
	padding: 0;
	height: 560px;
}

.locationSearchResult{
	width: 100%;
	float: left;
}

.store-locator-details-map{
	width: 670px;
	height: 547px;
}

.locationSearchResult .toggle-grid{
	display: none;
}

.pointDirectionForm #pointDirectionForm{
	float: left;
}

.pointDirectionForm #getDirections{
	float: right;
	margin: 0 0 8px;
	width: 50%;
}

.form-no-lable label{
	display: none;
}

//homepage popup
#homePagePopUp{display: none;}
.homePageModal{ position:fixed;}
.ui-dialog #homePagePopUp{display: block;}
.ui-dialog.email-signup-modal{position:fixed; top:10% !important;}

.locator-search-content input.error {background-color: #F1E6E6 !important; color: $errorColor; border: 1px solid $errorColor !important;}

//remembered items
// -------------------------------------
.remembered-items-send-to-friend {margin: 30px 0 30px 11px; text-align:right;}

.remembered-items-send-to-friend .button {
	margin-right:0 !important;
	width: 190px;
	display: block;
}

.remembered-row .item-details .id {float: left;width: 100%;}
.remembered-row .item-details .variationvalues {float: left;width: 100%;}
.remembered-row .item-details .edit {float: left;width: 100%;}

.remembered-row .item-total .discontinued-item-text {
	background:$white;
	color:$mainbordercolor;
	border:1px solid $mainbordercolor;
	padding:1em;
	text-align:center;
	display:block;
	clear:both;
	margin-right:0;
	cursor:default;
}

.remembered-row .item-total .out-of-stock-text {float: left;width: 80%; padding: 0 0 5px;}

.remembered-row .item-total .action {
	float: left;
	width: 80%;
	padding: 0 0 5px;
}

.remembered-row .item-price .price-standard{text-decoration:line-through;color:$price-list;}

.remembered-row button.remove-remember-item{padding:0;}
#notify-items-container table.item-list td.item-total{padding:1em 0 1em 1em;}

.pt_remembereditems #remembered-items-container .item-name a {
	float: left;
	color: $mainbrand;
}

.pt_remembereditems #notify-items-container .item-name a {float: left;font-weight: bold; color: $mainbrand;}
.pt_remembereditems table.item-list tr.empty-remembered-list{border:none;}
.pt_remembereditems table.item-list tr.empty-remembered-list td, .pt_cart table.item-list tr.empty-remembered-list td{ padding:1em 1em 7em 1em;}
.pt_remembereditems table.item-list td{height:auto; width:auto; }
.notify-header h2{border-bottom: 1px solid $mainbordercolor; padding: 20px 0 20px 0; margin: 0;}
.pt_remembereditems .caps{ text-transform:uppercase;}

.pt_remembereditems td.item-image{ min-width:16%;}
.pt_remembereditems td.item-name,
.pt_remembereditems td.item-total,
.pt_remembereditems td.item-details
{min-width:20%;}
.pt_remembereditems td.item-price,
.pt_remembereditems td.item-quantity{ min-width:12%;}
.pt_remembereditems table.item-list td.item-total{width:20% !important;}

// out of stock dialog
// -------------------------------------
#out-of-stock-main .title{ font-size: 1.8em; font-weight:bold;}
#out-of-stock-main .infotext{ padding: 12px 5px; }
#out-of-stock-main .item-image{ padding: 20px 25px; width: 30%; height:auto;}
#out-of-stock-main .item-name { padding: 20px 20px; width: 70%;}
#out-of-stock-main .item-name .name{ font-size: 1.1em; font-weight:bold; }
#out-of-stock-main .form-row-actions{ padding: 15px 5px; }
#out-of-stock-main .notify-when-in-stock-button{float:left;}
#out-of-stock-main .create-account-button{float:left;}
#out-of-stock-main .continue-button{float:right;}

// promotion page
// -------------------------------------
.promo-container .promotion-callout {font-size: 1.1em; font-weight:bold;}
.promo-container .promotion-details {margin-top:20px}

.not-us .other-show{display: block;}
.not-us .other-hide{display: none;}
.other-show{display: none;}

// return and exchange form
.reason-message, .window-message { display: none; }

// Demandware Integrated Wordpress Blog
// -------------------------------------
.tabcontent .blogtab.blog-search-loading {text-align: center;  background: url("../images/infinite-loading-indicator.gif") center no-repeat; height: 35px; width: 100%; margin-top: 30px;}
.dw_blogs { width: 950px; margin-left: -260px; }
.dw_blogs .blog-breadcrumb { position: absolute; top: -29px; left: -161px; background: url("../images/interface/icon-breadcrumb-separator.png") no-repeat scroll 4px center transparent; width: 605px; padding-left: 12px;}
.ie8 .dw_blogs .blog-breadcrumb, .ie7 .dw_blogs .blog-breadcrumb { left: 75px; top: -90px; background: url("../images/interface/icon-breadcrumb-separator.png") no-repeat 4px 7px; }
.dw_blogs .blog-breadcrumb a { @extend .breadcrumb-text !optional; @extend .breadcrumb-current !optional; text-transform: capitalize; line-height: 0;}
.dw_blogs .blog-breadcrumb a:hover { color: $mainbrand; }
.breadcrumb a[title="Blog"] { color: $subnavtext}

#most-recent-post { margin-left: 0px; width: 920px;}
#most-recent-post .blog-pic-wrapper { height: 420px; width: 920px; 	overflow: hidden;}
#most-recent-post .blog-pic-wrapper img { width: 920px; height: auto;}
.dw_blogs .blogsignup form span.error {
  	display: none;
}

.dw_blogs a.rss-feed {
	background: url("../images/feed-icon.png");
	display: block;
	height: 28px;
	width: 28px;
}

@media (min-width: 767px) {
	.dw_blogs a.rss-feed {
		float: right;
		margin-right: 30%;
	}
}

// DW Blog Search style fix
.tabcontent .blogtab .dw_blogs {
  margin-left: 0px;

  #dwBlog-post {
    margin-left: 0px;

    #dwBlog-content {
      padding: 0px;

      .small-snippet {
        margin: 30px 15px;
      }
    }
  }
}

// Addition of Social Icons to Blog
.dw_blogs #s-share-buttons {
	margin-bottom: 50px;

	a:before {
		content: " ";
		display: block;
		height: 20px;
		width: 20px;
	}

	.s3-facebook:before {
		background: url(../images/interface/facebook.png) center center no-repeat;
	}

	.s3-twitter:before {
		background: url(../images/interface/twitter.png) center center no-repeat;
	}

	.s3-email:before {
		background: url(../images/interface/mail.png) center center no-repeat;
	}

	a {
		padding: 10px;
		float: left;
		margin-right: 5px;
		margin-left: 5px;

		&:first-of-type {
			padding-left: 0;
			margin-left: 0;
		}
	}

	.pinit-btn-div {
		float: left;
		padding: 10px;
		margin-right: 5px;
		margin-left: 5px;

		span {
			background: none !important;
			height: 20px;
			width: 20px!important;
			box-shadow: none !important;
		}

		span:before {
			background: url(../images/interface/pinterest.png) center center no-repeat;
			content: " ";
			display: block;
			height: 20px;
			width: 20px;
		}
	}
}

// End Addition of Social Icons to Blog

@media (min-width: 767px) {
	#blogSignup {
		input.email {
			width: 215px;
		}
	}
}

#blogSignup {
	input.email {
		height: 46px;
	}
}

// Individual Post Pages
.dw_blogs .dwPost-content-area { width: 700px; background: $blog-background-color; z-index: 1; }
.ie8 .dw_blogs .dwPost-content-area, .ie7 .dw_blogs .dwPost-content-area { position: relative; float: left; }
.dw_blogs .entry-thumbnail { overflow: hidden; max-height: 440px; min-height: 1px;}
.dw_blogs .dwPost-content-area .dwPost-content .entry-header {width: 100%; margin-left: 0;}
.dw_blogs .dwPost-content-area .dwPost-content .entry-header .entry-thumbnail img { height: auto; width: 100%; }
.dw_blogs .dwPost-content-area .dwPost-content .entry-content { padding: 0 18px 12px 18px;}
.dw_blogs .dwPost-content-area .dwPost-content .entry-header hr { width: 662px; height: 1px; opacity: 0.5}
.dw_blogs .dwPost-content-area .dwPost-content  .date { background-color: $blog-date-banner; width: 170px; margin-left: -11px; padding: 8px 0; position: absolute; z-index: 4; text-align: center; }
.dw_blogs .dwPost-content-area .dwPost-content .date .entry-date { @extend .blog-post-date !optional;}
.dw_blogs .dwPost-content-area .dwPost-content .date-banner-wedge {background: url('../images/date_bannerWedge.png') no-repeat; height: 40px; width: 20px; position: absolute; left: -20px; margin-top: 5px;}
.dw_blogs .dwPost-content-area .dwPost-content .entry-meta .categories-links { margin-top: 58px; position: absolute; padding-left: 18px;}
.dw_blogs .dwPost-content-area .dwPost-content .dwTagList { color: $primarylink; padding-left: 18px;}
.dw_blogs .dwPost-content-area .dwPost-content h1.entry-title {margin-top: 83px; padding:0 18px 0px 18px;}
.dw_blogs .dwPost-content-area .dwPost-content legend { padding-top: 40px;  width: 640px;}
.dw_blogs .sidebar-container { top: 32px; right: 0px; position: absolute; width: 203px;}
.ie8 .dw_blogs .sidebar-container, .ie7 .dw_blogs .sidebar-container { margin-top: -60px; z-index: 999; float: right; position: relative;}
.dw_blogs .sidebar-container h3 { border-bottom: $sidebar-title-border; line-height: 30px;}
.dw_blogs .sidebar-container ul {margin: 0; padding: 0}
.dw_blogs .sidebar-container ul li {margin-top: 16px;}
.dw_blogs .entry-thumbnail img { vertical-align: bottom; }
.dw_blogs .dwPost-content-area .dwPost-content .entry-meta .categories-links a,
.dw_blogs .dwPost-content-area .dwPost-content .dwTagList a,
.dw_blogs .sidebar-container .recent-post-category {@extend .blog-category-text !optional}
.dw_blogs .sidebar-container .recent-post-headline h5 {margin: 0; font-weight: normal;}

.ie8 #wwwblog-comment-tabs .comment-list, .ie7 #wwwblog-comment-tabs .comment-list { border: 0; border-top: $sidebar-title-border; }
#wwwblog-comment-tabs ul li { @extend .hr-product-details-tab-default !optional; }
#wwwblog-comment-tabs ul .ui-state-active { @extend .hr-tab-active !optional; }
#wwwblog-comment-tabs .comment-list li { border-bottom: $sidebar-title-border; padding: 20px 0; }
#wwwblog-comment-tabs .comment-list li p { margin: 3px 0 0 0;}
#wwwblog-comment-tabs .says, #wwwblog-comment-tabs .fn { @extend .heading5 !optional; @extend .bold !optional; }
#wwwblog-comment-tabs .avatar { display: none; }
#wwwblog-comment-tabs time { @extend .default-text !optional; }
#wwwblog-comment-tabs { margin-top: 80px;}

.dw_blogs .dwPost-content-area .dwPost-content,
.dw_blogs #dwBlog-post .dwPost-content-area .dwPost-content,
.dw_blogs #dwBlog-post {

	.blog-carousel-container {
		float: left;
	}

	.product-carousel {
		text-align: center;
		width: 640px;

		h1 {
			font-size: 23px;
			padding: 30px 0 10px;
		}

		.jcarousel-container.jcarousel-container-horizontal {
			margin-left: 60px;
			width: 515px;
			background-color: $white;
		}

		.jcarousel-clip-horizontal {
			width: 466px;
			max-height: 530px;
		}

		li.jcarousel-item.jcarousel-item-horizontal {
			padding: 35px 20px;

			.product-tile {
				position: relative;

				.product-name {
					margin-top: 0;
					padding: 5px 0;
				    max-height: 70px;
				}

				.product-image {
					height: 180px;
				}

				.product-remember {
					position: relative;
					right: auto;
					top: auto;

					&:hover .remember-item {
						right: 1px;


						&.enabled {
							right: -3px;
						}
					}

					.remember-item {
						padding: 7px 30px 7px 7px;
						right: -25px;
						top: -40px;

						&:hover {
							text-align: right;
						}
					}
				}

				.product-swatches {
					.small-product-image-box {
						top: -5px;
					}

					ul.swatch-list {
						-o-box-shadow: 0 1px 5px 0 rgba(95, 96, 98, 0.3)!important;
						-webkit-box-shadow: 0 1px 5px 0 rgba(95, 96, 98, 0.3)!important;
						-moz-box-shadow: 0 1px 5px 0 rgba(95, 96, 98, 0.3)!important;
						box-shadow: 0 1px 5px 0 rgba(95, 96, 98, 0.3) !important;
						display: block;
						max-width: 400px !important;

						li.small-product-item.jcarousel-item.jcarousel-item-horizontal {
							padding: 24px 10px;
						}
					}
				}

			}
		}

		li.jcarousel-item-undefined.jcarousel-item-undefined-horizontal.jcarousel-item-placeholder,
		li.jcarousel-item-NaN.jcarousel-item-NaN-horizontal.jcarousel-item-placeholder {
			padding: 0;
			width: 0;
		}

		.jcarousel-next.jcarousel-next-horizontal {
			right: 10px;
		}

		.jcarousel-prev.jcarousel-prev-horizontal {
			left: 10px;
		}

		.jcarousel-next.jcarousel-next-horizontal,
		.jcarousel-prev.jcarousel-prev-horizontal
		{
			top: 40%;
		}

		ul.horizontal-carousel {
			padding-left: 0;

			li {
				list-style: none;
			}
		}

		.product-name {
			margin-top: 25px;
			padding: 5px 0;
		}
	}

}


.dw_blogs .archive-header {margin: 0 0 -25px -2px;}


//Wordpress Blog Search Results
#tab3 .dw_blogs {position: relative; margin: 0;}

// Wordpress Blog Editor Style Defaults
.dw_blogs .alignleft { float: left; margin: 5px 20px 5px 0; }
.dw_blogs .alignright { float: right; margin: 5px 0 5px 20px; }
.dw_blogs .aligncenter { display: block; margin: 5px auto; }
.dw_blogs img.alignnone { margin: 5px 0; }
// -------------------------------------
.termsandconditions, .guidelines{display: none;}

/* Alternate modal styles */
/* The following !important tags are to override js generated inline styles */
.ui-dialog.alt-dark {
	position:fixed;
	width: 100% !important;
	left:0 !important;
	right:0 !important;
	top:0;
	bottom:0;
	background: none;

	.dialog-content {
		width: 75% !important;
		height: 80% !important;
		overflow: hidden;
		position: relative;
		left:50%;
		transform: translateX(-50%);
	}

	.ui-dialog-titlebar-close {
		height:36px;
		width:36px;
	}

	.ui-icon-closethick {
		background-image:url("../images/interface/icon-ui-close-alt.png");
		background-size: 100% 100%;
		height:36px;
		width:36px;
	}

	iframe {
		border: 0;
		max-height:100%;
		max-width:100%;
	}
}

@media screen and (max-width: 720px) {

  #dwBlog-post.dwv2 {
    width: 100%;
    overflow: hidden;

    #page {
      width: 100%;
      margin: 0 auto;

    }

    #content {
      width: 100%;

    }

    .post {
      .entry-header {
        padding: 0 10px 30px;

        .entry-categories {

        }

        .entry-title {
          font-size: 22px;
        }

      }

      .entry-content {
        width: auto;

        padding: 0 10px 30px;

        float: none;

        h2 {
          padding: 0 0 20px;
          line-height: 1em;

        }

        img {
          max-width: 90%;
          height: auto;
        }

      }

    }

    .sidebar-container {
      width: auto;

      padding: 40px 10px;
      margin: 0;

      display: none;

      &.mobi {
        display: block;
      }

      h3 {
        padding: 0 0 25px;
      }

      ul {
        padding: 0;
      }

    }

    #feature-slider {
      margin: 0;
      padding: 0;

      background: $white;

      .blog-pic-wrapper {
        width: 100%;
        float: none;
        position: relative;
        overflow: hidden;
        margin: 0px;

        a {
          height: auto;
        }

        img {
          width: 100%;

          position: static;

        }

        &:after {
          width: 0px;
          height: 0px;
          margin: 0px 0px 0px 45%;
          display: block;
          position: absolute;
          top: auto;
          bottom: -1px;
          content: '';
          border-left: solid transparent;
          border-right: solid transparent;
          border-bottom: solid $white;
          border-width: 15px;

          transition: .25s ease;
          -o-transition: .25s ease;
          -ms-transition: .25s ease;
          -moz-transition: .25s ease;
          -webkit-transition: .25s ease;
        }
      }

      .post-category {
        padding: 25px 10px;

      }

      h2 {
        padding: 0 10px 25px;
      }

      .post_meta {
         padding: 0 10px 0;
      }

      p {
         padding: 5px 10px 25px;
      }
    }

    #dwBlog-content {
      .blog-landing-snippet {
        width: auto;

        margin: 0;
        padding: 10px;

        h2 {
          font-size: 20px;
        }
      }

    }

    .prof-header {
      width: auto;

      #prof-pic {
        padding: 0;
        width: 95%;

      }

      .prof-title {
        font-size: 25px;
      }

      .prof-social {
        padding: 0 10px;

        text-align: left;

        span {
          padding: 0px;
          display: inline;
        }

        ul {
          display: inline;
        }

        li {
        }

        a {
          padding: 0 5px;
        }
      }

    }

    .dw-cat-list {
      padding: 0px;
      margin: 30px 10px;

      position: relative;

      overflow: hidden;

      text-align: left;
      text-indent: 25px;

      @include transition( .25s ease );

      &:not(.popped) {
        height: 46px !important;
      }

      &.popped {

        li {
          display: block;
        }
      }

      #cat-list-dd {
        width: 46px;
        padding: 15px 0px;

        display: block;
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 1;

        font-size: 17px;
        font-weight: 700;
        text-align: left;

      }

      ul {
        position: relative;

        background-color: $white;

        &::before {
          padding: 15px 0;

          position: absolute;
          left: 0px;
          top: 0px;

          display: block;
          content: 'SORT BY: ';

          font-weight: 600;

        }

        &::after {
          width: 25px;
          height: 25px;
          display: inline-block;
        }

      }

      li {
        display: none;
        position: relative;
        text-indent: 110px;

        &.active {
          display: block;
          color: $primarylink;
          background-color: transparent;

          a {
            color: $primarylink;
          }

          &:after {
            border: none;
          }
        }
      }

      a {
        color: $primarylink;
      }
    }

  }

}


// FAQs
// -------------------------------------

.faquestions h4{background:url("../images/sprite-toggle-arrow-faq.png") no-repeat scroll left 5px transparent; padding-left:20px; cursor:pointer;}
.faquestions h4.expanded{background:url("../images/sprite-toggle-arrow-faq.png") no-repeat scroll left -35px transparent;}
.faquestions .toggle-content{padding-left:20px;}

//Error page
// -------------------------------------
.error-page .breadcrumb{padding-left:0; margin-left:0;}
.error-page-footer-asset, .error-page-footer-slot{padding:0 0 1em;}

//Sitemap
// -------------------------------------
.sitemap-categ{ float:left; width:25%;}
.sitemap-categ ul{padding-left:0; margin:0;}
.sitemap-categ ul ul, .sitemap-categ ul ul ul{padding-left:10px;}
.sitemap-box{ overflow:hidden; background:$lightbackdrop; padding:2%; margin-bottom:2%; line-height:1.4em;}
.sitemap-box.brands{background:none; padding:0;}
.sitemap-box.brands .sitemap-categ{background:$lightbackdrop; padding:2%; width:18%; margin-right:2%;}
.sitemap-box .sitemap-row{clear:both; overflow:hidden; margin:0 0 3%;}
.sitemap-box h2, .sitemap-box h6{margin:0; font-size:1.1em;}
.sitemap-box .content-asset ul li{list-style:none !important;}

.rating-state-o, .rating-state-b{display: none;}

.login-box #password-reset{display: block; margin-top: 5px;}

.grid-tile .rating-container{margin: auto; float: none;}

.pagination-conten{display: none;}
.infinite-scroll-off .pagination-conten{display: block;}
.search-result-options .search-page-label{display: none;}
.review-summary .rating-container-selected{width:0px;}

.question-information-box .error span{color: $errorColor !important;}
.question-information-box .error textarea{border: 1px solid $errorColor !important;}

// In Store Shipping Price Override

#shipping-method-list + fieldset { margin-bottom: 25px; }
.shipping-price-override-toggle { color: $mainbrand; background: url("../images/interface/icon-refinement-arrow.png") no-repeat 95% -30px $secondarybackground; padding: 8px 55px 8px 8px; }
.shipping-price-override-toggle.active { background-position: 95% 10px; }
.shipping-price-override-form { background: $secondarybackground; padding: 10px 0px 5px 10px;margin-bottom: 20px; margin-top: 8px;
	input[type="text"] { background: $white }
	button { margin-top: 8px; }
	#shipping-price-override-update { margin-right: 19px; }
	}
#shipping-method-list .struck {
text-decoration: line-through;
}

.pt_multireturnexchange {
	.multireturnexchangeform {
		button {
			float: right;
		}
	}
	.order-detail-options {
		border-top: 0;
		margin-top: 0;
		padding-top: 0;
		a {
			float: left;
			margin-top: -45px;
		}
	}
	.order-return-information-confirmation-form {
		margin-bottom: 40px;
		td {
			display: block;
			width: 100%;
			padding: 0;
			input[type="text"], input[type="password"], input[type="tel"], input[type="email"], textarea {
				width: 68%;
			}
			label {
				padding-top: 15px;
			}
		}
	}
}

.shipping-label img, .rma-slip img {
	max-width: 100%;
}
.shipping-label, .rma-slip {
	width: 48%;
	float: left;
	margin: 5px;
	box-sizing: border-box;
	border: solid 1px $mainbordercolor;
	margin-left: -1px;
}
.shipping-label .print-link, .rma-slip .print-link {
	background: none;
	padding: 0;
	margin-right: 0;
}

.account-overview {
	.search-result-header {
		div { padding: 5px 0px;width: 168px;text-align: center; float: left; }
	}
}

.pt_account .account-overview legend {margin-bottom: 5px;margin-top: 10px;padding-bottom: 3px;}

.toggle { cursor: pointer }

//Bday Club

.account-overview.birthday-club {
	.edit-child { margin-left: 10px; }
	.add-child { margin-top: 20px; }
	ul.search-result-items {
		li {
		  .order-history-items {
				> div { float: left; text-align: center; width: 127px; margin-right: 42px;
				.button { padding: 8px 8px 6px; }
				}
				.btn-holder { float: right; min-width: 140px; text-align: right; margin:0 }
			}
		 }
	}
}

.bday-club-form {
	input[type="text"], input[type="password"], input[type="email"], label { width: 95%; }
	label[for="dwfrm_editchild_verify"] { width: 345px;float: right; padding-top: 0.7em;}

}

// Self Service Credit

.account-overview.self-service {
	.divider { position: relative;width: 17px;float: left; margin: 215px 0 0 5px; }
}
//IE Compatibility Fixes
// -------------------------------------

.ie7 .pdp-main .product-description { float: none; }
.ie7 .pdp-main .product-price { float: none; }
.ie7 .pdp-main .dropdownvariation.product-variations .toggle span:first-child { background-position-y: -22px; display: block; padding-top: 17px; padding-bottom: 6px;  }
.ie7 .www-recommendations-placeholder.www-recommendations-2x2 { clear: none; float: right; }
.ie7 .pdp-main .www-recommendations-title { margin-top: 50px; }
.ie7 .pdp-main .product-detail #product-content .attribute-holder { margin-right: 50px; width: 200px;}
.ie7 .pdp-main .product-detail #product-content .product-actions { width: 170px; }
.ie7 .pdp-main .livechat-productdetail { width: 150px; }
.LPMcontainer {width: auto !important;}
.ie7 .pdp-main .product-detail #product-content .promotion {margin-top: 30px; }
.ie7 .pdp-main .social-media-icons .content-asset { width: 180px; }
.ie7 .recommended-product-tile .product-tile .product-name { padding-bottom: 5px; }
.ie7 .refinement { z-index: 30 }
.ie7 .refinement ul.swatches li { overflow: visible }
.ie7 .tfc-fitrec-product { display: none; }
.ie7 .refinements.horizontally .refinement h4 { margin: 30px 0 0 9px; }
.ie7.js .ui-dialog.ui-widget.ui-widget-content.ui-corner-all.quickview.ui-draggable { border: 1px solid $mainbordercolor; }
.ie7 #navigation { z-index: 101; }
.ie7 .productsuggestionterm .product-remember { display: none; }
.ie7.js #suggestions .rating-container { float: left; }
.ie7.js #suggestions div div.productsuggestionterm .product-tile { height: 90px }
.ie7 .image-toolbar .social-media-icons { display: none!important; }
.ie7 .homePageModal .ui-dialog-titlebar { position: absolute; width: 100%; }
.ie7 .email-signup-box input[type="submit"] { overflow: hidden; padding-top: 9999px}
.ie7 .page-break-backdrop { z-index: 0 }
.ie7 .cart-coupon-inner-box { padding: .5em 7px .7em!important; }

//SURVEY PAGE
#primary.primary-content .survey {
	label, .form-label-text {
		width: 100%;
		clear:both;
	}
	form label span {
    	text-transform: none;
  	}
  	ul {
  		clear:both;
  		margin: 0;
  		list-style: none;
  	}
  	input[type="checkbox"],
  	input[type="radio"] {
		margin: 5px 5px 5px 0;
	}
	.checkbox-group li {
		width: 247px;
		padding: 6px 15px 6px 0;
		line-height: 25px;
		display: inline-block;
		vertical-align: text-top;
	}
	ul:not(.checkbox-group) li {
		padding: 6px 2px 6px 0;
	}
	.form-label input[type="radio"]:first-child {
		margin-left: 20px;
	}
	.input-group li {
		float: left;
		margin-right: 17px;
	}
	.sub-question {
		margin-left: 40px;
	}
  .inline-checkbox {
    display: flex;
    flex-direction: row-reverse;
  }
}

.manage-subscriptions {
  input[type="checkbox"] {
    margin: .5em 1% 1em 0;
    display: inline;
    float: left;
  }
  .form-row {
    padding: 20px 0;
  }
  form label {
    display: inline-block;
    width: auto;
  }
}

.pt_cart .prodeal-invalid-items span {
	display: inline-block;
	border: solid 1px $errorColor;
	padding: 6px 15px;
	color: $errorColor;
	text-transform: uppercase;
	margin: 0 20px 10px 0;
}

.cart-guarantee-kicker {
  float: left;
}

.volumediscount, .mailinglist{
	label {width: 100%;}
}

.checkout-v2 {

	&.pt_cart #primary {width:675px; padding-right:30px;}
	&.pt_cart #secondary {width: 215px; margin: 48px 0 0;}
	.cart-actions {
		div#update-cart, button {
			background: none;
			padding: 0;
			margin: 0;
			@extend .primary-links !optional;
		}
		.print-link {@extend .primary-links !optional;}
		.print-box {float: left;}
		fieldset {width: auto;}
		.cart-action-continue-shopping {
			float: none;
			width: auto;
		}
		button.secondary-button {
			float: left;
			left: 187px;
			position: relative;
		}
		.cart-action-checkout {width: auto;}
	}
	}
//Mixins for each brand V2
.paypaldivider-or {font-weight: bold; text-transform: uppercase;}

//this needs to replace all brands in branding.scss and styled for each brand
.promo-code-box {
	float: none;
	padding: 10px 0;
	margin: 0;
	text-align: left;
	width: auto;
	clear: both;
	// background: url("../images/interface/icon-refinement-arrow.png") no-repeat scroll 100% -16px transparent;
    position: relative;
    // &:after {
    //     content: '';
    //     display: inline-block;
    //     position: absolute;
    //     right: 0;
    //     top: 50%;
    //     transform: translateY(-50%);
    //     @include sprite($icon-refinement-down);
    //     visibility: initial;
    // }
    // &.expanded:after {
    //     @include sprite($icon-refinement-up);
    // }
}

.fluidConfiguretooltip{
    position: absolute;
    z-index: 1000;
    width: 200px;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    border-style:solid;
	.title {
		color: inherit;
	}
	p {
		margin: 0px;
		line-height: 17px;
		font-family: inherit;
		color: inherit;
	}
	&.tooltip-left:before,&.tooltip-left:after{
	      position: absolute;
	      content: ' ';
	      top: 50%;
	      margin-top: -11px;
	      width: 0;
	      height: 0;
	      border-style: solid;
	      border-color: transparent;
	      border-width: 10px;
	      border-right: 0;
	}
    &.tooltip-left:before{
      right:-11px;
    }
    &.tooltip-left:after{
      right: -10px;
    }
    &.tooltip-top:before,&.tooltip-top:after{
     position: absolute;
     content: ' ';
     left: 50%;
     margin-left: -11px;
     width: 0;
     height: 0;
     border-style: solid;
     border-color: transparent;
     border-width: 10px;
     border-bottom: 0;
   }
   &.tooltip-top:before{
     bottom:-11px;
   }
}

//WEAR TEST FORM
#wear-test-form {

	.weartest-group {
		margin: 20px 0;

		&.required-group {
			.group-label:after {
				content: "*";
				padding-left: 3px;
			}

			label {
				display: none;
			}
		}

		&.multi {
			label {
				display: none;
			}

			.error label {
				display: block;
				max-width: none;
				width: auto;

				.required-indicator {
					display: none;
				}
			}
		}

		.group-label {
			font-weight: bold;
			font-size: 18px;
			padding-bottom: 10px;
		}

		label,
		input {
			display: inline-block;
		}

		label {
			max-width: 200px;
		}

		label[for="dwfrm_weartest_bo__OtherRace"] {
			display: inline-block;
		}

		select {
			width: auto;
		}

		.form-row {
			margin-bottom: .75em;

			ul {
				padding-left: 0;
				margin-top: 0;

				li {
					list-style: none;

					input[type="checkbox"],
					input[type="radio"] {
						margin: .75em 1% .75em 0;
					}
				}
			}
		}
	}

	button[type="submit"] {
		margin-top: 20px;
	}
}

#ambassador-application-form {
	.ambassador-application-header {
		margin-bottom: 40px;

		p {
			margin-bottom: 20px;
		}
	}

	.form-row {
		margin-bottom: 1em;
	}

	button[type="submit"] {
		display: block;
		margin-top: 10px;
	}
}

.send-to-friend-container {
	h3 {
		margin-top: 25px;
	}

	.login-box-content {
		border-top: 1px solid $mainbordercolor;
	}

	#send-to-friend-form {
		.email-input {
			width: 50%;
		}

		.form-row .input-radio + span {
			@extend .heading3 !optional;
		}

		.form-caption {
			margin-bottom: 15px;
		}
	}
}

// SAFARI SPECIFIC APPLE PAY BUTTON STYLES
.dw-apple-pay-button {
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: buy;
}
.mini-cart-totals {
  .dw-apple-pay-button, .dw-apple-pay-button:hover, .dw-apple-pay-button:active {
    box-sizing: border-box;
    width: 100%;
    padding: 15px 0;
    margin: 14px 0;
  }
}

.cart-actions .cart-action-checkout {
  .dw-apple-pay-button,
  .dw-apple-pay-button:hover,
  .dw-apple-pay-button:active {
    clear: both;
    margin: 10px 0;
    width: 180px;
    padding: 12px 0;

    & + .express-user-login { display: none; }
  }
}

.js-only {
	display: none;
	#dialog-container & {
		display: block;
	}
}

@media (min-width: 767px) {

	#blogSignup {
		margin-left: 30%;
	}
}

#blogSignup {

	label {
		width: 100%;
	}

	span.error {
		margin-left: 0;
	}

	input.email {
		float: left;
		box-sizing: border-box;
	}

	button {
		float: left;
	}
}
.pt_order-confirmation .tooltip-icon{display:none;}
.cart-footer .tooltip.tooltip-icon{display:none;}
/* CSS for high-resolution retina devices */
@media only screen and (-Webkit-min-device-pixel-ratio: 1.5), only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (min-device-pixel-ratio: 1.5) {
    .oo_inline_icon {
        background-image: url('../lib/onlineopinionV5/oo_icon_retina.gif');
        background-size: 100%;
    }
}
.availability-msg .out-of-stock{
	cursor:pointer;
	display:block;
}
.email-opt-in > a {text-transform:none;}

.bfx_show {
	display: none;
}

//LiveAgent Chat pop-up Styles
.embeddedServiceSidebar{
  &.layout-docked .dockableContainer { right: 140px !important; }
	.buttonWrapper * { box-sizing: border-box; }

	button.embeddedServiceSidebarButton {
    @extend .cta-primary;
  }

	.embeddedServiceSidebarMinimizedDefaultUI,
	.embeddedServiceSidebarMinimizedDefaultUI:hover {
		font-size: 18px;
	}

	a.chatOption:hover {
		color: $white;
		text-decoration: underline;
	}

	button.endChatButton.postChatButton { border: none; }
}
.isDesktopEnvironment .embeddedServiceSidebar .minimizedContainer { right: 140px !important; }
// Initially hidden until a chat agent has connected
.toggleEmbeddedChat { display: none; }
.embeddedServiceHelpButton .helpButton { display: none; }

// Utility classes to show/hide things in Poq app webviews
.poq-app {
	.app-hide {
		display: none;
	}
	.app-show {
		display: block;
	}
}
.additional-benefits {
	.payment-method svg,.shipping-address svg,.track-orders svg {
		fill : $primarylink;
	}
	.fast-checkout svg, .save-wishlists svg {
		fill: none;
		stroke: $primarylink;
		stroke-linecap: round;
		stroke-linejoin: round;
		stroke-width: 3.333px;
	}
}

.account-guest-signin .account-title-display {
	padding: 17px 0 0 52px !important;
  }
  
.account-guest-new-account .account-title-display {
	padding: 12px 0 12px 52px !important;
  }
  
/*
Start Sprite Classes
*/
@include all-sprites();
/*
End Sprite Classes
*/

@import 'core-v2/desktop/_buttons.scss';
@import 'core-v2/desktop/_header.scss';
@import 'core-v2/desktop/_footer.scss';
@import 'core-v2/desktop/_gallery-search.scss';
@import 'core-v2/desktop/_promotions.scss';
@import 'core-v2/_promotions.scss';
@import 'core-v2/desktop/_forms.scss';
@import 'core-v2/desktop/_cart.scss';
@import 'core-v2/desktop/_checkout.scss';
@import 'core-v2/desktop/checkout/_express-checkout.scss';
@import 'core-v2/desktop/_framework.scss';
@import 'core-v2/desktop/_pdpv2.scss';
@import 'core-v2/desktop/_product-slider.scss';
@import 'core-v2/desktop/_slick.scss';
@import 'core-v2/desktop/_account.scss';
@import 'core-v2/desktop/_page-not-found.scss';
@import 'core-v2/desktop/_store-locator.scss';
@import 'core-v2/desktop/_blog.scss';
@import 'core-v2/desktop/_emailpreferences.scss';
@import 'core-v2/desktop/_write-review.scss';
@import 'core-v2/desktop/_search.scss';
@import 'core-v2/desktop/_size-width-tooltips.scss';
@import 'core-v2/desktop/_compare.scss';
@import 'core-v2/desktop/_order-details.scss';
@import 'core-v2/desktop/_contactus.scss';
@import 'core-v2/desktop/_slick.scss';
@import 'core-v2/desktop/_jquery-ui-overwrites.scss';
@import 'core-v2/desktop/_new-header-help.scss';
@import './../sass/partials/_offline-chat-modal';
@import '../sass/partials/_signin_create_modals.scss';
@import 'core-v2/desktop/_mystery-product.scss';
// @import 'core-v2/desktop/_footbed.scss';
// @import 'core-v2/desktop/_gwp-modals.scss';
// @import 'core-v2/desktop/_gwp.scss';
// @import 'core-v2/desktop/_temp-support.scss';
@import '../sass/partials/header/minicart/index.scss';
@import '../sass/partials/header/mini-cart.scss';
@import '../sass/partials/checkout/express-checkout.scss';
@import '../sass/partials/header/_enhanced-site-search.scss';
@import '../sass/partials/_country-locale-dialog.scss';
@import '../sass/partials/_stepper.scss';
@import '../sass/partials/_signin_create_modals.scss';


