// base elements
// -------------------------------------

@import "../mixins.scss";
@import "../icon-vars";

// helper classes
// -------------------------------------
.block {
  display: block;
}
.align-baseline {
	align-items: baseline !important;
}
.clear{clear:both;}
.clearfix {
	&:after {
		clear: both;
		content: " ";
		display: block;
		font-size: 0;
		height: 0;
		visibility: hidden;
	}
}
.inner {
	@extend .clearfix !optional;
}
.clearfix-before {
	&:before {
		clear: both;
		content: " ";
		display: block;
		font-size: 0;
		height: 0.1px;
	}
}

.visually-hidden{
    border:0 none !important;
    height:1px !important;
    margin:-1px !important;
    overflow:hidden !important;
    padding:0 !important;
    position:absolute !important;
    width:1px !important;
}

@mixin visually-hidden {
  border:0 none;
  height:1px;
  margin:-1px;
  overflow:hidden;
  padding:0;
  position:absolute;
  width:1px;
}

.skip-nav {
    position: absolute;
    top: -100%;
    left: 5px;
    z-index: 1002;
    &:focus {
        top: 5px;
    }
}

.ie7 .visually-hidden{width:1px !important;}
.print-only {display:none;}
.hide { display: none !important; }
.invisible { visibility: hidden; }
ul.menu{float:left;margin:0;padding:0;}
ul.menu li{float:left;list-style:none outside none !important;}
.hideme {display: none !important; }
.right {float:right;}
.left {float:left;}
.horizontal-stack { overflow:hidden;width:100%;}
.horizontal-stack .stackme { float:left; }
$callout: #ff0000 !default;
$secondary: $grey-medium-light !default;
.body-callout {
	color: $callout !important;
}
.body-secondary {
	color: $secondary !important;
}
.text-center {
	text-align: center;
}
.width-100 {
	width: 100%;
}
.spacer-bottom {
	margin-bottom: 15px;
}

.d-flex {
  display: flex;
}

.flex {
  @include breakpoint-max($tablet) { // mobile devices have updated browsers, can use flex
    display: flex;
  }
}

.align-center {
  align-items: center;
}

.mobile-only {
  @include breakpoint($tablet) {
    display: none !important;
  }
}

.desktop-only {
  @include breakpoint-max($tablet) {
    display: none !important;
  }
}

// Utility classes to show/hide things in Poq app webviews
.poq-app {
	.app-hide {
		display: none;
	}
	.app-show {
		display: block;
	}
}

// make sure the live chat header color from SFSC doesn't get overridden
h2[embeddedService-chatHeader_chatHeader] {
  color: inherit;
}

/* 
  * This mixin will disable the hover effect on touchscreen devices. Also, it will prevent hover event break on IE.
  * https://stackoverflow.com/a/49958442 mixed with https://stackoverflow.com/a/68254082
  * https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer
  * https://developer.mozilla.org/en-US/docs/Web/CSS/@media/hover
  */
@mixin hover-supported {
  @media (hover: hover) and (pointer: fine), only screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
    @content;
  }
}

.ui-dialog.bonus-dialog {
  #bonus-product-list {
    .bonus-product-form {
      .size-chart-container {
        display: block !important;
      }
      span.bfx-product-size {
        display: none;
      }
    }
  }
}

