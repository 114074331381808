.ui-dialog {
	&.wishlist-merge-modal {
		text-align:center;
		width: 587px !important;

		@include breakpoint-max($tablet) {
			width: 300px !important;
			top: 200px !important;
		}

		.callout-title {
			font-weight: bold;
			font-size: 24px;
		}

		.callout-items-in-list {
			font-size: 16px;
		    line-height: normal;
		    margin: 16px 0 30px 0;
		}

    #wishListMergeForm {
      fieldset {
        @include breakpoint($tablet) {
          display: flex;
          justify-content: center;
        }

        #cta-wishlist-merge {
          @include breakpoint($tablet) {
            margin-left: 10px;
          }
        }
      }
    }

		.ui-widget-content {
			padding: 20px 50px 50px 50px !important;

			@include breakpoint-max($tablet) {
				padding: 10px 30px 30px 30px !important;
			}

			.cta-primary, .cta-secondary {
				@include breakpoint-max($tablet) {
					width: 100%;
				}
			}
		}

		.mob-merge-btn {
			@include breakpoint-max ($tablet) {
				margin-top: 15px;
			}
		}
	}
}
