.ui-dialog { border: $borderMixin; }
.product-grid-sale-price { color: $callout }

.product-zone-item-name {
  @include h4();
  &:hover, &:focus {
    color: $secondary;
    text-decoration: none;
  }
}

.product-zone-price { @include body($color: $dark-grey); }
.product-discounted-price .product-standard-price { color: $grey; }
.inverted-btn-more-colors { @include body-small($secondary); }

.primary-content, .page-content, .pt_product-search-result {
  h1 { text-transform: none; }
}

.did-you-mean {
  @include body(); 
  a { color: $primary; }
}

//Search
.search-header {
  h1 { @include h1(); }
  .product-result-count { @include body(); }
}

.size-refinements, .refinements ul#width {
  li a {
    border: $borderMixin;
    @include body-small();
    line-height: 34px;

    &:hover, &:active, &:focus {
      line-height: 34px;
      border-color: $primary;
    }
  }

  li.selected a { 
    @include body-small($color: $base, $line-height: 34px); 
    border-color: $primary;
  }
}

//Sort 
.sort-header { @include h3($line-height: 62px, $transform: uppercase); }

.sort-by-options {
  @include body-small($color: $base, $line-height: 62px);
  &:hover,
  &:focus {
    color: $secondary;
    text-decoration: none;
  }
  &.selected { @include body-small($color: $secondary, $line-height: 62px); }
}

// Filter
.refinements.horizontally, .refinements.vertically {
  .genericsizetype-refinement-wrapper a { @include body-small(); }

  .refinement {
    .toggle { 
      @include h3(); 
      padding-bottom: 8px;
    }

    .refinement-values-wrapper { line-height: 15px; }

    .wrap-swatches-box {
      background: $contrast;
      .genericsizetype-refinement-wrapper, .generic-size-header {
        margin-left: 18px; // snowflake style to keep refinements from touching border
      }
    }
    
    .selected-refinement-value { @include body-small($base, none, none, 10px); }
  }
}

.refinements ul:not(.swatches) li { margin-bottom: 0; }

.refinements.vertically {
  box-sizing: border-box;
  background: none;
  border: $borderMixin;
  border-top: 0;
  margin-top: 29px; //Snowflake per UAT

  .refinement {
    border-top: $borderMixin;
    .wrap-swatches-box { border-bottom: 0; }
    h4.generic-size-header { margin-bottom: 5px; } //Snowflake per UAT

    #genericSizeType { 
      padding-left: 26px; //Snowflake per UAT
      @include body-small();
    }

    a.refinement-link { @include body-small(); }
  }

  ul:not(.swatches) a:hover { color: $secondary; }
}

.refinement h4 { @include body-small();}
.refinement .toggle span { @include h3(); }

.refinement .refinement-values-wrapper a {
  @include body-small($line-height: 20px);
  &:hover, &:active, &:focus { color: $secondary; }
}

//Product Tiles
.product-tile {
  .product-name { //Snowflakes per UAT
    h3 { margin: 13px 0 0 0; }
    a { margin: 0; }
  } 
  
  .product-remember.enabled{
    line-height: 10px;
    &:hover a { overflow: hidden; }
  }

  .product-remember.remember-item.enabled { border: $borderMixin; }

  .product-arrival span {
    @include h3();
    background: $primary;
  }
  
  .promotion-callout { @include body-small($color: $primary); }
}


//Compare
.product-compare {
  margin: auto; //overwrite to center product compare checkbox
}
.compareitems {
  border-top: $borderMixin;
  z-index: 205; // snowflake to cover footer country select icon
  h4 { @include h2($dark-grey); }
  .product-name { @include h3(); }
  .compare-items-tab { overflow: hidden; }
}