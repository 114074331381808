/* Smartphone and small window */
.amazon-btn {
	padding: ($spacing * 2) 0;
	text-align: center;
}

.amazon-btn img {
	max-width: 100%;
}

.amazon-btn-right {
	clear: both;
	float: right;
}

.amazon-btn-billing {
	padding: ($spacing * 2) 0;
}

.amazon-btn-left {
	clear: both;
	float: left;
}

.amazon-item-list.item-list td.order-payment-summary {
	min-width: 160px;
}


#addressBookWidgetDiv .widget-container,
#walletWidgetDiv .widget-container,
#addressBookWidgetDiv iframe,
#walletWidgetDiv iframe {
	width: 100% !important;
}

#addressBookWidgetDiv,
#walletWidgetDiv {
	min-width: 280px;
	max-width: 600px;
	width: 100%;
	height: 228px;
	padding : 0 0 ($spacing * 5);
}

form legend {
	overflow: hidden; /* clearfix */
	clear: both;
}

.amazonheader {
	padding : 0 0 $spacing 0;
	margin : 0 0 0 -($spacing * 3);
}

.amazonpopup {
	padding : 0 0 0 ($spacing * 6);
}

.amazon-button {
	padding : 0 0 $spacing 0;
}

/* Ignore all the user actions when the button is disabled */
.amazon-button[disabled] {
	pointer-events: none;
	position: relative;
}

.amazon-button[disabled] img {
	opacity: 0.6;
}

.amazon-button[disabled]:before {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.billing-form{
	display: none;
}

.payment-message {
    color: #990000;
    font-size: 1em;
    padding-bottom: $spacing;
}

/* Desktop and tablet */
@media only screen and (min-width: 768px) {
    #addressBookWidgetDiv, #walletWidgetDiv {
    	width: 100%;
    	height: 228px;
   		padding : 0 0 ($spacing * 2) 0;
    }
}

@media screen and (max-width: 959px) and (min-width: 768px) {
	.amazon-item-list.item-list td {
		padding: ($spacing * 2) ($spacing * 0.5);
	}

	.primary-focus #secondary {
	  width: 190px;
	}

}
