.login-box .login-box-content {
    .field-name { @include h3(); }
    input { background: $form-field-background; }
    .label-inline label { @include body-small(); }
    a, a:hover { @include body($color: $secondary, $decoration: underline) }
}

#RegistrationForm, #CreditCardForm, #edit-address-form, .manage-subscriptions, #dwfrm_ordertrack {
    .legend-header, legend { @include h2($color: $dark-grey); }
    .field-name { @include h3($line-height: 1); }
    
    label { 
        line-height: 1; 
        .error-message { @include body-small($color: $deep-red, $line-height: 17px); }
    }
    
    .error-message {
        border-color: $deep-red;
        .field-name { color: $deep-red; }
    }

    input, select {
        @include body();
        background: $form-field-background;
        border: $borderMixin;
    }

    select { 
        cursor: pointer;
        border-radius: 0;
        background-image: url('../images/interface/select-arrow-bg.png');
        background-repeat: no-repeat;
        background-position: right center;
        &.error-message { color: $callout; }
    }

    .tooltip, .tooltip:hover { @include body($color: $secondary, $decoration: underline); }
    span.email-opt-in, legend span, .label-inline label { @include body(); }
}

#RegistrationForm, #edit-address-form, .manage-subscriptions, #dwfrm_ordertrack {
    a, a:hover{ @include body($color: $secondary, $decoration: underline); }
}
#CreditCardForm{
    a.cta-secondary, a.cta-secondary:hover{ @include cta(); }
}
.pt_account {
    h3 { @include h4(); }
    #primary a, #primary a:hover { @include body($color: $secondary, $decoration: underline); }
}

// The left navigation links for Account pages
#secondary.nav nav {
    .toggle {
        @include h4(); 
        cursor: default;
    }

    ul li a {
        @include body();
        &.left-nav-link-active { color: $secondary; }
    }
}

.checkbox-error {
    top: 1px;
    left: 0px;
    width: 14px;
    height: 14px;
}

.checkbox-error-age {
    width: 14px;
    height: 14px;
    left: 0px;
    top: 32px;
}

.custom-pref-reg { 

  .reg-form-check,
  .reg-form-error {
    top: 34px;
  }

  .reg-check-cont {
        .agreeterms-age-check{
            top:26px;
        }
    }

  .reg-check-cont {
      padding-left: 2px;
    .agreeterms-checked,
    .agreeterms-age-check {
        text-transform: initial !important;
        line-height: 6px !important;
    }
  }

  .reg-form-privacy-tag {
      display: none;
  }

  .reg-pass-button-cont {
    .register-password-show,
    .register-confirmpassword-show,
    .register-password-hide,
    .register-confirmpassword-hide {
        top: 27px;
    }
  }
}

#checkout-login-popup-modal input{
	border: 1px solid #bcbec0;
}

#RegistrationForm .col-holder .col-right {
    margin-top: 20px;
}

.mainUnregistered .custom-pref-reg .reg-check-cont .agreeterms-age-check{
    top:30px;
}
