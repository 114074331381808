$write-review-border: 1px solid $mainbordercolor !default;

.review-product {
  overflow: hidden; 
  margin: 30px 0 0; 
  border-bottom: none; 
  padding: 0 0 35px;

  @media (min-device-width: 767px) {
    &:after {
      content: '';
      display: block;
      clear: both;
    }

    .review-product-image {
      float: left;
      width: 225px;
      height: 200px;
      
      img {
        max-height: 95%;
        max-width: 95%;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    
    .review-product-info {
      float: left;
      box-sizing: border-box;
      width: 690px;
      padding-left: 30px;
      vertical-align: middle;
    }
  }
  
  .review-product-image {
    text-align: center;
    border: $write-review-border;
    background-color: $white;
  }

  .rating-container {
    float: none;
  }

  
  .review-product-info h3 {
    margin: 0 0 0.5em;
  }
}

.write-review-box,
.review-title-holder,
.review-product {
  @extend .write-review-body !optional;
  
  h1 {
    @extend .write-review-heading1 !optional;
  }
  
  h2 {
    @extend .write-review-heading2 !optional;
    border-bottom: $write-review-border;
    padding-bottom: 15px;

    span {
      margin: 0 0 0 10px;
      
      em { font-style: normal; }
    }
  }
  
  h3,
  h4,
  .form-row-box label,
  .form-row label span.field-name,
  label {
    @extend .write-review-heading3 !optional;
  }

  p,
  span.example-box,
  h2 span,
  .form-row label span.field-label-inline,
  .links-holder.checkbox-ages label,
  .ask-question-bold .links-holder.checkbox-ages label {
    @extend .write-review-body !optional;
  } 
}

.write-review-box {
  clear: both; 

  input[type="text"],
  input[type="password"],
  input[type="tel"],
  input[type="email"],
  textarea { 
    @extend .write-review-text-input !optional; 
    @extend .write-review-body !optional;
  }

  .info-text,
  .content-asset,
  .links-holder,
  .image-data {
    a {
      @extend .write-review-text-link !optional;
    }
  }

  .example-box {    
    display: block;
    clear: both;
    padding-top: 2px;
    width: 100%;
  } 

  .selection-holder .col-left input[type="radio"], 
  .selection-holder .col-right input[type="radio"] {
    clear: left; 
    float: left;
  }

  .selection-holder .col-left .form-row label, 
  .selection-holder .col-right .form-row label {
    margin: 0;
    width: 80%;
  }

  .upload-row iframe {
    border: none;
    height: 65px;
    width: 430px;
    overflow: hidden;
  }

  .upload-message, 
  .upload-error {
    width: 100%; 
    margin: 0 0 10px; 
  }

  .uploaded-images-box {
    margin: 0 0 10px;
    overflow: hidden;
    width: 100%;
  }

  .uploaded-images-box .image-data {
    margin: 0 0 10px;
    overflow: hidden;
  }

  .uploaded-images-box .image-data > img {
    max-width: 60px;
    float: left;
    margin-right: 3%;
  }

  .uploaded-images-box .image-data > div {
    float: left;
    width: 65%;
  }

  .links-holder.checkbox-ages .termsandconditions {
    padding: 0; 
    float: none;
    clear: none; 
    margin: 0 0 0 5px;  
    background: none;
    display: inline;
  }

  .rating-container {
    float: left; 
    margin-top: 0;
  }
  
  .rating-bar {
    float: left; 
    margin: 6px 0 0;
  }
  
  .current-state-b,
  .current-state-o {
    display: none;
  }
  
  .selection-holder {
    margin: 4px 0 0;
  }
  
  .selection-holder .form-row input[type="radio"] { 
    float: none; 
  }
  
  .selection-holder .form-row span { margin: 0 8px 0 0; }
  
  .selection-holder .col-left, 
  .selection-holder .col-right {
    width: 48%; 
    float: left; 
    letter-spacing: 0.1em;
  }
  
  .selection-holder .col-left > span, 
  .selection-holder .col-right > span {
    display: block; 
    margin: 0;
  }
  
  .selection-holder .col-left .form-row span, 
  .selection-holder .col-right .form-row span {
    display: block; 
    clear: right; 
    float: left; 
    margin: 5px 0;
  }
  
  .selection-holder .col-right { margin: 0 0 0 3%; }
  
  .radio-wrapper {
    display: inline-block;
    max-width: 39px;
  }
  
  .form-row-box.form-row-radio .selection-holder label, 
  .form-row-box.form-row-radio .selection-holder input {
    float: none; 
    margin: 4px 0 0; 
    text-align: center;
    width: 15px;
  }
  
  .form-row-box.form-row-radio .selection-holder .form-row span {
    margin: 0 6px 0 0; 
    letter-spacing: 0.03em;
  }
  
  .form-row-radio .selection-holder .radio-hold {
    margin: 4px 10px 0; 
    text-align: center;
  }
  
  .form-row-box.form-row-radio .selection-holder .form-row label, 
  .selection-holder .form-row label {
    margin: 0; 
  }

  .example-holder {
    width: 42%; 
    margin: 0 0 0 23%; 
    padding: 0; 
    color: $subnavtext; 
    clear: both; 
    display: block;
  }
  
  .example-holder .limit-left {
    float: left;
  }
  
  .example-holder .limit-right {
    float: right;
  }
  
  .add-product-box {
    border: 1px solid $mainbordercolor; 
    float: left; 
    padding: 7px 20px 20px; 
    text-align: center; 
    margin-bottom: 7px;
  }
  
  .add-product-image {
    background: url("../images/icons-review.png") no-repeat left top; 
    display: block; 
    width: 33px; 
    height: 32px; 
    margin-top: 5px;
  }

  .rating-bar > span {
    cursor: pointer;
  }
  
  .form-row-box {
    &.WIDTHFIT {

      .selection-holder {
        width: 100%;
        float: right;
      }
    }

    &.SIZEFITSIZING,
    &.SIZEFIT {
      float: left;
      width: 40%;

      .col-left { width: 100%; }
    }

    &.WIDTHFIT,
    &.SIZEFITSIZING,
    &.SIZEFIT {
      .form-row {
        margin: 0;
        width: 100%;

        label { 
          text-align: left; 
          width: 85%;
          padding-bottom: 0;
        }

        input[type="radio"] { margin: 7px 7px 0 1px; }
      }

      .size-width-description {
        margin-bottom: 5px;

        &:after { content: ":"; }
      }
    }
  }

  .review-action-btn {
    button, 
    .button {
      display: inline-block;
      width: 48%;
      box-sizing: border-box;
      vertical-align: top;
      height: 55px;
    }

    button {
      @extend .write-review-primary-btn !optional;
    }

    a.button.secondary-button {
      @extend .inverted-btn !optional;
      @extend .write-review-secondary-btn !optional;
    }
  }
}

.ie7 .write-review-box .selection-holder .col-left .form-row span, 
.ie7 .write-review-box .selection-holder .col-right .form-row span { float: none; }

.uploaded-images-box .remove-image {
  margin-top: 9px; 
  width: auto; 
  padding-left: 22px;
  text-indent: inherit;
}

.review-recommended {
  margin-top: 35px;

  h2 {
    border: 0;
  }
}

.review-recommended .product-recommendation-box li {
  border: $write-review-border;
  border-right: 0;

  &:last-child {
    border-right: $write-review-border;
  }
}

.review-thanks-wrapper h1 {
  margin-top: 0;
}

.review-thanks-content-wrapper {
  margin: 25px 0 30px;
}

.review-thanks-content,
.promotions-wrapper {
  display: inline-block;
  vertical-align: text-bottom; 
}

.review-thanks-content-wrapper .promotions-wrapper {
  margin-right: 30px;
}

.ask-question-box {

  .field-wrapper {
    float: left;
    width: 50%;
  }

  .preview-image {
    overflow: hidden; 
    margin: 14px 0; 
    float: left;
  }

  .info-text {
    float: right;
    width: 40%;

    h4 {
      margin: 0 0 10px; 
    }

    p {
      margin: 0 0 0.5em; 
    }

    ul {
      padding: 0 0 0 15px; 
      margin: 0.5em 0; 
      line-height: 1.4em;
    }

    ul li {
      margin: 0 0 6px;
    }
  }

  .links-holder {    
    overflow: hidden;

    &.checkbox-ages .checkbox-hold {
      position: relative; 
      float: left; 
      clear: both; 
      padding-right: 5px;
    }

    &.checkbox-ages input {
      margin: 2px 15px 0 0; 
      float: left; 
    }

    &.checkbox-ages label {
      margin: 5px 0 0; 
      text-align: left;
      width: 100%;
    }

    a {
      display: block; 
      background: url("../images/iconExternal.gif") no-repeat 100% 5px transparent; 
      float: left; 
      clear: both; 
      padding: 0 14px 4px 0;
    }
  }

  .example-box.example-box-b, 
  .example-box.example-box-c {
    display: none;
  }

  .form-row-box {
    overflow: hidden; 
    margin: 0 0 14px;
  }

  .form-row-box.form-row-required .form-row {
    width: 100%;
  }

  label, 
  .form-row-box.form-row-required .form-row label {
    width: 92%; 
    padding: 0 0 10px; 
  }
  
  .wrap-file-upload {
    input[type="file"] {
      visibility: hidden;
      width: 0;
      padding-left: 0;
    }
    
    label {
      display: inline;
      text-transform: initial;
      letter-spacing: initial;
    }
    
    a.btnFileUload {
      cursor: context-menu;
      padding: 5px 8px;
      display: initial;
      
      &:hover {
        display: initial;
      }
    }
    
    button {
        display: none;
    }
  } 

  .selection-holder, 
  .form-row {
    float: left; 
    width: 100%; 
    clear: none; 
    margin: 0 0 0.3em;
  }

  .selection-holder .form-row, 
  .checkbox-hold .form-row,  .radio-hold .form-row {
    width: auto;
  }

  .form-row label {
    width: auto; 
    margin: 0;
  }
  
  .sizefit-label { margin-top: 10px; }

  textarea { 
    box-sizing: border-box;
    height: 200px;
    margin-top: 5px;
    border: none;
  }
  
  input[type="text"], 
  input[type="email"], 
  textarea {
    width: 96.5%;
  }

  .form-row-box.form-row-required {
    input[type="text"], 
    input[type="email"], 
    textarea,
    select {
      width: 97%;
    }
  }

  .form-row-box.select-category label span{
    display: block;

    &.label-tag {
      margin: 7px 0 0; 
      padding: 0; 
      width: auto; 
      color: $subnavtext;
    }
  }

  .form-row-box.select-category label span

  .selection-holder {
    label {
      width: auto; 
      margin: 4px 17px 0 0;
    }

    input[type="checkbox"], 
    input[type="radio"] {
      float: left; 
      margin: 4px 4px 0 0;width: 18px;height:16px}

    .checkbox-hold, 
    .radio-hold {
      float: left; 
      margin: 0 0 7px;
    }

    .tag-box {
      clear: both;
    }

    .tag-box .tag-elem {
      float: left; 
      margin-bottom: 7px;
    }

    .tag-box input[type="text"] {
      width: 80%; 
      padding: 5px;
    }
  }

  .form-row-button {
    width: 100%;
  }

  .form-row-checkbox {
      margin: 0 0 5px 21%;

    .checkbox-hold {
      margin: 0 0 10px; 
      overflow: hidden;
    }

    input {
      float: left; 
      margin: 0 8px 0 0; 
      padding: 0;
    }

    label {
      width: auto; 
      margin: 0; 
      padding: 0;
    }
  }

  .comment-content {
    .avatar-img {
      float: left; 
      margin: 0 15px 0 0;
    }

    .enter-user-name {
      font-style: italic; 
      padding-top: 3px;
    }

    .comment-content p {
     margin: 0.4em 0;
    }

    .comment-title {
      float: left; 
      max-width: 450px;
    }

    .comment-date {
      float: right; 
      margin: 0.7em 0;
    }

    .comment-text {
      clear: both;
    }

    .comment-video {
      margin: 14px 0; 
      float: left; 
      position: relative;
    }
  }
}

.question-details-box, 
.question-information-box, 
.review-details-box, 
.share-photos-box, 
.include-photos-box, 
.indent-holder {
  padding: 0 0 30px;
  overflow: hidden;
}

.ask-question-box .preview-image .product-image-item, 
.preview-rating-box .product-image-item {
  float: left; 
  display: block; 
  margin: 0 10px 10px 0;
}

.ask-question-box .preview-image .product-image-item img, 
.ask-question-box .comment-content .comment-video img, 
.preview-rating-box .product-image-item img, 
.preview-rating-box .comment-video img {
  max-width: 100%; 
  width: 80px;
}

.ask-question-box .comment-content .comment-video img, 
.review-comments-video-i .comment-video img, 
.review-comments-i .comment-video img {
  width: 107px;
}

.question .user-data-content, 
.answers-box .user-data-content, 
.question-details-content .user-data-content, 
.answer-preview-data .user-data-content {
  overflow: hidden;
}

.question .user-data-content .avatar-img, 
.answers-box .user-data-content .avatar-img, 
.question-details-content .user-data-content .avatar-img, 
.answer-preview-data .user-data-content .avatar-img {
  float: left; 
  margin: 0 15px 0 0;
}

.ask-question-box .action-holder button, 
.ask-question-box .form-row-button button, 
.ask-question-box .form-row-button .button, 
.form-hold-action button {
  margin-right: 14px;
  width: 215px;
  
  &.secondary-button { margin-right: 0; }
}
