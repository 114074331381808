$cta-primary-background: $grey-charcoal-dark !default;
$cta-primary-background__hover: $grey-medium-light !default;
$cta-primary-text: $grey-medium-light !default;
$cta-primary-text__hover: $grey-charcoal-dark !default;
$cta-secondary-background: $grey-medium-light !default;
$cta-secondary-background: $grey-light-very !default;
$cta-secondary-text: $grey-charcoal-dark !default;
$cta-secondary-text__hover: $grey-charcoal-dark !default;
$disabled: $grey-silver !default;

// Default button styles

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  font-family: inherit;
  color: inherit;
  border: none;
  line-height: inherit;
}

// CTA Button styles

%_baseline-cta {
  border: none;
  box-sizing: border-box;
  display: block;
  height: 55px;
  line-height: 55px;
  min-width: 180px;
  padding: 0 12px;
  text-align: center;
  text-decoration: none;
  transition: 0.2s;

  @include cta();
  
  &.inlineblock {
    display: inline-block;
  }

  &.shrink {
    min-width: 0;
  }

  &[disabled], &[disabled]:hover {
    background-color: transparent;
    border: 1px solid $disabled;
    color: $disabled;
    cursor: default;

    @extend %brand-cta-disabled !optional;
  }
}


.cta-primary {
  @extend %_baseline-cta;
  background-color: $cta-primary-background;
  color: $cta-primary-text;
  
  &:hover, &:active, &:focus {
    background-color: $cta-primary-background__hover;
    color: $cta-primary-text__hover;
  }

  @extend %brand-cta-primary !optional;
}

.cta-secondary {
  @extend %_baseline-cta;
  background-color: $cta-secondary-background;
  color: $cta-secondary-text;
  
  &:hover, &:active, &:focus {
    color: $cta-secondary-text__hover;
    background-color: $cta-secondary-background__hover;
  }

  @extend %brand-cta-secondary !optional;
}

.button-text {
  background-color: transparent;
  border: none;
  color: $primarylink;
  height: auto;
  
  @extend .primary-links !optional;
  @extend %text-button !optional;
  
  &:hover {
    text-decoration: underline;
  }
}
