// Default out any vars used in this partial
$white: $white !default;
$search-refinement-color: $white !default;
$refinement-hover: #241fff !default;
$tile-image-height: 164px !default;
$slider-tile-width: 230px !default;
$slider-tile-padding: 11px !default;
$product-tile-rating-margin: auto auto 0 auto !default;
// product tile rating margin broken out since it's left aligned on saucony

// provided in _mixins.scss to provide a "switch" for brands to change
// where favorites lands in galleries
$gallery-favorites-layout: 0 !default;
$gallery-favorites-left-offset: 71% !default;
@include galleryFavoritesLayout($gallery-favorites-layout, $gallery-favorites-left-offset);

.search-header {
  margin: 20px 0 30px;
  overflow: hidden;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-end;
}

.search-results-header {
  float: left;
  max-width: 50%;

  p {
    margin: 0;

    &.results-text {
      text-transform: uppercase;
    }
  }

  h1 {
    display: inline-block;
    line-height: 1;
    margin: 0;
  }

  & + .opinionlab { margin-top: 10px; }
}

.search-page.wrap-top-search {
  width: 50%;
  float: right;

  .grid-sort-header {

  }
}

.sort-by a {
  padding-left: 5px;
}

.pt_product-search-result .product-tile .product-image {
  height: $tile-image-height;
}

.grid-sort-header {
  display: inline-block;
}

.search-result-items .grid-tile{
  background:none repeat scroll 0 0 $white;
  box-sizing: border-box;
  float:left;
  list-style:none outside none;
  margin:0;
  padding: 0;
  position: relative;

  .category-ad-content-asset {
    width: $slider-tile-width;
    box-sizing: border-box;
  }
}

.refinements {

  &.vertically {
    background: $grey-light-very; // this placeholder is only to display structure and should be overridden by brands

    .toggle {
      padding: 16px 17px 16px 12px;
      width: 100%;

      span {
        box-sizing: border-box;
        width: 100%;
      }
    }

    .refinement {
      &:last-child .toggle{
        border-bottom: 0;
      }
    }
    ul:not(.swatches) a:hover {color:$refinement-hover;}
  }

  &.horizontally {
    .refinement-values-wrapper {padding-bottom: 15px;}
    .wrap-swatches-box {
      // background: $grey-light-very; // this placeholder is only to display structure and should be overridden by brands
      box-sizing: border-box;
      padding-top: 18px;
      width: $slider-tile-width;
    }

    .swatches {
      width: 190px;
    }

    ul:not(.swatches) li{
      margin-bottom: 15px;

      &:last-child{margin-bottom: 7px;}
    }
  }


  .toggle {
    box-sizing:border-box;
    padding: 11px 10px 11px 12px;
    width: 140px;
  }

  .refinement-values-wrapper {
    padding: 0 19px 10px;

    h4 {margin: 10px 0 11px 0;}

    ul {padding: 0;}
  }

  .generic-size-header { padding-left: 19px; }
  #genericSizeType { padding-left: 25px; }
  .genericsizetype-refinement-wrapper { padding-left: 25px; margin-bottom: 16px; }

  .swatches {
    li {
      box-sizing: border-box;
      margin: 0 5px 5px 0;

      &:nth-child(5n) {margin-right: 0;}
    }
    a {
      background: $search-refinement-color;
      box-sizing: border-box;
      display: block;
      text-align: center;
      line-height: 34px;
      height: 34px;
      width:34px;
    }

    .selected a,
    a:hover,
    a:focus {
      background: $refinement-hover;
      border: 0;
      color: $search-refinement-color;
    }
  }

  ul:not(.swatches) {
    padding: 0 6px;

    li {
      padding: 0;
      margin-bottom: 12px;
      overflow: hidden;

      &:last-child{margin-bottom: 7px;}
    }
  }

  #width,
  #weite,
  #Largeur {
    li {
      margin-right: 6px;

      &:nth-child(4n) {margin-right: 0;}
    }
    a {
      width:42px;
    }
  }
}

.refinement-overflow-toggle {cursor:pointer;}

.product-tile {
  box-sizing: border-box;
  padding: 2*$slider-tile-padding;
  text-align: center;
  width: $slider-tile-width;
  height: 100%;
  display: flex;
  flex-direction: column;

  .product-image {
    margin-bottom: $slider-tile-padding;

    a {
      display: block;
      height: 100%;
    }

    img {
      max-height: 100%;
    }
  }

  .product-name {flex-shrink: 0;}

  .quickviewbutton-box {
    width: 160px;
    top:32px;
    left:50%;
    transform:translateX(-50%);
  }

  .product-arrival {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    span {
      padding: 9px;
    }
  }

  .rating-container {
    margin: $product-tile-rating-margin;
  }

  // .product-remember {
  //   position:absolute;
  //   top: 167px;
  //   right: 45px;
  //   @include galleryFavoritesLayout($gallery-favorites-layout);
  // }

  .product-customize-gallery {
    margin: 0 0 12px;
  }

  .preconfig-link {
    display: inline-block;
    padding-left: 23px;
    position: relative;
    line-height: 15px;
    &:before {
      content: '';
      position: absolute;
      left:0;
      @include sprite($gallery-icon-customize);
    }
  }

  .product-swatches-all {display:inline-block;}

  .promotion { margin-top: 10px; }

}
.opinionlab {
  text-align: right;
}
.oo_inline {
  @extend .default-p-text !optional;
  padding: 0 0 0 13px;
  position: relative;
}

.oo_inline_icon {
  background-image: url('../lib/onlineopinionV5/oo_icon.gif');
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  display: block;
  height: 100%;
  width: 9px;
  position: absolute;
  top:0;
  left:0;
}

.refinement-ui-tooltip {
    @include triangle(left, $white, 15px );
    @include filterShadow(left, -1px 1px 1px rgba(0, 0, 0, 0.1) );
}

.refinement-tooltip-icon{
	font-family : "AvenirNextLTW01-Medium", "Helvetica Neue", Helvetica, Arial, sans-serif;
  display     : inline-block;

	width : 15px;
  height: 15px;
  margin: 0 0 0 6px;

	border-radius : 50%;
  font-size     : 10px;
  line-height   : 15px;
  text-align    : center;
  text-transform: lowercase;

	color     : $white;
  background: #a9a9a9; //Default background color
}


.refinement-tooltip-container{
	display     : inline-flex;
	align-items : center;
	position    : relative;
	bottom      : 1px;
}

.recommended-product-tile .product-tile .product-image {
  height: 100%;

  img {
    width: $slider-tile-width - 2*$slider-tile-padding;
  }
}

.account-dashboard {
  .recommended-product-tile .product-tile .product-image {  
    img {
      width: 100%;
      min-height: 185px;
      max-height: 185px;
      object-fit: contain;
    }
  }
  .product-slider {
    .slides {
      .slick-prev {
        z-index: 1;
      }
      .slick-prev,
      .slick-next {
        top: 35%;
        font-size: 0;
      }
      @media only screen and (min-width: 768px) {
        .slick-list {
          margin-left: 0;
        }
      }
    }
    .product-tile {
      padding-left: 0 !important;
      .name-link {
        margin-bottom: 4px;
      }
      .product-pricing {
        margin-bottom: 5px;
      }
      .product-image {
        border: 1px solid $grey-light;
        margin-bottom: 8px;
        margin-left: 3px;
      }
    }
  }
}
