@use "sass:math";
@use "sass:map";

@function opposite-direction($directions) {
  $opposite-directions: ();
  $direction-map: (
    'top':    'bottom',
    'right':  'left',
    'bottom': 'top',
    'left':   'right',
    'center': 'center',
    'ltr':    'rtl',
    'rtl':    'ltr'
  );

  @each $direction in $directions {
    $direction: to-lower-case($direction);

    @if map-has-key($direction-map, $direction) {
      $opposite-directions: append($opposite-directions, unquote(map-get($direction-map, $direction)));
    } @else {
      @warn "No opposite direction can be found for `#{$direction}`. Direction omitted.";
    }
  }

  @return $opposite-directions;
}

@function str-replace($str,$mtch,$rplc) {
  $rtn: $str;

  //Quick and dirty loop 
  @for $i from 1 through 20 {
    $ind: str-index($rtn,$mtch);

    @if($ind) {
      $rtn: str-slice($rtn, 1, $ind - 1) + $rplc + str-replace(str-slice($rtn, $ind + str-length($mtch)), $mtch, $rplc); 
    } @else {
      @return $rtn;
    }

  }
  @return $rtn
  
}

@mixin box-shadow($shadow) {
  box-shadow: $shadow;
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  -ms-box-shadow: $shadow;
  -o-box-shadow: $shadow;
}

@mixin transition($transition) {
  transition: $transition;
  -o-transition: $transition;
  -ms-transition: $transition;
  -moz-transition: $transition;
  -webkit-transition: $transition;

}

@mixin triangle($direction, $color: currentcolor, $size: 1em) {
  &:before {
    content : '';

    width   : 0;
    height  : 0;
    position: absolute;

    $transparent-borders: $size solid transparent;

    border-#{$direction}: $transparent-borders;
    border-#{opposite-direction($direction)}: $size solid $color;

    @if $direction == top or $direction == bottom {

      #{$direction}:-(2 * $size);
      right : 0;
      left  : 0;
      margin: auto;
      border-left : $transparent-borders;
      border-right: $transparent-borders;

    } @else if $direction == right or $direction == left {

      #{$direction}:-(2 * $size);
      top: 50%;
      transform: translateY(-50%);
      border-bottom : $transparent-borders;
      border-top    : $transparent-borders;

    }
  }
}

//Used for complicated addon shapes like tooltip triangles
@mixin filterShadow($direction, $shadow) {
  &:before {
    $hor  : 0.7 * nth($shadow, 1);
    $vert : 0.7 * nth($shadow, 2);
    $disp : 0.7 * nth($shadow, 3);
    $color: nth($shadow, 4);

    @if $direction == left    and  $hor > -2 * $disp { $hor  : -2 * $disp; }
    @if $direction == right   and  $hor <  2 * $disp { $hor  :  2 * $disp; }
    @if $direction == top     and $vert > -2 * $disp { $vert : -2 * $disp; }
    @if $direction == bottom  and $vert <  2 * $disp { $vert :  2 * $disp; }

    -webkit-filter: drop-shadow($hor $vert $disp $color);
    filter:         drop-shadow($hor $vert $disp $color);
  }
}

//Mixin for tabs on the customizer PDP page
@mixin configurator-pdp-tabs-border-radiuses ($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
  -webkit-border-top-right-radius:    $topright;
  -webkit-border-bottom-right-radius: $bottomright;
  -webkit-border-bottom-left-radius:  $bottomleft;
  -webkit-border-top-left-radius:     $topleft;

  -moz-border-radius-topright:        $topright;
  -moz-border-radius-bottomright:     $bottomright;
  -moz-border-radius-bottomleft:      $bottomleft;
  -moz-border-radius-topleft:         $topleft;

  border-top-right-radius:            $topright;
  border-bottom-right-radius:         $bottomright;
  border-bottom-left-radius:          $bottomleft;
  border-top-left-radius:             $topleft;
  border: 0;
  padding: 0;
}

// provide a means to let brands change how their favorites is positioned
// Layout 0: favorites in the bottom right corner of the product image
// Layout 1: favorites above the product name, adjusting for customize and more colors
@mixin galleryFavoritesLayout($layoutStyle: 0, $leftOffset: 71%){
  @if $layoutStyle == 0 {
    .product-tile .product-remember {
      position:absolute;
      top: 167px;
      right: 45px;
    }
  }
  @else if $layoutStyle == 1 {
    .product-tile {
      .product-remember {
        position: absolute;
        top: 194px;
        left: 45%;
      }
      .product-swatches {
        margin-left: -32px;
        line-height: 15px;
      }
      .small-product-image-box { margin-left: -30%; }

      // begin the war of siblings
      .product-name {margin-top: 15px;}
      .product-swatches ~ .product-name {margin-top: 0}
      .product-customize-gallery ~ .product-remember {
        top: 221px;
      }
      .product-swatches ~ .product-remember {
        left: $leftOffset;

        &:before {
          content: '';
          border-left: 1px solid $primarylink;
          display: block;
          position: absolute;
          left: -2px;
          top: 3px;
          height: 13px;
        }
      }

      .product-image { margin-bottom: 40px; }
      .product-swatches ~ .product-image {margin-bottom: 11px;}
    }
  }
  @else if $layoutStyle == 2 {
    .product-tile .product-remember {
      position:absolute;
      top: 177px;
      right: 45px;
    }
  }
}

// Option for shaded first-column ("Featured") in main navigation
@mixin featured-column($background-color){
  .column1 ul.level-2 {
    padding: 10px 20px !important;
    box-sizing: border-box;
    background: $background-color;
  }
}

@mixin sprite-width($sprite, $scale) {
  $sprite-width: nth($sprite, 5);
  @if type-of($sprite-width) == 'number' and type-of($scale) == 'number' {
    width: math.div($sprite-width, $scale);
  }
}

@mixin sprite-height($sprite, $scale: 1) {
  $sprite-height: nth($sprite, 6);
  @if type-of($sprite-height) == 'number' and type-of($scale) == 'number' {
    height: math.div($sprite-height, $scale);
  }
}

@mixin sprite-position($sprite, $scale: 1) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  @if type-of($sprite-offset-x) == 'number' and type-of($sprite-offset-y) == 'number' and type-of($scale) == 'number' {
    background-position: math.div($sprite-offset-x, $scale)  math.div($sprite-offset-y, $scale);
  }
}

@mixin sprite-image($sprite, $scale: 1) {
  $sprite-width  : nth($sprite, 7);
  $sprite-height : nth($sprite, 8);
  $sprite-image  : nth($sprite, 9);

  background-image: url(#{$sprite-image});
  @if type-of($sprite-width) == 'number' and type-of($sprite-height) == 'number' and type-of($scale) == 'number' {
    background-size: math.div($sprite-width, $scale)  math.div($sprite-height, $scale);
  }
}

@mixin sprite($sprite, $scale: 1) {
  @include sprite-image($sprite, $scale);
  @include sprite-position($sprite, $scale);
  @include sprite-width($sprite, $scale);
  @include sprite-height($sprite, $scale);
}

@mixin sprite-scale-width($sprite, $width) {
  $icon-width : nth($sprite, 5);
  $scale      : math.div($icon-width, $width);

  @include sprite-image($sprite, $scale);
  @include sprite-position($sprite, $scale);
  @include sprite-width($sprite, $scale);
  @include sprite-height($sprite, $scale);
}

@mixin sprite-scale-padding($sprite, $scale, $width, $height) {
  $icon-offset-x : nth($sprite, 3);
  $icon-offset-y : nth($sprite, 4);
  $icon-width    : nth($sprite, 5);
  $icon-height   : nth($sprite, 5);
  $sprite-width  : nth($sprite, 7);
  $sprite-height : nth($sprite, 8);
  $sprite-image  : nth($sprite, 9);

  $padding-x : math.div(($width  - (math.div($icon-width, $scale))), 2);
  $padding-y : math.div(($height - (math.div($icon-height, $scale))), 2);

  width               : math.div($icon-width, $scale);
  height              : math.div($icon-height, $scale);
  background-size     : math.div($sprite-width, $scale)  math.div($sprite-height, $scale);
  background-image    : url(#{$sprite-image});
  background-clip     : content-box;
  box-sizing          : content-box !important;
  background-position : math.div($icon-offset-x, $scale + $padding-x)  math.div($icon-offset-y, $scale + $padding-y);
  padding             : $padding-y $padding-x;
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

@mixin all-sprites() {
  .sprite-icon {
    display: block;    
    @extend %sprite-icon !optional;
  }
  $sprite-size: (
    1: 'xl',
    2: 'l',
    3: 'm',
    4: 's',
    5: 'xs',
    6: 'xxs',
    7: 'xxxs'
  );
  @each $sprite in $spritesheet-sprites {
    $sprite-name: str-replace(nth($sprite, 10),' ','-');
    @for $size from 1 through 7 {
      .sprite-#{$sprite-name + '--' + map.get($sprite-size,$size)} {
        @include sprite($sprite,(.25 + ($size * .15)));
      }
    }
  }
}

@mixin scarcity-icon {
  width: 14px;
  height: 13px;
  margin-right: 5px;
  background-image: url('../images/cartcheckout/icon-alert.svg');
  background-repeat: no-repeat;
}

@-ms-keyframes spin {
    from { -ms-transform: rotate(0deg); }
    to { -ms-transform: rotate(360deg); }
}
@-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
@mixin spin($animationSpeed: 2000ms, $iteration: infinite, $timing: linear, $direction: normal) {
  -webkit-animation-name: spin;
  -webkit-animation-duration: $animationSpeed;
  -webkit-animation-iteration-count: $iteration;
  -webkit-animation-timing-function: $timing;
  -moz-animation-name: spin;
  -moz-animation-duration: $animationSpeed;
  -moz-animation-iteration-count: $iteration;
  -moz-animation-timing-function: $timing;
  -ms-animation-name: spin;
  -ms-animation-duration: $animationSpeed;
  -ms-animation-iteration-count: $iteration;
  -ms-animation-timing-function: $timing;

  animation-name: spin;
  animation-duration: $animationSpeed;
  animation-iteration-count: $iteration;
  animation-timing-function: $timing;
  animation-direction: $direction;
}

// Responsive media query mixins
// Variable sizes pulled from flexboxgrid
$mobile: 320px;
$tablet: 768px;
$desktop: 936px;
$large-desktop: 1024px;
$widescreen: 1280px;
@mixin breakpoint($device) {
    @media screen and (min-width: $device), print {
        @content;
    }
}
@mixin breakpoint-max($device) {
  @media screen and (max-width: ($device - 1px)), print {
      @content;
  }
}
