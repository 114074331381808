#single-page-checkout,
#order-confirmation {
  .spc-content-checkout-customer-help {

    & > div {
      margin: ($spacing * 2);
    }
    h3 {
      @extend .heading2;
      margin-bottom: 0.5em;
    }
    p {
      margin-bottom: 0.5em;
    }
    .cart-banner-box {
      margin-bottom: 0;
    }
    .action-hold ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        position: relative;
        display: inline-block;
        padding-right: 5px;
        font-size: 10px;
        text-align: left;
        a::after {
          content: "|";
          position: absolute;
          right: -5px;
        }
        ~ li {
          padding-right: 0;
          a::after {
            content: "";
          }
        }
      }
    }
  }
}
