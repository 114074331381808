$enhanced-search-base-color: $primary !default;
$enhanced-search-panel-background: $white !default;
$enhanced-search-placeholder-color: $grey-medium !default;
$enhanced-search-input-background: $grey-light-very !default;
$enhanced-search-input-border: 1px solid $enhanced-search-base-color !default;
$enhanced-search-submit-icon-color: $white !default;
$enhanced-search-submit-background-color: $enhanced-search-base-color !default;
$enhanced-search-clear-button-color: $enhanced-search-base-color !default;
$enhanced-search-x-button-color: $enhanced-search-base-color !default;
$enhanced-search-x-button-background: $enhanced-search-panel-background !default;

$enhanced-search-heading-color: $black !default;
$enhanced-search-overlay-color: #efefef;

.enhanced-site-search-active {
  overflow: hidden;

  #wrapper.pt_product-search-result {
    overflow: visible;
  }
}

.enhanced-site-search {
  .searchbar {
    display: flex;
    flex-flow: row;
    justify-content: space-between;

    @media screen and (min-width: 769px) and (max-width: 821px){
      width: max-content;
      float: right;
    }

    .close-button {
      background-color: $enhanced-search-x-button-background;
      height: 50px;
      width: 50px;
      line-height: 1;
      
      svg {
        height: 19px;
        width: 19px;

        path {
          stroke: $enhanced-search-x-button-color;
        }
      }
    }
  }

  .simple-search-form {
    width: 100%;
    display: flex;
    background-color: $enhanced-search-input-background;
    border: $enhanced-search-input-border;
    height: 40px; // + border = 50px
    z-index: 2; // brings it over flyout box-shadow
  }

  .clear-search {
    background-color: transparent;
    color: $enhanced-search-clear-button-color;
    font-size: 14px;
    line-height: 1;
    text-decoration: underline;
    height: 100%;
    opacity: 0.5;
    padding: 0 14px;
    display: none;

    &.active {
      display: block;
    }
  }

  .simple-search-input {
    font-size: 16px;
    @include breakpoint($tablet) {
      @include body-small();
    }
    height: 100%;
    flex: 1 0 auto;
    border: 0;
    border-radius: 0;
    width: 48%;
    background-color: $enhanced-search-input-background;

    &::placeholder {
      color: $enhanced-search-placeholder-color;
    }
  }

  .submit-btn {
    background-color: $enhanced-search-submit-background-color;
    height: 100%;
    width: 50px;
    padding: 0;
    line-height: 1;
    border: 0;
    border-radius: 0;

    svg {
      height: 30px;
      width: 30px;

      path {
       fill: $enhanced-search-submit-icon-color;
      }
    }
  }

  &.desktop-enhanced-site-search:not(.active) {
    .simple-search-form {
      border: 0;
    }

    .submit-btn {
      background-color: transparent;
      
      svg path {
        fill: $enhanced-search-submit-background-color;
      }
    }
  }
}

.mobile-enhanced-site-search {
  display: flex;
  visibility: hidden;
  flex-flow: column;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: $enhanced-search-panel-background;
  left: 100%;
  z-index: 10000;
  transition: left 0.5s, visibility 0s;
  transition-delay: 0s, 0.5s;
  // Hack for mobile iOS bottom menu
  max-height: -webkit-fill-available;
  min-height: 100vh;
  
  &.open {
    visibility: visible;
    left: 0;
    transition-delay: 0s, 0s;
  }
}

.sticky-search-enabled {
  &.is-responsive .desktop-enhanced-site-search,
  .pt_cart .desktop-enhanced-site-search {

    @include breakpoint($widescreen) {
      width: 306px;
      margin-right: 230px;
    }

    &.active {
      width: 607px;
    }
  }

  &:not(.is-responsive) #wrapper:not(.pt_cart) .desktop-enhanced-site-search {
    width: 200px;

    &.active {
      width: 607px;
    }
  }
  
  .desktop-enhanced-site-search {
    top: 0;

    .simple-search-form {
      height: 38px;
    }
  }
}

.enhanced-sticky-search-container {
  position: absolute;
  right: 11.5%;
  clear: both;
  width: 320px;
  height: 40px;
  padding: 8px;
}

@media screen and (max-width: 768px) {
  #desktop-header .enhanced-sticky-search-container{
    display: none;
  }
}

.desktop-enhanced-site-search {
  background-color: $enhanced-search-panel-background;
  position: absolute;
  transition: 0.3s width;
  right: 0;
  
  @include breakpoint($large-desktop) {
    width: 320px;
  }

  @media screen and (min-width: 769px) and (max-width: 821px){
    display: contents;
  }
  
  &.active {
    width: 607px;
    z-index: 10000;

    .simple-search-input,
    .simple-search-form {
      background-color: $enhanced-search-panel-background;
    }
  }

  .simple-search-form {
    border: $enhanced-search-input-border;
  }
}

#nohits-search-container {
  position: relative;

  .close-button {
    display: none;
  }

  .enhanced-site-search-results {
    display: none;

    @include breakpoint($tablet) {
      position: absolute;
      box-sizing: border-box;
      margin: 0;
    }
  }
    
  &.active .enhanced-site-search-results {
    @include breakpoint($tablet) {
      display: flex;
      max-width: 1067px;
      width: calc(100vw - 150px);
      height: 490px;
    }
  }

  .enhanced-products-container,
  .enhanced-searchterms {
    width: 50%;
    padding-right: 71px;
    
    @include breakpoint($large-desktop) {
      display: flex;
      padding: 0;
    }
  }
  
  .enhanced-search-group {
    @include breakpoint($large-desktop) {
      margin-left: 71px;
    }

    &.enhanced-did-you-mean {
      margin-left: 0;
      flex: 0 0 auto;
    }
  }
}

.search-overlay {
  &.active {
    display: block;
  }
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $enhanced-search-overlay-color;
  opacity: 0.7;
  z-index: 1300;
}

.enhanced-site-search-results {
  padding: 24px;
  height: 100%;
  overflow: auto;
  
  @include breakpoint($mobile) {
    padding-bottom: 100px;
  }

  @include breakpoint($tablet) {
    height: 0;
    padding: 0 24px;
    background-color: $enhanced-search-panel-background;
    
    .desktop-enhanced-site-search.active & {
      @include header-shadow();
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      height: 621px;
      padding: 24px;
      transition: 0.5s height, 0.5s padding;
      transition-delay: 0.2s;
    }
  }

  h3 {
    font-size: 16px;
    line-height: 22px;
    color: $enhanced-search-heading-color;
    text-transform: uppercase;
    font-weight: 500;
    padding-bottom: 8px;
    margin: 0;
    white-space: nowrap;
  }

  ul {
    padding: 0;
    margin: 0 0 24px;
    list-style-type: none;

    @include breakpoint($tablet) {
      padding-right: 60px;
    }
    
    li {
      padding-bottom: 8px;
    
      a {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400; // "normal"
      }
    }
  }

  .products li {
    padding-bottom: 16px;
    @include breakpoint($tablet) {
      padding-bottom: 8px;
    }
  }
}

@keyframes animateLoadingTiles {
  from {
    background-position: 0;
  }

  to {
    background-position: 332px;
  }
}

.enhanced-search-product-tile {
  display: flex;
  flex-flow: row nowrap;
  // margin-bottom: 8px;

  .image-container {
    width: 98px;
    height: 78px;
    margin-right: 8px;
    text-align: center;
    flex: 0 0 auto;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .product-attributes {
    display: flex;
    flex-flow: column;
    justify-content: center;

    .product-name {
      font-size: 16px;
      line-height: 23px;
    }

    .product-pricing, .product-price {
      @extend .product-zone-price !optional; // old adaptive pages
      @extend %product-zone-price !optional; // responsive pages
      font-size: 14px;
      line-height: 24px;

      .price-standard {
        text-decoration: none;
      }

      .product-sales-price {
        @extend .product-grid-sale-price !optional; // old adaptive pages
        @extend %product-grid-sale-price !optional; // responsive pages
        color:$sale-text-price;
      }
    }

    .product-standard-price {
      text-decoration: line-through;
      color:$price-strikethrough;
    }
  }

  .ghosted & {
    align-items: flex-start;
    position: relative;
    margin-bottom: 36px;
    
    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-image: linear-gradient(90deg, transparent 0%, $white 5%, transparent 10%);
      animation: animateLoadingTiles 1s linear 2;
    }
  
    .image-container {
      background-color: #f3f3f3;
      border-radius: 5px;
      height: 49px;
      width: 49px;
      margin-right: 16px;
    }
    
    .product-name {
      background-color: #f3f3f3;
      border-radius: 5px;
      height: 18px;
      width: 267px;
      margin-bottom: 7px;
    }
    
    .product-pricing {
      background-color: #f3f3f3;
      border-radius: 5px;
      height: 18px;
      width: 127px;
    }
  }
}
@import "_header-us.scss";
