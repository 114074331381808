.body { @include body(); }
.heading { @include h1(); }
.heading2 { @include h2(); }
.heading3 { @include h3(); }
.heading4 { @include h4(); }
.heading5 { @include h4(); }
.label-text { }
.inline-label-text { @include h3()}

strong {
  // prevent webfonts from going bold and actually apply bold font
  font-style: normal;
  font-family: $h3-family;
}

.italic {
  // adhering to naming conventions already deployed - thus the interesting paradox here
  font-style: normal;
}

.tool-tip-shadow { @include box-shadow-01(); }
.breadcrumb-current, .breadcrumb a.breadcrumb-last { @include body-small($color: $base, $decoration: none); }
.breadcrumb a, .breadcrumb-text { @include body-small($color: $dark-grey, $decoration: none); }
.breadcrumb a:hover { @include body-small($color: $base, $decoration: underline); }
.default-p-text { @include body(); }
.default-text { @extend .default-p-text; }

.primary-links {
  @include body($color: $secondary);
  &:hover, &:focus, &:active { color: $secondary;}
}

.form-caption a {
  &:hover { }
}

.bf-review, .tag-elem-off { }
.legend-text { }
.requirement-text { }
.form-text-input { }
.helper-text { }
.default-small-text { }
.alt-default-small-text { }

%brand-cta-primary {
  // brand-specific button overrides go here
}

%brand-cta-secondary {
  // brand-specific button overrides go here
}

%brand-cta-disabled {
  border: 0;
  background-color: $grey-light;
  color: $base;
}

// Mini-cart Scroll Bar
.jspTrack { background: $secondary; }
.jspDrag { background: $primary; }

//Tooltip Styling
.tooltip {
  .tooltip-content {
    @include box-shadow-03();
    strong { }
    p { }
  }
}

.tooltip-icon { }

// universal delivery message styles
.delivery-message, .delivery-style {
  .time, .date { }
}

.product-tile {
  .product-compare label { @include body-small(); }
  
  .product-remember.enabled:hover a {
    border: $borderMixin;
    line-height: 1;
    color: $base;
  }
}

.confirmation-login-modal #RegistrationForm {
	span.email-opt-in, .checkbox-label {
		font-family: $body-family;
	}
}

.account-login-register {

  #checkout-login-popup-modal,
  #checkout-createaccount-popup {
    .form-group-new .form-label {
      font-family: $body-family;
    }
  }
}

html[lang="en-us"] {
  #desktop-header #navigation.menu-nav-centered {
    .desktop-navigation {
      margin-right: 90px;
    }
  }
}

.stepper__item:last-child {
  margin-top: 2px;
}

@media screen and (max-width: 540px) {

  html[lang="de-DE"],
  html[lang="de-AT"] {
    .stepper__item.stepper__visited__state.order-completed:last-child {
      margin-top: 3px;
    }
  }
}
@media screen and (max-width: 768px){
	.account-nav-item .unauthenticated-list {
	  padding-left:34px !important;
	}
}
