html[lang="en-us"] {

  @media screen and (min-width: 1650px) {
    #header {
      position: relative;

      .top-of-header.menu-nav-centered {
        max-width: 1600px;
        margin: 0 calc((100% - 1600px)/2);

        &::after {
          content: '';
          position: absolute;
          width: 100vw;
          height: 100%;
          transform: translateX(calc((-100% + 1600px)/2));
          background: inherit;
        }

        .top-header-banner {
          width: 100%;
        }

        .top-header-links {
          right: -3px !important;
        }
      }
    }
  }

  @media screen and (max-width: 1650px) and (min-width: 769px) {
    #header .top-of-header.menu-nav-centered {
      .top-header-links {
        right: 22px !important;
      }
    }
  }

  @media screen and (min-width: 769px) {
    #desktop-header #navigation.menu-nav-centered {
      .inner {
        padding: 0 25px 0 25px;
        margin: 0 auto !important;
        position: relative;
        box-sizing: content-box;
      }

      .desktop-navigation {
        margin-right: 195px;
      }

      .enhanced-sticky-search-container {
        right: 66px;
      }

      .mini-cart-container {
        right: 15px;
        left: unset;
      }
    }
  }
}