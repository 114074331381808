// Variables for brand-specific files
$drawer-header-BG: transparent !default;
$drawer-header-font: arial, helvetica, sans-serif !default;
$drawer-header-height: 115px !default;
$drawer-content-BG: transparent !default;
$product-tile-price-font: '' !default;
$discontinued-cell-stroke: $borderMixin !default;
$technology-grid-width:33% !default;
$pdp-drawer-tech-margin: 14px 0 0 !default;

// PDP V2
.page-container {
  width: 960px;
  margin: 0px auto;
}

.product-info {
  .product-price{
    float:none;
  }
}

// remembered items
.pdp-main.pdpv2 .remember-item.icon-only{
  padding: 0px;
  display: block;
  height: 18px;
  width: 24px;
  &.enabled {
    margin: 0px;
    background-position: 0px -40px; // possibly belongs in branding styles.
  }
}

.pdp-drawer-header {
  color: inherit;
  background-color: $drawer-header-BG;
  font-family: $drawer-header-font;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  max-width: 920px;
  margin: auto;
  padding: 25px 0;

  .pdp-drawer-title { 
    float: left; 
    margin-top: 5px;
  }

  .rating-container {
    float: right;
    margin: 3px 6px 0 24px;
  }
} 

.pdp-state {
  float: right;
  @include sprite($drawer-icon-expand);
}

.pdp-drawers {
  width: 300%;
  margin-left: -100%;
  overflow: hidden;
}

.pdp-drawer {
  border-top: 1px solid $mainbordercolor;
  border-bottom: 0;

  .pdp-drawer-content {
    box-sizing: border-box;
    display: none;
    background-color: $drawer-content-BG;
    padding: 0 0 46px;
    max-width: 920px;
    margin: auto;

    &.no-padding {
      padding: 0px;
    }

    .description {
      margin: 0 0 30px;
      
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.active {
    .pdp-drawer-content {
      display: block;
    }

    .pdp-state {
      @include sprite($drawer-icon-expanded);
    }
  }

  &.technologies {

    .pdp-drawer-content { overflow: hidden; }

    .product-technologies {
      float: left;
      clear: none;
      margin: -8px 0 0 10px;

      &-icons {
        margin: 0;
      }

      .product-tab-content-asset {
        float: left;
      }

      .sprite-technologies {
        margin: $pdp-drawer-tech-margin;
      }
    }

    .content-asset {
      width: $technology-grid-width;
      clear: none;
      float: left;
      @if $technology-grid-width == 25% {
        &:nth-child(4n+1) { clear: left; }
      } @else if $technology-grid-width == 33% {
        &:nth-child(3n+1) { clear: left; }
      } @else if $technology-grid-width == 50% {
        &:nth-child(2n+1) { clear: left; }
      }
    }

  }

  &.reviews {

    .pdp-drawer-title {
      .reviews-tab-count {
        float: right;
      }
    }
    .reviewtab-content-box {
      .reviews-tab-count {
        display: block;
        text-align: center;
      }
    }
    &.reviewCount-empty {
      .pagination{display:none;}
    }
  }

  &.recommended {

    ul.www-recommendations {
      padding-bottom: 40px;
      display: table;
      width: 100%;

      li {
        display: table-cell;
        width: 25%;
      }
    }
  }

  &.recently-viewed {

    .recently-viewed-products {
      padding-bottom: 20px;

      .product-tile {
        text-align: center;

        .product-name {
          float: none;
          width: 100%;

          .name-link { display: block; }
        }

        .product-pricing {
          display: block;
          width: 100%;

          .product-price {
            width: 100%;
            display: block;
            text-align: center;
          }
        }
      }

      #horizontal-carousel {
        padding: 0;
        overflow: hidden;

        li {
          width: 228px;
          height: 280px;
          margin: 0;
        }
      }

      .jcarousel-container {
        margin-left: 0;
        width: 100%;
      }

      .jcarousel-clip {
        margin: 0 0 20px 0;
        width: auto;
      }

      .jcarousel-next {
        top: 130px;
        right: -20px;
      }

      .jcarousel-prev {
        top: 130px;
        left: -20px;
      }
    }
  }
}

// not a drawer, but mimics the drawer
#product-top-content .content-recommendations {
  width: 920px;
  margin: 0 auto;
  padding-bottom: 75px;

  ul.www-recommendations {
    display: table;
    width: 100%;

    li {
      border: $discontinued-cell-stroke;
      border-right: none;
      display: table-cell;
      margin: 0;
      padding: 0;
      position: relative;
      height: auto;
      width: 25%;

      &:last-child {
        border-right : $discontinued-cell-stroke
      }

      .product-image {
        margin-top: 15px;
      }
    }
    
    .content-tile {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
	
	.product-name {
    padding    : 0 0.5em;
    box-sizing : border-box;

    a {
      @extend %discontinued-product-name !optional;
    }
	}

	.product-pricing {
		@extend %discontinued-product-price !optional;
	}

	.product-tile {
		padding-top: 0;
		padding-bottom: 0;
	}
}
