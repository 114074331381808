#single-page-checkout,
.spc-modal-content {

  button.spc-button {
    overflow: hidden;

    @include breakpoint-max($tablet) {
      width: 100%;
    }

    &-link {
      @include body();
      color: $primarylink;
      background: none;

      padding: 0;
      height: 100%;
      text-transform: none;
      width: auto;

      &:hover{
        color: $mainbrand
      }
    }

    &-placeorder {
      margin-top: $spacing * 5;
      width: auto;

      @include breakpoint-max($tablet) {
        margin: ($spacing * 3) ($spacing * 2) ($spacing * 6);
        width: calc(100% - #{$spacing * 4});
      }
    }
  }
}

.spc-continue-to-billing,.spc-continue-disabled {
  margin-top: $spacing * 2;

  @include breakpoint-max($tablet) {
    margin-bottom: $spacing * 5;
  }
}

.spc-review-order {
  margin-top: $spacing * 5;

  @include breakpoint-max($tablet) {
    margin-bottom: $spacing * 5;
  }
}

[class*=col-] > .spc-button { width: 100%; }

.spc-button-no-fill {
  background: transparent;
  border: 1px $primary solid;
  color: $primary;
}