
// store locator
// -------------------------------------

.pt_store-locator { 
  .primary-content {
    border: none;
  }

  .col-1, 
  .col-2 {
    float: none; 
    width: 100%; 
    margin:0;
  }

  .locator-search.col-2 {
    margin: 0 0 20px;

    .label-inline label {
      width: 70%;
      margin: 0;
    }
  }

  .divider-search {
    margin: 23px 0;
  }

  .store-message {
    font-style: italic;
  }
}

.locator-search-content {
  margin-bottom: 20px;

  .form-row {
    margin: 0 0 0.7em;
  }

  label,
  legend {
    width: 100%;
  }

  legend {
    border-bottom: none;
  }

  select.input-select {
    width: 100%; 
    color: $mainbrand;
  }

  input[type="text"] {
    width: 100%; 
  }

  .label-inline label {
    width: 90%;
  }

  .form-row-button {
    margin: 8% 0 0;
  }

  .form-row-button button {
    width: 100%;
  }

  #zipcode-input {
    padding-top: 15px;
  }

  .form-row-country {
    border-bottom: 1px solid $mainbordercolor; 

    select.country {
      margin: 0 0 30px;
    }

    legend {
      padding-bottom: 0;
      margin-bottom: 20px;
    }
  }

  .locator-category-box {
    margin: 8% 0 0;
  }

  .locator-category-box .form-row {
    margin: 0 0 1.2em; 
    overflow: hidden;
  }

  .locator-category-box input[type="checkbox"] {
    float: left; 
    margin: 0;
  }

  .locator-category-box span {
    display: block; 
    margin: 0 0 0 10px; 
    float: left;
  }
  .country-field-hidden{
    display: none;
  }
}

//store result
.primary-content-store h1, .locationSearchResult h1 {
  margin: 0.5em 0;
}

.store-locator-header{
  background:none;
  font-style:italic;
  margin:20px 0 0;
  padding:20px 0;
}

#store-location-results {
  border-top:1px solid $mainbordercolor;

  tr td {
    border-bottom:1px solid $mainbordercolor; 
    padding:1.5em 1em; 
    line-height: 18px;
  }

  .star-icon {
    display: inline-block; 
    margin: 0 5px 0 0; 
    padding: 5px 0 0; 
    width: 12px; 
    height: 12px; 
    background:url("../images/star-icon.png") no-repeat scroll 0 6px transparent;
  }
}

.store-information .editbutton {
  padding: 5px 0 0;
}

.store-information .editbutton, 
#store-location-results a {
  display: block;
}

.store-information .editbutton:hover, 
#store-location-results a:hover {
  color: $mainbrand;
}

.store-name {
  margin :0 0 10px; 
  max-width: 230px;
}

.store-locator-no-results {
  border-top: 1px solid $mainbordercolor;
}

.locationSearchResult .search-result-options {
  margin: 10px 0;

  .pagination {
    margin: 15px 0;
    
    ul { display:block; }
  }
}

//end store result

//store detail page
.store-locator-details {
  float:left;
  width: 24%; 
  margin: 0 3% 0 0;

  img {
    max-width:100%; 
    margin: 0 0 25px;
  }

  h4 {
    margin: 0 0 5px;
  }

  p {
    margin: 0 0 1.5em; 
    line-height: 19px;
  }

  a:hover {
    color: $mainbrand;
  }
}

.store-locator-details-map{
  float:right;
  width:73%;
}
//end store detail page


//flagship stores page
.pt_flagship-stores #main {
  max-width: 920px; 
  min-height: 760px;
}

.flagship-stores ul { 
  display: inline-table; 
  width: 100%;

  .flagship-stores ul li {
    display: inline-block; 
    padding: 12px; 
    width: 22%; 
    vertical-align: top;
  }

  .flagship-stores ul li .storeadress {
    line-height: 18px; 
    margin: 0;
  }
}
//end flagship stores page
